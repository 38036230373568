import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@hkm/components/Posting/Dashboard/domain/actions';
import { selectPostingDashboardFilters } from '@hkm/components/Posting/Dashboard/domain/selectors';
import { PostingFiltersData } from '@hkm/components/Posting/Dashboard/filters/postingFiltersData';
import {
  HkmValidator,
  ValidationStatuses,
} from '@hkm/shared/interfaces/validationStatuses';
import { getFieldErrorMessage } from '@hkm/shared/validation/errorProvider';
import { Validator } from '@hkm/shared/validation/validator';
import { isValidNumberRange } from '@hkm/shared/validation/validators';

import { AcFieldSearch } from '@ac/mobile-components/dist/components/field';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import {
  Form,
  formFieldFactory,
  ValidationSchema,
} from '@ac/react-infrastructure';

interface Statuses extends ValidationStatuses {
  roomRanges?: HkmValidator;
}

const Schema: ValidationSchema<Statuses> = {
  roomRanges: isValidNumberRange,
};

// tslint:disable-next-line:no-empty-interface
export type PostingDashboardSearchFilterProps = Childless;

const PostingDashboardSearchFilter: FC<
  PostingDashboardSearchFilterProps
> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const testSelectorPrefix = 'postingSearchFilter';

  const FormField = useMemo(() => formFieldFactory<PostingFiltersData>(), []);
  const validator = useMemo<Validator<PostingFiltersData, Statuses>>(
    () => new Validator(Schema),
    []
  );
  const initialFilters = useSelector(selectPostingDashboardFilters);

  const onSubmit = useCallback(
    (filters: PostingFiltersData) => {
      dispatch(actions.pagination.setFilters(filters));
    },
    [dispatch]
  );

  const validate = useCallback(
    (values: PostingFiltersData) => validator.validate(values),
    [validator]
  );

  return (
    <Form
      initialValues={initialFilters as PostingFiltersData}
      validate={validate as never}
      onSubmit={onSubmit}
    >
      {(formRenderProps) => (
        <FormField valuePath="roomRanges">
          {(fieldRenderProps) => (
            <AcFieldSearch
              {...fieldRenderProps.input}
              testSelector={testSelectorPrefix}
              validation={getFieldErrorMessage(fieldRenderProps)}
              placeholder={t('POSTING.DASHBOARD.SEARCH_PLACEHOLDER')}
              onClick={formRenderProps.handleSubmit}
              onBlur={formRenderProps.handleSubmit}
            />
          )}
        </FormField>
      )}
    </Form>
  );
};

export default memo(PostingDashboardSearchFilter);
