import { RoomState } from '@ac/library-api';
import { HousekeepingRoomsApi } from '@ac/library-api/dist/api/v0/housekeeping';

export async function getHousekeepingRoomVersionId(
  roomId: string
): Promise<number> {
  const room: RoomState = (await HousekeepingRoomsApi.getRoomState({
    pathParams: { roomId },
  })) as RoomState;

  return +room.version;
}
