import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { TasksHousekeepingRoom } from '@hkm/components/TaskManagement/models/housekeeping';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import OverflowEllipsis from '@hkm/components/TaskManagement/shared/components/OverflowEllipsis/OverflowEllipsis';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import Accordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Accordion/Accordion';
import AreaGrid from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/AreaGrid/AreaGrid';
import { Preferences } from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Preferences/Preferences';
import RoomReservationAccompanyingGuest from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/RoomReservationAccompanyingGuest/RoomReservationAccompanyingGuest';
import RoomSharedReservations from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/RoomSharedReservations/RoomSharedReservations';
import BadgeHelpers from '@hkm/components/TaskManagement/utils/badgeHelpers';
import { getProfileName } from '@hkm/components/TaskManagement/utils/getProfileName';
import dayjs, { ConfigType } from 'dayjs';

import {
  HousekeepingRoomReservationDetailsDto,
  TitleOrders,
} from '@ac/library-api';
import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import {
  AlignItems,
  FlexDirection,
  MobileColor,
} from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

const Room = () => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);
  const { attributes } = useSelector(getRelationsState);
  const room = linkedObject?.object as TasksHousekeepingRoom;
  const effectiveValues = useSelector(selectEffectiveValues);
  const nameSetting = effectiveValues?.titleOrder
    ? effectiveValues.titleOrder
    : TitleOrders.LastNameFirstNameTitle;

  const earliestReservation = useMemo(() => {
    return (room.reservations || [])
      .concat()
      .sort(
        (
          a: HousekeepingRoomReservationDetailsDto,
          b: HousekeepingRoomReservationDetailsDto
        ) =>
          dayjs(a.eta || a.arrivalDate).isAfter(
            b.eta || (b.arrivalDate as ConfigType)
          )
            ? 1
            : -1
      )
      .shift();
  }, [room.reservations]);
  const hasVipOrMembership = earliestReservation
    ? !!earliestReservation.memberships?.length ||
      !!earliestReservation.guest?.vipStatus?.code
    : false;

  const hasSharedReservations = !!room?.reservations?.filter(
    (reservation) => reservation.isRoomShared
  ).length;

  const preferences = useMemo(() => {
    const attributesCodes = (attributes || [])
      .filter(({ id: attributeId }) =>
        (room.attributeIds || []).find((id) => id === attributeId)
      )
      .map(({ code }) => code);

    return (
      <Preferences attributesCodes={attributesCodes} attributes={attributes} />
    );
  }, [room.attributeIds, attributes]);

  const guestName = useMemo(() => {
    return earliestReservation
      ? getProfileName({
          firstName: earliestReservation?.guest?.personalData?.name?.firstName,
          lastName: earliestReservation?.guest?.personalData?.name?.lastName,
          title:
            earliestReservation?.guest?.personalData?.name?.titleCode
              ?.description,
          nameSetting,
        })
      : '-';
  }, [earliestReservation, nameSetting]);

  const occupancy = useMemo(() => {
    return earliestReservation?.occupancyDetails
      ? `${earliestReservation.occupancyDetails[0].adultCount} / ${earliestReservation.occupancyDetails[0].childCount}`
      : '-';
  }, [earliestReservation]);

  const reservationStatus = useMemo(() => {
    const hasStatus = !!earliestReservation?.status?.code;

    if (hasStatus) {
      return (
        <ac-flex alignItems={AlignItems.center}>
          <AcBadge
            className="ac-spacing-right-sm"
            badgeText={earliestReservation?.status?.code}
            backgroundColor={BadgeHelpers.getReservationStatusColor(
              earliestReservation?.status?.code ?? MobileColor.Black
            )}
            testSelector="task-reservation-status"
          />
          <OverflowEllipsis
            text={earliestReservation?.status?.description ?? ''}
            testSelector="task-reservation-status-description"
          />
        </ac-flex>
      );
    }

    return '-';
  }, [earliestReservation?.status]);

  return (
    <ac-flex direction={FlexDirection.column}>
      <AcFormElement
        className="ac-spacing-top-sm ac-spacing-bottom-sm"
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.ROOM_NUMBER')}
        testSelector="task-room-number"
      >
        {room.code ? room.code : '-'}
      </AcFormElement>
      <Accordion
        header={
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.ROOM_STATUS')}
          >
            {room.roomStatus ? (
              <ac-flex alignItems={AlignItems.center}>
                <AcBadge
                  className="ac-spacing-right-sm"
                  badgeText={room.roomStatus.code}
                  backgroundColor={BadgeHelpers.getRoomStatusColor(
                    room.roomStatus?.code ?? MobileColor.Black
                  )}
                  testSelector="task-room-status"
                />
                <OverflowEllipsis
                  text={room.roomStatus?.description ?? ''}
                  testSelector="task-room-status-description"
                />
              </ac-flex>
            ) : (
              '-'
            )}
          </AcFormElement>
        }
        id={room.id}
      >
        <AcFormElement
          className="ac-spacing-bottom-sm"
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.PREFERENCES')}
        >
          {room.attributeIds.length ? preferences : '-'}
        </AcFormElement>
        <AreaGrid>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.GUEST')}
            testSelector="task-room-occupancy"
          >
            {occupancy}
          </AcFormElement>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.ROOM_TYPE')}
            testSelector="task-room-type"
          >
            {room.roomType?.code ?? '-'}
          </AcFormElement>
        </AreaGrid>
        <AcFormElement
          className="ac-spacing-bottom-sm"
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.RESERVATION_STATUS')}
          testSelector="task-room-guest"
        >
          {reservationStatus}
        </AcFormElement>
        <AcFormElement
          className="ac-spacing-bottom-sm"
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.IN_USE')}
          testSelector="task-room-in-use"
        >
          {guestName}
          {hasVipOrMembership && (
            <AcIndicator
              className="ac-spacing-left-sm"
              icon={IconName.vip}
              backgroundColor={MobileColor.Warning}
              testSelector="task-vip-and-membership-indicator"
            />
          )}
          {!!earliestReservation?.guest?.disabilityStatuses?.length && (
            <AcIndicator
              className="ac-spacing-left-sm"
              icon={IconName.disability}
              backgroundColor={MobileColor.Alert}
              testSelector="task-disability-indicator"
            />
          )}
        </AcFormElement>

        {hasVipOrMembership && (
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.VIP_AND_MEMBERSHIP'
            )}
          >
            {earliestReservation?.guest?.vipStatus && (
              <AcBadge
                className="ac-spacing-right-sm ac-spacing-bottom-sm"
                badgeText={earliestReservation?.guest?.vipStatus.code}
                backgroundColor={
                  earliestReservation?.guest?.vipStatus.color ||
                  MobileColor.Warning
                }
                testSelector="task-guest-vip"
              />
            )}
            {!!earliestReservation?.memberships?.length &&
              earliestReservation?.memberships.map((membership, index) => (
                <AcBadge
                  className="ac-spacing-right-sm ac-spacing-bottom-sm"
                  key={membership.id}
                  badgeText={membership.membershipType?.code}
                  backgroundColor={
                    membership.membershipLevel?.color || MobileColor.Alert
                  }
                  testSelector={`task-membership-${index}`}
                />
              ))}
          </AcFormElement>
        )}
        {earliestReservation &&
          !!earliestReservation?.guest?.disabilityStatuses?.length && (
            <AcFormElement
              className="ac-spacing-bottom-sm"
              label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.SPECIAL_NEEDS')}
            >
              {earliestReservation?.guest?.disabilityStatuses.map(
                (code, index) => (
                  <AcBadge
                    className="ac-spacing-right-sm ac-spacing-bottom-sm"
                    key={code.code}
                    badgeText={code.code}
                    backgroundColor={code.color || MobileColor.Warning}
                    testSelector={`task-room-disability-code-${index}`}
                  />
                )
              )}
            </AcFormElement>
          )}
        {hasSharedReservations &&
          room.reservations?.length &&
          room.reservations?.length > 1 && (
            <AcFormElement
              className="ac-spacing-bottom-sm"
              label={t(
                'TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.SHARES_RESERVATION'
              )}
            >
              <RoomSharedReservations
                roomReservations={room.reservations}
                earliestReservation={earliestReservation}
              />
            </AcFormElement>
          )}
        {!!earliestReservation?.accompanyingGuests?.length && (
          <AcFormElement
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.ACCOMPANYING_GUEST'
            )}
          >
            {earliestReservation.accompanyingGuests
              .splice(0, 5)
              .map((guest, index) => (
                <RoomReservationAccompanyingGuest
                  key={guest.id}
                  guest={guest}
                  index={index}
                />
              ))}
          </AcFormElement>
        )}
      </Accordion>
    </ac-flex>
  );
};

export default Room;
