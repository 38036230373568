import { getGroupRoomsByStatus } from '@hkm/components/Attendant/shared/utils/getGroupRoomsByStatus';
import { createAttendantRoom } from '@hkm/types/attendant/factories/createAttendantRoom';
import { AttendantSheet } from '@hkm/types/attendant/models/AttendantSheet';

import {
  AttendantAssignmentRoomDetails,
  AttendantAssignmentSheetDetails,
} from '@ac/library-api';

export function createAttendantSheet(
  attendantAssignmentSheet: AttendantAssignmentSheetDetails,
  businessDate: string,
  propertyCheckInTime: string,
  propertyCheckOutTime: string
): AttendantSheet {
  const attendantRooms = attendantAssignmentSheet.roomAssignments.map(
    (room: AttendantAssignmentRoomDetails) =>
      createAttendantRoom(
        room,
        businessDate,
        propertyCheckInTime,
        propertyCheckOutTime
      )
  );

  return {
    ...attendantAssignmentSheet,
    roomAssignments: attendantRooms,
    groupedRoomAssignments: getGroupRoomsByStatus(attendantRooms),
  } as AttendantSheet;
}
