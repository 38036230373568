import { DashboardSort } from '@hkm/components/TaskManagement/Dashboard/domain/enums';
import {
  Filters,
  TaskDashboardList,
} from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import { TaskQuickFilter } from '@hkm/components/TaskManagement/domain/enums/TaskQuickFilter';
import { Store } from '@hkm/store/interfaces/store';

import { PagingInfo } from '@ac/library-api';

export const getDashboardFilters = (state: Store): Filters =>
  state.tasks.dashboard.selectedFilters;
export const getDashboardPaging = (state: Store): PagingInfo | undefined =>
  state.tasks.dashboard.list.paging;
export const getDashboardSearchString = (state: Store): string | undefined =>
  state.tasks.dashboard.searchValue;
export const getDashboardSort = (state: Store): DashboardSort =>
  state.tasks.dashboard.sort;
export const getDashboardQuickFilter = (
  state: Store
): TaskQuickFilter | undefined => state.tasks.dashboard.quickFilterName;

export const getPropertyTimezone = (state: Store): string | undefined =>
  state.property.activeProperty?.timeZoneCode;
export const getPropertyShortDateFormat = (state: Store): string | undefined =>
  state.property.activeProperty?.shortDateFormat;

export const getIsLoading = (state: Store): boolean =>
  state.tasks.dashboard.isLoading;
export const getDashboardList = (state: Store): TaskDashboardList =>
  state.tasks.dashboard.list;
