import React, { FC, memo, useCallback, useMemo } from 'react';
import { QuickFilterItem } from '@hkm/components/shared/QuickFilter/QuickFilterItem';
import { disabledMenuSwipeClass } from '@hkm/features/app/panels/menu/behaviours/useSwipeEvent';
import classNames from 'classnames';

import {
  AcButton,
  AcButtonPattern,
  AcButtonShape,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import {
  Changeable,
  Childless,
  Styleable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { IconName } from '@ac/web-components';

import './QuickFilter.css';

interface QuickFilterProps extends Styleable, Changeable<string[]>, Childless {
  items: Array<QuickFilterItem<string>>;
  active?: string[];
  disabled?: boolean;
}

const QuickFilter: FC<QuickFilterProps> = (props: QuickFilterProps) => {
  const activeSet = useMemo(() => {
    return new Set(props.active || []);
  }, [props.active]);

  const className = classNames(
    'quick-filter',
    disabledMenuSwipeClass,
    props.className
  );

  const toggle = useCallback(
    (filter: string) => {
      activeSet.has(filter) ? activeSet.delete(filter) : activeSet.add(filter);
      if (props.onChange) {
        props.onChange(Array.from(activeSet));
      }
    },
    [activeSet, props]
  );

  return (
    <div className={className} style={props.style}>
      <ac-flex class="quick-filter-scroll">
        {props.items.map((item) => (
          <AcButton
            key={item.value}
            className="quick-filter-button ac-spacing-left-sm"
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => toggle(item.value)}
            pattern={
              activeSet.has(item.value)
                ? AcButtonPattern.LightSelected
                : AcButtonPattern.Light
            }
            disabled={props.disabled}
            testSelector={`quick-filter-${item.value}`}
            shape={AcButtonShape.Rectangle}
          >
            <AcButtonContent
              icon={activeSet.has(item.value) ? IconName.check : undefined}
              text={item.label}
            />
          </AcButton>
        ))}
      </ac-flex>
    </div>
  );
};

export default memo(QuickFilter);
