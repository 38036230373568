import { useCallback, useState } from 'react';
import { MicroFrontendError } from '@hkm/features/microFrontends/errors/MicroFrontendError';
import {
  getDefaultMicroFrontendsState,
  MicroFrontendsState,
} from '@hkm/features/microFrontends/store/state';
import { PageNavBar } from '@hkm/publicApi/windowObject';

export interface MicroFrontendsStore {
  state: MicroFrontendsState;
  setError(error: MicroFrontendError | undefined): void;
  setLoading(loading: boolean): void;
  setNavBar(navBar: PageNavBar): void;
}

export function useMicroFrontendsStore(): MicroFrontendsStore {
  const [state, setState] = useState(getDefaultMicroFrontendsState());

  const setError = useCallback((error: MicroFrontendError | undefined) => {
    setState((prevState) => ({
      ...prevState,
      error,
      loading: false,
    }));
  }, []);

  const setLoading = useCallback((loading: boolean) => {
    setState((prevState) => ({
      ...prevState,
      loading,
    }));
  }, []);

  const setNavBar = useCallback((navBar: PageNavBar) => {
    setState((prevState) => ({
      ...prevState,
      navBar,
    }));
  }, []);

  return {
    state,
    setError,
    setLoading,
    setNavBar,
  };
}
