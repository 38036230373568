import React, { CSSProperties, memo, ReactElement, useCallback } from 'react';

import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { AcBasicBadgeProps } from '@ac/mobile-components/dist/components/badge/AcBadge';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { MobileColor, TextSize } from '@ac/mobile-components/dist/enums';
import { Styleable } from '@ac/mobile-components/dist/interfaces/componentProps';
import {
  getBorderColorClass,
  getTestSelectorAttribute,
  isColorEnum,
} from '@ac/mobile-components/dist/utils';

import './BadgeWithCount.css';

interface RenderCountProps {
  borderClass?: MobileColor | string;
  borderColor?: MobileColor | string;
}
interface BadgeWithCountBaseProps {
  count?: number;
  renderCount?: (props: RenderCountProps) => ReactElement | null;
  testSelector?: string;
}

export type BadgeWithCountProps = AcBasicBadgeProps &
  BadgeWithCountBaseProps &
  Styleable;

const BadgeWithCount: React.FC<BadgeWithCountProps> = ({
  count,
  renderCount,
  className,
  testSelector,
  ...badgeProps
}) => {
  const classes = {
    container: 'badge-with-count',
    countContainer: 'badge-with-count-container',
  };

  const internalRenderCount = useCallback(() => {
    const borderColor = badgeProps.borderColor || badgeProps.backgroundColor;
    const isColorAnEnum = isColorEnum(borderColor);
    const renderCountProps: RenderCountProps = {
      borderClass: isColorAnEnum ? getBorderColorClass(borderColor) : undefined,
      borderColor: !isColorAnEnum ? borderColor : undefined,
    };

    const styles: CSSProperties = {
      borderColor: renderCountProps.borderColor,
    };

    badgeProps.borderColor = borderColor;

    if (count !== undefined && count !== null) {
      return (
        <div
          className={`${classes.countContainer} ${renderCountProps.borderClass}`}
          style={styles}
          {...getTestSelectorAttribute(testSelector)}
        >
          <AcText size={TextSize.Main2} testSelector={`${testSelector}-count`}>
            {count}
          </AcText>
        </div>
      );
    }

    return renderCount && renderCount(renderCountProps);
  }, [count, renderCount, classes.countContainer, testSelector, badgeProps]);

  return (
    <ac-flex class={`${className} ${classes.container}`}>
      <AcBadge {...badgeProps} />
      {internalRenderCount()}
    </ac-flex>
  );
};

export default memo(BadgeWithCount);
