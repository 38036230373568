import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';

import { CodeValue } from '@ac/library-api';
import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { MobileColor } from '@ac/mobile-components/dist/enums';

interface ComponentProps {
  disabilityCodes?: CodeValue[];
}

const SpecialNeeds = (props: ComponentProps) => {
  const { guestStatuses } = useSelector(getRelationsState);

  const disabilities = useMemo(
    () =>
      (props.disabilityCodes || []).map((disabilityCode) => {
        const disabilityCodeInStatuses = guestStatuses.find(
          ({ code }) => code === disabilityCode.code
        );

        return {
          id: disabilityCodeInStatuses?.id,
          text: disabilityCodeInStatuses?.code,
          color: disabilityCodeInStatuses?.color,
        };
      }),
    [props.disabilityCodes, guestStatuses]
  );

  return (
    <>
      {!!disabilities.length &&
        disabilities.map((disability, index) => (
          <AcBadge
            key={disability.id}
            className="ac-spacing-right-sm ac-spacing-bottom-sm"
            badgeText={disability.text}
            backgroundColor={disability.color || MobileColor.Alert}
            testSelector={`task-area-disability-${index}`}
          />
        ))}
    </>
  );
};

export default SpecialNeeds;
