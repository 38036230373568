import { RoomOccupancyActions } from '@hkm/shared/domain/occupancyChange/occupancyChangeActions';
import { GuestCount } from '@hkm/shared/interfaces/guestCount';

import { deleteAction, updateAction } from '@ac/library-utils/dist/redux-utils';

export function createRoomOccupancyActions(
  resourceNamePrefix: string
): RoomOccupancyActions {
  return {
    setRoomOccupancy: updateAction<GuestCount, string>(
      `${resourceNamePrefix}/setRoomOccupancy`
    ),
    removeRoomOccupancy: deleteAction(
      `${resourceNamePrefix}/removeRoomOccupancy`
    ),
  };
}
