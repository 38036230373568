import { isRegExp } from 'util';
import { ErrorParameterHandler } from '@hkm/shared/errors/errorHandlers';
import _, { PropertyPath } from 'lodash';

import { EmptyInterface } from '@ac/library-api';

type objectKey = string | number | symbol;

interface WithPickedKeysOptions {
  parameters: EmptyInterface;
  match: objectKey | [objectKey, PropertyPath] | RegExp;
}

export const withPickedKeys = (
  callback: (args: Record<string, string>) => void,
  options: WithPickedKeysOptions
) => {
  const { parameters, match } = options;
  if (isRegExp(match)) {
    return callback(_.pickBy(parameters, (value, key) => match.test(key)));
  }

  return callback(_.pick(parameters, match));
};

export const withKeysMappedToSuffix = (
  callback: (parameters: unknown) => Promise<string | undefined> | string,
  suffixMap: string[]
): ErrorParameterHandler => {
  return (parameters: EmptyInterface) => {
    const mappedParameters = _.mapKeys(parameters, (value, key) => {
      return (
        suffixMap.find((suffix) => {
          const regexp = new RegExp(`${suffix}$`, 'i');

          return regexp.test(key);
        }) || key
      );
    });

    return callback(mappedParameters) as string;
  };
};

export const withExtractedKeys = (
  callback: (statusCollection: string[]) => string,
  extractedKeys: string | string[]
) => {
  return (parameters: EmptyInterface) => {
    const camelCasedKeys = _.mapKeys(parameters, (value, key) =>
      _.camelCase(key)
    );
    if (!Array.isArray(extractedKeys)) {
      return callback(camelCasedKeys[extractedKeys]);
    }
    const pickedParameters = _.pick(parameters, extractedKeys) as string[];

    return callback(pickedParameters);
  };
};
