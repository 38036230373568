import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { EffectiveValues } from '@hkm/shared/effectiveValues/effectiveValues';
import { ExpectedEffectiveValues } from '@hkm/shared/effectiveValues/expectedEffectiveValues';

export enum ExpectedEffectiveValuesResult {
  UNKNOWN,
  POSITIVE,
  NEGATIVE,
}

export function useHasExpectedEffectiveValues(
  expectedEffectiveValues?: ExpectedEffectiveValues
): ExpectedEffectiveValuesResult {
  const effectiveValues = useSelector(selectEffectiveValues);

  return useMemo(() => {
    let result: boolean = true;
    if (expectedEffectiveValues) {
      if (!effectiveValues) {
        return ExpectedEffectiveValuesResult.UNKNOWN;
      }

      result = Array.from(expectedEffectiveValues.keys()).every(
        (effectiveValue: keyof EffectiveValues) => {
          // in case when we expect array from effectiveValues we just check if it's define or not
          return Array.isArray(expectedEffectiveValues.get(effectiveValue))
            ? Array.isArray(effectiveValues[effectiveValue])
            : expectedEffectiveValues.get(effectiveValue) ===
                effectiveValues[effectiveValue];
        }
      );
    }

    return result
      ? ExpectedEffectiveValuesResult.POSITIVE
      : ExpectedEffectiveValuesResult.NEGATIVE;
  }, [expectedEffectiveValues, effectiveValues]);
}
