import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TasksIndividualProfile } from '@hkm/components/TaskManagement/models/profiles';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import CommunicationChannels from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Profile/CommunicationChannels/CommunicationChannels';
import { getProfileAddress } from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Profile/helpers';
import Accordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Accordion/Accordion';
import { Preferences } from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Preferences/Preferences';

import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { FlexDirection, MobileColor } from '@ac/mobile-components/dist/enums';

const IndividualProfile = () => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);
  const { membershipLevels, attributes } = useSelector(getRelationsState);
  const profile = linkedObject?.object as TasksIndividualProfile;

  const profileAddress = useMemo(
    () => getProfileAddress(profile.addresses),
    [profile.addresses]
  );

  const preferences = useMemo(() => {
    const attributesCodes = (profile.preferences || []).map(
      ({ code }) => code ?? ''
    );

    return (
      <Preferences attributesCodes={attributesCodes} attributes={attributes} />
    );
  }, [profile.preferences, attributes]);

  const memberships = useMemo(
    () =>
      profile.memberships?.length
        ? profile.memberships.map(
            ({ number: membershipNumber, levelCode, typeCode }, index) => {
              const badgeColor =
                membershipLevels.length && levelCode
                  ? membershipLevels.find(
                      ({ code: membershipLevelCode }) =>
                        membershipLevelCode === levelCode.code
                    )?.color
                  : MobileColor.Black;

              return (
                <AcBadge
                  className="ac-spacing-right-sm ac-spacing-bottom-sm"
                  key={membershipNumber}
                  badgeText={typeCode?.description}
                  backgroundColor={badgeColor}
                  testSelector={`task-membership-${index}`}
                />
              );
            }
          )
        : undefined,
    [profile.memberships, membershipLevels]
  );

  return (
    <ac-flex direction={FlexDirection.column}>
      <AcFormElement
        className="ac-spacing-top-sm ac-spacing-bottom-sm"
        label={t(
          'TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.INDIVIDUAL_PROFILE'
        )}
        testSelector="task-profile-name"
      >
        {profile.displayName}
      </AcFormElement>
      <Accordion
        header={
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.PREFERENCES')}
          >
            {profile.preferences?.length ? preferences : '-'}
          </AcFormElement>
        }
        id={profile.id}
      >
        <CommunicationChannels />

        <AcFormElement
          className="ac-spacing-bottom-sm"
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.ADDRESS')}
          testSelector="task-profile-address"
        >
          {profileAddress ?? '-'}
        </AcFormElement>
        <AcFormElement
          className="ac-spacing-bottom-sm"
          label={t(
            'TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.VIP_AND_MEMBERSHIP'
          )}
        >
          {profile.details?.vipCode && (
            <AcBadge
              className="ac-spacing-right-sm ac-spacing-bottom-sm"
              badgeText={`${profile.details?.vipCode.code}`}
              backgroundColor={
                profile.details?.vipCode.color || MobileColor.Warning
              }
              testSelector="task-profile-vip"
            />
          )}
          {memberships}
          {!profile.details?.vipCode && !memberships && '-'}
        </AcFormElement>
      </Accordion>
    </ac-flex>
  );
};

export default IndividualProfile;
