/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { PropsWithChildren } from 'react';
import { MenuPanelPresenterContextProvider } from '@hkm/features/app/panels/menu/presenter/Provider';

import { createProvider, ProviderComposer } from '@ac/react-infrastructure';

const providers = [
  createProvider({ component: MenuPanelPresenterContextProvider }),
];

export const AppPanelsPresenterProviders = ({
  children,
}: PropsWithChildren): JSX.Element => {
  return <ProviderComposer providers={providers}>{children}</ProviderComposer>;
};
