/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { MenuPanelContainer } from '@hkm/features/app/panels/menu/features/Container';
import { useMenuPanelPresenterContext } from '@hkm/features/app/panels/menu/presenter/context';
import { MenuContextProvider } from '@hkm/features/app/panels/menu/store/Provider';
import classNames from 'classnames';

import './Panel.scss';

export const MenuPanel = (): JSX.Element | null => {
  const presenter = useMenuPanelPresenterContext();

  return (
    <div
      className={classNames('menu-container', {
        'menu-container--opened': presenter.state.isVisible,
        'menu-container--forced': !!presenter.state.inputData?.hideCloseButton,
      })}
    >
      <MenuContextProvider>
        <MenuPanelContainer />
      </MenuContextProvider>
    </div>
  );
};
