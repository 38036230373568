import { getCustomConfig } from '@hkm/utils/getCustomConfig';

import {
  ApiOperation,
  HousekeepingOperationExecutionDetails,
  LongRunningProcess,
  OperationStatus,
  RawHousekeepingOperationExecutionDetails,
  SetMaintenances,
} from '@ac/library-api';
import { HousekeepingRoomsApiV1 } from '@ac/library-api/dist/api/v1/housekeeping/index';

export class SetMaintenanceHousekeepingProcess extends LongRunningProcess<
  undefined,
  SetMaintenances
> {
  private static _statusResponseHandler(
    response: ApiOperation<
      RawHousekeepingOperationExecutionDetails,
      HousekeepingOperationExecutionDetails
    >
  ) {
    return (
      !!response.status &&
      response.status.operationStatus === OperationStatus.Completed
    );
  }

  constructor(payload: SetMaintenances) {
    super({
      action: HousekeepingRoomsApiV1.postSetMaintenances,
      pathParams: undefined,
      payload,
      customHeaders: getCustomConfig().headers,
      statusResponseHandler: (
        response: ApiOperation<
          RawHousekeepingOperationExecutionDetails,
          HousekeepingOperationExecutionDetails
        >
      ) => SetMaintenanceHousekeepingProcess._statusResponseHandler(response),
    });
  }
}
