import { MappedConfigurationUser } from '@hkm/components/TaskManagement/Dashboard/mappers';
import { SelectedObjectModel } from '@hkm/components/TaskManagement/shared/interfaces/selectedObjectModel';
import { UpdateTaskLinkedObjectParams } from '@hkm/components/TaskManagement/TaskPreview/domain/interfaces';

import { LinkedObjectInfo, RawLinkedObject, RawTask } from '@ac/library-api';
import {
  createAction,
  createAsyncAction,
  createInApiAction,
  getListAction,
} from '@ac/library-utils/dist/redux-utils';

export const loadTask = getListAction<string, RawTask>(
  '@taskManagement/loadTask'
);

export const clearCurrentTask = createAction(
  '@taskManagement/clearCurrentTask'
);

export const loadLinkedObject = createAsyncAction<
  LinkedObjectInfo | RawLinkedObject,
  SelectedObjectModel
>('@taskManagement/loadLinkedObject');

export const loadTaskRelatedUsers = createAsyncAction<
  RawTask,
  MappedConfigurationUser[]
>('@taskManagement/loadTaskRelatedUsers');

export const clearLinkedObject = createAction(
  '@taskManagement/clearLinkedObject'
);

export const updateTaskLinkedObject = createInApiAction<
  UpdateTaskLinkedObjectParams,
  undefined
>('@taskManagement/updateTaskLinkedObject');

export const toggleFollowObjectChanges = createAction(
  '@taskManagement/toggleFollowObjectChanges'
);
