import APP_CONSTANTS from '@hkm/constants/app.constants';
import { Dictionary } from '@hkm/shared/dictionaries/dictionary';

import {
  buildFIQLFilter,
  DictionaryEntity,
  FIQLOperators,
  MethodQueryParams,
  RoomStatus,
  SubsidiaryEntityQueryParams,
} from '@ac/library-api';
import {
  AttributeApi,
  CleanTypeApi,
  FloorApi,
  GenericTypeApi,
  HousekeepingAttendantApi,
  HousekeepingSectionApi,
  NoteTypeApi,
  OutOfOrderReasonApi,
  RoomCategoryApi,
  RoomClassApi,
  RoomLocationApi,
} from '@ac/library-api/dist/api/v0/configuration/genericEntities';
import {
  RoomApi,
  RoomTypeApi,
} from '@ac/library-api/dist/api/v0/configuration/handcraftedEntities';
import {
  FrontDeskStatusApi,
  HousekeepingStatusApi,
  RoomStatusApi,
  SupportedUiLanguageApi,
} from '@ac/library-api/dist/api/v0/configuration/systemDictionaries';

export interface DictionaryConfig {
  api: DictionaryApi | typeof GenericTypeApi;
  sorter?: (a: DictionaryEntity, b: DictionaryEntity) => number;
  neverChanges?: boolean;
  filter?: string;
  includes?: string;
  genericEntityConfig?: {
    isRateEntity: boolean;
  };
}

export interface CustomDictionaryConfig extends DictionaryConfig {
  dictionaryName: Dictionary;
}

export interface DictionaryApi {
  getList: (
    ...args: MethodQueryParams<
      undefined,
      SubsidiaryEntityQueryParams,
      undefined
    >
  ) => Promise<unknown>;
}

const RoomStateOrder: RoomStatus[] = [
  RoomStatus.CL,
  RoomStatus.DI,
  RoomStatus.IN,
  RoomStatus.PU,
];

function roomStatesSort(a: DictionaryEntity, b: DictionaryEntity): number {
  const aIndex = RoomStateOrder.indexOf(a.code as RoomStatus);
  const bIndex = RoomStateOrder.indexOf(b.code as RoomStatus);

  return aIndex - bIndex;
}

export const DictionariesConfig = new Map<
  Dictionary,
  DictionaryConfig | DictionaryApi
>()
  .set(Dictionary.Room, RoomApi)
  .set(Dictionary.RoomType, RoomTypeApi)
  .set(Dictionary.RoomCategory, RoomCategoryApi)
  .set(Dictionary.RoomClass, RoomClassApi)
  .set(Dictionary.Attribute, {
    api: AttributeApi,
    neverChanges: true,
    includes: 'ref_attributeGroupId',
  })
  .set(Dictionary.RoomLocation, RoomLocationApi)
  .set(Dictionary.Floor, FloorApi)
  .set(Dictionary.HousekeepingSection, HousekeepingSectionApi)
  .set(Dictionary.OutOfOrderReason, OutOfOrderReasonApi)
  .set(Dictionary.Room, RoomApi)
  .set(Dictionary.RoomCondition, {
    api: GenericTypeApi,
    neverChanges: true,
    genericEntityConfig: {
      isRateEntity: false,
    },
  })
  .set(Dictionary.HousekeepingAttendant, HousekeepingAttendantApi)
  .set(Dictionary.CleanType, CleanTypeApi)
  .set(Dictionary.NoteTypes, NoteTypeApi)
  .set(Dictionary.RoomStatus, {
    api: RoomStatusApi,
    neverChanges: true,
    sorter: roomStatesSort,
  })
  .set(Dictionary.HousekeepingStatus, {
    api: HousekeepingStatusApi,
    neverChanges: true,
  })
  .set(Dictionary.FrontDeskStatus, {
    api: FrontDeskStatusApi,
    neverChanges: true,
  })
  .set(Dictionary.NoteTypes, {
    api: NoteTypeApi,
    neverChanges: true,
    filter: buildFIQLFilter(
      'noteRoles',
      FIQLOperators.contains,
      APP_CONSTANTS.NOTE_ROLES
    ),
  })
  .set(Dictionary.SupportedUiLanguages, {
    getList: () => SupportedUiLanguageApi.getList(),
    neverChanges: true,
  });
