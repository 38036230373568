import React, { FC } from 'react';

import {
  AcAccordion,
  AcAccordionClampContent,
  AcAccordionHeader,
  AcAccordionItem,
} from '@ac/mobile-components/dist/components/accordion';

import './InnerAccordion.css';

interface ComponentProps {
  header: React.ReactNode;
  id: string;
  children: React.ReactNode;
}

const InnerAccordion: FC<ComponentProps> = (props: ComponentProps) => {
  return (
    <AcAccordion className="inner-accordion">
      <AcAccordionItem id={props.id}>
        <AcAccordionHeader>{props.header}</AcAccordionHeader>
        <AcAccordionClampContent>{props.children}</AcAccordionClampContent>
      </AcAccordionItem>
    </AcAccordion>
  );
};

export default InnerAccordion;
