import { SheetSummaryGuestServiceStatus } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryGuestServiceStatus';
import { EffectiveValues } from '@hkm/shared/effectiveValues/effectiveValues';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';

import { AttendantAssignmentRoomDetails } from '@ac/library-api';

export const createGuestServiceStatusFactory = (
  roomsAssignments: AttendantRoom[],
  effectiveValues: EffectiveValues | undefined
): SheetSummaryGuestServiceStatus => {
  const guestServiceStatusSummary: SheetSummaryGuestServiceStatus = {
    doNotDisturb: 0,
    serviceDeferred: 0,
    serviceRefused: 0,
    serviceRequested: 0,
    servicePreferred: 0,
    total: 0,
    plannedCount: 0,
    adHocCount: 0,
  };

  return effectiveValues?.guestServiceStatuses
    ? roomsAssignments.reduce(
        (
          guestServiceStatus: SheetSummaryGuestServiceStatus,
          room: AttendantAssignmentRoomDetails
        ) => {
          if (
            effectiveValues?.guestServiceStatusServiceRequested &&
            room.serviceRequested
          ) {
            guestServiceStatus.serviceRequested += 1;
            guestServiceStatus.total += 1;
            guestServiceStatus.adHocCount += 1;
          }

          if (
            effectiveValues?.guestServiceStatusDoNotDisturbed &&
            room.doNotDisturb
          ) {
            guestServiceStatus.doNotDisturb += 1;
            guestServiceStatus.total += 1;
            guestServiceStatus.plannedCount += 1;
          }

          if (
            effectiveValues?.guestServiceStatusServiceRefused &&
            room.serviceRefused
          ) {
            guestServiceStatus.serviceRefused += 1;
            guestServiceStatus.total += 1;
            guestServiceStatus.plannedCount += 1;
          }

          if (
            effectiveValues?.guestServiceStatusServiceDeferred &&
            room.serviceDeferred
          ) {
            guestServiceStatus.serviceDeferred += 1;
            guestServiceStatus.total += 1;
            guestServiceStatus.adHocCount += 1;
          }

          if (
            effectiveValues?.guestServiceStatusServicePreferred &&
            room.servicePreferred
          ) {
            guestServiceStatus.servicePreferred += 1;
            guestServiceStatus.total += 1;
            guestServiceStatus.plannedCount += 1;
          }

          return guestServiceStatus;
        },
        guestServiceStatusSummary
      )
    : guestServiceStatusSummary;
};
