import APP_CONSTANTS from '@hkm/constants/app.constants';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { getOriginForNewRegion } from '@ac/library-utils/dist/utils/multi-region';

interface Input {
  regionCode: string;
  propertyId?: string;
  customPathname?: string;
}

export class RegionUrlGenerator {
  public readonly origin: string;
  public readonly domain: string | undefined;
  public readonly pathname: string;
  public readonly search: string | undefined;
  public readonly href: string;

  constructor({ regionCode, propertyId, customPathname }: Input) {
    this.domain = this.getDomain(regionCode);
    this.origin = this.getRegionSpecificOrigin(regionCode, this.domain);
    this.pathname = this.getPathName(customPathname);
    this.search = this.getSearchQuery(propertyId);

    this.href = this.search
      ? `${this.origin}${this.pathname}?${this.search}`
      : `${this.origin}${this.pathname}`;
  }

  private getDomain(regionCode: string): string | undefined {
    return acConfig.regions.find(
      ({ code }) => code.toLowerCase() === regionCode.toLowerCase()
    )?.domain;
  }

  private getRegionSpecificOrigin(
    regionCode?: string,
    domain?: string
  ): string {
    return getOriginForNewRegion({
      regionCode: regionCode ?? '',
      domain: domain ?? '',
      applicationNameInUrl: APP_CONSTANTS.APPLICATION_NAME_IN_URL,
    });
  }

  private getPathName(customPathname?: string): string {
    return customPathname || window.location.pathname;
  }

  private getSearchQuery(propertyId?: string): string | undefined {
    const search = new URLSearchParams(window.location.search.substring(1));

    if (propertyId) {
      search.append(APP_CONSTANTS.NEW_PROPERTY_ID, propertyId);
    }

    return search.toString();
  }
}
