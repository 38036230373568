import { HousekeepingAdvancedFiltersDictionaries } from '@hkm/components/Housekeeping/shared/filters/housekeepingAdvancedFiltersDictionaries';
import { CustomDictionaryConfig } from '@hkm/shared/dictionaries/dictionariesConfig';
import { Dictionary } from '@hkm/shared/dictionaries/dictionary';

export const HousekeepingDashboardDictionaries: Set<
  Dictionary | CustomDictionaryConfig
> = new Set<Dictionary | CustomDictionaryConfig>(
  HousekeepingAdvancedFiltersDictionaries
)
  .add(Dictionary.RoomStatus)
  .add(Dictionary.Room)
  .add(Dictionary.Attribute);
