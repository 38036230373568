import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPostingReservationChargedState } from '@hkm/components/Posting/PostingView/domain/selectors';

import { HousekeepingReservationCharge } from '@ac/library-api/dist/types/entities/housekeeping/rooms/housekeepingReservationCharge';

export function useReservationChargedItems(): Map<string, number> {
  const postingReservationChargedItems = useSelector(
    selectPostingReservationChargedState
  );

  return useMemo(() => {
    const reservationChargedMap: Map<string, number> = new Map<
      string,
      number
    >();

    (postingReservationChargedItems.data?.charges || []).map(
      (charge: HousekeepingReservationCharge) => {
        const chargeItemQuantity = reservationChargedMap.has(
          charge.transactionCodeId
        )
          ? reservationChargedMap.get(charge.transactionCodeId) ?? 0
          : 0;

        reservationChargedMap.set(
          charge.transactionCodeId,
          chargeItemQuantity + charge.quantity
        );
      }
    );

    return reservationChargedMap;
  }, [postingReservationChargedItems.data]);
}
