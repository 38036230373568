import { TasksReservation } from '@hkm/components/TaskManagement/models/reservations/index';
import { getProfileName } from '@hkm/components/TaskManagement/utils/getProfileName';
import { trimString } from '@hkm/components/TaskManagement/utils/trimString';
import i18n from 'i18next';

import {
  PageResponseDto,
  QueryQueryParams,
  ReservationDashboardItem,
  TitleOrders,
} from '@ac/library-api';
import { ViewsApi } from '@ac/library-api/dist/api/v0/booking/reservations';

export const getReservationsDashboard = async (
  params?: QueryQueryParams
): Promise<TasksReservation[]> => {
  let queryParams = {};
  if (params) {
    const { filter, query, sort } = params;
    queryParams = filter ? { filter, ...queryParams } : { ...queryParams };
    queryParams = query ? { query, ...queryParams } : { ...queryParams };
    queryParams = sort ? { sort, ...queryParams } : { ...queryParams };
  }

  return (
    (await ViewsApi.getDashboard({
      queryParams,
    })) as PageResponseDto<ReservationDashboardItem>
  ).results.map((reservation: ReservationDashboardItem) =>
    mapReservation(reservation)
  );
};

function mapReservation(
  reservation: ReservationDashboardItem,
  nameSetting?: TitleOrders
): TasksReservation {
  const reservationGuestName = reservation.guestDetails
    ? getProfileName({
        firstName: reservation.guestDetails?.firstName,
        lastName: reservation.guestDetails?.lastName,
        title: reservation.guestDetails?.titleCode?.description,
        nameSetting,
      })
    : undefined;
  const guestName = reservationGuestName
    ? `${i18n.t(
        'TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.GUEST'
      )} ${reservationGuestName},`
    : '';
  const confirmationNumber = `${i18n.t(
    'TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.CONFIRMATION'
  )} ${reservation.confirmationNumber},`;
  const roomNumber = reservation.roomNumber
    ? `${i18n.t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.ROOM')} ${
        reservation.roomNumber.code
      },`
    : '';
  const stayDates = `${i18n.t(
    'TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.STAY_DATES'
  )} ${reservation.arrivalDate}/${reservation.departureDate},`;
  const resStatus = reservation.statusCode
    ? `${i18n.t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.STATUS')} ${
        reservation.statusCode?.code
      }`
    : '';

  return {
    ...reservation,
    displayName: trimString(
      `${confirmationNumber} ${guestName} ${roomNumber} ${stayDates} ${resStatus}`
    ),
    reservationGuestName,
  } as TasksReservation;
}
