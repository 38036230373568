import * as actions from '@hkm/components/shared/Templates/Maintenance/shared/domain/actions';
import { MaintenanceUpdateState } from '@hkm/shared/domain/maintenanceUpdate/models/maintenanceUpdateState';
import { combineActions, ReducerMap } from 'redux-actions';

import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: MaintenanceUpdateState = {
  isPending: false,
  version: 0,
};

const reducerMap: ReducerMap<MaintenanceUpdateState, unknown> = {
  [actions.notifyMaintenanceUpdate]: (state) => ({
    ...state,
    version: state.version + 1,
  }),
  [combineActions(
    actions.updateAttendantMaintenanceActionsSet.updateMaintenance.trigger,
    actions.updateMaintenanceActionsSet.updateMaintenance.trigger
  ) as never]: (state) => ({
    ...state,
    isPending: true,
  }),
  [combineActions(
    actions.updateMaintenanceActionsSet.updateMaintenance.failure,
    actions.updateMaintenanceActionsSet.updateMaintenance.success,
    actions.updateAttendantMaintenanceActionsSet.updateMaintenance.failure,
    actions.updateAttendantMaintenanceActionsSet.updateMaintenance.success
  ) as never]: (state) => ({
    ...state,
    isPending: false,
  }),
};

export default handleActions(reducerMap, initialState);
