import { ImageResolutionName } from '@hkm/components/App/imageQueue/enum/imageResolutionName';

export interface ImageResolutionConfig {
  limit: number;
  width?: number;
  height?: number;
  resolutionName: ImageResolutionName;
}

interface ImageResolutionConfigList {
  [ImageResolutionName.Resolution_38x38]: ImageResolutionConfig;
  [ImageResolutionName.Resolution_400x]: ImageResolutionConfig;
}

class ImageResolution {
  public static getImageResolutionConfig(
    imageResolutionType: ImageResolutionName
  ): ImageResolutionConfig {
    return ImageResolution.imageResolutionConfigList[imageResolutionType];
  }

  private static imageResolutionConfigList: ImageResolutionConfigList = {
    [ImageResolutionName.Resolution_38x38]: {
      limit: 20,
      height: 38,
      width: 38,
      resolutionName: ImageResolutionName.Resolution_38x38,
    },
    [ImageResolutionName.Resolution_400x]: {
      limit: 5,
      width: 400,
      resolutionName: ImageResolutionName.Resolution_400x,
    },
  };
}

export { ImageResolution };
