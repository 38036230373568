import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskStatusDictionary } from '@hkm/components/TaskManagement/relations/domain/interfaces';
import { Permission } from '@hkm/shared/permissions/enum/Permission';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';
import { getLuhnAlghorithmValidationObject } from '@hkm/utils/getLuhnAlghorithmValidationObject';

import { TaskStatusRole } from '@ac/library-api';
import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcDivider } from '@ac/mobile-components/dist/components/divider';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader,
  AcModalPattern,
} from '@ac/mobile-components/dist/components/modal';
import { AcRadioButton } from '@ac/mobile-components/dist/components/radio-button';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTextarea } from '@ac/mobile-components/dist/components/textarea';
import {
  AlignItems,
  FlexDirection,
  JustifyContent,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import { Color, IconName, Size } from '@ac/web-components';

import './../TaskModals.css';

export interface ComponentProps {
  statuses: TaskStatusDictionary[];
  isVisible: boolean;
  currentStatusId?: string;
  onChange: (statusId: string, comment?: string) => void;
  onClose: () => void;
}

const TaskChangeStatusModal: FC<ComponentProps> = (props: ComponentProps) => {
  const { t } = useTranslation();
  const hasPermissionToCancelTask = usePermission(Permission.TaskCancel);
  const [checkedStatus, setCheckedStatus] = useState<
    TaskStatusDictionary | undefined
  >();
  const [comment, setComment] = useState<string | undefined>();
  const [commentValidation, setCommentValidation] = useState<
    string | undefined
  >();

  const clearModal = useCallback(() => {
    setCommentValidation(undefined);
    setComment(undefined);
    setCheckedStatus(undefined);
  }, []);

  const closeModal = useCallback(() => {
    props.onClose();
    clearModal();
  }, [clearModal, props]);

  const onCommentChange = useCallback(
    (value?: string) => {
      setComment(value);
      value
        ? setCommentValidation(undefined)
        : setCommentValidation(t('VALIDATORS.IS_REQUIRED'));
    },
    [t]
  );

  const changeStatus = useCallback(() => {
    if (!checkedStatus) {
      return;
    }
    if (checkedStatus.isCommentMandatory && !comment) {
      return setCommentValidation(t('VALIDATORS.IS_REQUIRED'));
    }
    props.onChange(checkedStatus.id, comment);
    clearModal();
  }, [checkedStatus, clearModal, comment, props, t]);

  const availableStatuses = useMemo(() => {
    const activeStatuses = props.statuses.filter(({ isActive }) => isActive);
    const statusesWithCurrentExcluded = props.currentStatusId
      ? activeStatuses.filter(({ id }) => id !== props.currentStatusId)
      : activeStatuses;
    if (hasPermissionToCancelTask) {
      return statusesWithCurrentExcluded;
    }

    return statusesWithCurrentExcluded.filter(
      ({ role }) => role !== TaskStatusRole.Cancellation
    );
  }, [hasPermissionToCancelTask, props.currentStatusId, props.statuses]);

  return (
    <AcModal
      className="task-modal"
      isOpen={props.isVisible}
      onClose={closeModal}
      pattern={AcModalPattern.Drawer}
    >
      <AcModalHeader showCloseButton={true} className="task-modal-header">
        {t('TASK_MANAGEMENT.DASHBOARD.OPTIONS.CHANGE_STATUS')}
      </AcModalHeader>
      <AcModalBody>
        {(availableStatuses || []).map((status) => {
          const setSelectedStatus = () => {
            setCheckedStatus(status);
            setComment(undefined);
            setCommentValidation(undefined);
          };

          return (
            <ac-flex
              key={status.id}
              class="full-width"
              direction={FlexDirection.column}
              data-test-selector={`task-status-${status.code}`}
              grow={true}
            >
              <ac-flex
                class="full-width"
                alignItems={AlignItems.center}
                onClick={setSelectedStatus}
              >
                <AcRadioButton checked={checkedStatus?.id === status.id} />
                <AcBadge
                  fit
                  badgeText={status.code}
                  backgroundColor={status.color || '000'}
                />
                <AcText className="ac-spacing-left-sm">
                  {status.displayName}
                </AcText>
              </ac-flex>
              <AcDivider />
            </ac-flex>
          );
        })}
      </AcModalBody>
      <AcModalFooter className="task-modal-footer">
        <ac-flex
          direction={FlexDirection.column}
          grow={true}
          justifyContent={JustifyContent.center}
          alignItems={AlignItems.center}
        >
          {checkedStatus?.isCommentMandatory && (
            <ac-flex
              class="full-width ac-spacing-top-xs ac-spacing-bottom-md"
              direction={FlexDirection.column}
              grow={true}
            >
              <AcTextarea
                className="comment-field"
                required={true}
                value={comment}
                placeholder={t('GLOBAL.FILL')}
                label={t('TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.COMMENT')}
                maxlength={200}
                validation={commentValidation}
                onChange={onCommentChange}
              />
              {getLuhnAlghorithmValidationObject(comment) && (
                <>
                  <AcText size={TextSize.Main3} className="ac-spacing-left-lg">
                    <ac-icon
                      class="ac-spacing-right-sm"
                      icon={IconName.information}
                      color={Color.informational}
                      size={Size.xs}
                    />
                    {getLuhnAlghorithmValidationObject(comment)}
                  </AcText>
                </>
              )}
              <AcText size={TextSize.Main3} className="ac-spacing-left-lg">
                {t('GLOBAL.REQUIRED_FIELDS')}
              </AcText>
            </ac-flex>
          )}
          <ac-flex
            class="full-width footer-buttons"
            direction={FlexDirection.column}
            grow={true}
          >
            <AcButton
              className="full-width"
              testSelector="change"
              onClick={changeStatus}
            >
              <AcButtonContent text={t('GLOBAL.CHANGE')} />
            </AcButton>
            <AcButton
              pattern={AcButtonPattern.Tertiary}
              className="full-width"
              testSelector="cancel"
              onClick={closeModal}
            >
              <AcButtonContent
                icon={IconName.cancel}
                text={t('GLOBAL.CANCEL')}
              />
            </AcButton>
          </ac-flex>
        </ac-flex>
      </AcModalFooter>
    </AcModal>
  );
};

export default memo(TaskChangeStatusModal);
