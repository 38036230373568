import React from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from '@hkm/shared/helpers/noop';

import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  FlexDirection,
  JustifyContent,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import { Color, IconName, Size } from '@ac/web-components';

import './AlertBarContent.scss';

interface Props {
  queueRoomsCount: number;
  serviceRequestedRoomsCount: number;
  showLink?: boolean;
  onShow?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const MultiElementAlertBar: React.FC<Props> = ({
  queueRoomsCount,
  serviceRequestedRoomsCount,
  showLink,
  onShow = noop,
}) => {
  const { t } = useTranslation();

  return (
    <ac-flex
      class="alert-bar-content"
      justifyContent={JustifyContent.spaceBetween}
    >
      <ac-flex>
        <AcText size={TextSize.Main2}>
          <ac-icon
            class="ac-spacing-right-md"
            icon={IconName.todo}
            color={Color.warning}
            size={Size.md}
          />
        </AcText>
        <ac-flex
          justifyContent={JustifyContent.flexStart}
          direction={FlexDirection.column}
        >
          <AcText size={TextSize.Main2}>
            {t('ATTENDANT_ASSIGNMENTS.SHARED.ALERT_BAR.QUEUE_ROOMS', {
              count: queueRoomsCount,
            })}
          </AcText>

          <AcText size={TextSize.Main2}>
            {t('ATTENDANT_ASSIGNMENTS.SHARED.ALERT_BAR.SERVICES_REQUESTED', {
              count: serviceRequestedRoomsCount,
            })}
          </AcText>
        </ac-flex>
      </ac-flex>

      {showLink && (
        <a href="#" onClick={onShow} className="link">
          <AcText size={TextSize.Main2}>{t('GLOBAL.SHOW')}</AcText>
        </a>
      )}
    </ac-flex>
  );
};
