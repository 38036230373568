import { createGuestPreferencesFactory } from '@hkm/components/Attendant/SheetSummary/models/factories/guestPreferencesFactory';
import { createGuestServiceStatusFactory } from '@hkm/components/Attendant/SheetSummary/models/factories/guestServiceFactory';
import { createHousekeepingTasksSummaryFactory } from '@hkm/components/Attendant/SheetSummary/models/factories/housekeepingTasksSummaryFactory';
import { createInstructionsFactory } from '@hkm/components/Attendant/SheetSummary/models/factories/instructionsFactory';
import { createLinenChangeFactory } from '@hkm/components/Attendant/SheetSummary/models/factories/linenChangeFactory';
import { createMembershipsFactory } from '@hkm/components/Attendant/SheetSummary/models/factories/membershipsFactory';
import { createSpecialNeedsFactory } from '@hkm/components/Attendant/SheetSummary/models/factories/specialNeedsFactory';
import { createVipsFactory } from '@hkm/components/Attendant/SheetSummary/models/factories/vipsFactory';
import { SheetsSummaryData } from '@hkm/components/Attendant/SheetSummary/models/SheetsSummaryData';
import { EffectiveValues } from '@hkm/shared/effectiveValues/effectiveValues';
import { AttendantSheet } from '@hkm/types/attendant/models/AttendantSheet';

import { AttendantTaskProgressType } from '@ac/library-api';

export function createSheetSummaryDataFactory(
  attendantAssignment: AttendantSheet,
  effectiveValues: EffectiveValues | undefined
): SheetsSummaryData {
  // all stats based only on not completed tasks
  const notCompletedRooms = attendantAssignment.roomAssignments.filter(
    (room) =>
      room.activeTask &&
      room.activeTask.progress?.code !== AttendantTaskProgressType.Completed
  );

  return {
    attendantName: attendantAssignment.attendant?.description || '',
    memberships: createMembershipsFactory(notCompletedRooms),
    vips: createVipsFactory(notCompletedRooms),
    guestPreferences: createGuestPreferencesFactory(notCompletedRooms),
    specialNeeds: createSpecialNeedsFactory(notCompletedRooms),
    housekeepingTasks: createHousekeepingTasksSummaryFactory(
      notCompletedRooms,
      effectiveValues
    ),
    instructions: createInstructionsFactory(attendantAssignment),
    guestService: createGuestServiceStatusFactory(
      notCompletedRooms,
      effectiveValues
    ),
    linenChange: createLinenChangeFactory(notCompletedRooms),
  };
}
