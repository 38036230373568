import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import {
  extractName,
  GuestPersonalDataNameWithOptionalTitle,
} from '@hkm/shared/helpers/extractName';

import { TitleOrders } from '@ac/library-api';

export function useExtractGuestFullName(
  guestPersonalDataName: GuestPersonalDataNameWithOptionalTitle
): string {
  const effectiveValues = useSelector(selectEffectiveValues);

  return useMemo(() => {
    const titleOrder = effectiveValues?.titleOrder
      ? effectiveValues?.titleOrder
      : TitleOrders.LastNameFirstNameTitle;

    return extractName(guestPersonalDataName, titleOrder);
  }, [effectiveValues, guestPersonalDataName]);
}
