import { isReservationInActiveStatus } from '@hkm/shared/reservations/isReservationInActiveStatus';
import { ReservedKindProps } from '@hkm/shared/reservedKind/getReservedKind';
import { ReservedKind } from '@hkm/shared/reservedKind/reservedKind';

export function getPostingReservedKind({
  reservation,
  maintenance,
}: ReservedKindProps): ReservedKind {
  if (isReservationInActiveStatus(reservation?.status)) {
    return ReservedKind.Reservation;
  }

  if (maintenance) {
    return ReservedKind.Maintenance;
  }

  return ReservedKind.None;
}
