import React from 'react';
import { useTranslation } from 'react-i18next';

import flagIcons from './icons';

import './LanguageFlag.scss';

interface Props {
  languageCode?: string;
}

export const Flag: React.FC<Props> = ({ languageCode }) => {
  const { i18n } = useTranslation();
  const activeFlag = flagIcons[languageCode || i18n.language];

  return (
    <div className="language-flag-wrapper">
      <img src={activeFlag} alt={activeFlag} className="language-flag-icon" />
    </div>
  );
};
