export const TaskManagementRequiredDictionaries: Set<string> = new Set<string>()
  .add('taskStatuses')
  .add('taskPriorities')
  .add('locations')
  .add('departments')
  .add('responsibleDepartments')
  .add('areas')
  .add('membershipLevels')
  .add('groupStatuses')
  .add('taskTypes')
  .add('taskTags');
