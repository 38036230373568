import { useCallback, useState } from 'react';
import {
  AppState,
  getDefaultAppState,
} from '@hkm/features/app/store/app/state';

import { UnitAssignmentListItem } from '@ac/library-api';
import {
  BusinessContextSource,
  ShijiEnterprisePlatformFeatureConfigBuilder,
} from '@ac/react-infrastructure';

export interface AppStore {
  state: AppState;
  setUp(properties: UnitAssignmentListItem[] | undefined): void;
}

export function useAppStore(): AppStore {
  const [state, setState] = useState(getDefaultAppState());

  const setUp = useCallback(
    (properties: UnitAssignmentListItem[] | undefined) => {
      const builder = new ShijiEnterprisePlatformFeatureConfigBuilder();

      setState((prevState) => ({
        ...prevState,
        properties: properties ?? [],
        initialized: true,
        platformConfiguration: builder
          .withCommunication()
          .withMetricsTracker()
          .withUserBusinessContext()
          .withPropertyWebsockets()
          .withPropertyBusinessContext({
            source: BusinessContextSource.Global,
          })
          .withTenantBusinessContext({
            source: BusinessContextSource.Global,
          })
          .build(),
      }));
    },
    []
  );

  return {
    state,
    setUp,
  };
}
