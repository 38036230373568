import { MicroFrontendError } from '@hkm/features/microFrontends/errors/MicroFrontendError';
import i18n from 'i18next';

export class UnableToLoadError extends MicroFrontendError {
  constructor() {
    super(
      i18n.t('MICRO_FRONTENDS.ERRORS.UNABLE_TO_LOAD.MESSAGE'),
      i18n.t('MICRO_FRONTENDS.ERRORS.UNABLE_TO_LOAD.DESCRIPTION')
    );
  }
}
