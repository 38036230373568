import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import IncognitoBadge from '@hkm/components/shared/IncognitoBadge/IncognitoBadge';
import GuestDisabilitiesTemplate from '@hkm/components/shared/Templates/Guest/Disabilities/GuestDisabilitiesTemplate';
import ConnectGuestAvatarTemplate from '@hkm/components/shared/Templates/Guest/GuestAvatar/ConnectGuestAvatarTemplate';
import GuestNameTemplate from '@hkm/components/shared/Templates/Guest/GuestNameTemplate';
import VipOrMemberTemplate from '@hkm/components/shared/Templates/Guest/VipOrMember/VipOrMemberTemplate';
import {
  getVipBadge,
  hasReservationDisabilities,
} from '@hkm/components/shared/Templates/Reservation/utils/reservationUtils';
import { VipOrMemberBadge } from '@hkm/shared/interfaces/vipOrMemberBadge';
import { UnifiedReservationGuestDetails } from '@hkm/types/reservation/models/UnifiedReservationGuestDetails';
import classNames from 'classnames';

import {
  AcAccordion,
  AcAccordionContent,
  AcAccordionHeader,
  AcAccordionItem,
  AcAccordionsElementProps,
} from '@ac/mobile-components/dist/components/accordion';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  FlexDirection,
  MobileColor,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { Styleable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { IconName, Size } from '@ac/web-components';

import './AccompanyingGuestsDetailsTemplate.css';

export interface AccompanyingGuestsDetailsTemplateProps extends Styleable {
  disableModals?: boolean;
  showGuestNames?: boolean;
  accompanyingGuests: UnifiedReservationGuestDetails[];
}

const AccompanyingGuestsDetailsTemplate: FC<
  AccompanyingGuestsDetailsTemplateProps
> = ({
  showGuestNames = true,
  ...props
}: AccompanyingGuestsDetailsTemplateProps) => {
  const { t } = useTranslation();
  const testSelectorPrefix = 'accompanying-guests';
  const effectiveValues = useSelector(selectEffectiveValues);
  const classes = classNames(
    'housekeeping-details-accompanying-guests',
    props.className
  );

  return (
    <div className={classes} style={props.style}>
      <div className="accompany-header">
        <ac-icon
          icon={IconName.accompanying}
          size={Size.sm}
          class="accompany-guest-icon"
        />
        <AcText color={MobileColor.Gray1} size={TextSize.Main2}>
          {t('ROOM_DETAILS.ACCOMPANYING_GUESTS')}
        </AcText>
      </div>
      <div className="accompany-content">
        <AcAccordion className="accordion-accompanying-guests">
          {props.accompanyingGuests.map(
            (
              accompanyingGuest: UnifiedReservationGuestDetails,
              index: number
            ) => {
              const guestPersonalData = showGuestNames
                ? accompanyingGuest.personalData
                : undefined;
              const hasDisabilities: boolean =
                hasReservationDisabilities(accompanyingGuest);
              const vipBadges: VipOrMemberBadge | undefined = getVipBadge(
                accompanyingGuest.vipCode
              );
              const hasAdditionalData: boolean = hasDisabilities || !!vipBadges;
              const vipBadgeColor = vipBadges ? vipBadges.color : undefined;

              return (
                <AcAccordionItem
                  id={accompanyingGuest.id}
                  key={accompanyingGuest.id}
                  disabled={!hasAdditionalData}
                  render={(accordionProps: AcAccordionsElementProps) => (
                    <>
                      <IncognitoBadge
                        testSelector={testSelectorPrefix}
                        details={
                          accompanyingGuest.personalData?.incognitoDetails
                        }
                        className="ac-spacing-bottom-sm"
                        disableModal={props.disableModals}
                      />

                      <ac-flex class="ac-gap-sm" alignItems={AlignItems.center}>
                        {showGuestNames &&
                          effectiveValues?.displayGuestAvatar && (
                            <ConnectGuestAvatarTemplate
                              guest={accompanyingGuest}
                            />
                          )}
                        <ac-box grow={true}>
                          <AcAccordionHeader
                            {...accordionProps}
                            testSelector={`${testSelectorPrefix}-${index}-accordionHeader`}
                          >
                            <AcFormElement
                              label={t('ROOM_DETAILS.GUEST')}
                              testSelector={formatTestSelector(
                                testSelectorPrefix,
                                'guest'
                              )}
                            >
                              <GuestNameTemplate
                                vipBadgeColor={vipBadgeColor}
                                guestPersonalData={guestPersonalData}
                                hasDisabilities={hasDisabilities}
                                customGuestName={t(
                                  'ROOM_DETAILS.ACCOMPANY_GUEST_CUSTOM_NAME',
                                  {
                                    number: index + 1,
                                  }
                                )}
                              />
                            </AcFormElement>
                          </AcAccordionHeader>
                        </ac-box>
                      </ac-flex>

                      <AcAccordionContent>
                        {hasDisabilities && (
                          <ac-flex direction={FlexDirection.column}>
                            <AcText
                              color={MobileColor.Gray1}
                              size={TextSize.Main2}
                              weight={TextWeight.Semibold}
                            >
                              {t('ROOM_DETAILS.DISABILITIES')}
                            </AcText>
                            <AcText
                              color={MobileColor.Black}
                              size={TextSize.Main1}
                            >
                              <GuestDisabilitiesTemplate
                                disableModal={props.disableModals}
                                disabilities={
                                  accompanyingGuest.disabilityStatusCodes
                                }
                                testSelector={`${testSelectorPrefix}-${index}-specialNeeds`}
                              />
                            </AcText>
                          </ac-flex>
                        )}

                        {!!vipBadges && (
                          <ac-flex
                            direction={FlexDirection.column}
                            class="ac-spacing-top-md"
                          >
                            <AcText
                              color={MobileColor.Gray1}
                              size={TextSize.Main2}
                              weight={TextWeight.Semibold}
                            >
                              {t('ROOM_DETAILS.VIP_OR_MEMBER')}
                            </AcText>
                            <VipOrMemberTemplate
                              disableModal={props.disableModals}
                              vipOrMemberBadges={[vipBadges]}
                              testSelector={`${testSelectorPrefix}-${index}-vip`}
                            />
                          </ac-flex>
                        )}
                      </AcAccordionContent>
                    </>
                  )}
                />
              );
            }
          )}
        </AcAccordion>
      </div>
    </div>
  );
};

export default memo(AccompanyingGuestsDetailsTemplate);
