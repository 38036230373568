import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { getGuestCountFromUnifiedReservationDetails } from '@hkm/components/Housekeeping/shared/helpers/getGuestCountFromUnifiedReservationDetails';
import DatetimeFormElement, {
  DatetimeVariant,
} from '@hkm/components/shared/DatetimeFormElement/DatetimeFormElement';
import ReservationStatusBadge from '@hkm/components/shared/ReservationStatusBadge/ReservationStatusBadge';
import GuestDisabilitiesTemplate from '@hkm/components/shared/Templates/Guest/Disabilities/GuestDisabilitiesTemplate';
import GuestNumbersTemplate from '@hkm/components/shared/Templates/Guest/GuestNumbersTemplate';
import GuestPreferencesTemplate from '@hkm/components/shared/Templates/Guest/Preferences/GuestPreferencesTemplate';
import VipOrMemberTemplate from '@hkm/components/shared/Templates/Guest/VipOrMember/VipOrMemberTemplate';
import {
  getVipAndMemberBadges,
  hasReservationDisabilities,
  hasReservationPreferences,
} from '@hkm/components/shared/Templates/Reservation/utils/reservationUtils';
import { VipOrMemberBadge } from '@hkm/shared/interfaces/vipOrMemberBadge';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

import { ReservationStatus } from '@ac/library-api';
import {
  AcFormElement,
  AcFormGroup,
  AcFormRow,
} from '@ac/mobile-components/dist/components/form-element';
import {
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

export interface PostingReservationDetailsProps extends Styleable, Testable {
  reservation: UnifiedReservationDetails;
}

const PostingReservationDetails: FC<PostingReservationDetailsProps> = (
  props: PostingReservationDetailsProps
) => {
  const { t } = useTranslation();

  const guest = props.reservation.guest;
  const hasPreferences: boolean = hasReservationPreferences(props.reservation);
  const hasDisabilities: boolean = hasReservationDisabilities(guest);
  const vipOrMemberBadges: VipOrMemberBadge[] = getVipAndMemberBadges(
    props.reservation
  );

  return (
    <AcFormGroup>
      {hasDisabilities && (
        <AcFormElement
          label={t('ROOM_DETAILS.DISABILITIES')}
          testSelector={formatTestSelector(props.testSelector, 'disabilities')}
        >
          <GuestDisabilitiesTemplate
            disabilities={guest?.disabilityStatusCodes}
            testSelector={formatTestSelector(
              props.testSelector,
              'disabilitiesValue'
            )}
          />
        </AcFormElement>
      )}

      {!!vipOrMemberBadges.length && (
        <AcFormElement
          label={t('ROOM_DETAILS.VIP_OR_MEMBER')}
          testSelector={formatTestSelector(props.testSelector, 'vip')}
        >
          <VipOrMemberTemplate
            vipOrMemberBadges={vipOrMemberBadges}
            testSelector={formatTestSelector(props.testSelector, 'vipValue')}
          />
        </AcFormElement>
      )}

      {hasPreferences && (
        <AcFormElement
          label={t('ROOM_DETAILS.PREFERENCES')}
          testSelector={formatTestSelector(props.testSelector, 'preferences')}
        >
          <GuestPreferencesTemplate
            preferences={props.reservation.preferences}
            testSelector={formatTestSelector(
              props.testSelector,
              'preferencesValue'
            )}
          />
        </AcFormElement>
      )}

      {(props.reservation.eta || props.reservation.etd) && (
        <AcFormRow className="ac-spacing-top-s">
          {props.reservation.eta && (
            <DatetimeFormElement
              datetime={props.reservation.eta}
              label={t('ROOM_DETAILS.ARRIVAL_DATE')}
              variant={DatetimeVariant.onlyDate}
            />
          )}
          {props.reservation.etd && (
            <DatetimeFormElement
              datetime={props.reservation.etd}
              label={t('ROOM_DETAILS.DEPARTURE_DATE')}
              variant={DatetimeVariant.onlyDate}
            />
          )}
        </AcFormRow>
      )}

      <AcFormElement
        label={t('ROOM_DETAILS.GUEST_COUNT')}
        testSelector={formatTestSelector(props.testSelector, 'guestCount')}
      >
        <GuestNumbersTemplate
          count={getGuestCountFromUnifiedReservationDetails(props.reservation)}
        />
      </AcFormElement>

      {props.reservation.status && (
        <AcFormElement
          label={t('GLOBAL.RESERVATION_STATUS.TITLE.LONG')}
          testSelector={formatTestSelector(
            props.testSelector,
            'reservationStatus'
          )}
        >
          <ReservationStatusBadge
            status={props.reservation.status.code as ReservationStatus}
            showLabel={true}
          />
        </AcFormElement>
      )}
    </AcFormGroup>
  );
};

export default memo(PostingReservationDetails);
