import React, { FC, memo, useCallback } from 'react';
import { useMenuPanelPresenterContext } from '@hkm/features/app/panels/menu/presenter/context';
import * as H from 'history';

import { AcIconButton } from '@ac/mobile-components/dist/components/icon-button';
import { AcWrappedLink } from '@ac/mobile-components/dist/components/wrapped-link';
import {
  Childless,
  Clickable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { IconName } from '@ac/web-components';

interface Props extends Childless, Clickable {
  link?: H.Location | string;
}

const HeaderCloseButton: FC<Props> = ({ onClick, link }) => {
  const { hide } = useMenuPanelPresenterContext();

  const onButtonClick = useCallback(() => {
    void hide();
    if (onClick) {
      onClick();
    }
  }, [hide, onClick]);

  return (
    <AcWrappedLink link={link}>
      <AcIconButton icon={IconName.cancel} onClick={onButtonClick} />
    </AcWrappedLink>
  );
};

export default memo(HeaderCloseButton);
