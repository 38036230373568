/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { PropsWithChildren } from 'react';
import { MobileFeaturesContext } from '@hkm/features/app/store/features/context';
import { useMobileFeaturesStore } from '@hkm/features/app/store/features/store';

export function MobileFeaturesContextProvider({ children }: PropsWithChildren) {
  const store = useMobileFeaturesStore();

  return (
    <MobileFeaturesContext.Provider value={store}>
      {children}
    </MobileFeaturesContext.Provider>
  );
}
