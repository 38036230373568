/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import classNames from 'classnames';

import { AcIconButton } from '@ac/mobile-components/dist/components/icon-button';
import { MobileColor } from '@ac/mobile-components/dist/enums';
import { IconName, Size } from '@ac/web-components';

interface MenuModuleItemProps {
  isActive?: boolean;
  icon: IconName;

  onClick?(): void;
}

export const Module = (props: MenuModuleItemProps): JSX.Element => {
  const classes = classNames('module-item', {
    active: props.isActive,
  });

  return (
    <div className={classes}>
      <AcIconButton
        icon={props.icon}
        color={MobileColor.White}
        onClick={props.onClick}
        size={Size.md}
      />
    </div>
  );
};
