import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectedFooter from '@hkm/components/shared/LayoutComponents/ConnectedFooter';
import { MaintenanceFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/model/maintenanceFormData';

import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFooterWideButtons } from '@ac/mobile-components/dist/components/layout';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { FormRenderProps } from '@ac/react-infrastructure';
import { IconName } from '@ac/web-components';

export interface MaintenanceCreateFooterProps extends Childless {
  formProps: FormRenderProps<MaintenanceFormData>;
  onCancel(): void;
}

const MaintenanceFormFooter: FC<MaintenanceCreateFooterProps> = (
  props: MaintenanceCreateFooterProps
) => {
  const { t } = useTranslation();
  const prefix = 'maintenance-create-footer';

  return (
    <ConnectedFooter className={prefix} noPadding={true}>
      <AcFooterWideButtons>
        <AcButton
          onClick={props.formProps.handleSubmit}
          disabled={props.formProps.invalid}
          testSelector={`${prefix}-confirm-button`}
        >
          <AcButtonContent text={t('GLOBAL.SUBMIT')} />
        </AcButton>

        <AcButton
          onClick={props.onCancel}
          pattern={AcButtonPattern.Tertiary}
          testSelector={`${prefix}-cancel-button`}
        >
          <AcButtonContent icon={IconName.cancel} text={t('GLOBAL.CANCEL')} />
        </AcButton>
      </AcFooterWideButtons>
    </ConnectedFooter>
  );
};

export default memo(MaintenanceFormFooter);
