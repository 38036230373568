import { PropertyDetails } from '@ac/library-api';
import { PropertiesApi } from '@ac/library-api/dist/api/v0/configuration';

interface GetPropertyNameById {
  propertyId: string;
}

export async function getPropertyNameById(parameters: GetPropertyNameById) {
  const { propertyId } = parameters;
  try {
    const propertyById = (await PropertiesApi.getById({
      pathParams: { propertyId },
    })) as PropertyDetails;

    return propertyById.name ?? propertyId;
  } catch (e) {
    return propertyId;
  }
}
