import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';

export function useGuestServiceMap(): Map<
  GuestServiceStatusType,
  boolean | undefined
> {
  const effectiveValues = useSelector(selectEffectiveValues);
  const isServicePreferredEnabled =
    effectiveValues?.guestServiceStatusServicePreferred &&
    effectiveValues?.enabledHousekeepingSchedule;

  return useMemo(
    () =>
      new Map<GuestServiceStatusType, boolean | undefined>([
        [
          GuestServiceStatusType.ServiceRequested,
          effectiveValues?.guestServiceStatusServiceRequested,
        ],
        [
          GuestServiceStatusType.ServiceDeferred,
          effectiveValues?.guestServiceStatusServiceDeferred,
        ],
        [
          GuestServiceStatusType.DoNotDisturb,
          effectiveValues?.guestServiceStatusDoNotDisturbed,
        ],
        [GuestServiceStatusType.ServicePreferred, isServicePreferredEnabled],
        [
          GuestServiceStatusType.ServiceRefused,
          effectiveValues?.guestServiceStatusServiceRefused,
        ],
      ]),
    [effectiveValues, isServicePreferredEnabled]
  );
}
