import { EffectiveValueCode } from '@hkm/shared/effectiveValues/effectiveValueCode';
import { EffectiveValues } from '@hkm/shared/effectiveValues/effectiveValues';

import { EffectiveSettingDetails } from '@ac/library-api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function booleanExtractor(valueObject: any): boolean {
  return valueObject.value;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function valueExtractor(valueObject: any): any {
  return valueObject.value;
}

interface Config {
  property: keyof EffectiveValues;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueExtractor: (valueObject: any) => any;
}

const configsMap = new Map<EffectiveValueCode, Config>()
  .set(EffectiveValueCode.GreenService, {
    property: 'greenService',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.UsePickupStatus, {
    property: 'usePickupStatus',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.UseInspectedStatus, {
    property: 'useInspectedStatus',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.BypassHKAttendantSummary, {
    property: 'bypassHKAttendantSummary',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.GuestServiceStatuses, {
    property: 'guestServiceStatuses',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.GuestServiceStatusDoNotDisturbed, {
    property: 'guestServiceStatusDoNotDisturbed',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.GuestServiceStatusServiceDeferred, {
    property: 'guestServiceStatusServiceDeferred',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.GuestServiceStatusServicePreferred, {
    property: 'guestServiceStatusServicePreferred',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.GuestServiceStatusServiceRefused, {
    property: 'guestServiceStatusServiceRefused',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.GuestServiceStatusServiceRequested, {
    property: 'guestServiceStatusServiceRequested',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.EnableAttendantMobileApplication, {
    property: 'enableAttendantMobileApplication',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.EnableHousekeepingMobileApplication, {
    property: 'enableHousekeepingMobileApplication',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.EnableMaintenanceMobileApplication, {
    property: 'enableMaintenanceMobileApplication',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.TitleOrder, {
    property: 'titleOrder',
    valueExtractor,
  })
  .set(EffectiveValueCode.DisplayGuestAvatar, {
    property: 'displayGuestAvatar',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.DisplayNotesInMobileAttendant, {
    property: 'displayNotesInMobileAttendant',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.EnabledHousekeepingMobilePosting, {
    property: 'enabledHousekeepingMobilePosting',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.EnabledHousekeepingMobilePostingPrompt, {
    property: 'enabledHousekeepingMobilePostingPrompt',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.EnabledHousekeepingMobilePostingCashier, {
    property: 'enabledHousekeepingMobilePostingCashier',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.EnableHousekeepingMobilePostingEmails, {
    property: 'enabledHousekeepingMobilePostingEmails',
    valueExtractor: booleanExtractor,
  })

  .set(EffectiveValueCode.EnabledHousekeepingSchedule, {
    property: 'enabledHousekeepingSchedule',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.EnabledDayUse, {
    property: 'enabledDayUse',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.MobilePostingItems, {
    property: 'mobilePostingItems',
    valueExtractor,
  })
  .set(EffectiveValueCode.OccupancyDiscrepancy, {
    property: 'occupancyDiscrepancy',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.MobAttOccupancyPrompt, {
    property: 'mobAttOccupancyPrompt',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.EnabledQueueRooms, {
    property: 'enabledQueueRooms',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.HourlyInventoryManagement, {
    property: 'enabledHourlyInventoryManagement',
    valueExtractor: (value) => value?.feature ?? false,
  })
  .set(EffectiveValueCode.EnableTaskManagementMobileApplication, {
    property: 'enableTaskManagementMobileApplication',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.EnabledReadyBy, {
    property: 'enabledReadyBy',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.SupportedHousekeepingMobileLanguages, {
    property: 'supportedHousekeepingMobileLanguages',
    valueExtractor,
  })
  .set(EffectiveValueCode.EnableRoomConditions, {
    property: 'enableRoomConditions',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.TravelEffortPoints, {
    property: 'travelEffortPoints',
    valueExtractor: booleanExtractor,
  })
  .set(EffectiveValueCode.BypassTrackingAttendantInRoom, {
    property: 'bypassTrackingAttendantInRoom',
    valueExtractor: booleanExtractor,
  });

export function createEffectiveValuesFromSettings(
  settings: EffectiveSettingDetails[]
): EffectiveValues {
  return settings.reduce((values, setting) => {
    const config = configsMap.get(setting.code as EffectiveValueCode);

    return config
      ? { ...values, [config.property]: config.valueExtractor(setting.value) }
      : values;
  }, {}) as EffectiveValues;
}
