import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AttendantRoomItem from '@hkm/components/Attendant/RoomList/Body/AttendantRoomItem/AttendantRoomItem';
import NoRoomItem from '@hkm/components/Attendant/RoomList/Body/NoRoom/NoRoomItem';
import { AlertBar } from '@hkm/components/Attendant/shared/components/alertBar/AlertBar';
import {
  selectPendingRoomQueue,
  selectServiceRequested,
} from '@hkm/components/Attendant/shared/domain/selectors';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import TaskProgressTypeBadge from '@hkm/components/shared/AttendantTaskProgressTypeBadge/AttendantTaskProgressTypeBadge';
import { disabledMenuSwipeClass } from '@hkm/features/app/panels/menu/behaviours/useSwipeEvent';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';

import { AttendantTaskProgressType } from '@ac/library-api';
import {
  AcAnchorList,
  AcAnchorSection,
} from '@ac/mobile-components/dist/components/anchor-list';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTileGroup } from '@ac/mobile-components/dist/components/tile';
import {
  MobileColor,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';

interface AttendantRoomListBodyProps {
  groupedRooms: Map<AttendantTaskProgressType, AttendantRoom[]>;
}

const AttendantRoomListBody: FC<AttendantRoomListBodyProps> = (
  props: AttendantRoomListBodyProps
) => {
  const prefix = 'attendant-room-list';
  const { t } = useTranslation();
  const effectiveValues = useSelector(selectEffectiveValues);
  const pendingRoomQueue = useSelector(selectPendingRoomQueue);
  const serviceRequested = useSelector(selectServiceRequested);

  const groupedRoomsArray = useMemo(() => {
    let attendantTasks = [...props.groupedRooms.keys()];

    if (effectiveValues?.bypassTrackingAttendantInRoom) {
      attendantTasks = attendantTasks.filter(
        (task) =>
          ![
            AttendantTaskProgressType.InProgress,
            AttendantTaskProgressType.Paused,
          ].includes(task)
      );
    }

    return attendantTasks;
  }, [effectiveValues?.bypassTrackingAttendantInRoom, props.groupedRooms]);

  const renderListBasedOnCleaningStatus = useCallback(
    (statusKey: AttendantTaskProgressType, key: number = 0) => {
      const rooms = props.groupedRooms.get(statusKey);
      const isLast = key === groupedRoomsArray.length - 1;

      return (
        <AcAnchorSection
          to={statusKey}
          key={`${statusKey}${rooms?.length}`}
          isLast={isLast}
          label={t(`ATTENDANT_ASSIGNMENTS.ROOM_LIST.ROOM_STATUS.${statusKey}`, {
            count: rooms?.length,
          })}
        >
          <div className={`${prefix}-section-header`}>
            <AcText
              color={MobileColor.Black}
              weight={TextWeight.Semibold}
              size={TextSize.Main2}
            >
              <TaskProgressTypeBadge status={statusKey} />
            </AcText>
          </div>

          <AcTileGroup>
            {rooms?.map((attendantRoom: AttendantRoom) => {
              return (
                <AttendantRoomItem
                  key={attendantRoom.id}
                  room={attendantRoom}
                  taskType={statusKey}
                />
              );
            })}
          </AcTileGroup>

          {rooms?.length === 0 && (
            <NoRoomItem
              roomStatus={statusKey}
              className="ac-spacing-bottom-md"
            />
          )}
        </AcAnchorSection>
      );
    },
    [t, groupedRoomsArray.length, props.groupedRooms]
  );

  const renderList = useMemo(() => {
    return groupedRoomsArray.map(renderListBasedOnCleaningStatus);
  }, [renderListBasedOnCleaningStatus, groupedRoomsArray]);

  return (
    <AcAnchorList
      navClass={disabledMenuSwipeClass}
      className={`${prefix}-navigation`}
    >
      <AlertBar
        queueRoomsCount={pendingRoomQueue.length}
        serviceRequestedRoomsCount={serviceRequested.length}
      />
      <div className={`${prefix}-section-list`}>{renderList}</div>
    </AcAnchorList>
  );
};

export default memo(AttendantRoomListBody);
