import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import { ObjectToComponentMap } from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/constants';

import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTileShell } from '@ac/mobile-components/dist/components/tile';
import {
  FlexDirection,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

import './LinkedObject.css';

const LinkedObject = () => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);

  const ObjectComponent =
    linkedObject?.type && ObjectToComponentMap[linkedObject.type];

  return (
    <AcTileShell className="task-area">
      <ac-flex direction={FlexDirection.column}>
        <ac-flex class="task-area-header">
          <ac-icon icon={IconName.linkedRsv} class="ac-spacing-right-sm" />
          <AcText
            uppercase={true}
            weight={TextWeight.Semibold}
            size={TextSize.Main2}
            testSelector="task-area-title"
          >
            {t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.TITLE')}
          </AcText>
        </ac-flex>
        <ObjectComponent />
      </ac-flex>
    </AcTileShell>
  );
};

export default LinkedObject;
