// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorMessage = (response: any): string => {
  return (
    (response.Details && response.Details[0].Message) ||
    (response.details &&
      response.details.length &&
      response.details[0].message) ||
    response.message ||
    (response.error && response.error.message)
  );
};
