/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React from 'react';
import { useSwipeEvent } from '@hkm/features/app/panels/menu/behaviours/useSwipeEvent';
import { Content } from '@hkm/features/app/panels/menu/features/content/Content';

export const MenuPanelContainer = (): JSX.Element | null => {
  useSwipeEvent();

  return <Content />;
};
