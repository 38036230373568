import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import classNames from 'classnames';

import { TransactionCodeReadModel } from '@ac/library-api';
import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import {
  AcFieldNumber,
  AcFieldNumberVariant,
} from '@ac/mobile-components/dist/components/field';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTileShell } from '@ac/mobile-components/dist/components/tile';
import {
  AlignItems,
  FlexDirection,
  JustifyContent,
  MobileColor,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { FieldRenderProps } from '@ac/react-infrastructure';

import './TransactionCodeItem.css';

export interface TransactionCodeItemProps extends Testable {
  fieldProps: FieldRenderProps<number>;
  transactionCode?: TransactionCodeReadModel;
  charged?: number;
}

const TransactionCodeItem: FC<TransactionCodeItemProps> = (
  props: TransactionCodeItemProps
) => {
  const {
    fieldProps: { input },
    transactionCode,
  } = props;

  const { i18n, t } = useTranslation();
  const prefix = 'transaction-code-item';
  const shellClass = classNames(prefix, 'ac-spacing-top-sm', {
    selected: !!input.value,
  });

  return (
    <AcTileShell className={shellClass} testSelector={props.testSelector}>
      <ac-flex alignItems={AlignItems.center}>
        <ac-flex
          direction={FlexDirection.column}
          justifyContent={JustifyContent.flexStart}
          class={`${prefix}-name`}
        >
          <AcText color={MobileColor.Black} ellipsis={true}>
            {transactionCode?.getSubCodeDescription(i18n.language) ||
              transactionCode?.getSubCodeDescription(
                APP_CONSTANTS.FALLBACK_LANGUAGE
              )}
          </AcText>
          {!!props.charged && (
            <AcBadge
              fit={true}
              className="ac-spacing-top-s"
              borderColor={MobileColor.Warning}
              badgeTextSize={TextSize.Main3}
              backgroundColor={MobileColor.WarningLight}
              badgeText={t('POSTING.POSTING_DETAILS.CHARGED_ITEM', {
                count: props.charged,
              })}
            />
          )}
        </ac-flex>

        <ac-flex
          justifyContent={JustifyContent.flexEnd}
          alignItems={AlignItems.center}
          class={`${prefix}-post`}
        >
          <AcFieldNumber
            {...input}
            min={0}
            max={APP_CONSTANTS.MAX_QUANTITY_SINGLE_CHARGE}
            variant={AcFieldNumberVariant.Borderless}
          />
        </ac-flex>
      </ac-flex>
    </AcTileShell>
  );
};

export default memo(TransactionCodeItem);
