import { createContext } from 'react';

import { PanelPresenterStore } from '@ac/react-infrastructure';
import { useDefinedContext } from '@ac/react-infrastructure/dist/features';

export interface MenuPanelInput {
  hideCloseButton: boolean;
}

export type MenuPanelPresenterStore = PanelPresenterStore<MenuPanelInput, void>;

export const MenuPanelPresenterContext = createContext<
  MenuPanelPresenterStore | undefined
>(undefined);

export const useMenuPanelPresenterContext = (): MenuPanelPresenterStore =>
  useDefinedContext(MenuPanelPresenterContext);
