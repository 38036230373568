import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { RoomMaintenanceState } from '@ac/library-api';
import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { MobileColor } from '@ac/mobile-components/dist/enums';
import {
  Childless,
  Styleable,
} from '@ac/mobile-components/dist/interfaces/componentProps';

interface MaintenanceStateBadgeProps extends Styleable, Childless {
  status: RoomMaintenanceState;
  showLabel?: boolean;
}

const colorMap = new Map<RoomMaintenanceState, MobileColor>()
  .set(RoomMaintenanceState.Active, MobileColor.MaintenanceActive)
  .set(RoomMaintenanceState.Pending, MobileColor.MaintenancePending);

const MaintenanceStateBadge: FC<MaintenanceStateBadgeProps> = (
  props: MaintenanceStateBadgeProps
) => {
  const { t } = useTranslation();
  const color: MobileColor = colorMap.get(props.status) || MobileColor.Gray4;
  const badgeText = t(`GLOBAL.MAINTENANCE_STATE.${props.status}`);
  const labelText = props.showLabel ? badgeText : undefined;
  const testSelectorPrefix = 'maintenance-details';

  const className: string = classNames(
    'maintenance-state-badge',
    props.className
  );

  return (
    <AcBadge
      style={props.style}
      className={className}
      badgeText={badgeText}
      label={labelText}
      backgroundColor={color}
      labelUppercase={true}
      testSelector={`${testSelectorPrefix}-state`}
    />
  );
};

export default MaintenanceStateBadge;
