import { Dispatch, useReducer } from 'react';
import { UsersSelectorAction } from '@hkm/components/shared/features/UsersSelector/reducer/actions';
import { createUsersSelectorReducer } from '@hkm/components/shared/features/UsersSelector/reducer/reducer';
import {
  getDefaultUsersSelectorState,
  UsersSelectorState,
} from '@hkm/components/shared/features/UsersSelector/store/state';

export interface UserSelectorStore {
  state: UsersSelectorState;
  userSelectorDispatch: Dispatch<UsersSelectorAction>;
}

export const useUsersSelectorStore = (): UserSelectorStore => {
  const [state, dispatch] = useReducer(
    createUsersSelectorReducer,
    getDefaultUsersSelectorState()
  );

  return {
    state,
    userSelectorDispatch: dispatch,
  };
};
