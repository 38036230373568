/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { MenuPanel } from '@hkm/features/app/panels/menu/Panel';

export const AppPanels = (): JSX.Element => {
  return (
    <>
      <MenuPanel />
    </>
  );
};
