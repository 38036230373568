import React, { FC, memo } from 'react';

import { AcText } from '@ac/mobile-components/dist/components/text';
import { TextSize, TextWeight } from '@ac/mobile-components/dist/enums';

import './OverflowEllipsis.css';

interface ComponentProps {
  text: string;
  testSelector: string;
  size?: TextSize;
  weight?: TextWeight;
}

const OverflowEllipsis: FC<ComponentProps> = (props: ComponentProps) => {
  return (
    <AcText
      className="overflow-ellipsis-text"
      testSelector={props.testSelector}
      size={props.size}
      weight={props.weight}
    >
      {props.text}
    </AcText>
  );
};

export default memo(OverflowEllipsis);
