import React, { FC, memo } from 'react';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { useMenuPanelPresenterContext } from '@hkm/features/app/panels/menu/presenter/context';

import { AcIconButton } from '@ac/mobile-components/dist/components/icon-button';
import { AcWrappedLink } from '@ac/mobile-components/dist/components/wrapped-link';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { IconName } from '@ac/web-components';

const HeaderHelpButton: FC<Childless> = () => {
  const { hide } = useMenuPanelPresenterContext();

  return (
    <AcWrappedLink link={APP_ROUTES.HELP}>
      <AcIconButton icon={IconName.help} onClick={hide} />
    </AcWrappedLink>
  );
};

export default memo(HeaderHelpButton);
