import { createContext } from 'react';
import { MicroFrontendsStore } from '@hkm/features/microFrontends/store/store';

import { useDefinedContext } from '@ac/react-infrastructure';

export const MicroFrontendsContext = createContext<
  MicroFrontendsStore | undefined
>(undefined);

export const useMicroFrontendsContext = (): MicroFrontendsStore =>
  useDefinedContext(MicroFrontendsContext);
