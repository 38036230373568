import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCheckOutTime } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import MaintenanceCreateBody from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/Body/MaintenanceFormBody';
import * as actions from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/actions';
import MaintenanceCreateFooter from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/Footer/MaintenanceFormFooter';
import { MaintenanceCreateFieldDisability } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/model/MaintenanceCreateFieldDisability';
import { MaintenanceFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/model/maintenanceFormData';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import {
  HkmValidator,
  ValidationStatuses,
} from '@hkm/shared/interfaces/validationStatuses';
import { Validator } from '@hkm/shared/validation/validator';
import {
  isDateNotEmpty,
  isEndDateBeforeStartDate,
  isNonEmptyString,
  isStartDateAfterEndDate,
  isStartDateBeforeBusinessDate,
} from '@hkm/shared/validation/validators';
import { UnifiedRoomDetails } from '@hkm/types/room/model/UnifiedRoomDetails';
import { dayjs } from '@hkm/utils/dayjs-extended';

import {
  RoomMaintenanceStatus,
  RoomStatus,
  SetMaintenances,
} from '@ac/library-api';
import { Form, ValidationSchema } from '@ac/react-infrastructure';

interface Statuses extends ValidationStatuses {
  roomNumbers?: HkmValidator;
  statusCode?: HkmValidator;
  fromTime?: HkmValidator;
  toTime?: HkmValidator;
  reasonCode?: HkmValidator;
  comment?: HkmValidator;
  returnStatusCode?: HkmValidator;
}

const Schema: ValidationSchema<Statuses> = {
  roomNumbers: [isNonEmptyString],
  statusCode: [isNonEmptyString],
  fromTime: [
    isDateNotEmpty,
    isStartDateAfterEndDate,
    isStartDateBeforeBusinessDate,
  ],
  toTime: [isDateNotEmpty, isEndDateBeforeStartDate],
  reasonCode: [isNonEmptyString],
  comment: [isNonEmptyString],
  returnStatusCode: [isNonEmptyString],
};

interface MaintenanceFormProps {
  title: string;
  roomDetails?: UnifiedRoomDetails;
  initialValues: MaintenanceFormData;
  disableFields?: MaintenanceCreateFieldDisability;
  backward(): void;
  onSubmit(formData: SetMaintenances): void;
  onValidateMaintenance(formData: SetMaintenances): void;
}

const MaintenanceCreateForm: FC<MaintenanceFormProps> = (
  props: MaintenanceFormProps
) => {
  const prefix = 'maintenance-create';
  const dispatch = useDispatch();
  const checkOutTime = useSelector(selectCheckOutTime);

  const [validator] = useState<Validator<MaintenanceFormData, Statuses>>(
    new Validator(Schema)
  );

  const initialValues: MaintenanceFormData = useRef({
    ...props.initialValues,
    fromTime:
      props.initialValues.fromTime || dayjs().add(1, 'day').toISOString(),
    toTime: props.initialValues.toTime || dayjs().add(20, 'day').toISOString(),
  }).current;

  const validate = useCallback(
    (values: MaintenanceFormData) => validator.validate(values),
    [validator]
  );

  const createSetMaintenance = useCallback(
    (formData: MaintenanceFormData) => {
      const roomNumbers = formData.roomNumbers
        ? formData.roomNumbers.split(',')
        : [];
      const [hour, minute] = checkOutTime ? checkOutTime.split(':') : [];

      const data: SetMaintenances = {
        roomNumbers: roomNumbers.map((item) => item.trim()).filter(Boolean),
        comment: formData.comment,
        reasonCode: formData.reasonCode ?? '',
        returnStatusCode: formData.returnStatusCode as RoomStatus,
        statusCode: formData.statusCode as RoomMaintenanceStatus,
        fromTime: dayjs
          .utc(formData.fromTime)
          .set('hour', parseInt(hour, 10))
          .set('minute', parseInt(minute, 10))
          .format(APP_CONSTANTS.DEFAULT_OUTPUT_DATE_FORMAT),
        toTime: dayjs
          .utc(formData.toTime)
          .set('hour', 23)
          .set('minute', 59)
          .format(APP_CONSTANTS.DEFAULT_OUTPUT_DATE_FORMAT),
      };

      return data;
    },
    [checkOutTime]
  );

  const onValidateRequest = useCallback(
    (formValues: MaintenanceFormData) => {
      props.onValidateMaintenance(createSetMaintenance(formValues));
    },
    [createSetMaintenance, props]
  );

  const onSubmit = useCallback(
    (formData: MaintenanceFormData) => {
      props.onSubmit(createSetMaintenance(formData));
    },
    [createSetMaintenance, props]
  );

  useEffect(() => {
    return () => {
      dispatch(actions.clearConflicts());
    };

    // eslint-disable-next-line
  }, []);

  return (
    <Form
      initialValues={initialValues}
      validate={validate as never}
      onSubmit={onSubmit}
    >
      {(formRenderProps) => (
        <>
          <ConnectedHeader close={props.backward} testSelector={prefix}>
            {props.title}
          </ConnectedHeader>
          <MaintenanceCreateBody
            formProps={formRenderProps}
            roomDetails={props.roomDetails}
            onValidateRequest={onValidateRequest}
            disableFields={props.disableFields}
          />
          <MaintenanceCreateFooter
            formProps={formRenderProps}
            onCancel={props.backward}
          />
        </>
      )}
    </Form>
  );
};

export default memo(MaintenanceCreateForm);
