import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import * as appActions from '@hkm/components/App/domain/actions';
import { selectNotes } from '@hkm/components/App/domain/selectors';
import HousekeepingDetailsBody from '@hkm/components/Housekeeping/Details/Body/HousekeepingDetailsBody';
import * as detailsActions from '@hkm/components/Housekeeping/Details/domain/actions';
import { selectHousekeepingDetailsState } from '@hkm/components/Housekeeping/Details/domain/selectors';
import DoNotMoveHeaderIcon from '@hkm/components/shared/DoNotMove/DoNotMoveHeaderIcon';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import HeaderTwoLineTitle from '@hkm/components/shared/LayoutComponents/HeaderTwoLineTitle';
import { selectMaintenanceAttachmentsState } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/domain/selectors';
import { createMaintenanceAttachmentDataFromResponse } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/factories/maintenanceAttachmentDataFactory';
import { MaintenanceAttachmentData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/maintenanceAttachmentData';
import { selectMaintenanceUpdateState } from '@hkm/components/shared/Templates/Maintenance/shared/domain/selectors';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { HousekeepingNotificationEnvelopeContent } from '@hkm/services/signalRClient/envelopeContent/HousekeepingNotificationEnvelopeContent';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { useNotification } from '@hkm/shared/hooks/useNotification';
import { doesRoomHaveDoNotMoveFlag } from '@hkm/shared/reservations/hasDoNotMoveFlag';
import { ReservedKind } from '@hkm/shared/reservedKind/reservedKind';

import { NotificationEnvelope } from '@ac/library-api/dist/types/entities/signalr/notificationEnvelope';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { AlignItems, MobileColor } from '@ac/mobile-components/dist/enums';
import { useComponentDidUpdateEffect } from '@ac/mobile-components/dist/hooks';
import { Color, IconName, Size } from '@ac/web-components';

import './HousekeepingDetailsContainer.css';

export interface HousekeepingDetailsRouteParams {
  id: string;
}
const HousekeepingDetailsContainer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const notes = useSelector(selectNotes);
  const roomState = useSelector(selectHousekeepingDetailsState);
  const maintenanceUpdateState = useSelector(selectMaintenanceUpdateState);
  const maintenanceAttachmentsState = useSelector(
    selectMaintenanceAttachmentsState
  );

  const attachments: MaintenanceAttachmentData[] = (
    roomState.attachments || []
  ).map((file) => createMaintenanceAttachmentDataFromResponse(file));

  useEffect(() => {
    dispatch(detailsActions.reset());
    if (params.id) {
      dispatch(detailsActions.initialize(params.id));
    }

    // eslint-disable-next-line
  }, []);

  const notificationCallback = useCallback(
    (data?: NotificationEnvelope<HousekeepingNotificationEnvelopeContent>) => {
      if (data) {
        if (data?.notification?.roomId === params.id) {
          dispatch(detailsActions.fetchRoom.trigger());
        }
      } else {
        dispatch(detailsActions.fetchRoom.trigger());
      }
    },
    [dispatch, params.id]
  );

  useNotification(
    SignalREventType.HousekeepingRoomChanged,
    notificationCallback
  );

  useComponentDidUpdateEffect(() => {
    dispatch(detailsActions.fetchRoom.trigger());
  }, [maintenanceUpdateState.version, maintenanceAttachmentsState.version]);

  // fetch needed notes for reservations and store them
  useEffect(() => {
    if (roomState.room) {
      if (roomState.room?.currentReservedKind === ReservedKind.Reservation) {
        dispatch(
          appActions.fetchReservationNotes.trigger(
            roomState.room.currentReservationsIds || []
          )
        );
      } else {
        dispatch(
          appActions.fetchReservationNotes.trigger(
            roomState.room?.futureActiveReservations?.map(
              (reservation) => reservation.id
            ) || []
          )
        );
      }
    }

    // eslint-disable-next-line
  }, [roomState]);

  // Navigate to dashboard if we initialized, but no room is available
  if (!roomState.room && roomState.didInitialize) {
    return <Navigate to={APP_ROUTES.HOUSEKEEPING.DASHBOARD} />;
  }

  const showSpinner =
    !roomState.didInitialize ||
    roomState.isChanging ||
    roomState.isFetching ||
    maintenanceUpdateState.isPending ||
    notes.isFetching;

  const hasDoNotMoveFlag = doesRoomHaveDoNotMoveFlag(roomState.room);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const doNotMoveIcon = useMemo(() => <DoNotMoveHeaderIcon />, []);

  return (
    <>
      <ConnectedHeader
        color={
          roomState.room?.isInPendingRoomQueue ? MobileColor.Warning : undefined
        }
        close={APP_ROUTES.HOUSEKEEPING.DASHBOARD}
        left={hasDoNotMoveFlag ? doNotMoveIcon : undefined}
      >
        {roomState.room && (
          <ac-flex alignItems={AlignItems.center}>
            {roomState.room.isInPendingRoomQueue && (
              <ac-icon
                class="ac-spacing-right-md"
                icon={IconName.queue}
                color={Color.white}
                size={Size.lg}
              />
            )}
            <HeaderTwoLineTitle
              upperText={
                roomState.room.isInPendingRoomQueue
                  ? t('GLOBAL.QUEUE_ROOM')
                  : t('ROOM_DETAILS.TITLE')
              }
              lowerText={roomState.room.roomNumber?.code || '\u00A0'}
            />
          </ac-flex>
        )}
        {!roomState.room && t('ROOM_DETAILS.TITLE')}
      </ConnectedHeader>
      <AcBody
        className="housekeeping-details-container-body"
        showSpinner={showSpinner}
      >
        {roomState.room && (
          <HousekeepingDetailsBody
            room={roomState.room}
            maintenanceAttachments={attachments}
          />
        )}
      </AcBody>
    </>
  );
};

export default memo(HousekeepingDetailsContainer);
