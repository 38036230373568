import APP_CONSTANTS from '@hkm/constants/app.constants';

import { LocalizedString } from '@ac/library-api';
import { SessionService } from '@ac/library-utils/dist/services';

export const getLocalizedString = (
  localizedStringArray?: LocalizedString[]
): string => {
  const userUILanguage = SessionService.getLanguage()?.toUpperCase();

  return localizedStringArray
    ? localizedStringArray.find(
        ({ languageCode }) => languageCode === userUILanguage
      )?.content ||
        localizedStringArray.find(
          ({ languageCode }) => languageCode === APP_CONSTANTS.DATA_LANGUAGE
        )?.content ||
        ''
    : '';
};
