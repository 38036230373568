import * as appActions from '@hkm/components/App/domain/actions';
import { MappedConfigurationUser } from '@hkm/components/TaskManagement/Dashboard/mappers';
import { SelectedObjectModel } from '@hkm/components/TaskManagement/shared/interfaces/selectedObjectModel';
import * as actions from '@hkm/components/TaskManagement/TaskPreview/domain/actions';
import { TaskDetailsState } from '@hkm/components/TaskManagement/TaskPreview/domain/interfaces';
import { ReducerMap } from 'redux-actions';

import { RawTask } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: TaskDetailsState = {
  task: undefined,
  linkedObject: undefined,
  relatedUsers: undefined,
  isLoading: false,
  isLoadingLinkedObject: false,
};

const reducerMap: ReducerMap<TaskDetailsState, unknown> = {
  [appActions.clearAllViews]: () => ({ ...initialState }),
  [actions.loadTask.trigger]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [actions.loadTask.success]: (state, action: Action<RawTask>) => ({
    ...state,
    task: action.payload,
    isLoading: false,
  }),
  [actions.loadTask.failure]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [actions.clearCurrentTask]: (state) => ({
    ...state,
    task: undefined,
  }),
  [actions.loadLinkedObject.trigger]: (state) => ({
    ...state,
    isLoadingLinkedObject: true,
  }),
  [actions.loadLinkedObject.failure]: (state) => ({
    ...state,
    isLoadingLinkedObject: false,
  }),
  [actions.loadLinkedObject.success]: (
    state,
    action: Action<SelectedObjectModel>
  ) => ({
    ...state,
    isLoadingLinkedObject: false,
    linkedObject: {
      ...action.payload,
      followObjectChanges: state.task?.linkedObject?.followObjectChanges,
    },
  }),
  [actions.loadTaskRelatedUsers.success]: (
    state,
    action: Action<MappedConfigurationUser[]>
  ) => ({
    ...state,
    relatedUsers: action.payload,
  }),
  [actions.clearLinkedObject]: (state) => ({
    ...state,
    linkedObject: undefined,
  }),
  [actions.toggleFollowObjectChanges]: (state) => ({
    ...state,
    linkedObject: {
      ...(state.linkedObject as SelectedObjectModel),
      followObjectChanges: !state.linkedObject?.followObjectChanges,
    },
  }),
  [actions.updateTaskLinkedObject.trigger]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [actions.updateTaskLinkedObject.failure]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [actions.updateTaskLinkedObject.success]: (state) => ({
    ...state,
    isLoading: false,
  }),
};

export default handleActions(reducerMap, initialState);
