import { GuestCount } from '@hkm/shared/interfaces/guestCount';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

export function getGuestCountFromUnifiedReservationDetails(
  reservation: UnifiedReservationDetails
): GuestCount {
  const adultCount = Math.max(
    ...reservation.occupancyDetails.map(
      (occupancy) => occupancy.adultCount ?? 0
    )
  );
  const childCount = Math.max(
    ...reservation.occupancyDetails.map(
      (occupancy) => occupancy.childCount ?? 0
    )
  );

  return { adultCount, childCount };
}
