import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchRoomQueue } from '@hkm/components/App/domain/actions';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { useNotification } from '@hkm/shared/hooks/useNotification';

export function useRoomQueueChangedNotification() {
  const dispatch = useDispatch();

  const triggerFetch = useCallback(() => {
    dispatch(fetchRoomQueue.trigger());
  }, [dispatch]);

  useNotification(SignalREventType.RoomQueueChanged, triggerFetch);
}
