import i18n from '@hkm/i18n';

import { SessionService } from '@ac/library-utils/dist/services';

export function changeLanguage(lang: string): void {
  const language = lang.toUpperCase();

  SessionService.setLanguage(language);
  void i18n.changeLanguage(language);

  window.location.reload();
}
