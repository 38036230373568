import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  fetchCurrentCustomer,
  fetchFeatureToggles,
} from '@hkm/components/App/domain/actions';
import { userDetails, userLogin } from '@hkm/components/Login/domain/actions';
import {
  businessDate,
  properties,
  property,
} from '@hkm/components/Menu/PropertySelector/domain/actions';
import { setSignalRClient } from '@hkm/services/signalRClient/signalRClient';
import { createEffectiveValuesFromSettings } from '@hkm/shared/effectiveValues/effectiveValuesFactory';
import {
  Permission,
  PermissionIds,
} from '@hkm/shared/permissions/enum/Permission';

import { UnitAssignmentListItem } from '@ac/library-api';
import { SepModuleBusinessContextData } from '@ac/library-api/dist/businessContext/contextData';
import { CustomerContextData } from '@ac/library-api/dist/businessContext/customer';
import { PropertyContextData } from '@ac/library-api/dist/businessContext/property/types';
import { UserContextData } from '@ac/library-api/dist/businessContext/user';
import { isDefined } from '@ac/library-utils/dist/utils';

interface Output {
  setUp(
    data: SepModuleBusinessContextData,
    properties: UnitAssignmentListItem[]
  ): void;
}

/**
 * TODO Should be removed when mobile app will lose redux store
 */
export function useBackwardCompatibility(): Output {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const setUpLogin = useCallback((): void => {
    dispatch(userLogin.success(true));
  }, [dispatch]);

  const setUpCustomer = useCallback(
    (customerData?: CustomerContextData): void => {
      if (!isDefined(customerData)) {
        dispatch(
          fetchCurrentCustomer.failure(
            new Error(
              t('INITIALIZATION_PROCESS.ERRORS.MISSING_CUSTOMER_INFORMATION')
            )
          )
        );

        return;
      }

      dispatch(fetchCurrentCustomer.success(customerData.details));
    },
    [t, dispatch]
  );

  const setUpUser = useCallback(
    (userData?: UserContextData): void => {
      if (!isDefined(userData)) {
        dispatch(
          userDetails.failure(
            new Error(
              t('INITIALIZATION_PROCESS.ERRORS.MISSING_USER_INFORMATION')
            )
          )
        );

        return;
      }

      dispatch(userDetails.success(userData.details));
    },
    [t, dispatch]
  );

  const setUpProperties = useCallback(
    (propertiesData: UnitAssignmentListItem[]): void => {
      dispatch(properties.success(propertiesData));
    },
    [dispatch]
  );

  const setUpFeatureToggles = useCallback(
    (propertyData?: PropertyContextData): void => {
      if (!isDefined(propertyData)) {
        dispatch(
          fetchFeatureToggles.failure(
            new Error(
              t('INITIALIZATION_PROCESS.ERRORS.MISSING_PROPERTY_INFORMATION')
            )
          )
        );

        return;
      }

      dispatch(fetchFeatureToggles.success(propertyData.featureToggles));
    },
    [t, dispatch]
  );

  const setUpBusinessDate = useCallback(
    (propertyData?: PropertyContextData): void => {
      if (!isDefined(propertyData)) {
        dispatch(
          businessDate.failure(
            new Error(
              t('INITIALIZATION_PROCESS.ERRORS.MISSING_PROPERTY_INFORMATION')
            )
          )
        );

        return;
      }

      if (!isDefined(propertyData.businessDate)) {
        dispatch(
          businessDate.failure(
            new Error(
              t(
                'INITIALIZATION_PROCESS.ERRORS.MISSING_BUSINESS_DATE_INFORMATION'
              )
            )
          )
        );

        return;
      }

      dispatch(businessDate.success(propertyData.businessDate));
    },
    [t, dispatch]
  );

  const setUpProperty = useCallback(
    (propertyData?: PropertyContextData): void => {
      if (!isDefined(propertyData)) {
        dispatch(
          property.failure(
            new Error(
              t('INITIALIZATION_PROCESS.ERRORS.MISSING_PROPERTY_INFORMATION')
            )
          )
        );

        return;
      }

      const permissionsMap = new Map<string, string>(
        Object.entries(PermissionIds).map(([key, value]) => [value, key])
      );

      const permissions = propertyData.permissions.permissionIds
        .map((permissionId) => permissionsMap.get(permissionId))
        .filter(Boolean) as Permission[];

      const effectiveValues = createEffectiveValuesFromSettings(
        propertyData.settings.effectiveSettingsDetails
      );

      setSignalRClient(propertyData.details.id);
      dispatch(
        property.success({
          activeProperty: propertyData.details,
          permissions,
          effectiveValues,
        })
      );
    },
    [t, dispatch]
  );

  const setUp = useCallback(
    (
      data: SepModuleBusinessContextData,
      propertiesList: UnitAssignmentListItem[]
    ): void => {
      setUpProperty(data.property);
      setUpBusinessDate(data.property);
      setUpFeatureToggles(data.property);
      setUpProperties(propertiesList);
      setUpUser(data.user);
      setUpCustomer(data.customer);
      setUpLogin();
    },
    [
      setUpBusinessDate,
      setUpCustomer,
      setUpFeatureToggles,
      setUpLogin,
      setUpProperties,
      setUpProperty,
      setUpUser,
    ]
  );

  return {
    setUp,
  };
}
