import { createDateRangeFilter } from '@hkm/shared/helpers/apiFilter/apiDateRangeFiltersFactory';

export function createDayRangeFilter<T>(
  day: string | undefined,
  propertyA: keyof T,
  propertyB?: keyof T
): string | undefined {
  if (!day) {
    return undefined;
  }

  return createDateRangeFilter<T>(day, day, propertyA, propertyB);
}
