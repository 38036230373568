import {
  BorderTimeValues,
  FilterObjects,
} from '@hkm/components/TaskManagement/Dashboard/domain/enums';
import { Filters } from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import DateTimeHelpers from '@hkm/components/TaskManagement/utils/dateTime';

import {
  buildFIQLFilter,
  combineFilters,
  FIQLOperators,
} from '@ac/library-api';

// TODO: Cleanup
export default class DashboardFilterBuilder {
  public taskStatuses?: string[] = [];
  public taskPriorities?: string[] = [];
  public departments?: string[] = [];
  public assignees?: string[] = [];
  public dateFrom?: string;
  public dateTo?: string;
  public timeFrom?: string;
  public timeTo?: string;
  public areas?: string[] = [];
  public unassignedOnly?: boolean = false;

  public filters: string[] = [];

  constructor(filters: Filters) {
    this.taskStatuses = filters.taskStatuses;
    this.taskPriorities = filters.taskPriorities;
    this.departments = filters.departments;
    this.assignees = filters.assignees;
    this.dateFrom = filters.dateFrom;
    this.dateTo = filters.dateTo;
    this.timeFrom = filters.timeFrom;
    this.timeTo = filters.timeTo;
    this.areas = filters.areas;
    this.unassignedOnly = filters.unassignedOnly;
  }

  public withDepartments() {
    this.setFilterString(FilterObjects.department, this.departments);

    return this;
  }

  public withTaskStatuses() {
    this.setFilterString(FilterObjects.taskStatus, this.taskStatuses);

    return this;
  }

  public withTaskPriorities() {
    this.setFilterString(FilterObjects.taskPriority, this.taskPriorities);

    return this;
  }

  public withTaskAssignee() {
    this.setFilterString(FilterObjects.assignees, this.assignees);

    return this;
  }

  public withArea() {
    this.setAreaFilterString(this.areas);

    return this;
  }

  public withUnassignedOnly() {
    this.setUnassignedFilterString(this.unassignedOnly);

    return this;
  }

  public withDateFrom() {
    this.setDateFromFilterString(this.dateFrom, this.timeFrom);

    return this;
  }

  public withDateTo() {
    this.setDateToFilterString(this.dateTo, this.timeTo);

    return this;
  }

  public getFilter(): string {
    return this.filters.length
      ? combineFilters(this.filters, FIQLOperators.and) ?? ''
      : '';
  }

  private setFilterString(objectType: FilterObjects, ids?: string[]): void {
    if (ids?.length) {
      this.filters.push(
        combineFilters(
          ids.map((id) =>
            buildFIQLFilter(`${objectType}.id`, FIQLOperators.equal, id)
          ),
          FIQLOperators.or
        ) ?? ''
      );
    }
  }

  private setDateFromFilterString(
    dateString?: string,
    timeString?: string
  ): void {
    timeString =
      (timeString
        ? DateTimeHelpers.separateTimeDate(timeString).dueTime
        : undefined) || BorderTimeValues.startTime;
    if (dateString?.length) {
      const dateFromFilter = this.getDateFilterString(
        dateString,
        timeString,
        false
      );

      this.filters.push(
        buildFIQLFilter(
          FilterObjects.date,
          FIQLOperators.greaterThenOrEqual,
          dateFromFilter
        ) ?? ''
      );
    }
  }

  private setDateToFilterString(
    dateString?: string,
    timeString?: string
  ): void {
    timeString =
      (timeString
        ? DateTimeHelpers.separateTimeDate(timeString).dueTime
        : undefined) || BorderTimeValues.endTime;
    if (dateString?.length) {
      const dateToFilter = this.getDateFilterString(dateString, timeString);

      this.filters.push(
        buildFIQLFilter(
          FilterObjects.date,
          FIQLOperators.lessThenOrEqual,
          dateToFilter
        ) ?? ''
      );
    }
  }

  private getDateFilterString(
    dateString: string,
    timeString: string,
    isEndDate = true
  ): string {
    return DateTimeHelpers.combineDateTime(
      new Date(dateString),
      timeString,
      isEndDate
    );
  }

  private setAreaFilterString(areas?: string[]): void {
    if (areas?.length) {
      this.filters.push(
        combineFilters(
          areas.map((area) =>
            buildFIQLFilter(FilterObjects.area, FIQLOperators.equal, area)
          ),
          FIQLOperators.or
        ) ?? ''
      );
    }
  }

  private setUnassignedFilterString(isUnassignedOnly?: boolean): void {
    if (isUnassignedOnly) {
      this.filters.push(
        buildFIQLFilter(
          `${FilterObjects.assignees}.id`,
          FIQLOperators.hasValue,
          false
        ) ?? ''
      );
    }
  }
}

export function getDashboardFilterString(filters: Filters): string {
  const builder = new DashboardFilterBuilder(filters)
    .withDepartments()
    .withTaskStatuses()
    .withTaskPriorities()
    .withTaskAssignee()
    .withArea()
    .withUnassignedOnly()
    .withDateFrom()
    .withDateTo();

  return builder.getFilter();
}
