import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import InnerAccordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/InnerAccordion/InnerAccordion';
import SpecialNeeds from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/SpecialNeeds/SpecialNeeds';
import VipAndMember from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/VipAndMember/VipAndMember';
import { getProfileName } from '@hkm/components/TaskManagement/utils/getProfileName';

import {
  HousekeepingRoomReservationDetailsDto,
  TitleOrders,
} from '@ac/library-api';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { MobileColor, TextSize } from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

interface ComponentProps {
  reservation: HousekeepingRoomReservationDetailsDto;
  index: number;
}

const RoomSharedReservation = (props: ComponentProps) => {
  const { reservation, index } = props;
  const { guest } = reservation;
  const { t } = useTranslation();
  const requireAccordion =
    !!guest?.disabilityStatuses?.length || !!guest?.vipStatus;
  const effectiveValues = useSelector(selectEffectiveValues);
  const nameSetting = effectiveValues?.titleOrder
    ? effectiveValues.titleOrder
    : TitleOrders.LastNameFirstNameTitle;

  const guestName = useMemo(
    () =>
      guest?.personalData
        ? getProfileName({
            firstName: guest.personalData.name.firstName,
            lastName: guest.personalData.name.lastName,
            title: guest.personalData.name.titleCode?.description,
            nameSetting,
          })
        : '-',
    [guest?.personalData, nameSetting]
  );

  const guestInfo = useMemo(
    () => (
      <>
        <AcText
          size={TextSize.Main2}
          className="ac-spacing-right-sm"
          testSelector={`task-shared-reservation-guest-name-${index}`}
        >
          {guestName}
        </AcText>

        {!!guest?.vipStatus && (
          <AcIndicator
            className="ac-spacing-right-sm"
            icon={IconName.vip}
            backgroundColor={MobileColor.Warning}
            testSelector={`task-shared-reservation-guest-vip-indicator-${index}`}
          />
        )}
        {!!guest?.disabilityStatuses?.length && (
          <AcIndicator
            icon={IconName.disability}
            backgroundColor={MobileColor.Alert}
            testSelector={`task-shared-reservation-guest-disability-indicator-${index}`}
          />
        )}
      </>
    ),
    [guest, guestName, index]
  );

  return (
    <>
      {requireAccordion ? (
        <InnerAccordion header={guestInfo} id={guest?.id || ''}>
          {!!guest?.vipStatus && (
            <AcFormElement
              label={t(
                'TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.VIP_AND_MEMBERSHIP'
              )}
              className="ac-spacing-top-md"
            >
              <VipAndMember vipStatusCode={guest?.vipStatus} memberships={[]} />
            </AcFormElement>
          )}
          {!!guest?.disabilityStatuses?.length && (
            <AcFormElement
              label={t(
                'TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.SPECIAL_NEEDS'
              )}
            >
              <SpecialNeeds disabilityCodes={guest?.disabilityStatuses} />
            </AcFormElement>
          )}
        </InnerAccordion>
      ) : (
        guestInfo
      )}
    </>
  );
};

export default RoomSharedReservation;
