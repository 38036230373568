import { AttendantEffectiveValues } from '@hkm/components/Attendant/shared/config/attendantEffeectiveValues';
import { EffectiveValues } from '@hkm/shared/effectiveValues/effectiveValues';

export const HousekeepingPostingEffectiveValues: Map<
  keyof EffectiveValues,
  unknown
> = new Map<keyof EffectiveValues, unknown>().set(
  'enabledHousekeepingMobilePosting',
  true
);

export const AttendantPostingEffectiveValues: Map<
  keyof EffectiveValues,
  unknown
> = new Map<keyof EffectiveValues, unknown>([
  ...HousekeepingPostingEffectiveValues,
  ...AttendantEffectiveValues,
]);
