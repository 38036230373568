import React, { FC, memo, useEffect, useState } from 'react';
import { getStringByteLength } from '@hkm/utils/getStringByteLength';

import {
  getInitials,
  HousekeepingRoomReservationGuestPersonalDataDetailsDto,
  IdCodeDescriptionColor,
} from '@ac/library-api';
import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import {
  Clickable,
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { AvatarSize, Color, IconName } from '@ac/web-components';

import './GuestAvatarTemplate.scss';

interface GuestAvatarTemplateProps extends Clickable, Testable, Styleable {
  hasDisabilities: boolean;
  guestPersonalData?: HousekeepingRoomReservationGuestPersonalDataDetailsDto;
  guestVipCode?: IdCodeDescriptionColor;
  getAvatar?: Promise<string>;
}

const GuestAvatarTemplate: FC<GuestAvatarTemplateProps> = (
  props: GuestAvatarTemplateProps
) => {
  const [image, setImage] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const { firstName, lastName } = props.guestPersonalData?.name ?? {
    firstName: '',
    lastName: '',
  };
  const initials = getInitials(firstName ?? '', lastName ?? '');
  const hasTwoBytesCharacters = getStringByteLength(initials) > 2;

  useEffect(() => {
    const getAvatar = async () => {
      if (props.getAvatar) {
        setLoading(true);
        try {
          const result = await props.getAvatar;
          setImage(result);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    };

    void getAvatar();
  }, [props.getAvatar]);

  const vipColor = props.guestVipCode ? props.guestVipCode.color : undefined;

  return (
    <ac-avatar
      data-test-selector="guest-avatar"
      loading={loading}
      image={image}
      size={AvatarSize.md}
      onClick={props.onClick}
    >
      <div className="display-contents">
        {!image && (
          <>
            {props.hasDisabilities && (
              <ac-icon icon={IconName.disability} color={Color.alert} />
            )}
            {!props.hasDisabilities &&
              (hasTwoBytesCharacters ? (
                <ac-icon icon={IconName.profile} color={Color.black} />
              ) : (
                initials
              ))}
          </>
        )}
        {vipColor && (
          <div className="ac-avatar-indicator-wrapper">
            <AcIndicator backgroundColor={vipColor} icon={IconName.vip} />
          </div>
        )}
      </div>
    </ac-avatar>
  );
};

export default memo(GuestAvatarTemplate);
