import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useExtractGuestFullName } from '@hkm/shared/hooks/useExtractGuestFullName';

import { HousekeepingRoomReservationGuestPersonalDataDetailsDto } from '@ac/library-api';
import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  FlexDirection,
  MobileColor,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import { Color, IconName } from '@ac/web-components';

interface GuestNameTemplateProps {
  guestPersonalData?: HousekeepingRoomReservationGuestPersonalDataDetailsDto;
  hasDisabilities?: boolean;
  vipBadgeColor?: string;
  customGuestName?: string;
}

const GuestNameTemplate: FC<GuestNameTemplateProps> = ({
  customGuestName = '',
  ...props
}: GuestNameTemplateProps) => {
  const { t } = useTranslation();

  const formattedName = props.guestPersonalData?.name
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useExtractGuestFullName(props.guestPersonalData?.name)
    : customGuestName;
  const formattedAltName =
    props.guestPersonalData?.alternateName &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useExtractGuestFullName(props.guestPersonalData?.alternateName);

  return (
    <ac-flex direction={FlexDirection.column}>
      <AcText color={MobileColor.Black} size={TextSize.Main1}>
        {formattedName}
        {props.hasDisabilities && (
          <AcIndicator
            className="ac-spacing-left-sm"
            icon={IconName.disability}
            backgroundColor={Color.alert}
          />
        )}
        {!!props.vipBadgeColor && (
          <AcIndicator
            className="ac-spacing-left-xs"
            backgroundColor={props.vipBadgeColor}
            icon={IconName.vip}
          />
        )}
      </AcText>
      {formattedAltName && (
        <AcText color={MobileColor.Black} size={TextSize.Main1}>
          {t('GLOBAL.IN_BRACKETS', { text: formattedAltName })}
        </AcText>
      )}
    </ac-flex>
  );
};

export default memo(GuestNameTemplate);
