import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FinalizeStepProcessType } from '@hkm/components/Attendant/RoomDetails/body/FinalizeTaskProcess/FinalizeStepProcessType';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { AttendantPostingPermissionsConfig } from '@hkm/components/Posting/shared/config/postingPermissionsConfig';
import { getPostingAttendantReservations } from '@hkm/components/Posting/shared/utils/getPostingReservation';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';

import { HousekeepingStatus } from '@ac/library-api';

export function useCreateProcessingSteps(
  room: AttendantRoom
): FinalizeStepProcessType[] {
  const effectiveValues = useSelector(selectEffectiveValues);
  const hasPostingPermission = usePermission(
    ...AttendantPostingPermissionsConfig
  );

  return useMemo(() => {
    const steps = [];

    const hasMobilePostingPromptOn =
      effectiveValues?.enabledHousekeepingMobilePosting &&
      effectiveValues?.enabledHousekeepingMobilePostingPrompt;

    const hasPostingReservations =
      getPostingAttendantReservations(room.currentReservations).length > 0;

    const isPostingStepRequired =
      hasPostingPermission &&
      hasMobilePostingPromptOn &&
      hasPostingReservations;
    const isOccupancyStepRequired =
      effectiveValues?.occupancyDiscrepancy &&
      effectiveValues?.mobAttOccupancyPrompt &&
      !room.roomOccupancy.hasReport &&
      (room.frontdeskStatus?.code as unknown as HousekeepingStatus) ===
        HousekeepingStatus.Occupied;

    steps.push(FinalizeStepProcessType.NotInitialized);

    if (isOccupancyStepRequired) {
      steps.push(FinalizeStepProcessType.OccupancyConfirmation);
    }

    if (isPostingStepRequired) {
      steps.push(FinalizeStepProcessType.ChargePosting);
    }

    steps.push(FinalizeStepProcessType.Finalized);

    return steps;
  }, [effectiveValues, hasPostingPermission, room]);
}
