import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRoomStates } from '@hkm/components/App/domain/selectors';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { Permission } from '@hkm/shared/permissions/enum/Permission';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';

import { DictionaryEntity, RoomStatus } from '@ac/library-api';

export enum EnabledRoomStatesPurpose {
  DISPLAY,
  CHANGE,
}

export function useEnabledRoomStates(
  purpose: EnabledRoomStatesPurpose = EnabledRoomStatesPurpose.CHANGE
): DictionaryEntity[] {
  const states = useSelector(getRoomStates);

  const effectiveValues = useSelector(selectEffectiveValues);
  const hasPermission = usePermission(
    Permission.HousekeepingSetRoomStatusInspected
  );

  return useMemo(() => {
    const showInspectStatus =
      effectiveValues?.useInspectedStatus &&
      (hasPermission || purpose === EnabledRoomStatesPurpose.DISPLAY);
    const unusedStates: Set<string> = new Set<string>(
      [
        showInspectStatus ? undefined : RoomStatus.IN,
        effectiveValues?.usePickupStatus ? undefined : RoomStatus.PU,
      ].filter(Boolean) as string[]
    );

    return (states || []).filter(
      (state) => !unusedStates.has(state.code ?? '')
    );
  }, [states, effectiveValues, hasPermission, purpose]);
}
