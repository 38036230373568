import { UnknownObject } from '@hkm/types/shared/unknownObject';

import { buildFIQLFilter, FIQLOperators } from '@ac/library-api';

export interface ApiFiltersFactoryConfig<T> {
  field: keyof T;
  customOperator?: FIQLOperators;
  subField?: string;
}

export function createApiFilters<S extends UnknownObject, T>(
  filters: S,
  configs: Map<keyof S, ApiFiltersFactoryConfig<T>>
) {
  return Object.entries(filters)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]: [string, string]) => {
      const config = configs.get(key as keyof S);
      const subField = config ? config.subField : undefined;

      return config
        ? buildFIQLFilter(
            `${String(config.field)}${subField ? `.${subField}` : ''}`,
            config.customOperator || FIQLOperators.equal,
            value
          )
        : undefined;
    })
    .filter(Boolean);
}
