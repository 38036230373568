import {
  formatProfileName,
  HousekeepingGuestFirstNameLastNameTitleCodeDto,
  TitleOrders,
} from '@ac/library-api';

export type GuestPersonalDataNameWithOptionalTitle = Omit<
  HousekeepingGuestFirstNameLastNameTitleCodeDto,
  'titleCode'
> &
  Partial<Pick<HousekeepingGuestFirstNameLastNameTitleCodeDto, 'titleCode'>>;

export function extractName(
  user: GuestPersonalDataNameWithOptionalTitle,
  titleOrder: TitleOrders
) {
  const { titleCode, firstName, lastName } = user;
  const profileNameSegments = {
    title: titleCode ? titleCode.description : '',
    firstName,
    lastName,
    middleName: undefined,
    suffix: undefined,
  };

  return formatProfileName(titleOrder, profileNameSegments);
}
