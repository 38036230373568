/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { PropsWithChildren } from 'react';
import { NotificationsContext } from '@hkm/features/notifications/store/context';
import { useNotificationsStore } from '@hkm/features/notifications/store/store';

export const NotificationsContextProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const store = useNotificationsStore();

  return (
    <NotificationsContext.Provider value={store}>
      {children}
    </NotificationsContext.Provider>
  );
};
