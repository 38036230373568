import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';

import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { AcNoContent } from '@ac/mobile-components/dist/components/no-content';
import { AcNoContentText } from '@ac/mobile-components/dist/components/no-content/acNoContentText';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  JustifyContent,
  TextSize,
} from '@ac/mobile-components/dist/enums';

import './AttendantSheetNotFound.css';

const AttendantSheetNotFound: React.FC = () => {
  const { t } = useTranslation();

  const content: AcNoContentText = {
    title: t('ATTENDANT_ASSIGNMENTS.SHEET_NOT_FOUND.INFO_TITLE'),
    description: t('ATTENDANT_ASSIGNMENTS.SHEET_NOT_FOUND.INFO_DESCRIPTION'),
  };

  return (
    <>
      <ConnectedHeader showHelpButton={true}>
        <AcText size={TextSize.H2}>
          {t('ATTENDANT_ASSIGNMENTS.SHEET_NOT_FOUND.TITLE')}
        </AcText>
      </ConnectedHeader>
      <AcBody className="attendant-sheet-not-found-body">
        <ac-flex
          alignItems={AlignItems.center}
          justifyContent={JustifyContent.center}
          class="attendant-sheet-not-found-content"
        >
          <AcNoContent text={content} />
        </ac-flex>
      </AcBody>
    </>
  );
};

export default memo(AttendantSheetNotFound);
