const APP_CONSTANTS = {
  IDENTITY_CLIENT_ID: 'MobileHousekeeping',
  APPLICATION_NAME_IN_URL: 'housekeeping-mobile',
  MODULE_CLASS: 'housekeeping-mobile-module',
  DATA_LANGUAGE: 'EN',
  FALLBACK_LANGUAGE: 'EN',
  DEBOUNCE_TIMEOUT: 700,
  MAX_REQUEST_REPEAT: 20,
  API_DATE_FORMAT: 'YYYY-MM-DD',
  DEFAULT_TIMEZONE_DATE_FORMAT: 'YYYY-MM-DD HH:mm',
  DEFAULT_OUTPUT_DATE_FORMAT: 'YYYY-MM-DD[T]HH:mm:ss',
  DEFAULT_UTC_OUTPUT_DATE_FORMAT: 'YYYY-MM-DD[T]HH:mm:ss[Z]',
  DEFAULT_12H_DISPLAY_TIME: 'hh:mm A',
  DEFAULT_24H_DISPLAY_TIME: 'HH:mm',
  NOTE_ROLES: ['GEN', 'HK'],
  MAX_FETCH_SIZE: 200,
  MAX_QUANTITY_SINGLE_CHARGE: 999,
  ACCOUNT_STATUSES: ['RS', 'DI', 'IH', 'DO', 'OP', 'CO'],
  NEW_PROPERTY_ID: 'newPropertyId',
  TIME_BEGINNING: '01:00:00',
  TIME_END: '23:59:59',
};

export default APP_CONSTANTS;
