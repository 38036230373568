import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import * as postingViewActions from '@hkm/components/Posting/PostingView/domain/actions';
import { selectPostingTransactionCodesState } from '@hkm/components/Posting/PostingView/domain/selectors';

import { TransactionCodeReadModel } from '@ac/library-api';

export function useFetchTransactionCodes(): Map<
  string,
  TransactionCodeReadModel
> {
  const dispatch = useDispatch();
  const postingViewState = useSelector(selectPostingTransactionCodesState);
  const mobilePostingItems = useSelector(
    selectEffectiveValues
  )?.mobilePostingItems;

  useEffect(() => {
    if (!postingViewState.didInitialize) {
      dispatch(postingViewActions.fetchTransactionCodes.trigger());
    }

    // eslint-disable-next-line
  }, []);

  return useMemo(() => {
    const transactionArray =
      postingViewState.data && mobilePostingItems
        ? postingViewState.data.filter(
            (transactionCode: TransactionCodeReadModel) =>
              mobilePostingItems.includes(transactionCode.id)
          )
        : [];

    return new Map<string, TransactionCodeReadModel>(
      transactionArray.map((transactionCode: TransactionCodeReadModel) => [
        transactionCode.id,
        transactionCode,
      ])
    );
  }, [postingViewState, mobilePostingItems]);
}
