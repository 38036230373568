import React, { memo } from 'react';
import classNames from 'classnames';

import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  MobileColor,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { Styleable } from '@ac/mobile-components/dist/interfaces/componentProps';

interface CountWithDescriptionBaseProps extends Styleable {
  count: number;
  description: string;
  prefix?: string;
}

const CountWithDescription: React.FC<CountWithDescriptionBaseProps> = (
  props: CountWithDescriptionBaseProps
) => {
  const classes = classNames(props.className, 'count-with-description');

  return (
    <div className={classes} style={props.style}>
      {props.prefix && (
        <AcText
          size={TextSize.Main2}
          color={MobileColor.Gray3}
          className="ac-spacing-left-md"
        >
          {`${props.prefix} `}
        </AcText>
      )}
      <AcText size={TextSize.Main2} weight={TextWeight.Semibold}>
        {`${props.count} `}
      </AcText>
      <AcText size={TextSize.Main2} weight={TextWeight.Regular}>
        {props.description}
      </AcText>
    </div>
  );
};

export default memo(CountWithDescription);
