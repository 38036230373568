import React, { FC, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import {
  selectHousekeepingDashboardFilters,
  selectHousekeepingDashboardIsLoading,
} from '@hkm/components/Housekeeping/Dashboard/domain/selectors';
import { HousekeepingFiltersData } from '@hkm/components/Housekeeping/shared/models/housekeepingFiltersData';
import QuickFilter from '@hkm/components/shared/QuickFilter/QuickFilter';
import { QuickFilterItem } from '@hkm/components/shared/QuickFilter/QuickFilterItem';
import {
  EnabledRoomStatesPurpose,
  useEnabledRoomStates,
} from '@hkm/shared/hooks/useEnabledRoomStates';

import { RoomStatus } from '@ac/library-api';
import { Styleable } from '@ac/mobile-components/dist/interfaces/componentProps';

// tslint:disable-next-line:no-empty-interface
export type HousekeepingDashboardQuickFilterProps = Styleable;

const HousekeepingDashboardQuickFilter: FC<
  HousekeepingDashboardQuickFilterProps
> = (props: HousekeepingDashboardQuickFilterProps) => {
  const dispatch = useDispatch();
  const roomStates = useEnabledRoomStates(EnabledRoomStatesPurpose.DISPLAY);
  const filters = useSelector(selectHousekeepingDashboardFilters);
  const isLoading = useSelector(selectHousekeepingDashboardIsLoading);

  function onQuickFilterChange(states: RoomStatus[]) {
    const newFilters: HousekeepingFiltersData = {
      ...filters,
      roomStates: states.length ? states : undefined,
    };
    dispatch(actions.pagination.setFilters(newFilters));
  }

  const filterItems: Array<QuickFilterItem<RoomStatus>> = useMemo(
    () =>
      roomStates.map((state) => ({
        value: state.code as RoomStatus,
        label: state.name ?? '',
      })),
    [roomStates]
  );

  return (
    <QuickFilter
      items={filterItems}
      active={filters?.roomStates}
      className={props.className}
      style={props.style}
      disabled={isLoading}
      onChange={onQuickFilterChange}
    />
  );
};

export default memo(HousekeepingDashboardQuickFilter);
