import {
  NumberRange,
  NumberRangeOrText,
} from '@hkm/shared/numberRange/numberRange';
import { NumberRangeType } from '@hkm/shared/numberRange/numberRangeType';
import { uniq } from 'lodash';

const prefixToTypeMap: Map<string, NumberRangeType> = new Map()
  .set('>=', NumberRangeType.GreaterOrEqual)
  .set('<=', NumberRangeType.LesserOrEqual)
  .set('>', NumberRangeType.Greater)
  .set('<', NumberRangeType.Lesser)
  .set('=', NumberRangeType.Equal);

const typeToPrefixMap: Map<NumberRangeType, string> = new Map(
  Array.from(prefixToTypeMap).map(([key, value]) => [value, key])
).set(NumberRangeType.Equal, '');

const numberRangeParsers: Array<(text: string) => NumberRangeOrText | null> = [
  singleNumberParser,
  prefixedSingleNumberParser,
  rangeParser,
];

const numberRangeAndTextParsers: Array<
  (text: string) => NumberRangeOrText | null
> = [...numberRangeParsers, textParser];

export function parseNumberOrTextToNumberRangeOrText(
  numberList: Array<string | number>
): NumberRangeOrText[] {
  const numbers: number[] = [];
  const strings: string[] = [];
  numberList.forEach((value) => {
    if (typeof value === 'string') {
      strings.push(value);
    } else {
      numbers.push(value);
    }
  });
  const sortedNumbers = uniq(numbers.sort());
  const sortedStrings = uniq(strings.sort());

  const ranges: NumberRangeOrText[] = [];
  for (let i = 0; i < sortedNumbers.length; ) {
    const start = sortedNumbers[i];
    const end = (() => {
      let rend = sortedNumbers[i];
      // eslint-disable-next-line no-empty
      while (++rend === sortedNumbers[++i]) {}

      return --rend;
    })();

    ranges.push({
      a: start,
      b: end,
      type: start === end ? NumberRangeType.Equal : NumberRangeType.InRange,
    });
  }

  return [...ranges, ...sortedStrings];
}

export function formatNumberRanges(
  ranges: NumberRangeOrText[] | undefined | null
): string {
  return (ranges || [])
    .map((range) =>
      typeof range === 'string' ? range : formatNumberRange(range)
    )
    .join(', ');
}

function formatNumberRange(range: NumberRange): string {
  return range.type === NumberRangeType.InRange
    ? `${range.a}-${range.b}`
    : `${typeToPrefixMap.get(range.type)}${range.a}`;
}

export interface ParseNumberRangesOptions {
  disallowTextPortions?: boolean;
}

export function parseNumberRanges(
  rangesString: string | undefined,
  options?: ParseNumberRangesOptions
): NumberRangeOrText[] | null {
  // Clean string from spaces
  rangesString = (rangesString || '').replace(/\s/g, '');

  // Get all ranges and remove empty ones
  const ranges: string[] = rangesString.split(',').filter(Boolean);

  // Parse ranges
  const disallowTextPortions = !!options?.disallowTextPortions;
  const selectedParserSet = disallowTextPortions
    ? numberRangeParsers
    : numberRangeAndTextParsers;
  const parsedRanges: NumberRangeOrText[] = ranges
    .map((text) => {
      for (const parser of selectedParserSet) {
        const result = parser(text);
        if (result) {
          return result;
        }
      }

      return null;
    })
    .filter(Boolean) as NumberRangeOrText[];

  // Only if all parsed properly, then the whole text is valid
  return parsedRanges.length === ranges.length ? parsedRanges : null;
}

function rangeParser(text: string): NumberRange | null {
  const splitText = text.split('-');
  if (splitText.length !== 2) {
    return null;
  }

  const numbers = splitText
    .map((part) => singleNumberParser(part))
    .filter(Boolean);
  if (numbers.length !== 2) {
    return null;
  }

  const [left, right] = numbers;
  let a = left?.a;
  let b = right?.a;

  if (a && b) {
    if (a === b) {
      return { type: NumberRangeType.Equal, a };
    }
    if (a > b) {
      const temp = a;
      a = b;
      b = temp;
    }

    return { type: NumberRangeType.InRange, a, b };
  } else {
    return null;
  }
}

function prefixedSingleNumberParser(text: string): NumberRange | null {
  const executed = /^\D+/.exec(text);
  if (!executed) {
    return null;
  }
  const prefix = executed[0];
  const type = prefixToTypeMap.get(prefix);
  if (type === undefined) {
    return null;
  }
  const num: string = text.substr(prefix.length);
  const singleNumber = singleNumberParser(num);

  return singleNumber ? { ...singleNumber, type } : null;
}

function singleNumberParser(text: string): NumberRange | null {
  let parsed: number | null = null;
  try {
    parsed = Math.abs(Number.parseInt(text, 10));
    if (
      isNaN(parsed) ||
      !isFinite(parsed) ||
      parsed.toString().length !== text.length
    ) {
      parsed = null;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return parsed === null ? null : { type: NumberRangeType.Equal, a: parsed };
}

function textParser(text: string): string | null {
  return text ? text : null;
}
