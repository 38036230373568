import React, { FC, memo } from 'react';
import PostingDashboardSearchFilter from '@hkm/components/Posting/Dashboard/SubHeader/SearchFilter/PostingDashboardSearchFilter';
import PostingDashboardSortSelect from '@hkm/components/Posting/Dashboard/SubHeader/SortSelector/PostingDashboardSortSelect';

import { AcSubheader } from '@ac/mobile-components/dist/components/layout';

import './PostingDashboardSubHeader.css';

const PostingDashboardSubHeader: FC = () => {
  const prefix = 'posting-dashboard-subheader';

  return (
    <AcSubheader className={prefix} noPadding={true}>
      <PostingDashboardSearchFilter />
      <div className={`${prefix}-option`}>
        <PostingDashboardSortSelect />
      </div>
    </AcSubheader>
  );
};

export default memo(PostingDashboardSubHeader);
