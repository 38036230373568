import { UnifiedReservationGuestMembership } from '@hkm/types/reservation/models/UnifiedReservationGuestMembership';

import {
  AttendantAssignmentMembershipDetails,
  HousekeepingRoomReservationMembershipDetailsDto,
} from '@ac/library-api';

export function mapReservationMembership(
  guestMembership: HousekeepingRoomReservationMembershipDetailsDto &
    AttendantAssignmentMembershipDetails
): UnifiedReservationGuestMembership {
  return {
    id: guestMembership.id,
    membershipLevel: guestMembership.membershipLevel,
    membershipType: guestMembership.membershipType,
  };
}
