import { AttendantAssignmentTaskData } from '@hkm/components/Attendant/shared/domain/interfaces/assignmentTask';
import { createGreenServiceToggleActions } from '@hkm/shared/domain/greenServiceToggle/greenServiceToggleActionsFactory';
import { createGuestServiceChangeActions } from '@hkm/shared/domain/guestServiceChange/guestServiceChangeActionsFactory';
import { createHousekeepingStatusChangeActions } from '@hkm/shared/domain/housekeepingStatusChange/housekeepingStatusChangeActionsFactory';
import { createMaintenanceFetchAttachmentActions } from '@hkm/shared/domain/maintenanceAttachment/fetchAttachement/maintenanceFetchAttachmentActionsFactory';
import { createRoomOccupancyActions } from '@hkm/shared/domain/occupancyChange/occupancyChangeActionsFactory';
import { createRoomConditionsChangeActions } from '@hkm/shared/domain/roomConditionsChange/roomConditionsChangeActionsFactory';
import { createRoomStatusChangeActions } from '@hkm/shared/domain/roomStatusChange/roomStatusChangeActionsFactory';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { AttendantSheet } from '@hkm/types/attendant/models/AttendantSheet';

import {
  createAction,
  getOneAction,
  updateAction,
} from '@ac/library-utils/dist/redux-utils';

import { AttendantAssignmentTasksData } from './interfaces/assignmentTasksData';

export const fetchAssignedSheet = getOneAction<undefined, AttendantSheet>(
  'attendant/assignment/fetchSheet'
);
export const setViewingAttendantRoom = createAction<AttendantRoom | null>(
  'attendant/assignment/setViewingAttendantRoom'
);

export const addStaleRoomIds = createAction<string[]>(
  'attendant/assignment/addStaleRoomIds'
);
export const setStaleSheetVersionId = createAction<number>(
  'attendant/assignment/setStaleSheetVersionId'
);
export const clearAttendantStaleData = createAction(
  'attendant/assignment/clearAttendantStaleData'
);

export const roomStatus = createRoomStatusChangeActions('attendant/assignment');
export const roomConditions = createRoomConditionsChangeActions(
  'attendant/assignment'
);
export const guestService = createGuestServiceChangeActions(
  'attendant/assignment'
);
export const housekeepingStatus = createHousekeepingStatusChangeActions(
  'attendant/assignment'
);
export const roomOccupancy = createRoomOccupancyActions('attendant/assignment');
export const greenService = createGreenServiceToggleActions(
  'attendant/assignment'
);
export const maintenanceAttachment = createMaintenanceFetchAttachmentActions(
  'attendant/assignment'
);

export const runAttendantTask = updateAction<
  AttendantAssignmentTaskData,
  undefined
>('attendant/assignment/runTask');
export const runAttendantTasks = updateAction<
  AttendantAssignmentTasksData,
  undefined
>('attendant/assignment/runTasks');
