import {
  TasksAccount,
  TasksArAccount,
} from '@hkm/components/TaskManagement/models/cashiering';
import { getProfileName } from '@hkm/components/TaskManagement/utils/getProfileName';
import { trimString } from '@hkm/components/TaskManagement/utils/trimString';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import i18n from 'i18next';

import {
  AccountDashboardItem,
  AccountDashboardMembership,
  AccountDashboardProfileDetails,
  AccountDashboardReservationDetails,
  ARAccountDashboardItem,
  CodeValue,
  PageResponse,
  QueryQueryParams,
  RawAccountDashboardItem,
  RawARAccountDashboardItem,
  TitleOrders,
} from '@ac/library-api';
import { ViewsApi } from '@ac/library-api/dist/api/v0/cashiering';

export const getARDashboard = async (
  params?: QueryQueryParams
): Promise<TasksArAccount[]> => {
  let queryParams = {};
  if (params) {
    const { filter, query } = params;
    queryParams = filter ? { filter, ...queryParams } : { ...queryParams };
    queryParams = query ? { query, ...queryParams } : { ...queryParams };
  }

  return (
    (await ViewsApi.ARAccountsApi.getARDashboard({
      queryParams,
    })) as PageResponse<RawARAccountDashboardItem, ARAccountDashboardItem>
  ).results.map((ar) => mapArAccount(ar));
};

const mapArAccount = (ar: ARAccountDashboardItem): TasksArAccount => {
  const accountName = ar.accountNameDetails
    ? `${i18n.t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.NAME')} ${
        ar.accountNameDetails.fullName
      },`
    : '';
  const accountNumber = `${i18n.t(
    'TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.ACCOUNT'
  )} ${ar.accountNumber},`;
  const accountType = ar.arAccountType
    ? `${i18n.t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.TYPE')} ${
        ar.arAccountType?.code
      }`
    : '';

  return {
    ...ar,
    displayName: trimString(`${accountNumber} ${accountName} ${accountType}`),
  };
};

export const getAccountDashboard = async (
  nameSetting: TitleOrders,
  params?: QueryQueryParams
): Promise<TasksAccount[]> => {
  const allAccountStatusesFilter = `(accountStatusCode.code==${APP_CONSTANTS.ACCOUNT_STATUSES.join(
    ',accountStatusCode.code=='
  )})`;

  let queryParams = {};
  if (params) {
    const { filter, query } = params;
    const fullFilter = filter
      ? `${filter};${allAccountStatusesFilter}`
      : allAccountStatusesFilter;

    queryParams = query
      ? { filter: fullFilter, query, ...queryParams }
      : { filter: fullFilter, ...queryParams };
  }

  return (
    (await ViewsApi.AccountsApi.getDashboard({
      queryParams,
    })) as PageResponse<RawAccountDashboardItem, AccountDashboardItem>
  ).results.map((account) => normalizeAccount(account, nameSetting));
};

const normalizeAccount = (
  {
    id,
    balance,
    authorizationAmount,
    accountNumber,
    currencyCode,
    accountStatusCode,
    profileDetails,
    membership,
    reservationDetails,
  }: {
    id: string;
    balance: number;
    authorizationAmount?: number;
    accountNumber?: string;
    currencyCode?: CodeValue;
    accountStatusCode?: CodeValue;
    profileDetails?: AccountDashboardProfileDetails;
    membership?: AccountDashboardMembership;
    reservationDetails?: AccountDashboardReservationDetails;
  },
  nameSetting: TitleOrders
): TasksAccount => {
  const normalizedAccount: TasksAccount = {
    id,
    balance,
    authorizationAmount,
    accountNumber,
    currencyCode,
    accountStatusCode,
    profileDetails,
    membership,
    reservationDetails,
    displayName: '',
  };

  let profileName =
    profileDetails?.firstName &&
    getProfileName({
      firstName: profileDetails?.firstName,
      lastName: profileDetails?.lastName,
      title: profileDetails?.titleCode?.description,
      nameSetting,
    });

  if (!profileName) {
    profileName =
      profileDetails?.companyName ?? profileDetails?.travelAgentName;
  }

  return {
    ...normalizedAccount,
    displayName: mapAccountToDisplayName(normalizedAccount, nameSetting),
    profileName,
  };
};

const mapAccountToDisplayName = (
  account: TasksAccount,
  nameSetting?: TitleOrders
): string => {
  const roomNumber = account.reservationDetails
    ? `${i18n.t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.ROOM')} ${
        account.reservationDetails?.roomNumber
      },`
    : '';
  let profileName =
    account.profileDetails &&
    getProfileName({
      firstName: account.profileDetails?.firstName,
      lastName: account.profileDetails?.lastName,
      title: account.profileDetails?.titleCode?.description,
      nameSetting,
    });

  if (!profileName) {
    profileName =
      account.profileDetails?.companyName ??
      account.profileDetails?.travelAgentName ??
      '';
  }

  const profileFullName = `${i18n.t(
    'TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.PROFILE'
  )} ${profileName}`;
  const accountNumber = account.accountNumber
    ? `${i18n.t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.ACCOUNT')} ${
        account.accountNumber
      },`
    : '';
  const accStatus = account.accountStatusCode
    ? `${i18n.t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.STATUS')} ${
        account.accountStatusCode.code
      }`
    : '';

  return trimString(
    `${accountNumber} ${profileFullName} ${roomNumber} ${accStatus}`
  );
};
