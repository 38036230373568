import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CountWithDescription from '@hkm/components/Attendant/SheetSummary/body/CountWithDesciption/CountWithDescription';
import { SheetsSummaryAccordion } from '@hkm/components/Attendant/SheetSummary/body/SheetSummaryAccordion/SheetsSummaryAccordion';
import { SheetSummaryGuestServiceStatus } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryGuestServiceStatus';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';

import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  FlexDirection,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

interface GuestServiceRequestProps {
  guestServiceStatus: SheetSummaryGuestServiceStatus;
}

export const GuestServiceRequest = (props: GuestServiceRequestProps) => {
  const { t } = useTranslation();
  const effectiveValues = useSelector(selectEffectiveValues);

  const { serviceDeferred, serviceRequested, adHocCount } =
    props.guestServiceStatus;
  const renderSubtitle = useMemo(
    () => (
      <AcText
        size={TextSize.H1}
        weight={TextWeight.Semibold}
        testSelector="guest-service-request-total"
      >
        {adHocCount}
      </AcText>
    ),
    [adHocCount]
  );

  const renderContent = useMemo(
    () => (
      <ac-flex direction={FlexDirection.column}>
        {effectiveValues?.guestServiceStatusServiceDeferred && (
          <CountWithDescription
            count={serviceDeferred}
            description={t(
              `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.ServiceDeferred}`
            )}
          />
        )}

        {effectiveValues?.guestServiceStatusServiceRequested && (
          <CountWithDescription
            count={serviceRequested}
            description={t(
              `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.ServiceRequested}`
            )}
          />
        )}
      </ac-flex>
    ),
    [
      t,
      effectiveValues?.guestServiceStatusServiceDeferred,
      effectiveValues?.guestServiceStatusServiceRequested,
      serviceDeferred,
      serviceRequested,
    ]
  );

  return (
    <SheetsSummaryAccordion
      icon={IconName.guestServiceStatus}
      title={t('GLOBAL.GUEST_SERVICE_REQUEST.TITLE')}
      renderSubtitle={renderSubtitle}
      renderContent={renderContent}
      expandDisabled={adHocCount === 0}
    />
  );
};
