import * as actions from '@hkm/components/Login/domain/actions';
import { UserContext } from '@hkm/components/Login/domain/interfaces';
import { SystemUserType } from '@hkm/shared/enum/systemUserType';
import { ReducerMap } from 'redux-actions';

import { UserContextDetails } from '@ac/library-api/dist/businessContext/user/types';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: UserContext = {
  systemUser: null,
  userDetails: null,
};

const reducerMap: ReducerMap<UserContext, unknown> = {
  [actions.userLogin.success]: (state, action: Action<boolean>) => ({
    ...state,
    systemUser: {
      type: action.payload
        ? SystemUserType.Authorised
        : SystemUserType.Anonymous,
    },
  }),
  [actions.userDetails.success]: (
    state,
    action: Action<UserContextDetails>
  ) => ({
    ...state,
    userDetails: action.payload,
  }),
};

export default handleActions(reducerMap, initialState);
