import { ToastInterface } from '@ac/web-components/dist/types/components/ac-toast/types';

export interface Toast extends ToastInterface {
  id: string;
}

export interface NotificationsState {
  toasts: Toast[];
}

export function getDefaultNotificationsState(): NotificationsState {
  return {
    toasts: [],
  };
}
