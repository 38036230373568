import { AttendantTaskOrder } from '@hkm/components/Attendant/shared/config/attendantTaskOrder';
import { getReservedKind } from '@hkm/shared/reservedKind/getReservedKind';
import { groupReservedKinds } from '@hkm/shared/reservedKind/groupReservedKinds';
import { getRoomOccupancy } from '@hkm/shared/roomOccupancy/factory/getRoomOccupancy';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { mapAttendantMaintenanceDetails } from '@hkm/types/maintenance/mappers/mapMaintenanceDetails';
import { mapAttendantReservationDetails } from '@hkm/types/reservation/mappers/mapReservationDetails';
import { mapAttendantRoomDetails } from '@hkm/types/room/mappers/mapRoomDetails';

import {
  AttendantAssignmentRoomDetails,
  AttendantAssignmentTaskDetails,
  AttendantTaskProgressType,
  FrontDeskStatus,
  HousekeepingStatus,
  ReservationStatus,
} from '@ac/library-api';

export function createAttendantRoom(
  attendantAssignmentRoomDetails: AttendantAssignmentRoomDetails,
  businessDate: string,
  propertyCheckInTime: string,
  propertyCheckOutTime: string
): AttendantRoom {
  const reservedKinds = groupReservedKinds({
    businessDate,
    propertyCheckInTime,
    propertyCheckOutTime,
    reservations: attendantAssignmentRoomDetails.reservations.map(
      (reservation) => mapAttendantReservationDetails(reservation)
    ),
    maintenances: attendantAssignmentRoomDetails.maintenance
      ? [
          mapAttendantMaintenanceDetails(
            attendantAssignmentRoomDetails.maintenance
          ),
        ]
      : undefined,
    reservationKindResolver: getReservedKind,
  });

  // In case when we have more than 1 task in room then show only one based on task priority order
  const sortedTasks = attendantAssignmentRoomDetails.assignedTasks
    .concat()
    .sort(
      (a: AttendantAssignmentTaskDetails, b: AttendantAssignmentTaskDetails) =>
        AttendantTaskOrder.indexOf(
          a.progress?.code as AttendantTaskProgressType
        ) -
        AttendantTaskOrder.indexOf(
          b.progress?.code as AttendantTaskProgressType
        )
    );
  const activeTask = sortedTasks[0];

  const roomOccupancy = getRoomOccupancy(
    {
      currentReservations: (reservedKinds.currentReservations || []).map(
        (reservation) => ({
          occupancyDetails: reservation.occupancyDetails,
          status: reservation.status?.code as ReservationStatus,
        })
      ),
      frontdeskStatus:
        attendantAssignmentRoomDetails.frontdeskStatus?.code ??
        FrontDeskStatus.Vacant,
      housekeepingStatus:
        attendantAssignmentRoomDetails.housekeepingStatus?.code ??
        HousekeepingStatus.Vacant,
      occupancyDiscrepancy: attendantAssignmentRoomDetails.occupancyDiscrepancy,
    },
    businessDate
  );

  const unifiedRoomDetails = mapAttendantRoomDetails(
    attendantAssignmentRoomDetails
  );

  const isInPendingRoomQueue =
    attendantAssignmentRoomDetails.queueRoomEntries.some(
      (queueRoomEntry) => !queueRoomEntry.readyAt
    );

  return {
    ...attendantAssignmentRoomDetails,
    ...reservedKinds,
    activeTask,
    assignedTasks: sortedTasks,
    roomOccupancy,
    unifiedRoomDetails,
    isInPendingRoomQueue,
  };
}
