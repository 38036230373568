import * as appActions from '@hkm/components/App/domain/actions';
import { MaintenanceUploadAttachmentActions } from '@hkm/shared/domain/maintenanceAttachment/uploadAttachement/maintenanceUploadAttachmentActions';
import {
  AddMaintenanceAttachmentData,
  RemoveMaintenanceAttachmentData,
  UpdateMaintenanceAttachmentData,
} from '@hkm/shared/domain/maintenanceAttachment/uploadAttachement/models/maintenanceUploadAttachmentData';
import { getHousekeepingRoomVersionId } from '@hkm/shared/services/getHousekeepingRoomVersionId';
import { getCustomConfig } from '@hkm/utils/getCustomConfig';
import { takeLatest } from '@redux-saga/core/effects';
import i18n from 'i18next';
import { put } from 'redux-saga/effects';
import { call } from 'redux-saga-test-plan/matchers';

import { LibraryApiResponse, RoomMaintenancesPathParam } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { Config } from '@ac/library-utils/dist/declarations/config';

interface MaintenanceAttachmentApi {
  postRoomMaintenancesFile: (args: {
    pathParams: RoomMaintenancesPathParam;
    data: FormData;
    customConfig: Config;
    fullResponse: boolean;
  }) => Promise<LibraryApiResponse<File>>;
  patchRoomMaintenancesFile: (args: {
    pathParams: RoomMaintenancesPathParam;
    data: { name: string; description: string };
    customConfig: Config;
  }) => Promise<LibraryApiResponse<File>>;
  deleteRoomMaintenancesFile: (args: {
    pathParams: RoomMaintenancesPathParam;
    customConfig: Config;
  }) => Promise<LibraryApiResponse<File>>;
  getRoomMaintenancesFileContent: (args: {
    pathParams: RoomMaintenancesPathParam;
  }) => Promise<LibraryApiResponse<File>>;
}

export interface MaintenanceAttachmentSagaApi {
  maintenanceAttachmentApi: MaintenanceAttachmentApi;
}

export function createUploadMaintenanceAttachmentSaga(
  actions: MaintenanceUploadAttachmentActions,
  api: MaintenanceAttachmentSagaApi
) {
  function* addAttachment(action: Action<AddMaintenanceAttachmentData>) {
    try {
      let didSetMetadata: boolean = false;

      const fileId: string = yield call(
        postRoomMaintenancesFile,
        action.payload
      );

      if (fileId) {
        didSetMetadata = yield call(
          patchRoomMaintenancesFileIfNeedds,
          action.payload,
          fileId
        );
      }

      if (fileId) {
        yield put(actions.addAttachment.success(action.payload.roomId));
        if (didSetMetadata) {
          yield put(
            appActions.displaySuccess(i18n.t('ATTACHMENTS.ADD_SUCCESS'))
          );
        } else {
          yield put(
            appActions.displayWarning(i18n.t('ATTACHMENTS.ADD_PARTIAL_SUCCESS'))
          );
        }
      }
    } catch (e) {
      yield put(actions.addAttachment.failure(e));
      yield put(appActions.displayExtractedError(e));
    }
  }

  function* postRoomMaintenancesFile(data: AddMaintenanceAttachmentData) {
    const formData = new FormData();
    formData.append('file', data.file as File);

    const uploadResponse: LibraryApiResponse<File> =
      yield api.maintenanceAttachmentApi.postRoomMaintenancesFile({
        pathParams: {
          roomId: data.roomId,
          maintenanceId: data.maintenanceId,
        },
        data: formData,
        customConfig: getCustomConfig({
          version: data.roomVersionId,
        }) as Config,
        fullResponse: true,
      });

    return uploadResponse.headers.location?.split('/').pop();
  }

  function* patchRoomMaintenancesFileIfNeedds(
    data: AddMaintenanceAttachmentData,
    fileId: string
  ) {
    const needsUpdate: boolean =
      !!data.description || data.file?.name !== data.name;

    if (needsUpdate) {
      const updatedRoomVersionId: number = yield getHousekeepingRoomVersionId(
        data.roomId
      );

      yield api.maintenanceAttachmentApi.patchRoomMaintenancesFile({
        pathParams: {
          roomId: data.roomId,
          maintenanceId: data.maintenanceId,
          fileId,
        },
        data: {
          name: data.name,
          description: data.description,
        },
        customConfig: getCustomConfig({
          version: updatedRoomVersionId,
        }) as Config,
      });
    }

    return true;
  }

  function* removeAttachment(action: Action<RemoveMaintenanceAttachmentData>) {
    try {
      yield api.maintenanceAttachmentApi.deleteRoomMaintenancesFile({
        pathParams: {
          roomId: action.payload.roomId,
          maintenanceId: action.payload.maintenanceId,
          fileId: action.payload.fileId,
        },
        customConfig: getCustomConfig({
          version: action.payload.roomVersionId,
        }) as Config,
      });

      yield put(actions.removeAttachment.success(action.payload.roomId));
      yield put(
        appActions.displaySuccess(i18n.t('ATTACHMENTS.REMOVE_SUCCESS'))
      );
    } catch (e) {
      yield put(actions.removeAttachment.failure(e));
      yield put(appActions.displayExtractedError(e));
    }
  }

  function* updateAttachment(action: Action<UpdateMaintenanceAttachmentData>) {
    try {
      yield api.maintenanceAttachmentApi.patchRoomMaintenancesFile({
        pathParams: {
          roomId: action.payload.roomId,
          maintenanceId: action.payload.maintenanceId,
          fileId: action.payload.fileId,
        },
        data: {
          name: action.payload.name,
          description: action.payload.description,
        },
        customConfig: getCustomConfig({
          version: action.payload.roomVersionId,
        }) as Config,
      });

      yield put(actions.updateAttachment.success(action.payload.roomId));
      yield put(
        appActions.displaySuccess(i18n.t('ATTACHMENTS.UPDATE_SUCCESS'))
      );
    } catch (e) {
      yield put(actions.updateAttachment.failure(e));
      yield put(appActions.displayExtractedError(e));
    }
  }

  function* getFile(action: Action<UpdateMaintenanceAttachmentData>) {
    try {
      const file: File =
        yield api.maintenanceAttachmentApi.getRoomMaintenancesFileContent({
          pathParams: {
            roomId: action.payload.roomId,
            maintenanceId: action.payload.maintenanceId,
            fileId: action.payload.fileId,
          },
        });

      yield put(
        actions.getAttachmentFile.success({
          attachmentId: action.payload.fileId,
          file,
        })
      );
    } catch (e) {
      yield put(actions.getAttachmentFile.failure(e));
      yield put(appActions.displayExtractedError(e));
    }
  }

  return function* () {
    yield takeLatest(actions.addAttachment.trigger, addAttachment);
    yield takeLatest(actions.removeAttachment.trigger, removeAttachment);
    yield takeLatest(actions.updateAttachment.trigger, updateAttachment);
    yield takeLatest(actions.getAttachmentFile.trigger, getFile);
  };
}
