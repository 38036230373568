import { DiscrepancyType } from '@ac/library-api';
import { MobileColor } from '@ac/mobile-components/dist/enums';

const colorMap = new Map<DiscrepancyType, MobileColor>()
  .set(DiscrepancyType.Skip, MobileColor.DiscrepancySkip)
  .set(DiscrepancyType.Sleep, MobileColor.DiscrepancySleep)
  .set(DiscrepancyType.Occupancy, MobileColor.DiscrepancyOccupancy);

export function getDiscrepancyColor(status: DiscrepancyType): MobileColor {
  return colorMap.get(status) || MobileColor.Gray3;
}
