import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionFormData } from '@hkm/components/Posting/PostingView/Body/PostingDetailsBody';
import { ReservationChargesData } from '@hkm/shared/domain/reservationCharges/models/reservationChargesData';

import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFooterWideButtons } from '@ac/mobile-components/dist/components/layout';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { FormRenderProps } from '@ac/react-infrastructure';
import { IconName } from '@ac/web-components';

export interface PostingFooterProps extends Testable {
  formProps: FormRenderProps<TransactionFormData>;
  onCloseHandler(): void;
  onSubmitHandler(data: ReservationChargesData): void;
}

const PostingFooter: FC<PostingFooterProps> = (props: PostingFooterProps) => {
  const { t } = useTranslation();
  const { values } = props.formProps;
  const totalItems = useMemo(
    () =>
      Object.values(values).reduce((value: number, sum: number) => {
        return sum + value;
      }, 0),
    [values]
  );

  return (
    <AcFooterWideButtons>
      <AcButton
        onClick={props.formProps.handleSubmit}
        disabled={props.formProps.invalid || totalItems <= 0}
        testSelector={`${props.testSelector}-confirm-button`}
      >
        <AcButtonContent text={t('GLOBAL.SUBMIT')} />
      </AcButton>

      <AcButton
        onClick={props.onCloseHandler}
        pattern={AcButtonPattern.Tertiary}
        testSelector={`${props.testSelector}-cancel-button`}
      >
        <AcButtonContent icon={IconName.cancel} text={t('GLOBAL.CANCEL')} />
      </AcButton>
    </AcFooterWideButtons>
  );
};

export default memo(PostingFooter);
