import { useCallback } from 'react';
import { useAppContext } from '@hkm/features/app/store/app/context';
import { isPropertyLocatedInAnotherRegion } from '@hkm/features/property/isPropertyLocatedInAnotherRegion';
import { redirectToNewRegion } from '@hkm/features/region/redirectToNewRegion';
import { Navigator } from '@hkm/utils/navigator';

import { SessionService } from '@ac/library-utils/dist/services';

interface Output {
  changeProperty(id: string): void;
}

export function useChangeProperty(): Output {
  const { state } = useAppContext();

  const changeProperty = useCallback(
    (id: string): void => {
      const property = state.properties?.find(({ unitId }) => unitId === id);

      if (!property) {
        return;
      }

      SessionService.setPropertyId(id);

      isPropertyLocatedInAnotherRegion(property)
        ? redirectToNewRegion(property)
        : Navigator.reload();
    },
    [state.properties]
  );

  return {
    changeProperty,
  };
}
