import { PostingSort } from '@hkm/components/Posting/Dashboard/enum/postingSort';
import { PostingFiltersData } from '@hkm/components/Posting/Dashboard/filters/postingFiltersData';
import { createPaginationActions } from '@hkm/store/pagination/paginationActionsFactory';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';

import { HousekeepingRoomDetailsDto } from '@ac/library-api';
import { createAction } from '@ac/library-utils/dist/redux-utils';

export const pagination = createPaginationActions<
  HousekeepingRoomDetailsDto,
  HousekeepingRoom,
  PostingSort,
  PostingFiltersData
>('posting/dashboard');
export const patchChangedRoom = createAction<string>(
  'housekeeping/posting/dashboard/patchChangedRoom'
);
export const replaceChangedRoom = createAction<HousekeepingRoom>(
  'housekeeping/posting/dashboard/replaceChangedRoom'
);
