import * as appActions from '@hkm/components/App/domain/actions';
import * as notificationActions from '@hkm/components/App/notificationConsumer/domain/actions';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { RoomStatusChangeActions } from '@hkm/shared/domain/roomStatusChange/roomStatusChangeActions';
import { RoomStatusChangeRequest } from '@hkm/shared/domain/roomStatusChange/roomStatusChangeRequest';
import { SourceApiType } from '@hkm/shared/domain/sourceApiType';
import { getCustomConfig } from '@hkm/utils/getCustomConfig';
import { put, takeLatest } from '@redux-saga/core/effects';
import i18n from 'i18next';

import {
  HousekeepingRoomsApi,
  MobileAttendantApi,
} from '@ac/library-api/dist/api/v0/housekeeping';
import { Action } from '@ac/library-utils/dist/declarations';

export function createAttendantRoomStatusChangeSaga(
  actions: RoomStatusChangeActions
) {
  return createRoomStatusChangeSaga(actions, SourceApiType.MobileAttendantApi);
}

export function createHousekeepingRoomStatusChangeSaga(
  actions: RoomStatusChangeActions
) {
  return createRoomStatusChangeSaga(
    actions,
    SourceApiType.HousekeepingRoomsApi
  );
}

function createRoomStatusChangeSaga(
  actions: RoomStatusChangeActions,
  sourceApiType: SourceApiType
) {
  function housekeepingChangeStatus(action: Action<RoomStatusChangeRequest>) {
    return HousekeepingRoomsApi.postChangeRoomStatus({
      pathParams: { roomNumber: action.payload.roomNumber },
      data: {
        statusCode: action.payload.roomStatus,
      },
      customConfig: getCustomConfig({
        version: action.payload.housekeepingRoomVersion,
      }),
    });
  }

  function attendantChangeStatus(action: Action<RoomStatusChangeRequest>) {
    return MobileAttendantApi.postChangeRoomStatus({
      pathParams: { roomId: action.payload.roomId },
      data: {
        statusCode: action.payload.roomStatus,
      },
      customConfig: getCustomConfig({
        version: action.payload.housekeepingRoomVersion,
      }),
    });
  }

  function* changeStatus(action: Action<RoomStatusChangeRequest>) {
    try {
      yield sourceApiType === SourceApiType.MobileAttendantApi
        ? attendantChangeStatus(action)
        : housekeepingChangeStatus(action);

      yield put(actions.changeRoomStatus.success(action.payload.roomId));
      yield put(
        notificationActions.expectNotification(
          SignalREventType.HousekeepingRoomChanged
        )
      );
      yield put(
        appActions.displaySuccess(i18n.t('ROOM_STATUS_CHANGE.SUCCESS'))
      );
    } catch (e) {
      yield put(appActions.displayExtractedError(e));
      yield put(actions.changeRoomStatus.failure(e));
    }
  }

  return function* () {
    yield takeLatest(actions.changeRoomStatus.trigger, changeStatus);
  };
}
