import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatNumberRanges,
  parseNumberRanges,
} from '@hkm/shared/numberRange/numberRangesParser';
import { getFieldErrorMessage } from '@hkm/shared/validation/errorProvider';

import { AcFieldText } from '@ac/mobile-components/dist/components/field';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps/childless';
import { Styleable } from '@ac/mobile-components/dist/interfaces/componentProps/styleable';
import { genericMemo } from '@ac/mobile-components/dist/utils';
import {
  formFieldFactory,
  FormRenderProps,
  KeyTypeInObjectOrArray,
  ValueInObjectOrArray,
} from '@ac/react-infrastructure';

interface AdvancedFiltersMultiSelectsProps<T> extends Childless, Styleable {
  testSelectorPrefix: string;
  formProps: FormRenderProps<T>;
  field: keyof T;
  label: string;
}

function AdvancedFiltersNumberRange<T>(
  props: AdvancedFiltersMultiSelectsProps<T>
): ReactElement {
  const { t } = useTranslation();
  const FormField = useMemo(() => formFieldFactory<T>(), []);

  function onBlur() {
    const parsed = parseNumberRanges(
      props.formProps.values[props.field] as unknown as string | undefined
    );
    if (parsed) {
      props.formProps.form.change(
        props.field,
        formatNumberRanges(parsed) as never
      );
    }
  }

  return (
    <FormField valuePath={props.field as unknown as KeyTypeInObjectOrArray<T>}>
      {(fieldRenderProps) => (
        <AcFieldText
          {...fieldRenderProps.input}
          onChange={(value) =>
            fieldRenderProps.input.onChangeCallback(
              value as ValueInObjectOrArray<T, KeyTypeInObjectOrArray<T>>
            )
          }
          value={fieldRenderProps.input.value as string | undefined}
          className={props.className}
          style={props.style}
          onBlur={onBlur}
          validation={getFieldErrorMessage(fieldRenderProps)}
          placeholder={t('GLOBAL.FILL')}
          label={props.label}
          testSelector={`${props.testSelectorPrefix}-${String(props.field)}`}
        />
      )}
    </FormField>
  );
}

export default genericMemo(AdvancedFiltersNumberRange);
