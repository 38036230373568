/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { PropsWithChildren, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { AcMobileProvider } from '@ac/mobile-components/dist/config';

import '@ac/mobile-components/dist/styles/index.css';

interface Props {
  root: HTMLElement;
}

/**
 * @todo
 *
 * It should be removed when mobile-components will be rewritten to web-components
 */
export const MobileProvider = ({
  root,
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  const config = useMemo(
    () => ({
      rootElement: root,
      linkComponent: Link,
    }),
    [root]
  );

  return <AcMobileProvider config={config}>{children}</AcMobileProvider>;
};
