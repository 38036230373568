import React, { FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@hkm/components/App/notificationConsumer/domain/actions';
import { NotificationConsumer } from '@hkm/components/App/notificationConsumer/NotificationConsumer';
import ChannelsManagerContext from '@hkm/components/App/notificationConsumer/NotificationConsumerContext';
import { selectActiveProperty } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';

import {
  useComponentDidUpdateEffect,
  usePrevious,
} from '@ac/mobile-components/dist/hooks';
import { ChildRequired } from '@ac/mobile-components/dist/interfaces/componentProps';

export interface ConsumerMap {
  [key: string]: NotificationConsumer[];
}

const NotificationConsumerManager: FC<ChildRequired> = (
  props: ChildRequired
) => {
  const dispatch = useDispatch();
  const [notificationConsumerMap, setNotificationConsumerMap] =
    useState<ConsumerMap>({});

  const activeProperty = useSelector(selectActiveProperty);
  const previousPropertyId = usePrevious(activeProperty && activeProperty.id);

  const subscribe = (
    signalREventType: SignalREventType,
    notificationConsumer: NotificationConsumer
  ) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!notificationConsumerMap.hasOwnProperty(signalREventType)) {
      notificationConsumerMap[signalREventType] = [];
    }

    notificationConsumerMap[signalREventType].push(notificationConsumer);
    setNotificationConsumerMap({ ...notificationConsumerMap });
  };

  const unSubscribe = (
    signalREventType: SignalREventType,
    consumerName: string
  ) => {
    // eslint-disable-next-line no-prototype-builtins
    if (notificationConsumerMap.hasOwnProperty(signalREventType)) {
      const correspondents = notificationConsumerMap[signalREventType]
        .map((notificationConsumer: NotificationConsumer) => {
          return notificationConsumer.consumerName !== consumerName
            ? notificationConsumer
            : undefined;
        })
        .filter(Boolean) as NotificationConsumer[];

      notificationConsumerMap[signalREventType] = correspondents;
      setNotificationConsumerMap({ ...notificationConsumerMap });
    }
  };

  const dispatchSubscription = (signalREventType: SignalREventType) => {
    dispatch(
      actions.subscribeNotification({
        signalREventType,
        propertyId: activeProperty?.id ?? '',
        notificationConsumers: notificationConsumerMap[signalREventType],
      })
    );
  };

  // Refresh subscription each time notificationConsumerMap has changed
  useEffect(
    () =>
      Object.keys(notificationConsumerMap).forEach(
        (signalREventType: SignalREventType) =>
          dispatchSubscription(signalREventType)
      ),

    // eslint-disable-next-line
    [notificationConsumerMap]
  );

  // Refresh all subscription in case when property id is changed
  useComponentDidUpdateEffect(() => {
    if (previousPropertyId && activeProperty?.id !== previousPropertyId) {
      // remove all subscription
      Object.keys(notificationConsumerMap).forEach(
        (signalREventType: SignalREventType) =>
          dispatchSubscription(signalREventType)
      );

      // set all subscription once again with new property
      Object.keys(notificationConsumerMap).forEach(
        (signalREventType: SignalREventType) =>
          dispatchSubscription(signalREventType)
      );
    }
  }, [activeProperty]);

  return (
    <ChannelsManagerContext.Provider
      value={{ subscribe, unSubscribe, notificationConsumerMap }}
    >
      {props.children}
    </ChannelsManagerContext.Provider>
  );
};

export default memo(NotificationConsumerManager);
