import APP_CONSTANTS from '@hkm/constants/app.constants';

import { addModuleClass } from '@ac/library-utils/dist/utils/micro-frontends';
import { defineCustomElements } from '@ac/web-components/dist/loader';

import '@ac/web-components/dist/web-components/web-components.css';

export function initializeWebComponents(): void {
  addModuleClass(APP_CONSTANTS.MODULE_CLASS);

  defineCustomElements(window);
}
