import { TransactionCodesFiltersData } from '@hkm/components/Posting/PostingView/filters/TransactionCodesFiltersData';

export const transactionCodesDefaultFilterValues: TransactionCodesFiltersData =
  {
    allowManualPosting: true,
    defaultPostingValue: 0,
    isActive: true,
    isNonRevenue: false,
    isPostingAllowed: true,
  };
