import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';

import { createAction, getOneAction } from '@ac/library-utils/dist/redux-utils';

export const reset = createAction('posting/reset');

export const initializeHousekeepingPostingRoom = createAction<string>(
  'posting/housekeeping/initialize'
);
export const setRoomFromDashboard = createAction<HousekeepingRoom>(
  'posting/housekeeping/setRoomFromDashboard'
);
export const fetchHousekeepingPostingRoom = getOneAction<
  undefined,
  HousekeepingRoom
>('posting/housekeeping/fetchRoom');

export const initializeAttendantPostingRoom = createAction<string>(
  'posting/attendant/initialize'
);
export const setRoomFromAttendantSheet = createAction<AttendantRoom>(
  'posting/attendant/setRoomFromAttendantSheet'
);
