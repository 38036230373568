import { GroupedReservedKinds } from '@hkm/shared/reservedKind/groupedReservedKinds';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

import { ReservationStatus } from '@ac/library-api';

export function isGreenServiceObtainableByRoom(
  room: GroupedReservedKinds,
  statusExtractor: (reservation: UnifiedReservationDetails) => ReservationStatus
): boolean {
  return !!room.currentReservations?.some(
    (reservation) => statusExtractor(reservation) === ReservationStatus.IH
  );
}
