import { useCallback } from 'react';
import { getProperties } from '@hkm/features/app/commands/initialization/property/request/getProperties';
import { setPropertyInThirdPartLibraries } from '@hkm/features/property/setPropertyInThirdPartLibraries';
import { getEffectiveProperties } from '@hkm/features/region/getEffectiveProperties';
import { redirectToNewRegion } from '@hkm/features/region/redirectToNewRegion';
import { shouldUserOperateInAnotherRegion } from '@hkm/features/region/shouldUserOperateInAnotherRegion';

import { UnitAssignmentListItem } from '@ac/library-api';

interface SetUpOutput {
  properties: UnitAssignmentListItem[];
  property: UnitAssignmentListItem | undefined;
}

interface Output {
  setUp(): Promise<SetUpOutput>;
}

export function usePropertyInitialization(): Output {
  const setUp = useCallback(async (): Promise<SetUpOutput> => {
    const properties = await getProperties();

    const { effectiveProperty, regionProperty } =
      getEffectiveProperties(properties);

    if (
      shouldUserOperateInAnotherRegion({
        regionProperty,
        effectiveProperty,
      })
    ) {
      redirectToNewRegion(effectiveProperty);

      return {
        properties: [],
        property: undefined,
      };
    }

    setPropertyInThirdPartLibraries(effectiveProperty);

    return {
      properties,
      property: effectiveProperty,
    };
  }, []);

  return {
    setUp,
  };
}
