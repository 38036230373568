import { useCallback, useMemo } from 'react';
import { GuestServiceStatusOptions } from '@hkm/components/Attendant/shared/models/GuestServiceStatusOptions';
import { useGuestServices } from '@hkm/shared/hooks/useGuestServices';

import { DictionaryEntity } from '@ac/library-api/dist/types/entities/configuration/systemDictionaries/systemDictionary';

interface Output {
  getPlannedServiceValue: (
    option: GuestServiceStatusOptions
  ) => DictionaryEntity | undefined;
  getAdHocServiceValue: (
    option: GuestServiceStatusOptions
  ) => DictionaryEntity | undefined;
  getServiceValue: (
    option: GuestServiceStatusOptions
  ) => DictionaryEntity | undefined;
}

export function useGuestServiceValue(): Output {
  const { allGuestServices, allPlannedGuestServices, allAdHocGuestServices } =
    useGuestServices();

  const getPlannedServiceValue = useCallback(
    (option: GuestServiceStatusOptions) => {
      return allPlannedGuestServices.find((key) => option[key.code ?? '']);
    },
    [allPlannedGuestServices]
  );

  const getAdHocServiceValue = useCallback(
    (option: GuestServiceStatusOptions) => {
      return allAdHocGuestServices.find((key) => option[key.code ?? '']);
    },
    [allAdHocGuestServices]
  );

  const getServiceValue = useCallback(
    (option: GuestServiceStatusOptions) => {
      return allGuestServices.find((key) => option[key.code ?? '']);
    },
    [allGuestServices]
  );

  return useMemo(
    () => ({
      getPlannedServiceValue,
      getAdHocServiceValue,
      getServiceValue,
    }),
    [getAdHocServiceValue, getPlannedServiceValue, getServiceValue]
  );
}
