export enum EffectiveValueCode {
  GreenService = 'GREENSERVICE',
  UsePickupStatus = 'USEPICKUPSTATUS',
  UseInspectedStatus = 'USEINSPECTEDSTATUS',
  TitleOrder = 'TITLEORDER',
  BypassHKAttendantSummary = 'BYPASSHKATTENDANTSUMMARY',
  GuestServiceStatuses = 'GUESTSERVICESTATUSES',
  GuestServiceStatusDoNotDisturbed = 'GUESTSERVICESTATUSDONOTDISTURB',
  GuestServiceStatusServiceDeferred = 'GUESTSERVICESTATUSSERVICEDEFERRED',
  GuestServiceStatusServicePreferred = 'GUESTSERVICESTATUSSERVICEPREFERRED',
  GuestServiceStatusServiceRefused = 'GUESTSERVICESTATUSSERVICEREFUSED',
  GuestServiceStatusServiceRequested = 'GUESTSERVICESTATUSSERVICEREQUESTED',
  DisplayGuestAvatar = 'HK_MOB_DISPLAYGUESTAVATAR',
  DisplayNotesInMobileAttendant = 'HK_MOB_ATT_DISPLAYNOTES',
  EnableAttendantMobileApplication = 'ATTENDANTAPPUSED',
  EnableMaintenanceMobileApplication = 'HK_MOB_MAINTENANCE',
  EnableHousekeepingMobileApplication = 'HK_MOB_SUP_APPUSED',
  EnabledHousekeepingMobilePosting = 'HK_MOB_POSTING',
  EnabledHousekeepingMobilePostingPrompt = 'HK_MOB_ATT_POSTINGPROMPT',
  EnabledHousekeepingMobilePostingCashier = 'HK_MOB_POSTINGCASHIER',
  EnableHousekeepingMobilePostingEmails = 'HK_MOB_POSTINGEMAILS',
  EnabledHousekeepingSchedule = 'HK_HOUSEKEEPINGSCHEDULE',
  EnabledDayUse = 'DAYUSE',
  MobilePostingItems = 'HK_MOB_POSTINGITEMS',
  OccupancyDiscrepancy = 'HK_OCCUPANCYDISCREPANCY',
  MobAttOccupancyPrompt = 'HK_MOB_ATT_OCCUPANCYPROMPT',
  EnabledQueueRooms = 'RES_QUEUEROOMS',
  HourlyInventoryManagement = 'HOURLYINVENTORYMANAGEMENT',
  EnabledReadyBy = 'RES_QUEUEROOMS_READYBY',
  EnableTaskManagementMobileApplication = 'USEMOBILETASKMANAGEMENTAPPLICATION',
  SupportedHousekeepingMobileLanguages = 'LANGHSKM',
  EnableRoomConditions = 'HK_ROOMCONDITIONS',
  TravelEffortPoints = 'HK_TRAVELEFFORTPOINTS',
  BypassTrackingAttendantInRoom = 'BYPASSTRACKINGATTENDANTINROOM',
}
