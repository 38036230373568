import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@hkm/components/Posting/Dashboard/domain/actions';
import { selectPostingDashboardSort } from '@hkm/components/Posting/Dashboard/domain/selectors';
import { PostingSort } from '@hkm/components/Posting/Dashboard/enum/postingSort';
import { toCapitalSnakeCase } from '@hkm/shared/helpers/toCapitalSnakeCase';

import {
  AcSelect,
  AcSelectPattern,
  AcSelectValue,
} from '@ac/mobile-components/dist/components/select';

const sorts: PostingSort[] = [
  PostingSort.RoomNumber,
  PostingSort.HousekeepingStatus,
];

const PostingDashboardSortSelect: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeSort = useSelector(selectPostingDashboardSort);

  const selectItems: AcSelectValue[] = sorts.map((sort) => {
    const translationTag = toCapitalSnakeCase(sort);

    return {
      value: sort as string,
      itemLabel: t(`ROOM_LIST.SORT.LIST.${translationTag}`),
      inputLabel: t(`ROOM_LIST.SORT.INPUT.${translationTag}`),
    };
  });

  const onChange = useCallback(
    (selectValue: PostingSort) => {
      dispatch(actions.pagination.setSort(selectValue));
    },
    [dispatch]
  );

  return (
    <AcSelect
      label={t('COMMON.SORT_BY')}
      showInputs={true}
      onChange={onChange}
      itemsList={selectItems}
      selectedItem={activeSort as PostingSort}
      pattern={AcSelectPattern.Dropdown}
      testSelector="posting-dashboard-sort"
    />
  );
};

export default memo(PostingDashboardSortSelect);
