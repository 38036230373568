import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AdvancedFilterChipsRenderProp } from '@hkm/components/shared/AdvancedFilters/advancedFilterChipsRenderProp';
import ConnectedFooter from '@hkm/components/shared/LayoutComponents/ConnectedFooter';

import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFooterWideButtons } from '@ac/mobile-components/dist/components/layout';
import { AcWrappedLink } from '@ac/mobile-components/dist/components/wrapped-link';
import { FlexDirection } from '@ac/mobile-components/dist/enums';
import {
  formatTestSelector,
  genericMemo,
} from '@ac/mobile-components/dist/utils';
import { FormRenderProps } from '@ac/react-infrastructure';

import './AdvancedFiltersFooter.css';

export interface AdvancedFiltersFooterProps<T> {
  formProps: FormRenderProps<T>;
  chips: AdvancedFilterChipsRenderProp<T>;
  testSelectorPrefix: string;
  backPath: string;
}

function AdvancedFiltersFooter<T>(
  props: AdvancedFiltersFooterProps<T>
): ReactElement {
  const { t } = useTranslation();

  function changeFormValues(value: T) {
    props.formProps.form.changeMany(value as Partial<T>);
  }

  return (
    <ConnectedFooter className="advanced-filters-footer" noPadding={true}>
      <ac-flex direction={FlexDirection.column}>
        <div className="advanced-filters-footer-chips">
          {props.chips(props.formProps.values, changeFormValues)}
        </div>

        <AcFooterWideButtons>
          <AcButton
            disabled={props.formProps.invalid}
            onClick={props.formProps.handleSubmit}
            testSelector={formatTestSelector(props.testSelectorPrefix, 'apply')}
          >
            <AcButtonContent text={t('GLOBAL.APPLY_AND_CLOSE')} />
          </AcButton>

          <AcWrappedLink link={props.backPath}>
            <AcButton
              className="advanced-filters-footer-wrapped-button"
              pattern={AcButtonPattern.Tertiary}
              testSelector={formatTestSelector(
                props.testSelectorPrefix,
                'cancel'
              )}
            >
              <AcButtonContent text={t('GLOBAL.CANCEL')} />
            </AcButton>
          </AcWrappedLink>
        </AcFooterWideButtons>
      </ac-flex>
    </ConnectedFooter>
  );
}

export default genericMemo(AdvancedFiltersFooter);
