import { SheetSummaryLinenChange } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryLinenChange';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';

import { AttendantAssignmentRoomDetails } from '@ac/library-api';

interface RoomTypeLinenInfo {
  count: number;
  codeName: string;
}

interface RoomTypesLinenInfo {
  [key: string]: RoomTypeLinenInfo;
}

export const createLinenChangeFactory = (
  roomsAssignments: AttendantRoom[]
): SheetSummaryLinenChange => {
  let total = 0;

  const roomTypesLinenInfo = roomsAssignments.reduce(
    (linenInfo: RoomTypesLinenInfo, room: AttendantAssignmentRoomDetails) => {
      if (room.linenChange && room.roomType) {
        const roomTypeLinenInfo = linenInfo[room.roomType.id] || {
          codeName: `${room.roomType.code} - ${room.roomType?.name}`,
          count: 0,
        };
        roomTypeLinenInfo.count = roomTypeLinenInfo.count + 1;
        linenInfo[room.roomType.id] = roomTypeLinenInfo;
        total = total + 1;
      }

      return linenInfo;
    },
    {}
  );

  return {
    total,
    roomTypesLinenChange: Object.values(roomTypesLinenInfo),
  };
};
