import { dayjs } from '@hkm/utils/dayjs-extended';
import { Dayjs } from 'dayjs';

import { ReservedKindReservation } from './groupReservedKinds';

interface Group {
  reservations: ReservedKindReservation[];
  groupArrival: string;
}

export function groupSharedReservations(
  sortedReservations: ReservedKindReservation[]
): ReservedKindReservation[][] {
  const sharedReservations = sortedReservations.filter(
    (reservation) => reservation.isShared
  );
  const notSharedReservations = sortedReservations.filter(
    (reservation) => !reservation.isShared
  );

  const sharedGroups = groupReservationsMarkedAsShared(sharedReservations);
  const notSharedGroups = groupReservationsMarkedAsNotShared(
    notSharedReservations
  );

  return [...sharedGroups, ...notSharedGroups]
    .sort((a, b) => a.groupArrival.localeCompare(b.groupArrival))
    .map((group) => group.reservations);
}

function groupReservationsMarkedAsNotShared(
  reservations: ReservedKindReservation[]
): Group[] {
  return reservations.map((reservation) => ({
    reservations: [reservation],
    groupArrival: reservation.arrivalDate,
  }));
}

function groupReservationsMarkedAsShared(
  sharedReservations: ReservedKindReservation[]
): Group[] {
  let currentGroup: ReservedKindReservation[] = [];
  const groups: ReservedKindReservation[][] = [];

  let groupArrival: Dayjs | null = null;
  let groupDeparture: Dayjs | null = null;

  for (let i = 0; i < sharedReservations.length; i++) {
    const isFirst = i === 0;
    const reservation = sharedReservations[i];
    const arrival = dayjs(reservation.arrivalDate);
    const departure = dayjs(reservation.departureDate);

    if (
      !isFirst &&
      isBetweenOrSameAsArrival(arrival, groupArrival, groupDeparture)
    ) {
      if (!groupDeparture) {
        groupDeparture = departure;
      } else {
        groupDeparture = departure.isAfter(groupDeparture)
          ? departure
          : groupDeparture;
      }
    } else {
      currentGroup = [];
      groups.push(currentGroup);
      groupArrival = arrival;
      groupDeparture = departure;
    }

    currentGroup.push(reservation);
  }

  return groups.map((group) => ({
    reservations: group,
    groupArrival:
      group
        .map((reservation) => reservation.arrivalDate)
        .sort((a, b) => a.localeCompare(b))
        .shift() ?? '',
  }));
}

function isBetweenOrSameAsArrival(
  n: Dayjs,
  a: Dayjs | null,
  b: Dayjs | null
): boolean {
  if (!a || !b) {
    return false;
  }

  return n.isSame(a, 'day') || n.isBetween(a, b, 'day');
}
