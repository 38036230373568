import React from 'react';
import { useTranslation } from 'react-i18next';

import { AcText } from '@ac/mobile-components/dist/components/text';
import { JustifyContent, TextSize } from '@ac/mobile-components/dist/enums';
import { Color, IconName, Size } from '@ac/web-components';

import './AlertBarContent.scss';

interface Props {
  serviceRequestedRoomsCount: number;
  showLink?: boolean;
  onShow?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const ServiceRequestedRoomsAlertBar = ({
  serviceRequestedRoomsCount,
  showLink,
  onShow,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ac-flex
      justifyContent={JustifyContent.spaceBetween}
      class="alert-bar-content"
    >
      <AcText size={TextSize.Main2}>
        <ac-icon
          class="ac-spacing-right-md"
          icon={IconName.todo}
          color={Color.warning}
          size={Size.md}
        />
        {t('ATTENDANT_ASSIGNMENTS.SHARED.ALERT_BAR.SERVICES_REQUESTED', {
          count: serviceRequestedRoomsCount,
        })}
      </AcText>
      {showLink && (
        <a href="#" onClick={onShow} className="link">
          <AcText size={TextSize.Main2}>{t('GLOBAL.SHOW')}</AcText>
        </a>
      )}
    </ac-flex>
  );
};
