import { useCallback } from 'react';
import { useAcpObject } from '@hkm/features/app/commands/initialization/acp/useAcpObject';
import { useBackwardCompatibility } from '@hkm/features/app/commands/initialization/backwardCompatibility/useBackwardCompatibility';
import { useBusinessContextInitialization } from '@hkm/features/app/commands/initialization/businessContext/useBusinessContextInitialization';
import { usePropertyInitialization } from '@hkm/features/app/commands/initialization/property/usePropertyInitialization';
import { getPropertyWebsocket } from '@hkm/features/app/commands/initialization/websocket/getPropertyWebsocket';
import { setUpLanguage } from '@hkm/features/app/commands/language/setUpLanguage';
import { useAppContext } from '@hkm/features/app/store/app/context';
import { useMicroFrontendsRegistration } from '@hkm/features/microFrontends/commands/useMicroFrontendsRegistration';

interface Output {
  setUp(): Promise<void>;
}

export function useMobileAppInitialization(): Output {
  const backwardCompatibility = useBackwardCompatibility();
  const propertyInitialization = usePropertyInitialization();
  const businessContextInitialization = useBusinessContextInitialization();
  const acpObject = useAcpObject();
  const appContext = useAppContext();
  const microFrontends = useMicroFrontendsRegistration();

  const setUp = useCallback(async (): Promise<void> => {
    window.ACP = acpObject.create();

    const { properties, property } = await propertyInitialization.setUp();

    if (!property) {
      setUpLanguage(undefined);
      appContext.setUp(undefined);

      return;
    }

    const businessContext = await businessContextInitialization.setUp(
      property.unitId
    );

    if (!businessContext.context.property) {
      setUpLanguage(undefined);
      appContext.setUp(undefined);

      return;
    }

    window.ACP.businessContext = businessContext;
    window.ACP.api = {
      webSockets: getPropertyWebsocket(property.unitId),
    };

    setUpLanguage(businessContext.context);

    backwardCompatibility.setUp(businessContext.context, properties);
    appContext.setUp(properties);

    microFrontends.register();
  }, [
    acpObject,
    microFrontends,
    appContext,
    backwardCompatibility,
    businessContextInitialization,
    propertyInitialization,
  ]);

  return {
    setUp,
  };
}
