/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { PropsWithChildren } from 'react';
import { MicroFrontendsContext } from '@hkm/features/microFrontends/store/context';
import { useMicroFrontendsStore } from '@hkm/features/microFrontends/store/store';

export const MicroFrontendsContextProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const store = useMicroFrontendsStore();

  return (
    <MicroFrontendsContext.Provider value={store}>
      {children}
    </MicroFrontendsContext.Provider>
  );
};
