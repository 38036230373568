/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { PropsWithChildren } from 'react';
import { AppContext } from '@hkm/features/app/store/app/context';
import { useAppStore } from '@hkm/features/app/store/app/store';

export const AppContextProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const store = useAppStore();

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};
