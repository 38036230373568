import React, { FC, memo } from 'react';

import { AcIconButton } from '@ac/mobile-components/dist/components/icon-button';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorProp } from '@ac/mobile-components/dist/utils';
import { IconName } from '@ac/web-components';

import './DoNotMoveHeaderIcon.css';

const DoNotMoveHeaderIcon: FC<Testable> = (props) => {
  return (
    <AcIconButton
      icon={IconName.lock}
      className="do-not-move-header-icon"
      {...getTestSelectorProp(props.testSelector, 'doNotMoveHeaderIcon')}
    />
  );
};

export default memo(DoNotMoveHeaderIcon);
