import { useCallback } from 'react';
import { MissingBusinessContextError } from '@hkm/features/microFrontends/errors/MissingBusinessContextError';
import { MissingEventBusError } from '@hkm/features/microFrontends/errors/MissingEventBusError';
import { useMicroFrontendsContext } from '@hkm/features/microFrontends/store/context';

import { globalBusinessContextUpdatedEventCreator } from '@ac/library-api';

type ModuleLifecycle = (props: { name: string }) => Promise<void>;

export interface SingleSpaLifecycle {
  bootstrap: ModuleLifecycle | ModuleLifecycle[];
  mount: ModuleLifecycle | ModuleLifecycle[];
  unmount: ModuleLifecycle | ModuleLifecycle[];
}

interface Output {
  inject(module: SingleSpaLifecycle): SingleSpaLifecycle;
}

export function useMicroFrontendsLifecycleInjections(): Output {
  const { setLoading } = useMicroFrontendsContext();
  const { setError } = useMicroFrontendsContext();

  const inject = useCallback(
    (module: SingleSpaLifecycle): SingleSpaLifecycle => ({
      ...module,
      mount: [
        async function () {
          const context = window.ACP?.businessContext?.context;

          if (!context) {
            const error = new MissingBusinessContextError();

            return setError(error);
          }

          const eventBus = window.ACP?.communication?.eventBus;

          if (!eventBus) {
            const error = new MissingEventBusError();

            return setError(error);
          }

          eventBus.dispatch(globalBusinessContextUpdatedEventCreator(context));
          setLoading(false);
        },
        ...(Array.isArray(module.mount) ? module.mount : [module.mount]),
      ],
      unmount: [
        async function () {
          setLoading(true);
        },
        ...(Array.isArray(module.unmount) ? module.unmount : [module.unmount]),
      ],
    }),
    [setError, setLoading]
  );

  return {
    inject,
  };
}
