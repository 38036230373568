import { Permission } from '@hkm/shared/permissions/enum/Permission';
import { PermissionConjunction } from '@hkm/shared/permissions/enum/PermissionConjunction';
import { PermissionsConfig } from '@hkm/shared/permissions/types/permissionsConfig';

export const TaskManagementViewPermissions = [
  Permission.UseMobileTaskManagementApplication,
] as const;

export const TaskManagementViewPermissionsConfig: PermissionsConfig = [
  TaskManagementViewPermissions,
  PermissionConjunction.All,
];
