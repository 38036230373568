import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { AcText } from '@ac/mobile-components/dist/components/text';
import { AlignItems, TextSize } from '@ac/mobile-components/dist/enums';
import { Color, IconName, Size } from '@ac/web-components';

interface EscalatedFlagProps {
  class?: string;
  indexId?: string;
}

const EscalatedFlag: FC<EscalatedFlagProps> = (props) => {
  const testSelector = `${props.indexId}-escalated-flag`;
  const { t } = useTranslation();

  return (
    <ac-flex alignItems={AlignItems.center}>
      <ac-icon
        class={props.class}
        icon={IconName.suspend}
        color={Color.warning}
        size={Size.sm}
        data-test-selector={testSelector}
      />
      <AcText size={TextSize.Main2} className="ac-spacing-left-sm">
        {t('TASK_MANAGEMENT.DASHBOARD.LABELS.TASK_ESCALATED')}
      </AcText>
    </ac-flex>
  );
};

export default memo(EscalatedFlag);
