import { MaintenanceViewsApi } from '@ac/library-api/dist/api/v0/housekeeping';

interface GetMaintenanceStatusCodeById {
  maintenanceId: string;
}

export async function getMaintenanceStatusCodeById({
  maintenanceId,
}: GetMaintenanceStatusCodeById) {
  try {
    const maintenanceById = await MaintenanceViewsApi.getById({
      pathParams: { maintenanceId },
    });

    return maintenanceById.data.status?.code ?? maintenanceId;
  } catch (e) {
    return maintenanceId;
  }
}
