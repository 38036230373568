import { VipOrMemberBadge } from '@hkm/shared/interfaces/vipOrMemberBadge';
import { GroupedReservedKinds } from '@hkm/shared/reservedKind/groupedReservedKinds';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { UnifiedReservationGuestDetails } from '@hkm/types/reservation/models/UnifiedReservationGuestDetails';
import { UnifiedReservationGuestMembership } from '@hkm/types/reservation/models/UnifiedReservationGuestMembership';

import { IdCodeDescriptionColor } from '@ac/library-api';

export const hasReservationsDisabilities = (
  reservations: UnifiedReservationDetails[]
): boolean => {
  return reservations.some((reservation) =>
    [reservation.guest, reservation.accompanyingGuests]
      .filter(Boolean)
      .some(
        (guest: UnifiedReservationGuestDetails) =>
          !!guest.disabilityStatusCodes?.length
      )
  );
};

export const getAllVipAndMemberBadges = (
  reservations: UnifiedReservationDetails[]
): VipOrMemberBadge[] =>
  reservations.reduce(
    (prev: VipOrMemberBadge[], reservation: UnifiedReservationDetails) => [
      ...prev,
      ...getAllVipAndMemberBadgesFromReservation(reservation),
    ],
    []
  );

const getAllVipAndMemberBadgesFromReservation = (
  reservation: UnifiedReservationDetails
): VipOrMemberBadge[] => {
  return [
    ...[reservation.guest, ...reservation.accompanyingGuests]
      .filter(Boolean)
      .map((item) => getVipBadge(item?.vipCode)),
    ...getMembershipBadges(reservation.memberships),
  ].filter(Boolean) as VipOrMemberBadge[];
};

export const hasReservationPreferences = (
  reservation?: UnifiedReservationDetails
): boolean => !!(reservation?.preferences || []).length;

export const hasReservationSharedGuest = (
  room: GroupedReservedKinds
): boolean => !!(room?.currentSharedReservations || []).length;

export const hasReservationDisabilities = (
  guest?: UnifiedReservationGuestDetails
): boolean => !!(guest?.disabilityStatusCodes || []).length;

export const hasReservationAccompanyingGuests = (
  reservation?: UnifiedReservationDetails
): boolean => !!(reservation?.accompanyingGuests || []).length;

export const getVipBadge = (
  vipCode?: IdCodeDescriptionColor
): VipOrMemberBadge | undefined =>
  vipCode
    ? ({
        text: vipCode.code,
        color: vipCode.color,
        description: vipCode.description,
      } as VipOrMemberBadge)
    : undefined;

export const getMembershipBadges = (
  membershipCodes: UnifiedReservationGuestMembership[]
): VipOrMemberBadge[] =>
  membershipCodes
    .map((code) => ({
      text: code.membershipType?.code,
      color: code.membershipLevel?.color,
      description: code.membershipType?.description,
    }))
    .filter(Boolean) as VipOrMemberBadge[];

export const getVipAndMemberBadges = (
  reservation?: UnifiedReservationDetails
) =>
  reservation
    ? ([
        getVipBadge(reservation.guest?.vipCode),
        ...getMembershipBadges(reservation.memberships),
      ].filter(Boolean) as VipOrMemberBadge[])
    : [];
