import { ChangeRoomStatusResult } from '@hkm/components/Housekeeping/Batch/domain/interfaces';
import { HousekeepingBatchStatusChangeData } from '@hkm/components/Housekeeping/shared/models/housekeepingBatchStatusChangeData';

import { createAction, updateAction } from '@ac/library-utils/dist/redux-utils';

export const changeSingleRoomStatus = updateAction<
  ChangeRoomStatusResult,
  ChangeRoomStatusResult
>('changeRoomStatus');
export const changeBatchRoomsStatus = updateAction<
  HousekeepingBatchStatusChangeData,
  undefined
>('changeBatchRoomsStatus');
export const changeBatchRoomsStatusResult = createAction<
  ChangeRoomStatusResult[]
>('changeBatchRoomsStatusResult');
export const clearBatchRoomsStatusResult = createAction<undefined>(
  'clearBatchRoomsStatusResult'
);
