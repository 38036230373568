import { OriginatorSource } from '@ac/library-api';
import { IconName } from '@ac/web-components';

const originatorSourceIconMap: Map<OriginatorSource, IconName> = new Map();
originatorSourceIconMap.set(OriginatorSource.TravelAgent, IconName.travel);
originatorSourceIconMap.set(OriginatorSource.Cashiering, IconName.cashiering);
originatorSourceIconMap.set(OriginatorSource.Company, IconName.company);
originatorSourceIconMap.set(OriginatorSource.Individual, IconName.profile);
originatorSourceIconMap.set(OriginatorSource.ReservationGroup, IconName.group);
originatorSourceIconMap.set(OriginatorSource.Ar, IconName.accountsReceivable);
originatorSourceIconMap.set(
  OriginatorSource.Reservation,
  IconName.reservations
);
originatorSourceIconMap.set(
  OriginatorSource.MeetingsAndEventsBooking,
  IconName.conferences
);

export default originatorSourceIconMap;
