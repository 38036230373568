import React, { FC, useMemo } from 'react';
import RoomSharedReservation from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/RoomSharedReservation/RoomSharedReservation';

import { HousekeepingRoomReservationDetailsDto } from '@ac/library-api';

interface ComponentProps {
  earliestReservation?: HousekeepingRoomReservationDetailsDto;
  roomReservations?: HousekeepingRoomReservationDetailsDto[];
}

const RoomSharedReservations: FC<ComponentProps> = (props: ComponentProps) => {
  const { earliestReservation, roomReservations } = props;

  const filteredSharedReservations = useMemo(
    () =>
      (roomReservations || [])
        .filter(
          (reservation) =>
            reservation.isRoomShared &&
            reservation.id !== earliestReservation?.id
        )
        .splice(0, 5),
    [earliestReservation, roomReservations]
  );

  return (
    <>
      {(filteredSharedReservations || []).map((reservation, index) => (
        <RoomSharedReservation
          key={reservation.id}
          reservation={reservation}
          index={index}
        />
      ))}
    </>
  );
};

export default RoomSharedReservations;
