import * as H from 'history';

import { LinkOrCallback } from '../interfaces/linkOrCallback';

export interface LinkOrCallbackObject {
  link?: H.Location | string;
  callback?: () => void;
}

export function splitLinkOrCallback(
  linkOrCallback?: LinkOrCallback
): LinkOrCallbackObject {
  if (typeof linkOrCallback === 'function') {
    return { callback: linkOrCallback };
  } else if (linkOrCallback) {
    return { link: linkOrCallback };
  } else {
    return {};
  }
}
