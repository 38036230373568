/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { useCallback, useMemo } from 'react';
import { useNotificationsContext } from '@hkm/features/notifications/store/context';

import {
  AcOverlayMessageContainer,
  OverlayMessageState,
  OverlayMessageType,
} from '@ac/mobile-components/dist/components/overlay-message';
import { ToastSeverity } from '@ac/web-components';

const TOAST_SEVERITY_MAP_TO_OVERLAY_MESSAGE_TYPE = {
  [ToastSeverity.success]: OverlayMessageType.Success,
  [ToastSeverity.error]: OverlayMessageType.Error,
  [ToastSeverity.warning]: OverlayMessageType.Warning,
  [ToastSeverity.info]: OverlayMessageType.Info,
};

const OVERLAY_MESSAGE_TYPE_MAP_TO_TOAST_SEVERITY = {
  [OverlayMessageType.Success]: ToastSeverity.success,
  [OverlayMessageType.Error]: ToastSeverity.error,
  [OverlayMessageType.Warning]: ToastSeverity.warning,
  [OverlayMessageType.Info]: ToastSeverity.info,
};

export const Notifications = (): JSX.Element => {
  const { state, setToasts } = useNotificationsContext();

  const messages = useMemo((): OverlayMessageState[] => {
    return state.toasts.map((toast) => ({
      id: toast.id,
      type: TOAST_SEVERITY_MAP_TO_OVERLAY_MESSAGE_TYPE[toast.severity],
      node: toast.message.toString(),
    }));
  }, [state.toasts]);

  const onMessageChange = useCallback(
    (toasts: OverlayMessageState[]) => {
      setToasts(
        toasts.map((toast) => ({
          id: toast.id,
          severity: OVERLAY_MESSAGE_TYPE_MAP_TO_TOAST_SEVERITY[toast.type],
          message: toast.node?.toString() ?? '',
        }))
      );
    },
    [setToasts]
  );

  return (
    <AcOverlayMessageContainer
      onMessagesChange={onMessageChange}
      bottomOffsetRefs={[]}
      messages={messages}
    />
  );
};
