import { SheetSummaryValue } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryValue';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { pickAllBySelector } from '@hkm/utils/pickAllBySelector';
import { reduceToUnique } from '@hkm/utils/reduceToUnique';
import flatten from 'lodash/flatten';

import { IdCodeDescriptionColor } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

export const createVipsFactory = (
  roomsAssignments: AttendantRoom[]
): SheetSummaryValue[] => {
  const getReservationVips = (
    reservation: UnifiedReservationDetails
  ): IdCodeDescriptionColor[] => {
    const result = [];
    const guestVips: IdCodeDescriptionColor[] =
      reservation.accompanyingGuests
        ?.map((guest) => guest.vipCode)
        .filter(isDefined) || [];

    if (reservation.guest?.vipCode) {
      result.push(reservation.guest.vipCode);
    }

    return [...result, ...guestVips];
  };

  const allVips: IdCodeDescriptionColor[] = flatten(
    pickAllBySelector(roomsAssignments, (roomAssignment) =>
      flatten(
        (roomAssignment.currentReservations || []).map<
          IdCodeDescriptionColor[]
        >(getReservationVips)
      )
    )
  );

  return reduceToUnique(
    allVips,
    (original, result) => original.code === result.code,
    (vip) => ({
      code: vip.code || '',
      description: vip.description || '',
      color: vip.color || '',
      count: 1,
    }),
    (item) => ({ ...item, count: item.count + 1 })
  );
};
