import React, { memo, ReactNode, useCallback } from 'react';
import classNames from 'classnames';

import {
  AcAccordion,
  AcAccordionClampContent,
  AcAccordionContent,
  AcAccordionHeader,
  AcAccordionItem,
} from '@ac/mobile-components/dist/components/accordion';
import { AcDivider } from '@ac/mobile-components/dist/components/divider';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { JustifyContent } from '@ac/mobile-components/dist/enums';
import { Styleable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { IconName, Size } from '@ac/web-components';

import './SheetsSummaryAccordion.css';

export interface SheetsSummaryAccordionProps extends Styleable {
  title: string;
  renderSubtitle?: ReactNode;
  renderContent?: ReactNode;
  renderClampContent?: ReactNode;
  expandDisabled?: boolean;
  onExpandedChange?: (isExpanded: boolean) => void;
  icon: IconName;
}

export const SheetsSummaryAccordion: React.FC<SheetsSummaryAccordionProps> = (
  props: SheetsSummaryAccordionProps
) => {
  // NOTE:  sheets-summary-accordion2 fixes styles priority
  const classes = classNames(
    'sheets-summary-accordion',
    'sheets-summary-accordion2',
    props.className
  );
  const expandedChange = useCallback(
    (expandedList: string[]) => {
      if (props.onExpandedChange) {
        props.onExpandedChange(expandedList.length > 0);
      }
    },
    [props]
  );

  return (
    <ac-box class={classes}>
      <ac-flex
        justifyContent={JustifyContent.center}
        class="sheets-summary-accordion-icon"
      >
        <ac-icon
          icon={props.icon}
          size={Size.xlg}
          class="ac-spacing-top-md ac-spacing-bottom-md"
        />
      </ac-flex>
      <AcAccordion
        className="sheets-summary-accordion-info"
        onChange={expandedChange}
      >
        <AcAccordionItem id="1" disabled={props.expandDisabled}>
          <AcAccordionHeader testSelector="sheets-summary-accordion-header">
            <AcText testSelector="sheets-summary-accordion-title">
              {props.title}
            </AcText>
          </AcAccordionHeader>

          {props.renderSubtitle && <div>{props.renderSubtitle}</div>}

          {props.renderClampContent && (
            <AcAccordionClampContent minVisibleLines={2}>
              {props.renderClampContent}
            </AcAccordionClampContent>
          )}

          {props.renderContent && (
            <AcAccordionContent>
              <AcDivider className="sheets-summary-title-content-divider" />
              {props.renderContent}
            </AcAccordionContent>
          )}
        </AcAccordionItem>
      </AcAccordion>
    </ac-box>
  );
};

export default memo(SheetsSummaryAccordion);
