import React from 'react';

export interface AppSpinnerContextData {
  registerLoading(id: string): void;
  unregisterLoading(id: string): void;
}

export const AppSpinnerContext = React.createContext<AppSpinnerContextData>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerLoading: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unregisterLoading: () => {},
});
