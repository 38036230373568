import { generatePath } from 'react-router';
import { HousekeepingViewPermissionsConfig } from '@hkm/components/Housekeeping/shared/config/housekeepingViewPermissionsConfig';
import { MaintenanceViewPermissionsConfig } from '@hkm/components/Maintenance/config/maintenanceViewPermissionsConfig';
import {
  selectPermissionsSet,
  selectPropertyDateFormats,
} from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { selectReservations } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/selectors';
import { MaintenanceFormValidationConflictData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/Validation/maintenanceFormValidationConflictData';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { hasPermission } from '@hkm/shared/permissions/helper/permissionHelper';
import { getStore } from '@hkm/store/configureStore';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { dayjs } from '@hkm/utils/dayjs-extended';
import i18n from 'i18next';

import { ConflictDetails } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';

export function createMaintenanceFormValidationConflictsDataFromConflictDetails(
  conflicts: ConflictDetails[] | undefined
): MaintenanceFormValidationConflictData[] {
  return (conflicts || []).reduce((array, conflict) => {
    const newArray = [...array];
    if (conflict.overlapingReservation) {
      newArray.push(createFromOverlappingReservation(conflict));
    }
    if (conflict.overlapingMaintenance) {
      newArray.push(createFromOverlappingMaintenance(conflict));
    }

    return newArray;
  }, [] as MaintenanceFormValidationConflictData[]);
}

function createFromOverlappingMaintenance(
  details: ConflictDetails
): MaintenanceFormValidationConflictData {
  return {
    roomNumber: details.roomNumber ?? '',
    roomType: details.roomType?.code ?? '',
    reason: i18n.t('MAINTENANCE_CREATE.VALIDATION.MODAL.REASON_MAINTENANCE'),
    status: i18n.t(
      `GLOBAL.MAINTENANCE_STATUS.SHORT.${details.overlapingMaintenance?.status?.code}`
    ),
    start: formatDate(details.overlapingMaintenance?.start ?? ''),
    end: formatDate(details.overlapingMaintenance?.end ?? ''),
    link: getMaintenanceURL(details.overlapingMaintenance?.id ?? ''),
  };
}

function createFromOverlappingReservation(
  details: ConflictDetails
): MaintenanceFormValidationConflictData {
  return {
    roomNumber: details.roomNumber ?? '',
    roomType: details.roomType?.code ?? '',
    reason: i18n.t('MAINTENANCE_CREATE.VALIDATION.MODAL.REASON_RESERVATION'),
    status: details.overlapingReservation?.status?.description ?? '',
    start: formatDate(details.overlapingReservation?.stayStart ?? ''),
    end: formatDate(details.overlapingReservation?.stayEnd ?? ''),
    link: getReservationURL(details.roomId),
    reservation: getReservation(details.overlapingReservation?.id ?? ''),
  };
}

function getMaintenanceURL(id: string): string | undefined {
  const permissions = selectPermissionsSet(getStore());
  const canLink = hasPermission(
    permissions,
    ...MaintenanceViewPermissionsConfig
  );

  return canLink
    ? `${acConfig.frontendUrls.housekeepingMobile}${generatePath(
        APP_ROUTES.MAINTENANCE.DETAILS,
        { id }
      )}`
    : undefined;
}

function getReservationURL(id: string): string | undefined {
  const permissions = selectPermissionsSet(getStore());
  const canLink = hasPermission(
    permissions,
    ...HousekeepingViewPermissionsConfig
  );

  return canLink
    ? `${acConfig.frontendUrls.housekeepingMobile}${generatePath(
        APP_ROUTES.HOUSEKEEPING.DETAILS,
        { id }
      )}`
    : undefined;
}

function formatDate(date: string): string {
  return dayjs(date).format(getTimeFormat());
}

function getTimeFormat(): string {
  return selectPropertyDateFormats(getStore()).shortDate;
}

function getReservation(
  reservationId: string
): UnifiedReservationDetails | undefined {
  return selectReservations(getStore()).find(
    (reservation: UnifiedReservationDetails) => reservation.id === reservationId
  );
}
