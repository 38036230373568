import { RegionUrlGenerator } from '@hkm/features/region/regionUrlGenerator';

import { assertIsDefined } from '@ac/library-utils/dist/utils/assertIsDefined';

interface Property {
  regionCode?: string;
  unitId?: string;
}

export function redirectToNewRegion(property?: Property): void {
  assertIsDefined(property);

  const url = new RegionUrlGenerator({
    regionCode: property?.regionCode ?? '',
    propertyId: property?.unitId,
  });

  window.location.replace(url.href);
}
