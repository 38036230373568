/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { useCallback, useMemo } from 'react';
import { Navbar } from '@hkm/features/app/features/navbar/Navbar';

import { Errors } from '@ac/web-components';

export enum ErrorPageType {
  AccessDenied = 'AccessDenied',
  NotFound = 'NotFound',
}

interface Props {
  type: ErrorPageType;
}

export const ErrorPage = ({ type }: Props): JSX.Element => {
  const errorType = useMemo(() => {
    const map = {
      [ErrorPageType.AccessDenied]: Errors.error403,
      [ErrorPageType.NotFound]: Errors.error404,
    };

    return map[type] ?? Errors.error500;
  }, [type]);

  const onPrimaryClickCallback = useCallback(() => {
    window.history.back();
  }, []);

  return (
    <>
      <Navbar />
      <ac-error-page
        errorType={errorType}
        onPrimaryClickCallback={onPrimaryClickCallback}
      />
    </>
  );
};
