import { PostingPagination } from '@hkm/components/Posting/Dashboard/domain/interfaces';
import { Store } from '@hkm/store/interfaces/store';
import { createSelector } from 'reselect';

export const selectPostingDashboardPagination = (
  state: Store
): PostingPagination => state.postingDashboard.paginationState;
export const selectPostingDashboardSort = createSelector(
  selectPostingDashboardPagination,
  (state) => state.sort
);
export const selectPostingDashboardFilters = createSelector(
  selectPostingDashboardPagination,
  (state) => state.filters
);
