export function arrayToMapByKey<D, K extends keyof D>(
  data: D[] | undefined,
  key: K
): Map<D[K], D> {
  const map = new Map<D[K], D>();
  if (data) {
    data.forEach((item: D) => map.set(item[key], item));
  }

  return map;
}
