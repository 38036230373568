import { ReservedKindReservation } from '@hkm/shared/reservedKind/groupReservedKinds';

import { ReservationStatus } from '@ac/library-api';

const nextStates = new Set<ReservationStatus>()
  .add(ReservationStatus.DI)
  .add(ReservationStatus.RS);

export function getNextMainReservation(
  sortedReservations: ReservedKindReservation[],
  exclude?: ReservedKindReservation[]
): ReservedKindReservation | undefined {
  return sortedReservations.find(
    (reservation) =>
      nextStates.has(reservation.status) && !exclude?.includes(reservation)
  );
}
