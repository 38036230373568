import React, { FC, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import * as batchActions from '@hkm/components/Housekeeping/Batch/domain/actions';
import {
  selectChangeBatchRoomsStatusResult,
  selectIsChangeBatchStatusPending,
} from '@hkm/components/Housekeeping/Batch/domain/selectors';
import BatchStatusChange from '@hkm/components/Housekeeping/Batch/statusChange/BatchStatusChange';
import BatchStatusResult from '@hkm/components/Housekeeping/Batch/statusResult/BatchStatusResult';
import * as dashboardActions from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import APP_ROUTES from '@hkm/constants/routing.constants';
import {
  checkActionsStatus,
  ResultStatus,
} from '@hkm/shared/helpers/actionResult';

import { AcSpinnerCover } from '@ac/mobile-components/dist/components/spinner';

const BatchContainer: FC = () => {
  const dispatch = useDispatch();
  const batchChangeResult = useSelector(selectChangeBatchRoomsStatusResult);
  const isPending = useSelector(selectIsChangeBatchStatusPending);
  const batchChangeStatus = checkActionsStatus(batchChangeResult);

  useEffect(() => {
    return () => {
      dispatch(dashboardActions.pagination.refreshPage());
      dispatch(batchActions.clearBatchRoomsStatusResult());
    };

    // eslint-disable-next-line
  }, []);

  if (isPending) {
    return <AcSpinnerCover />;
  } else if (batchChangeStatus === ResultStatus.Success) {
    return <Navigate to={APP_ROUTES.HOUSEKEEPING.DASHBOARD} />;
  }

  return (
    <>
      {batchChangeStatus === ResultStatus.Failed && batchChangeResult && (
        <BatchStatusResult result={batchChangeResult} />
      )}
      {batchChangeStatus === ResultStatus.Indefinite && <BatchStatusChange />}
    </>
  );
};

export default memo(BatchContainer);
