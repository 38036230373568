export enum RequiredAggregatedEntityRelations {
  TaskStatus = 'taskStatus',
  TaskPriority = 'taskPriority',
  ResponsibleDepartment = 'responsibleDepartment',
  Department = 'department',
  MembershipLevel = 'membershipLevel',
  GroupStatus = 'groupStatus',
  Location = 'location',
  ProfileType = 'profileType',
  GuestStatus = 'guestStatus',
  TaskType = 'taskType',
  TaskTag = 'taskTag',
}
