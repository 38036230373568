import { getInitials } from '@ac/library-api';
import { useUserBusinessContext } from '@ac/react-infrastructure';

interface Output {
  email: string;
  userName: string;
  initials: string;
}

export function useActiveUser(): Output {
  const { state } = useUserBusinessContext();

  return {
    email: state.businessContext?.details?.email ?? '',
    userName: state.businessContext?.details?.userName ?? '',
    initials: getInitials(
      state.businessContext?.details?.personalData?.firstName ?? '',
      state.businessContext?.details?.personalData?.lastName ?? ''
    ),
  };
}
