import { dayjs } from '@hkm/utils/dayjs-extended';
import { ConfigType, Dayjs, OpUnitType } from 'dayjs';

import { defaultOutputFormat } from '@ac/mobile-components/dist/components/field';

export function periodIntersects(
  start1: string,
  end1: string,
  start2: string,
  end2: string,
  granularity: OpUnitType = 'day'
) {
  return (
    dayjs(start1).isSame(start2, granularity) ||
    dayjs(end1).isSame(end2, granularity) ||
    dayjs(start2).isBetween(start1, end1, granularity) ||
    dayjs(end2).isBetween(start1, end1, granularity) ||
    dayjs(start1).isBetween(start2, end2, granularity) ||
    dayjs(end1).isBetween(start2, end2, granularity)
  );
}

export function createTimeWithTimezone(
  config: ConfigType,
  timeZone?: string
): Dayjs {
  const date = dayjs(config);

  return !timeZone
    ? date
    : dayjs(date.toDate().toLocaleString('en-US', { timeZone }));
}

export function getTZMinutesOffset(timeZoneCode?: string): number {
  return timeZoneCode
    ? dayjs().diff(createTimeWithTimezone(new Date(), timeZoneCode), 'minute')
    : 0;
}

export function createLocalTimeFromTzTime(
  config?: ConfigType,
  timeZone?: string
): string | undefined {
  if (!config || !timeZone) {
    return;
  }

  const date = dayjs(config);
  const offset = getTZMinutesOffset(timeZone);

  return date.add(offset, 'minute').format(defaultOutputFormat);
}
