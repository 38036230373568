import { MaintenanceFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/model/maintenanceFormData';

interface MaintenanceFormValidationRelevantData {
  roomNumbers?: string;
  fromTime?: string;
  toTime?: string;
}

export function getMaintenanceFormValidationRelevantData(
  data: MaintenanceFormData
): MaintenanceFormValidationRelevantData {
  return {
    roomNumbers: data.roomNumbers,
    fromTime: data.fromTime,
    toTime: data.toTime,
  };
}
