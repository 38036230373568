import { combineFilters } from '@ac/library-api';

export function createCombinedApiFilters(
  ...filters: Array<string | undefined>
): string | undefined {
  const combinedFilters = combineFilters(filters.filter(Boolean));

  return combinedFilters && combinedFilters.length
    ? `(${combinedFilters})`
    : undefined;
}
