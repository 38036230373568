import { MaintenanceCreateState } from '@hkm/shared/domain/maintenanceCreate/models/maintenanceCreateState';
import { Store } from '@hkm/store/interfaces/store';
import { createSelector } from 'reselect';

export const selectMaintenanceCreateState = (
  state: Store
): MaintenanceCreateState => state.maintenanceCreate;
export const selectMaintenanceCreateValidationConflicts = createSelector(
  selectMaintenanceCreateState,
  (state) => state.validationConflicts
);
export const selectMaintenanceCreateValidationLoading = createSelector(
  selectMaintenanceCreateState,
  (state) => state.isLoadingConflicts
);
export const selectReservations = createSelector(
  selectMaintenanceCreateState,
  (state) => state.reservations
);
