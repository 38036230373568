import React, { FC, HTMLAttributes, memo, ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPropertyDateFormats } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { dayjs } from '@hkm/utils/dayjs-extended';

import {
  AcFormElement,
  AcFormRow,
} from '@ac/mobile-components/dist/components/form-element';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  JustifyContent,
  MobileColor,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import {
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorProp } from '@ac/mobile-components/dist/utils';

export enum DatetimeVariant {
  onlyTime = 'only-time',
  onlyDate = 'only-date',
  datetime = 'datetime',
}

export interface DatetimeFormElementProps
  extends HTMLAttributes<HTMLDivElement>,
    Testable,
    Styleable {
  smallLabel?: boolean;
  smallText?: boolean;
  datetime: string;
  label?: string | ReactNode;
  timeLabel?: string | ReactNode;
  variant?: DatetimeVariant;
  color?: MobileColor;
  flatAndJustify?: JustifyContent;
}

const DatetimeFormElement: FC<DatetimeFormElementProps> = ({
  label = '',
  variant = DatetimeVariant.datetime,
  ...props
}) => {
  const formats = useSelector(selectPropertyDateFormats);
  const textSize = props.smallText ? TextSize.Main2 : TextSize.Main1;
  const showTime = !(variant === DatetimeVariant.onlyDate);
  const showDate = !(variant === DatetimeVariant.onlyTime);
  const splitDatetimeLabels = !!props.timeLabel;

  const date = useMemo(
    () => (
      <AcText
        color={props.color || MobileColor.Black}
        size={textSize}
        {...getTestSelectorProp(props.testSelector, 'dateText')}
      >
        {dayjs(props.datetime).format(formats.shortDate)}
      </AcText>
    ),
    [
      props.datetime,
      formats.shortDate,
      textSize,
      props.testSelector,
      props.color,
    ]
  );

  const time = useMemo(
    () => (
      <AcText
        color={props.color || MobileColor.Gray3}
        size={textSize}
        {...getTestSelectorProp(props.testSelector, 'timeText')}
      >
        {dayjs(props.datetime).format(formats.time)}
      </AcText>
    ),
    [props.datetime, formats.time, textSize, props.testSelector, props.color]
  );

  const inner = useMemo(
    () => (
      <>
        {showDate && (
          <AcFormElement
            label={label}
            equalLineHeight={true}
            smallLabel={props.smallLabel}
            className="date-time-element"
            flatAndJustify={props.flatAndJustify}
          >
            <>
              {date}&nbsp;
              {!splitDatetimeLabels && showTime && time}
            </>
          </AcFormElement>
        )}

        {splitDatetimeLabels && showTime && (
          <AcFormElement
            equalLineHeight={true}
            className="time-element"
            label={props.timeLabel}
            smallLabel={props.smallLabel}
            flatAndJustify={props.flatAndJustify}
          >
            {time}
          </AcFormElement>
        )}
      </>
    ),
    [
      label,
      props.timeLabel,
      props.smallLabel,
      props.flatAndJustify,
      date,
      showDate,
      showTime,
      splitDatetimeLabels,
      time,
    ]
  );

  return (
    <div className={props.className}>
      {splitDatetimeLabels ? <AcFormRow>{inner}</AcFormRow> : inner}
    </div>
  );
};

export default memo(DatetimeFormElement);
