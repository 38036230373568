import { FieldRenderProps } from '@ac/react-infrastructure';

export function getFieldErrorMessage<T>(
  props: FieldRenderProps<T>,
  onlyIfFieldModified: boolean = true
): string | undefined {
  const { error: errors, modified, submitFailed } = props.meta;
  const shouldShowMessage = onlyIfFieldModified
    ? errors.length > 0 && (modified || submitFailed)
    : errors.length > 0;

  return shouldShowMessage
    ? errors
        .map((error: { description: string }) => error.description)
        .join('\n')
    : undefined;
}
