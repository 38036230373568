import { createContext } from 'react';
import { UserSelectorStore } from '@hkm/components/shared/features/UsersSelector/store/store';

import { useDefinedContext } from '@ac/react-infrastructure/dist/features/featureSchema';

export const UsersSelectorContext = createContext<
  UserSelectorStore | undefined
>(undefined);

export const useUsersSelectorContext = (): UserSelectorStore =>
  useDefinedContext(UsersSelectorContext);
