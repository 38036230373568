import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { AttendantSheet } from '@hkm/types/attendant/models/AttendantSheet';

export function findAttendantRoom(
  roomId: string,
  attendantAssignmentSheet?: AttendantSheet
): AttendantRoom | undefined {
  return attendantAssignmentSheet
    ? attendantAssignmentSheet.roomAssignments.find(
        (rooms) => rooms.id === roomId
      )
    : undefined;
}
