import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CountWithDescription from '@hkm/components/Attendant/SheetSummary/body/CountWithDesciption/CountWithDescription';
import SheetsSummaryAccordion from '@hkm/components/Attendant/SheetSummary/body/SheetSummaryAccordion/SheetsSummaryAccordion';
import { SheetSummaryHousekeepingTasks } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryHouskeepingTasks';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';

import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  FlexDirection,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

export interface HousekeepingTasksSummaryProps {
  housekeepingTasksSummary: SheetSummaryHousekeepingTasks;
  floorCount: number;
}

export const HousekeepingTasksSummary: React.FC<
  HousekeepingTasksSummaryProps
> = (props: HousekeepingTasksSummaryProps) => {
  const { t } = useTranslation();
  const isGreenServiceEnabled = useSelector(
    selectEffectiveValues
  )?.greenService;
  const isTravelEffortPointsEnabled = useSelector(
    selectEffectiveValues
  )?.travelEffortPoints;
  const floorCount = props.floorCount;

  const { total, greenService, serviceTypes } = props.housekeepingTasksSummary;
  const renderSubtitle = useMemo(
    () => (
      <AcText
        size={TextSize.H1}
        weight={TextWeight.Semibold}
        testSelector="housekeeping-tasks-summary-total"
      >
        {total}
      </AcText>
    ),
    [total]
  );

  const renderContent = useMemo(
    () => (
      <ac-flex direction={FlexDirection.column}>
        {serviceTypes.map((serviceType, index) => (
          <React.Fragment key={index}>
            <CountWithDescription
              count={serviceType.value}
              description={serviceType.description}
            />

            {!!serviceType.dayUseValue && (
              <CountWithDescription
                count={serviceType.dayUseValue}
                prefix={t(
                  'ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.HOUSEKEEPING_TASKS.INCLUDES'
                )}
                description={t('GLOBAL.DAY_USE')}
              />
            )}
          </React.Fragment>
        ))}

        {isGreenServiceEnabled && (
          <>
            <CountWithDescription
              count={greenService.value}
              description={t(
                'ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.HOUSEKEEPING_TASKS.GREEN_SERVICE_REQUESTS'
              )}
            />

            {!!greenService.dayUseValue && (
              <CountWithDescription
                count={greenService.dayUseValue}
                prefix={t(
                  'ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.HOUSEKEEPING_TASKS.INCLUDES'
                )}
                description={t('GLOBAL.DAY_USE')}
              />
            )}
          </>
        )}
        {isTravelEffortPointsEnabled && (
          <>
            <CountWithDescription
              count={floorCount > 1 ? floorCount - 1 : 0}
              description={t(
                'ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.HOUSEKEEPING_TASKS.FLOOR_CHANGE'
              )}
            />
          </>
        )}
      </ac-flex>
    ),
    [
      t,
      serviceTypes,
      greenService,
      isGreenServiceEnabled,
      floorCount,
      isTravelEffortPointsEnabled,
    ]
  );

  return (
    <SheetsSummaryAccordion
      icon={IconName.activities}
      title={t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.HOUSEKEEPING_TASKS.TITLE')}
      renderSubtitle={renderSubtitle}
      renderContent={renderContent}
      expandDisabled={total === 0}
    />
  );
};

export default memo(HousekeepingTasksSummary);
