/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import configureStore from '@hkm/store/configureStore';
import rootSaga from '@hkm/store/sagas';

export const store = configureStore();

store.runSaga(rootSaga);

/**
 * @todo
 *
 * We should reduce number of redux usage
 * It should be removed when every page will be migrated to the Context
 */
export const ReduxProvider = ({ children }: PropsWithChildren): JSX.Element => {
  return <Provider store={store}>{children}</Provider>;
};
