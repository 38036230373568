import * as actions from '@hkm/components/Housekeeping/Details/domain/actions';
import { HousekeepingDetailsState } from '@hkm/components/Housekeeping/Details/domain/interfaces';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';
import { combineActions, ReducerMap } from 'redux-actions';

import { MaintenanceFile } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: HousekeepingDetailsState = {};

const reducerMap: ReducerMap<HousekeepingDetailsState, unknown> = {
  [actions.initialize]: (state, action: Action<string>) => ({
    ...state,
    roomId: action.payload,
  }),
  [actions.setRoomFromDashboard]: (
    state,
    action: Action<HousekeepingRoom>
  ) => ({
    ...state,
    room: action.payload,
    didInitialize: true,
  }),

  [actions.fetchRoom.trigger]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [actions.fetchRoom.failure]: (state) => ({
    ...state,
    isFetching: false,
    didInitialize: true,
  }),
  [actions.fetchRoom.success]: (state, action: Action<HousekeepingRoom>) => ({
    ...state,
    room: {
      ...action.payload,
    },
    isFetching: false,
    didInitialize: true,
    isChanging: false,
  }),

  [actions.fetchAttachments.trigger]: (state) => ({
    ...state,
    isFetchingAttachments: true,
  }),
  [actions.fetchAttachments.failure]: (state) => ({
    ...state,
    isFetchingAttachments: false,
    didInitializeAttachments: true,
  }),
  [actions.fetchAttachments.success]: (
    state,
    action: Action<MaintenanceFile[]>
  ) => ({
    ...state,
    attachments: action.payload,
    isFetchingAttachments: false,
    didInitializeAttachments: true,
  }),

  [combineActions(
    actions.roomOccupancy.setRoomOccupancy.trigger,
    actions.roomOccupancy.removeRoomOccupancy.trigger,
    actions.greenService.toggleGreenService.trigger,
    actions.roomStatus.changeRoomStatus.trigger,
    actions.roomConditions.changeRoomConditions.trigger,
    actions.guestService.changeGuestServiceStatus.trigger,
    actions.housekeepingStatus.changeHousekeepingStatus.trigger
  ) as never]: (state) => ({
    ...state,
    isChanging: true,
  }),

  [combineActions(
    actions.roomOccupancy.setRoomOccupancy.failure,
    actions.roomOccupancy.removeRoomOccupancy.failure,
    actions.greenService.toggleGreenService.failure,
    actions.roomStatus.changeRoomStatus.failure,
    actions.roomConditions.changeRoomConditions.failure,
    actions.guestService.changeGuestServiceStatus.failure,
    actions.housekeepingStatus.changeHousekeepingStatus.failure
  ) as never]: (state) => ({ ...state, isChanging: false }),

  [actions.reset]: () => ({
    ...initialState,
  }),
};

export default handleActions(reducerMap, initialState);
