import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { GuestCount } from '@hkm/shared/interfaces/guestCount';

import { AcText } from '@ac/mobile-components/dist/components/text';
import { TextSize, TextWeight } from '@ac/mobile-components/dist/enums';
import { IconName, Size } from '@ac/web-components';

interface GuestNumbersTemplateProps {
  count: GuestCount;
}

const GuestNumbersTemplate: FC<GuestNumbersTemplateProps> = (
  props: GuestNumbersTemplateProps
) => {
  const { t } = useTranslation();

  return (
    <>
      <AcText
        weight={TextWeight.Semibold}
        size={TextSize.Main2}
        className="ac-spacing-right-lg"
      >
        <ac-icon
          size={Size.sm}
          icon={IconName.adults}
          class="ac-spacing-right-xs"
        />
        {t('GLOBAL.ADULTS', { count: props.count.adultCount })}
      </AcText>
      <AcText weight={TextWeight.Semibold} size={TextSize.Main2}>
        <ac-icon
          size={Size.sm}
          icon={IconName.children}
          class="ac-spacing-right-xs"
        />
        {t('GLOBAL.CHILDREN', { count: props.count.childCount })}
      </AcText>
    </>
  );
};

export default memo(GuestNumbersTemplate);
