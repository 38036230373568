import { MaintenanceAttachmentData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/maintenanceAttachmentData';
import { uid } from '@hkm/utils/uid';

import { MaintenanceFile } from '@ac/library-api';

export function createMaintenanceAttachmentDataFromResponse(
  response: MaintenanceFile
): MaintenanceAttachmentData {
  return {
    id: response.id,
    fileName: response.name,
    description: response.description,
    metadata: response.metadata,
  };
}

export function createNewMaintenanceAttachmentDataFromFile(
  file: File
): MaintenanceAttachmentData {
  return {
    fileName: file.name.split('.').slice(0, -1).join('.'),
    id: uid(),
    description: '',
    file,
  };
}
