import { MaintenancePermissions } from '@hkm/components/Maintenance/config/maintenanceViewPermissionsConfig';
import { PermissionConjunction } from '@hkm/shared/permissions/enum/PermissionConjunction';
import { PermissionsConfig } from '@hkm/shared/permissions/types/permissionsConfig';

export const MaintenanceManagePermissions = [
  ...MaintenancePermissions,
] as const;

export const MaintenanceManagePermissionsConfig: PermissionsConfig = [
  MaintenanceManagePermissions,
  PermissionConjunction.All,
];
