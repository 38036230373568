import {
  FilterQueryPageQueryParams,
  getDataForAllPages,
  PageQueryParams,
  PageResponse,
  RawRoom,
  Room,
} from '@ac/library-api';
import { RoomApi } from '@ac/library-api/dist/api/v0/configuration/handcraftedEntities';

export const getAllRooms = async (
  filter?: FilterQueryPageQueryParams
): Promise<Room[]> => {
  return (
    await getDataForAllPages(
      ({ pageNumber, pageSize }: PageQueryParams) =>
        RoomApi.getList({
          queryParams: { pageNumber, pageSize, ...filter },
        }) as Promise<PageResponse<RawRoom, Room>>
    )
  ).results;
};
