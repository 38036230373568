import { buildFIQLFilter, FIQLOperators } from '@ac/library-api';
import { IndividualApi } from '@ac/library-api/dist/api/v0/profiles';

interface GetProfileFullNameById {
  profileId: string;
}

export async function getProfileFullNameById(
  parameters: GetProfileFullNameById
) {
  const { profileId } = parameters;
  try {
    const profileById = await IndividualApi.getDashboard({
      queryParams: {
        filter: buildFIQLFilter('id', FIQLOperators.equal, profileId),
      },
    });

    return profileById?.[0].formattedName ?? profileId;
  } catch (e) {
    return profileId;
  }
}
