/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';

import { Errors, IconName } from '@ac/web-components';

interface Props {
  headerTitle: string;
}

export const MobilePostingMisconfigurationErrorPage = ({
  headerTitle,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <ConnectedHeader>{headerTitle}</ConnectedHeader>
      <ac-error-page
        errorType={Errors.custom}
        error={{
          icon: IconName.information,
          header: t('POSTING.MISCONFIGURATION_ERROR_PAGE.HEADER'),
          subheader: t('POSTING.MISCONFIGURATION_ERROR_PAGE.SUBHEADER'),
        }}
      />
    </>
  );
};
