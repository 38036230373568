import { getLibraryApiCustomConfig } from '@hkm/features/api/getLibraryApiCustomConfig';
import { Logger } from '@hkm/utils/logger';

import {
  buildFIQLFilter,
  combineFilters,
  FIQLOperators,
  getDataForAllPages,
  OrganizationUnitType,
  PageResponse,
  RawUnitAssignmentListItem,
  UnitAssignmentListItem,
} from '@ac/library-api';
import { UnitAccessApi } from '@ac/library-api/dist/api/v0/permissionManagement';

export async function getProperties(): Promise<UnitAssignmentListItem[]> {
  try {
    const pages = await getDataForAllPages(({ pageNumber, pageSize }) => {
      return UnitAccessApi.getMyUnits({
        queryParams: {
          filter: combineFilters([
            buildFIQLFilter(
              'type',
              FIQLOperators.equal,
              OrganizationUnitType.Property
            ),
            buildFIQLFilter('isPublished', FIQLOperators.equal, true),
          ]),
          pageNumber,
          pageSize,
        },
        customConfig: getLibraryApiCustomConfig({
          skipCache: true,
        }),
      }) as Promise<
        PageResponse<RawUnitAssignmentListItem, UnitAssignmentListItem>
      >;
    });

    return pages.results;
  } catch (error) {
    await Logger.error(error);

    return [];
  }
}
