import * as appActions from '@hkm/components/App/domain/actions';
import { MaintenanceUpdateActions } from '@hkm/shared/domain/maintenanceUpdate/maintenanceUpdateActions';
import { MaintenanceUpdateData } from '@hkm/shared/domain/maintenanceUpdate/models/maintenanceUpdateData';
import { takeLatest } from '@redux-saga/core/effects';
import i18n from 'i18next';
import { put } from 'redux-saga/effects';

import { Action } from '@ac/library-utils/dist/declarations';

export interface MaintenanceUpdateSagaApi {
  postUpdateMaintenance(
    maintenanceUpdateData: MaintenanceUpdateData
  ): Promise<unknown>;
}

export function createMaintenanceUpdateSaga(
  actions: MaintenanceUpdateActions,
  maintenanceUpdateSagaApi: MaintenanceUpdateSagaApi
) {
  function* updateMaintenanceData(action: Action<MaintenanceUpdateData>) {
    try {
      yield maintenanceUpdateSagaApi.postUpdateMaintenance(action.payload);

      yield put(actions.updateMaintenance.success(action.payload.roomId));
      yield put(actions.notifyMaintenanceUpdate());
      yield put(
        appActions.displaySuccess(
          i18n.t('ROOM_DETAILS.UPDATE_MAINTENANCE_SUCCESS')
        )
      );
    } catch (e) {
      yield put(actions.updateMaintenance.failure(e));
      yield put(
        appActions.displayError(
          i18n.t('ROOM_DETAILS.UPDATE_MAINTENANCE_FAILED')
        )
      );
    }
  }

  return function* () {
    yield takeLatest(actions.updateMaintenance.trigger, updateMaintenanceData);
  };
}
