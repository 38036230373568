import React, { ReactElement, useEffect, useLayoutEffect, useRef } from 'react';
import { AdvancedFiltersBodyRenderProp } from '@hkm/components/shared/AdvancedFilters/advancedFilterChipsRenderProp';

import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { genericMemo } from '@ac/mobile-components/dist/utils';
import { FormRenderProps } from '@ac/react-infrastructure';

export interface AdvancedFiltersBodyProps<T> {
  formProps: FormRenderProps<T>;
  body: AdvancedFiltersBodyRenderProp<T>;
}

const bottomScrollAnchorEpsilonPx: number = 20;

function AdvancedFiltersBody<T>(
  props: AdvancedFiltersBodyProps<T>
): ReactElement {
  const bodyRef = useRef<HTMLDivElement>(null);
  const scrollBottom = useRef<number>();

  useEffect(() => {
    const body = bodyRef.current;
    if (body) {
      body.addEventListener('scroll', onScroll);
    }

    return () => {
      if (body) {
        body.removeEventListener('scroll', onScroll);
      }
    };

    // eslint-disable-next-line
  }, [bodyRef.current]);

  // If form changes, then we restore the scroll position based on the bottom scroll from last scroll
  useLayoutEffect(() => {
    if (
      bodyRef.current &&
      scrollBottom.current !== undefined &&
      scrollBottom.current < bottomScrollAnchorEpsilonPx
    ) {
      bodyRef.current.scrollTop =
        bodyRef.current.scrollHeight -
        bodyRef.current.clientHeight -
        scrollBottom.current;
    }
  }, [props.formProps]);

  function onScroll(e: Event) {
    const target = e.target as HTMLDivElement;
    scrollBottom.current =
      target.scrollHeight - target.scrollTop - target.clientHeight;
  }

  return (
    <AcBody className="advanced-filters-body" scrollRef={bodyRef}>
      {props.body(props.formProps)}
    </AcBody>
  );
}

export default genericMemo(AdvancedFiltersBody);
