import React from 'react';
import { UsersSelectorContext } from '@hkm/components/shared/features/UsersSelector/store/context';
import { useUsersSelectorStore } from '@hkm/components/shared/features/UsersSelector/store/store';

interface ComponentProps {
  children: React.ReactNode;
}

export const UsersSelectorProvider = (props: ComponentProps) => {
  const store = useUsersSelectorStore();

  return (
    <UsersSelectorContext.Provider value={store}>
      {props.children}
    </UsersSelectorContext.Provider>
  );
};
