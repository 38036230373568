import APP_CONSTANTS from '@hkm/constants/app.constants';
import { dayjs } from '@hkm/utils/dayjs-extended';

import { buildFIQLFilter, FIQLOperators } from '@ac/library-api';

export function createDateRangeFilter<T>(
  from: string | undefined,
  to: string | undefined,
  propertyA: keyof T,
  propertyB?: keyof T
): string | undefined {
  if (!from || !to) {
    return undefined;
  }

  from = dayjs(from).startOf('day').format(APP_CONSTANTS.API_DATE_FORMAT);
  to = dayjs(to).endOf('day').format(APP_CONSTANTS.API_DATE_FORMAT);

  const fromOperator: string | undefined = buildFIQLFilter(
    propertyA as string,
    FIQLOperators.greaterThenOrEqual,
    from
  );
  const toOperator: string | undefined = buildFIQLFilter(
    (propertyB || propertyA) as string,
    FIQLOperators.lessThenOrEqual,
    to
  );

  return `${fromOperator}T${APP_CONSTANTS.TIME_BEGINNING}${FIQLOperators.and}${toOperator}T${APP_CONSTANTS.TIME_END}`;
}
