import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChipsItem } from '@hkm/components/shared/Chips/ChipsItem';
import classNames from 'classnames';

import {
  AcBadge,
  AcBadgeList,
} from '@ac/mobile-components/dist/components/badge';
import {
  AcButton,
  AcButtonSize,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { MobileColor } from '@ac/mobile-components/dist/enums';
import {
  Childless,
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';

import './Chips.css';

interface ChipsProps extends Styleable, Childless, Testable {
  items: Array<ChipsItem<string>>;
  onRemove: (value: string) => void;
  onClear: () => void;
}

// tslint:disable:jsx-no-lambda
const Chips: FC<ChipsProps> = (props: ChipsProps) => {
  const { t } = useTranslation();

  const className: string = classNames('chips', props.className);

  return (
    <div className={className} style={props.style}>
      <ac-flex class="chips-scroll">
        <AcButton
          size={AcButtonSize.Small}
          onClick={props.onClear}
          className="ac-spacing-left-sm"
          testSelector={'chips-clear-all'}
        >
          <AcButtonContent text={t('GLOBAL.CLEAR_ALL')} />
        </AcButton>
        <AcBadgeList wrap={false} className="ac-spacing-left-sm">
          {props.items.map((item) => (
            // tslint:disable-next-line:jsx-no-lambda
            <AcBadge
              key={item.id}
              backgroundColor={MobileColor.Gray4}
              badgeText={item.label}
              onRemove={() => props.onRemove(item.value)}
              testSelector={`chips-${item.value}`}
            />
          ))}
        </AcBadgeList>
      </ac-flex>
    </div>
  );
};

export default memo(Chips);
