import { ReservedKindProps } from '@hkm/shared/reservedKind/getReservedKind';
import { groupReservedKinds } from '@hkm/shared/reservedKind/groupReservedKinds';
import { ReservedKind } from '@hkm/shared/reservedKind/reservedKind';
import { getRoomOccupancy } from '@hkm/shared/roomOccupancy/factory/getRoomOccupancy';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';
import { mapHousekeepingMaintenanceDetails } from '@hkm/types/maintenance/mappers/mapMaintenanceDetails';
import { mapHousekeepingReservationDetails } from '@hkm/types/reservation/mappers/mapReservationDetails';
import { mapHousekeepingRoomDetails } from '@hkm/types/room/mappers/mapRoomDetails';

import {
  FrontDeskStatus,
  HousekeepingRoomDetailsDto,
  HousekeepingStatus,
  ReservationStatus,
} from '@ac/library-api';

export function createHousekeepingRoom(
  roomDetails: HousekeepingRoomDetailsDto,
  businessDate: string,
  reservationKindResolver: (props: ReservedKindProps) => ReservedKind,
  propertyCheckInTime?: string,
  propertyCheckOutTime?: string
): HousekeepingRoom {
  const reservedKinds = groupReservedKinds({
    businessDate,
    propertyCheckInTime,
    propertyCheckOutTime,
    reservations: roomDetails.reservations?.map((reservation) =>
      mapHousekeepingReservationDetails(reservation)
    ),
    maintenances: roomDetails.maintenances?.map((maintenance) =>
      mapHousekeepingMaintenanceDetails(maintenance)
    ),
    reservationKindResolver,
  });

  const roomOccupancy = getRoomOccupancy(
    {
      currentReservations: (reservedKinds.currentReservations || []).map(
        (reservation) => ({
          occupancyDetails: reservation.occupancyDetails,
          status: reservation.status?.code as ReservationStatus,
        })
      ),
      frontdeskStatus:
        roomDetails.frontdeskStatus?.code ?? FrontDeskStatus.Vacant,
      housekeepingStatus:
        roomDetails.housekeepingStatus?.code ?? HousekeepingStatus.Vacant,
      occupancyDiscrepancy: roomDetails.occupancyDiscrepancy,
    },
    businessDate
  );

  const unifiedRoomDetails = mapHousekeepingRoomDetails(roomDetails);

  const isInPendingRoomQueue =
    roomDetails.queueRoomEntries?.some(
      (queueRoomEntry) => !queueRoomEntry.readyAt
    ) ?? false;
  const isInReadyRoomQueue =
    !!roomDetails.queueRoomEntries?.length && !isInPendingRoomQueue;

  return {
    ...roomDetails,
    ...reservedKinds,
    roomOccupancy,
    unifiedRoomDetails,
    isInPendingRoomQueue,
    isInReadyRoomQueue,
  };
}
