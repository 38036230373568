import { LibraryApiRequestHeaders } from '@ac/library-api';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { isDefined } from '@ac/library-utils/dist/utils';

interface Props {
  version?: string | number;
  correlationId?: string;
}

export enum LibraryApiHeaders {
  Authorization = 'Authorization',
  AcceptLanguage = 'Accept-Language',
  IfMatch = 'If-Match',
  AcCorrelationId = 'AC-Correlation-ID',
}

export function getLibraryApiHeaders({
  version,
  correlationId,
}: Props): LibraryApiRequestHeaders {
  const headers = {
    [LibraryApiHeaders.Authorization]: `Bearer ${
      LoginService.authData()?.token ?? ''
    }`,
    [LibraryApiHeaders.AcceptLanguage]: SessionService.getLanguage() ?? 'EN',
  };

  if (isDefined(version)) {
    headers[LibraryApiHeaders.IfMatch] = version;
  }

  if (isDefined(correlationId)) {
    headers[LibraryApiHeaders.AcCorrelationId] = correlationId;
  }

  return headers;
}
