import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DashboardSort as DashboardSortOption } from '@hkm/components/TaskManagement/Dashboard/domain/enums';
import {
  getDashboardSort,
  getIsLoading,
} from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import { useTaskDashboardManager } from '@hkm/components/TaskManagement/Dashboard/useTaskDashboardManager';
import { sortOptions } from '@hkm/components/TaskManagement/mappers';
import i18next from 'i18next';

import {
  AcSelect,
  AcSelectPattern,
} from '@ac/mobile-components/dist/components/select';

const DashboardSort: FC = () => {
  const { updateDashboardSort } = useTaskDashboardManager();
  const { t } = useTranslation();
  const activeSort = useSelector(getDashboardSort);
  const isLoading = useSelector(getIsLoading);

  const sortFiltersOptions = useMemo(() => sortOptions(i18next), []);
  const onChange = useCallback(
    (selectValue: DashboardSortOption) => {
      updateDashboardSort(selectValue);
    },
    [updateDashboardSort]
  );

  return (
    <AcSelect
      label={t('COMMON.SORT_BY')}
      showInputs={true}
      onChange={onChange}
      disabled={isLoading}
      itemsList={sortFiltersOptions}
      selectedItem={activeSort}
      pattern={AcSelectPattern.Dropdown}
      testSelector="task-management-dashboard-sort"
    />
  );
};

export default memo(DashboardSort);
