import {
  ImageQueryParams,
  ImageResolutionType,
} from '@hkm/components/App/imageQueue/domain/interfaces';

import { getOneAction } from '@ac/library-utils/dist/redux-utils';

export const fetchIndividualImages = getOneAction<
  ImageQueryParams,
  ImageResolutionType
>('app/images/individual/fetch');
