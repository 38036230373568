import { ImagesResolutionTypeQueue } from '@hkm/components/App/imageQueue/domain/interfaces';
import { uniq } from 'lodash';

/**
 * Find all unique ids that we need to fetch from the server
 * we remove those which were already cached
 *
 * @param queryImagesIds
 * @param cachedImagesQueue
 * @param limit
 */
export function filterCachedImagesIds(
  queryImagesIds: string[],
  cachedImagesQueue?: ImagesResolutionTypeQueue,
  limit?: number
): string[] {
  const uniqueQueryImagesIds = uniq(queryImagesIds);

  return !cachedImagesQueue
    ? uniqueQueryImagesIds.slice(0, limit)
    : uniqueQueryImagesIds
        .filter(
          (queryImageId: string) =>
            !cachedImagesQueue.imageQueue.includes(queryImageId)
        )
        .slice(0, limit);
}
