import React, { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLuhnAlghorithmValidationObject } from '@hkm/utils/getLuhnAlghorithmValidationObject';

import { AcButton } from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader,
} from '@ac/mobile-components/dist/components/modal';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTextarea } from '@ac/mobile-components/dist/components/textarea';
import {
  JustifyContent,
  MobileColor,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import {
  Disableable,
  Styleable,
  Validatable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { Color, IconName, Size } from '@ac/web-components';

import './CommentModal.css';

export interface CommentModalProps extends Styleable, Validatable, Disableable {
  comment: string | undefined;
  roomNumber: string;
  roomType: string;
  onSaveModal(newComment: string): void;
}
const CommentModal: FC<CommentModalProps> = (props: CommentModalProps) => {
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const [commentField, setCommentField] = useState<string | undefined>(
    props.comment
  );

  const isNotEmpty: boolean = !!commentField?.length;
  const { t } = useTranslation();

  useEffect(() => {
    setCommentField(props.comment);
  }, [props.comment]);

  function openModal(event: { preventDefault: () => void }): void {
    event.preventDefault();
    setModalVisibility(true);
  }

  function onChange(newValue: string): void {
    setCommentField(newValue);
  }

  function closeModal(): void {
    // rollback changes
    setCommentField(props.comment);
    setModalVisibility(false);
  }

  function onSaveModal(): void {
    props.onSaveModal(commentField || '');
    closeModal();
  }

  return (
    <>
      {!props.disabled && (
        <a href="#" onClick={openModal} className="link">
          <AcText color={MobileColor.Black} size={TextSize.Main1}>
            {props.comment}
          </AcText>
        </a>
      )}

      {props.disabled && (
        <AcText color={MobileColor.Black} size={TextSize.Main1}>
          {props.comment}
        </AcText>
      )}

      {props.validation && (
        <a href="#" onClick={openModal} className="unstyled-link">
          <AcText color={MobileColor.Alert} size={TextSize.Main1}>
            {props.validation}
          </AcText>
        </a>
      )}

      <AcModal
        className="comment-modal"
        isOpen={isModalVisible}
        onClose={closeModal}
      >
        <AcModalHeader className="comment-modal-header" showCloseButton={true}>
          {t('ROOM_DETAILS.COMMENT')}
        </AcModalHeader>

        <AcModalBody className="comment-modal-body">
          <ac-flex justifyContent={JustifyContent.spaceBetween}>
            <AcFormElement
              label={t('ROOM_DETAILS.ROOM_NUMBER')}
              flatAndJustify={JustifyContent.spaceBetween}
            >
              {props.roomNumber}
            </AcFormElement>
            <AcFormElement
              label={t('ROOM_DETAILS.ROOM_TYPE')}
              flatAndJustify={JustifyContent.spaceBetween}
            >
              {props.roomType}
            </AcFormElement>
          </ac-flex>

          <AcTextarea
            className="ac-spacing-top-md ac-spacing-bottom-md"
            required={true}
            value={commentField}
            label={t('ROOM_DETAILS.COMMENT')}
            onChange={onChange}
            maxlength={255}
            validation={getLuhnAlghorithmValidationObject(commentField)}
          />
          {getLuhnAlghorithmValidationObject(commentField) && (
            <>
              <AcText size={TextSize.Main3} className="ac-spacing-left-md">
                <ac-icon
                  class="ac-spacing-right-sm"
                  icon={IconName.information}
                  color={Color.informational}
                  size={Size.xs}
                />
                {getLuhnAlghorithmValidationObject(commentField)}
              </AcText>
            </>
          )}
        </AcModalBody>

        <AcModalFooter>
          <AcButton
            onClick={onSaveModal}
            style={{ width: '100%' }}
            disabled={!isNotEmpty}
          >
            <AcButtonContent text={t('GLOBAL.SAVE')} />
          </AcButton>
        </AcModalFooter>
      </AcModal>
    </>
  );
};

export default memo(CommentModal);
