import { MicroFrontendError } from '@hkm/features/microFrontends/errors/MicroFrontendError';
import i18n from 'i18next';

export class MissingBusinessContextError extends MicroFrontendError {
  constructor() {
    super(
      i18n.t('MICRO_FRONTENDS.ERRORS.MISSING_BUSINESS_CONTEXT.MESSAGE'),
      i18n.t('MICRO_FRONTENDS.ERRORS.MISSING_BUSINESS_CONTEXT.DESCRIPTION')
    );
  }
}
