import { Permission } from '@hkm/shared/permissions/enum/Permission';
import { PermissionConjunction } from '@hkm/shared/permissions/enum/PermissionConjunction';
import { PermissionsConfig } from '@hkm/shared/permissions/types/permissionsConfig';

export const AttendantViewPermissions = [
  Permission.HousekeepingUseMobileAttendantApplication,
] as const;

export const AttendantViewPermissionsConfig: PermissionsConfig = [
  AttendantViewPermissions,
  PermissionConjunction.All,
];
