import React, { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import DashboardQuickFilterItem from '@hkm/components/TaskManagement/Dashboard/DashboardSubheader/DashboardQuickFilterWrapper/DashboardQuickFilterItem/DashboardQuickFilterItem';
import { getDashboardQuickFilter } from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import { useTaskDashboardManager } from '@hkm/components/TaskManagement/Dashboard/useTaskDashboardManager';
import { TaskQuickFilter } from '@hkm/components/TaskManagement/domain/enums/TaskQuickFilter';
import { quickFilters } from '@hkm/components/TaskManagement/mappers';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import { disabledMenuSwipeClass } from '@hkm/features/app/panels/menu/behaviours/useSwipeEvent';
import classNames from 'classnames';
import i18next from 'i18next';

const DashboardQuickFilterWrapper: FC = () => {
  const { updateQuickFilter } = useTaskDashboardManager();
  const quickFilter = useSelector(getDashboardQuickFilter);
  const className = classNames('quick-filter', disabledMenuSwipeClass);
  const relations = useSelector(getRelationsState);
  const quickFiltersOptions = useMemo(() => quickFilters(i18next), []);

  const applyQuickFilter = useCallback(
    (filter: TaskQuickFilter) => {
      const currentFilter = quickFilter !== filter ? filter : undefined;
      updateQuickFilter(quickFilter, currentFilter, relations.currentUser);
    },
    [quickFilter, relations, updateQuickFilter]
  );

  return (
    <div className={className}>
      <ac-flex class="quick-filter-scroll">
        {quickFiltersOptions.map((quickFilterItem, index) => (
          <DashboardQuickFilterItem
            key={`${quickFilterItem.value}-${index}`}
            quickFilter={quickFilterItem}
            appliedQuickFilter={quickFilter}
            applyQuickFilter={applyQuickFilter}
          />
        ))}
      </ac-flex>
    </div>
  );
};

export default memo(DashboardQuickFilterWrapper);
