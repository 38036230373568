import * as appActions from '@hkm/components/App/domain/actions';
import { GreenServiceToggleActions } from '@hkm/shared/domain/greenServiceToggle/greenServiceToggleActions';
import { GreenServiceToggleRequest } from '@hkm/shared/domain/greenServiceToggle/greenServiceToggleRequest';
import { getCustomConfig } from '@hkm/utils/getCustomConfig';
import { put, takeLatest } from '@redux-saga/core/effects';
import i18n from 'i18next';

import {
  HousekeepingRoomsApi,
  MobileAttendantApi,
} from '@ac/library-api/dist/api/v0/housekeeping';
import { Action } from '@ac/library-utils/dist/declarations';

export function createHousekeepingGreenServiceToggleSagaFactory(
  actions: GreenServiceToggleActions
) {
  function* toggleGreenService(action: Action<GreenServiceToggleRequest>) {
    try {
      const { hasGreenService, roomId, roomVersion } = action.payload;
      const isRequesting = !hasGreenService;

      const method = isRequesting
        ? HousekeepingRoomsApi.postRequestGreenService
        : HousekeepingRoomsApi.postCancelGreenService;

      yield method({
        pathParams: { roomId },
        customConfig: getCustomConfig({
          version: roomVersion,
        }),
      });

      const key = isRequesting
        ? 'ROOM_DETAILS.GREEN_SERVICE_REQUEST_SUCCESS'
        : 'ROOM_DETAILS.GREEN_SERVICE_CANCEL_SUCCESS';
      yield put(appActions.displaySuccess(i18n.t(key)));
      yield put(actions.toggleGreenService.success(roomId));
    } catch (e) {
      yield put(appActions.displayExtractedError(e));
      yield put(actions.toggleGreenService.failure(e));
    }
  }

  return function* () {
    yield takeLatest(actions.toggleGreenService.trigger, toggleGreenService);
  };
}

export function createAttendantGreenServiceToggleSagaFactory(
  actions: GreenServiceToggleActions
) {
  function* toggleGreenService(action: Action<GreenServiceToggleRequest>) {
    try {
      const { hasGreenService, roomId, roomVersion } = action.payload;
      const isRequesting = !hasGreenService;

      const method: (args: unknown) => Promise<unknown> = isRequesting
        ? (MobileAttendantApi.postRequestGreenService as never)
        : (MobileAttendantApi.postCancelGreenService as never);

      yield method({
        pathParams: { roomId },
        customConfig: getCustomConfig({
          version: roomVersion,
        }),
      });

      const key = isRequesting
        ? 'ROOM_DETAILS.GREEN_SERVICE_REQUEST_SUCCESS'
        : 'ROOM_DETAILS.GREEN_SERVICE_CANCEL_SUCCESS';
      yield put(appActions.displaySuccess(i18n.t(key)));
      yield put(actions.toggleGreenService.success(roomId));
    } catch (e) {
      yield put(appActions.displayExtractedError(e));
      yield put(actions.toggleGreenService.failure(e));
    }
  }

  return function* () {
    yield takeLatest(actions.toggleGreenService.trigger, toggleGreenService);
  };
}
