import * as appActions from '@hkm/components/App/domain/actions';
import * as actions from '@hkm/components/TaskManagement/Dashboard/domain/actions';
import { Filters } from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import {
  getDashboardFilters,
  getDashboardPaging,
  getDashboardSearchString,
  getDashboardSort,
  getPropertyTimezone,
} from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import { getDashboardFilterString } from '@hkm/components/TaskManagement/Dashboard/FilterStringBuilder';
import { getConfigurationWithLanguage } from '@hkm/utils/api';
import { put, select, takeLatest } from 'redux-saga/effects';

import {
  PageResponse,
  PagingInfo,
  QueryQueryParams,
  RawTaskDashboardItem,
  TaskDashboardItem,
} from '@ac/library-api';
import { TasksApi } from '@ac/library-api/dist/api/v0/taskManagement';

function* fetchDashboard() {
  try {
    const filters: Filters = yield select(getDashboardFilters);
    const paging: PagingInfo = yield select(getDashboardPaging);
    const query: string = yield select(getDashboardSearchString);
    const sort: string = yield select(getDashboardSort);
    const filterString = getDashboardFilterString(filters);
    const customConfig = getConfigurationWithLanguage();
    const propertyTimeZone: string = yield select(getPropertyTimezone);
    const queryParams: QueryQueryParams = {
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize,
      query,
      sort,
      filter: filterString,
    };
    const result: PageResponse<RawTaskDashboardItem, TaskDashboardItem> =
      yield TasksApi.getViews({ customConfig, queryParams });

    yield put(
      actions.fetchDashboard.success({ list: result, propertyTimeZone })
    );
  } catch (error) {
    yield put(appActions.displayExtractedError(error));
    yield put(actions.fetchDashboard.failure(error));
  }
}

export default function* dashboardSagas() {
  yield takeLatest(actions.fetchDashboard.trigger, fetchDashboard);
  yield takeLatest(actions.updateSelectedFilters, fetchDashboard);
  yield takeLatest(actions.updateSort, fetchDashboard);
  yield takeLatest(actions.updatePaginationPage, fetchDashboard);
  yield takeLatest(actions.updatePagingAndFilters, fetchDashboard);
  yield takeLatest(actions.updateFiltersAndSearch, fetchDashboard);
  yield takeLatest(actions.clearFilteringState, fetchDashboard);
}
