import { HousekeepingViewPermissions } from '@hkm/components/Housekeeping/shared/config/housekeepingViewPermissionsConfig';
import { Permission } from '@hkm/shared/permissions/enum/Permission';
import { PermissionConjunction } from '@hkm/shared/permissions/enum/PermissionConjunction';
import { PermissionsConfig } from '@hkm/shared/permissions/types/permissionsConfig';

export const HousekeepingBatchPermissions = [
  ...HousekeepingViewPermissions,
  Permission.HousekeepingManageRoomStatuses,
] as const;

export const HousekeepingManagePermissionsConfig: PermissionsConfig = [
  HousekeepingBatchPermissions,
  PermissionConjunction.All,
];
