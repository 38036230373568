import React, { FC, HTMLAttributes, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { AcText } from '@ac/mobile-components/dist/components/text';
import { TextSize } from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorAttribute } from '@ac/mobile-components/dist/utils';
import { IconName, Size } from '@ac/web-components';

interface Props extends HTMLAttributes<HTMLDivElement>, Testable {
  small?: boolean;
}

const ReservationsMovementsInfo: FC<Props> = ({
  testSelector,
  small,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <div
      {...rest}
      {...getTestSelectorAttribute(testSelector, 'reservationsMovementsInfo')}
    >
      <ac-icon
        class="reservations-movements-info-icon ac-spacing-right-s"
        icon={IconName.transfer}
        size={small ? Size.sm : Size.h2}
      />
      <AcText size={small ? TextSize.Main3 : TextSize.Main2}>
        {t('GLOBAL.RESERVATIONS_MOVEMENTS')}
      </AcText>
    </div>
  );
};

export default memo(ReservationsMovementsInfo);
