import { LibraryApiHeaders } from '@hkm/features/api/getLibraryApiHeaders';

import { Config, ConfigBuilder } from '@ac/library-api';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { isDefined } from '@ac/library-utils/dist/utils';

export interface CustomConfigOptions {
  version?: string | number;
}

export function getCustomConfig(options?: CustomConfigOptions): Config {
  options = options || {};
  const { version } = options;

  const combinedHeaders = {
    [LibraryApiHeaders.Authorization]: `Bearer ${
      LoginService.accessToken() ?? ''
    }`,
    [LibraryApiHeaders.AcceptLanguage]: SessionService.getLanguage() ?? 'EN',
  };

  if (isDefined(version)) {
    combinedHeaders['If-Match'] = version;
  }

  return new ConfigBuilder().setHeaders(combinedHeaders).getConfig();
}
