import { createMaintenanceApiFilter } from '@hkm/components/Maintenance/shared/models/factories/maintenanceApiFilterFactory';
import { MaintenanceFiltersData } from '@hkm/components/Maintenance/shared/models/maintenanceFiltersData';
import { RoomsNotFoundException } from '@hkm/shared/errors/ErrorException';
import { createMaintenanceRoom } from '@hkm/types/maintenance/factory/createMaintenanceRoom';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';

import { RoomMaintenanceState } from '@ac/library-api';
import { MaintenanceViewsApi } from '@ac/library-api/dist/api/v0/housekeeping';

// todo change to proper endpoint when it'll be available, for now we filter dashboard endpoint
export async function getMaintenanceRoomById(
  id: string
): Promise<MaintenanceRoom> {
  const filters: MaintenanceFiltersData = {
    id: [id],
    extraMaintenanceStates: [
      RoomMaintenanceState.Canceled,
      RoomMaintenanceState.Released,
    ],
  };

  const page = await MaintenanceViewsApi.getDashboard({
    queryParams: {
      filter: createMaintenanceApiFilter(filters),
      pageSize: 1,
    },
  });

  if (page.data.results.length === 0) {
    throw new RoomsNotFoundException<string>(id);
  }

  return createMaintenanceRoom(page.data.results[0]);
}
