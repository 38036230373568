import { parseNumberRanges } from '@hkm/shared/numberRange/numberRangesParser';
import { dayjs } from '@hkm/utils/dayjs-extended';
import i18n from 'i18next';

import { ValidationResult } from '@ac/react-infrastructure';

interface TimeGenericObject {
  fromTime?: string;
  toTime?: string;
}
interface BusinessDate {
  businessDate: string;
}

function error(key: string): ValidationResult[] {
  return [{ severity: 'error', description: i18n.t(key) }] as never[];
}

function valid(): ValidationResult[] {
  return [];
}

function hasError(status: ValidationResult[]): boolean {
  return status.length > 0;
}

export function isDefined(value: string): ValidationResult[] {
  return value === undefined || value === null
    ? error('VALIDATORS.IS_REQUIRED')
    : valid();
}

export function isNonEmptyString(str: string): ValidationResult[] {
  return hasError(isDefined(str)) || str.trim().length === 0
    ? error('VALIDATORS.IS_REQUIRED')
    : valid();
}

export function isNonEmptyArray(array: string[]): ValidationResult[] {
  return !Array.isArray(array) || array.length === 0
    ? error('VALIDATORS.IS_REQUIRED')
    : valid();
}

export function isValidNumberRange(range: string): ValidationResult[] {
  return !parseNumberRanges(range)
    ? error('VALIDATORS.NUMBER_RANGES')
    : valid();
}

export function isDateNotEmpty(date?: string): ValidationResult[] {
  return !!date && dayjs(date).isValid()
    ? valid()
    : error('VALIDATORS.IS_REQUIRED');
}

export function isEndDateBeforeStartDate(
  value: string,
  date: TimeGenericObject
): ValidationResult[] {
  if (!!date.fromTime && !!date.toTime) {
    return dayjs(date.toTime).isBefore(date.fromTime)
      ? error('VALIDATORS.END_DATE_BEFORE_START_DATE')
      : valid();
  }

  return valid();
}

export function isStartDateAfterEndDate(
  value: string,
  date: TimeGenericObject
): ValidationResult[] {
  if (!!date.fromTime && !!date.toTime) {
    return dayjs(date.fromTime).isAfter(date.toTime)
      ? error('VALIDATORS.START_DATE_AFTER_END_DATE')
      : valid();
  }

  return valid();
}

export function isStartDateBeforeBusinessDate(
  fromDate: string,
  data: BusinessDate
): ValidationResult[] {
  return dayjs(fromDate).isBefore(data.businessDate)
    ? error('VALIDATORS.START_DATE_BEFORE_BUSINESS_DATE')
    : valid();
}
