import React from 'react';
import HeaderCloseButton from '@hkm/components/shared/LayoutComponents/HeaderCloseButton';
import HeaderHelpButton from '@hkm/components/shared/LayoutComponents/HeaderHelpButton';
import { useMicroFrontendsContext } from '@hkm/features/microFrontends/store/context';

export const NavbarRight = (): JSX.Element => {
  const {
    state: { navBar },
  } = useMicroFrontendsContext();

  return (
    <>
      {navBar.showHelpIcon && <HeaderHelpButton />}
      {(navBar.closeConfig?.link || navBar.closeConfig?.onClickCallback) && (
        <HeaderCloseButton
          link={navBar.closeConfig.link}
          onClick={navBar.closeConfig.onClickCallback}
        />
      )}
    </>
  );
};
