import { Filters } from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import { TimeUnits } from '@hkm/components/TaskManagement/domain/enums/TimeUnits';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import moment, { Moment } from 'moment-timezone';

import { ScheduledDueDateTime, TaskStatusRole } from '@ac/library-api';

export default class DateTimeHelpers {
  public static formatDateForField = (
    standardDateFormat: string | Date,
    dateFormat: string = 'YYYY-MM-DD'
  ): string => {
    return moment(standardDateFormat).format(dateFormat);
  };

  public static formatTimeForField = (
    standardDateFormat: string | Date,
    timeFormat: string = 'HH:mm'
  ): string => {
    return moment(standardDateFormat).format(timeFormat);
  };

  public static formatTime = (
    time?: string,
    timeFormat: string = APP_CONSTANTS.DEFAULT_24H_DISPLAY_TIME
  ): string | undefined => {
    if (!time) return '';

    return moment(time, APP_CONSTANTS.DEFAULT_24H_DISPLAY_TIME).format(
      timeFormat
    );
  };

  public static includeTimezoneToPropertyTime = (
    dateTime: Moment,
    timeZoneCode: string
  ): string => {
    return moment
      .tz(dateTime, timeZoneCode)
      .format(APP_CONSTANTS.DEFAULT_TIMEZONE_DATE_FORMAT);
  };

  public static getDefaultDateFilter = (
    propertyTimeZoneCode: string
  ): Partial<Filters> => {
    const currentBrowserDateTime = moment();
    const dueDateFrom = DateTimeHelpers.includeTimezoneToPropertyTime(
      currentBrowserDateTime,
      propertyTimeZoneCode
    );
    const formattedDueDateFrom =
      DateTimeHelpers.formatDateForField(dueDateFrom);
    const dueDateTo = moment
      .tz(currentBrowserDateTime, propertyTimeZoneCode)
      .add(7, 'days')
      .format(APP_CONSTANTS.DEFAULT_TIMEZONE_DATE_FORMAT);
    const formattedDueDateTo = DateTimeHelpers.formatDateForField(dueDateTo);

    return {
      dateFrom: formattedDueDateFrom.toString(),
      dateTo: formattedDueDateTo.toString(),
    };
  };

  public static formatDateTimeFromMinutes = (
    minutes: number,
    timeZoneCode: string
  ): { dueDate: Date; dueTime: string } => {
    const dateTime = moment();
    DateTimeHelpers.addOrSubstractTime(
      dateTime,
      minutes,
      false,
      TimeUnits.minutes
    );

    const timezonedDateTime = DateTimeHelpers.includeTimezoneToPropertyTime(
      dateTime,
      timeZoneCode
    );
    // Because js Date is behaving differently regarding formats - "YYYY/MM/DD" is the most efficient one to create Date object
    const dueDate = new Date(
      DateTimeHelpers.formatDateForField(timezonedDateTime, 'YYYY/MM/DD')
    );

    return {
      dueDate,
      dueTime: DateTimeHelpers.formatTimeForField(timezonedDateTime),
    };
  };

  public static combineDateTime = (
    date?: Date,
    time?: string,
    isEndDate = true
  ): string => {
    const dueDateString = date && moment(date).format('YYYY-MM-DD[T]');
    const seconds = isEndDate ? '59' : '00';

    return (
      (dueDateString && time && `${dueDateString}${time}:${seconds}`) || ''
    );
  };

  public static separateTimeDate = (
    standardDateFormat: Date | string
  ): { dueDate?: Date; dueTime: string } => {
    if (standardDateFormat) {
      // Because js Date is behaving differently regarding formats - "YYYY/MM/DD" is the most efficient one to create Date object
      const dueDate = new Date(
        DateTimeHelpers.formatDateForField(standardDateFormat, 'YYYY/MM/DD')
      );
      const dueTime = DateTimeHelpers.formatTimeForField(standardDateFormat);

      return { dueDate, dueTime };
    }

    return { dueDate: undefined, dueTime: '' };
  };

  public static separateTime = (
    time: string
  ): { hours: string; minutes: string } => {
    const splitArray = time.split(':');

    return { hours: splitArray[0], minutes: splitArray[1] };
  };

  public static combineTime = (
    hours: string | number,
    minutes: string | number
  ): string => {
    return `${hours}:${minutes}`;
  };

  public static calculateDateTime = (
    dateTime: string,
    isSubstract: boolean,
    timeObject: { days?: number; hours?: number; minutes?: number }
  ): string => {
    let calculatedTime = moment(dateTime);

    if (timeObject.days) {
      calculatedTime = DateTimeHelpers.addOrSubstractTime(
        calculatedTime,
        timeObject.days,
        isSubstract,
        TimeUnits.days
      );
    } else if (timeObject.hours) {
      calculatedTime = DateTimeHelpers.addOrSubstractTime(
        calculatedTime,
        timeObject.hours,
        isSubstract,
        TimeUnits.hours
      );
    } else if (timeObject.minutes) {
      calculatedTime = DateTimeHelpers.addOrSubstractTime(
        calculatedTime,
        timeObject.minutes,
        isSubstract,
        TimeUnits.minutes
      );
    }

    return calculatedTime.format(APP_CONSTANTS.DEFAULT_OUTPUT_DATE_FORMAT);
  };

  public static isDateTimeOverdue = (
    propertyTimeZoneCode: string,
    dueDate?: string,
    dueTime?: string,
    role?: string
  ): boolean => {
    if (DateTimeHelpers.shouldValidateDateTime(dueDate, dueTime, role)) {
      if (!dueDate) {
        return false;
      }

      const currentBrowserDateTime = moment();
      const currentPropertyDateTime = moment(
        DateTimeHelpers.includeTimezoneToPropertyTime(
          currentBrowserDateTime,
          propertyTimeZoneCode
        )
      );

      const taskEntityDateTime = moment(
        dueDate + ' ' + (dueTime ?? '23:59'),
        APP_CONSTANTS.DEFAULT_OUTPUT_DATE_FORMAT
      );

      return !(taskEntityDateTime > currentPropertyDateTime);
    } else {
      return false;
    }
  };

  public static getScheduledDueDateTimeValueName = (
    scheduledDueDateTime: ScheduledDueDateTime
  ): TimeUnits => {
    if (scheduledDueDateTime.days) {
      return TimeUnits.days;
    } else if (scheduledDueDateTime.hours) {
      return TimeUnits.hours;
    } else {
      return TimeUnits.minutes;
    }
  };

  public static isDateInRange = (
    date: string,
    startDate?: string,
    endDate?: string
  ): boolean => {
    if (!startDate && !endDate) {
      return true;
    }

    if (startDate && !endDate) {
      return moment(date).isAfter(startDate);
    }

    if (!startDate && endDate) {
      return moment(date).isBefore(endDate);
    }

    return moment(date).isBetween(startDate, endDate);
  };

  public static getTodayUTCDateTime = (): string => {
    return moment()
      .utc()
      .local()
      .format(APP_CONSTANTS.DEFAULT_UTC_OUTPUT_DATE_FORMAT);
  };

  private static addOrSubstractTime = (
    dateTime: moment.Moment,
    value: number,
    isSubstract: boolean,
    timeType: TimeUnits
  ): moment.Moment => {
    return isSubstract
      ? dateTime.subtract(value, timeType)
      : dateTime.add(value, timeType);
  };

  private static shouldValidateDateTime = (
    dueDate?: string,
    dueTime?: string,
    role?: string
  ): boolean => {
    if (!dueDate && !dueTime) {
      return false;
    }
    if (
      role === TaskStatusRole.Cancellation ||
      role === TaskStatusRole.Completion
    ) {
      return false;
    }

    return true;
  };
}
