import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  generatePath,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import PostingDetailsBody from '@hkm/components/Posting/PostingView/Body/PostingDetailsBody';
import * as postingViewActions from '@hkm/components/Posting/PostingView/domain/actions';
import {
  selectIsLoadingPostingView,
  selectPostingReservationChargedState,
} from '@hkm/components/Posting/PostingView/domain/selectors';
import { useFetchTransactionCodes } from '@hkm/components/Posting/PostingView/hooks/useFetchTransactionCodes';
import * as sharedActions from '@hkm/components/Posting/shared/domain/actions';
import {
  selectAttendantIsLoadingRoom,
  selectAttendantPostingRoomState,
} from '@hkm/components/Posting/shared/domain/selectors';
import { useIsMobilePostingConfigured } from '@hkm/components/Posting/shared/features/misconfigurationErrorPage/commands/useIsMobilePostingConfigured';
import { MobilePostingMisconfigurationErrorPage } from '@hkm/components/Posting/shared/features/misconfigurationErrorPage/MisconfigurationErrorPage';
import {
  getPostingAttendantReservations,
  getPostingHousekeepingReservation,
} from '@hkm/components/Posting/shared/utils/getPostingReservation';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import HeaderTwoLineTitle from '@hkm/components/shared/LayoutComponents/HeaderTwoLineTitle';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { ReservationChargesData } from '@hkm/shared/domain/reservationCharges/models/reservationChargesData';
import { useFetchAssignedSheetOnNotification } from '@hkm/shared/hooks/useFetchAssignedSheetOnNotification';
import { PostChargeLocationState } from '@hkm/shared/interfaces/locationStates';

import { AcBody } from '@ac/mobile-components/dist/components/layout';

const AttendantPostingView: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const { roomId, reservationId } = params;
  const roomState = useSelector(selectAttendantPostingRoomState);

  const transactionCodesMap = useFetchTransactionCodes();
  const reservationChargedState = useSelector(
    selectPostingReservationChargedState
  );

  const isLoadingRoom = useSelector(selectAttendantIsLoadingRoom);
  const isLoadingPosting = useSelector(selectIsLoadingPostingView);
  const showSpinner = isLoadingRoom || isLoadingPosting;
  const { isMobilePostingConfigured } = useIsMobilePostingConfigured();

  useFetchAssignedSheetOnNotification();

  useEffect(() => {
    if (!roomState.didInitialize || roomState.roomId !== roomId) {
      dispatch(sharedActions.reset());
      if (params.roomId) {
        dispatch(sharedActions.initializeAttendantPostingRoom(params.roomId));
      }
    }

    if (reservationId) {
      dispatch(
        postingViewActions.attendantReservationChargesActionsSet.fetchReservationRecentCharges.trigger(
          reservationId
        )
      );
    }

    return () => {
      dispatch(postingViewActions.resetReservationRecentCharges());
    };
  }, [
    dispatch,
    roomId,
    params.roomId,
    reservationId,
    roomState.didInitialize,
    roomState.roomId,
  ]);

  const postingReservation = useMemo(
    () =>
      reservationId
        ? getPostingHousekeepingReservation(
            reservationId,
            roomState.room?.currentReservations
          )
        : undefined,
    [roomState, reservationId]
  );

  const closePathHandler = useCallback(() => {
    const locationState = (location.state || {}) as PostChargeLocationState;
    const pathname = locationState.returnPath
      ? locationState.returnPath
      : getPostingAttendantReservations(roomState.room?.currentReservations)
          .length > 1
      ? generatePath(APP_ROUTES.MOBILE_POSTING.ATTENDANT.RESERVATIONS, {
          roomId,
        })
      : generatePath(APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_DETAILS, { roomId });

    navigate(pathname, {
      state: {
        data: locationState.data,
      } as PostChargeLocationState,
    });
  }, [location.state, navigate, roomId, roomState.room?.currentReservations]);

  const onSubmitHandler = useCallback(
    (data: ReservationChargesData) => {
      dispatch(
        postingViewActions.attendantReservationChargesActionsSet.postReservationCharges.trigger(
          data
        )
      );
    },
    [dispatch]
  );

  if (!isMobilePostingConfigured) {
    return (
      <MobilePostingMisconfigurationErrorPage
        headerTitle={t('POSTING.POSTING_DETAILS.TITLE')}
      />
    );
  }

  // hack to not be redirected accidentally during swapping room data in store
  if (roomState.roomId !== roomId) {
    return null;
  }

  if (
    // Navigate to dashboard if we initialized, but no room is available
    (!roomState.room && roomState.didInitialize) ||
    // or reservation doesn't exist or do not have the appropriate status
    (roomState.room && !postingReservation) ||
    // or there is no information about current state of reservation charge
    (reservationChargedState.didInitialize && !reservationChargedState.data)
  ) {
    return (
      <Navigate
        to={generatePath(APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_DETAILS, {
          roomId,
        })}
      />
    );
  }

  return (
    <>
      <ConnectedHeader showHelpButton={true} close={closePathHandler}>
        {roomState.room && roomState.room.roomNumber && (
          <HeaderTwoLineTitle
            upperText={t('POSTING.POSTING_DETAILS.TITLE')}
            lowerText={roomState.room.roomNumber || '\u00A0'}
          />
        )}
        {!roomState.room && t('POSTING.POSTING_DETAILS.TITLE')}
      </ConnectedHeader>
      <AcBody
        className="posting-details-dashboard-body"
        showSpinner={showSpinner}
      >
        {postingReservation &&
          transactionCodesMap.size !== 0 &&
          roomState.room?.unifiedRoomDetails && (
            <PostingDetailsBody
              onSubmitHandler={onSubmitHandler}
              onCloseHandler={closePathHandler}
              reservation={postingReservation}
              transactionCodesMap={transactionCodesMap}
              unifiedRoomDetails={roomState.room.unifiedRoomDetails}
            />
          )}
      </AcBody>
    </>
  );
};

export default memo(AttendantPostingView);
