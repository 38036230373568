import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMobileFeaturesContext } from '@hkm/features/app/store/features/context';
import {
  ConfigurationDictionaryPattern,
  DictionarySelectFormat,
} from '@hkm/features/configuration/dictionaries/interfaces';

import { LanguageApi } from '@ac/library-api/dist/api/v0/configuration/systemDictionaries';
import { DictionaryEntity } from '@ac/library-api/dist/types/entities/configuration';
import { DictionaryUtils } from '@ac/library-utils/dist/utils';

export type LanguagesDictionaryPattern = ConfigurationDictionaryPattern<
  DictionaryEntity,
  {
    toAcSelect: () => DictionarySelectFormat[];
  },
  {
    byCode: Record<string, DictionaryEntity>;
  }
>;

export function useLanguagesDictionary(): LanguagesDictionaryPattern {
  const [languagesDictionary, setLanguagesDictionary] =
    useState<DictionaryEntity[]>();
  const { app } = useMobileFeaturesContext();

  useEffect(() => {
    const fetchLanguages = async () => {
      const languages = await LanguageApi.getList();
      setLanguagesDictionary(languages as unknown as DictionaryEntity[]);
    };

    fetchLanguages();
  }, []);

  const items = useMemo(() => {
    return (languagesDictionary ?? []).sort(
      DictionaryUtils.sorter.displaySequenceAndCode.ascending
    );
  }, [languagesDictionary]);

  const active = useMemo(() => {
    const languages = app.availableLanguages.map((lang) => lang.toUpperCase());

    return items.filter((item) =>
      item.code ? languages.includes(item.code.toUpperCase()) : false
    );
  }, [app.availableLanguages, items]);

  const dictionaryByCode = useMemo(() => {
    return items.reduce((dictionary, item) => {
      if (item.code) {
        dictionary[item.code] = item;
      }

      return dictionary;
    }, {});
  }, [items]);

  const mapToSelectFormat = useCallback(
    (records: DictionaryEntity[]): DictionarySelectFormat[] => {
      return records.map((record) => ({
        value: record.code ?? '',
        inputLabel: record.name ?? '',
        itemLabel: record.name ?? '',
      }));
    },
    []
  );

  return useMemo(
    () => ({
      all: {
        items,
        format: {
          toAcSelect: (records?: DictionaryEntity[]) =>
            mapToSelectFormat(records ?? items),
        },
      },
      active: {
        items: active,
        format: {
          toAcSelect: (records?: DictionaryEntity[]) =>
            mapToSelectFormat(records ?? active),
        },
      },
      dictionary: {
        byCode: dictionaryByCode,
      },
      dependency: [],
    }),
    [items, active, dictionaryByCode, mapToSelectFormat]
  );
}
