import { formatProfileName, TitleOrders } from '@ac/library-api';

interface FilledProfileDetails {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  title?: string;
  suffix?: string;
  nameSetting?: TitleOrders;
}

export const getProfileName = (profileDetails: FilledProfileDetails) => {
  return formatProfileName(
    profileDetails.nameSetting
      ? profileDetails.nameSetting
      : TitleOrders.LastNameFirstNameTitle,
    {
      title: profileDetails.title,
      firstName: profileDetails.firstName,
      middleName: profileDetails.middleName,
      lastName: profileDetails.lastName,
      suffix: profileDetails.suffix,
    }
  );
};
