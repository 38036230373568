import APP_ROUTES from '@hkm/constants/routing.constants';

import { acConfig } from '@ac/library-utils/dist/declarations';

const getFrontendModuleUrl = (
  name: keyof typeof acConfig.frontendUrls
): string => {
  if (process.env.REACT_APP_DEVELOPMENT_IP_ADDRESS) {
    return `http://${process.env.REACT_APP_DEVELOPMENT_IP_ADDRESS}:${window.location.port}`;
  }

  return window.location.hostname === 'localhost' &&
    name === 'housekeepingMobile'
    ? window.location.origin
    : acConfig.frontendUrls[name];
};

export const getRootUrl = (): string => {
  return `${getFrontendModuleUrl('housekeepingMobile')}`;
};

const getLoginUrl = (): string => {
  return `${getFrontendModuleUrl('housekeepingMobile')}${APP_ROUTES.LOGIN}`;
};

export default getLoginUrl;
