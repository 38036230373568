import { useCallback } from 'react';

import { Logger, LoggerService } from '@ac/library-api';

export let logger: LoggerService | undefined;

interface Output {
  log(log: Error): Promise<void>;
}

export function useMicroFrontendsLogger(): Output {
  const log = useCallback(async (error: Error): Promise<void> => {
    if (!logger) {
      Logger.setAsMain();
      logger = await Logger.init('housekeepingMobile');
    }

    logger.error(error.message);
    // eslint-disable-next-line no-console
    console.error(error);
  }, []);

  return {
    log,
  };
}
