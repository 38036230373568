import { useCallback } from 'react';
import {
  SingleSpaLifecycle,
  useMicroFrontendsLifecycleInjections,
} from '@hkm/features/microFrontends/commands/useMicroFrontendsLifecycleInjections';
import { useMicroFrontendsLogger } from '@hkm/features/microFrontends/commands/useMicroFrontendsLogger';
import { MissingAppUrlError } from '@hkm/features/microFrontends/errors/MissingAppUrlError';
import { UnableToLoadError } from '@hkm/features/microFrontends/errors/UnableToLoadError';
import { UnexpectedError } from '@hkm/features/microFrontends/errors/UnexpectedError';
import { useMicroFrontendsContext } from '@hkm/features/microFrontends/store/context';

import { loadReactModule } from '@ac/library-utils/dist/utils/micro-frontends/loading/page/react';
import { AppToRender } from '@ac/library-utils/dist/utils/micro-frontends/rendering/shared';

const EXPORTED_EMPTY_COMPONENT = {
  bootstrap: [],
  mount: [],
  unmount: [],
};

interface Output {
  load(appUrl?: string): Promise<SingleSpaLifecycle>;
}

interface ReactModule {
  getComponents(): Promise<unknown>;
  getIndexInContainer(): Promise<AppToRender>;

  /**
   * @private
   * This method should be only available in hk/tm repo runtime
   * Avoid adding it to lib-utils, it will be replaced in future by mobile container
   */
  getIndexInMobileHousekeepingContainer(): Promise<AppToRender>;
}

export function useMicroFrontendsReactLoader(): Output {
  const { setError } = useMicroFrontendsContext();
  const { inject } = useMicroFrontendsLifecycleInjections();
  const { log } = useMicroFrontendsLogger();

  const load = useCallback(
    async (appUrl?: string): Promise<SingleSpaLifecycle> => {
      if (!appUrl) {
        const error = new MissingAppUrlError();

        await log(error);
        setError(error);

        return EXPORTED_EMPTY_COMPONENT;
      }
      try {
        const acModule = (await loadReactModule(appUrl)) as ReactModule;
        const exportedApp =
          await acModule.getIndexInMobileHousekeepingContainer();

        return inject(exportedApp);
      } catch (error) {
        await log(error);

        if (error.message === 'Failed to fetch') {
          setError(new UnableToLoadError());
        } else {
          setError(new UnexpectedError(error.message));
        }
      }

      return EXPORTED_EMPTY_COMPONENT;
    },
    [inject, log, setError]
  );

  return {
    load,
  };
}
