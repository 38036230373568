import APP_ROUTES from '@hkm/constants/routing.constants';

interface Detector {
  pathname: string;
  expectedUrl: string;
}

export function isMicroFrontend({ pathname, expectedUrl }: Detector): boolean {
  if (pathname?.startsWith(APP_ROUTES.MICRO_FRONTEND)) {
    const candidate = pathname.substring(APP_ROUTES.MICRO_FRONTEND.length);

    return candidate?.startsWith(expectedUrl ?? '');
  }

  return false;
}
