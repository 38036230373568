import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MaintenanceAttachments from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/Attachments/MaintenanceAttachments';
import { selectMaintenanceAttachmentsFilesMap } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/domain/selectors';
import { AttachmentMangeAccessLevel } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/enum/attachmentMangeAccessLevel';
import { MaintenanceAttachmentData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/maintenanceAttachmentData';
import {
  AddMaintenanceAttachmentData,
  RemoveMaintenanceAttachmentData,
  UpdateMaintenanceAttachmentData,
} from '@hkm/shared/domain/maintenanceAttachment/uploadAttachement/models/maintenanceUploadAttachmentData';

import { RoomMaintenancesAttachmentPathParam } from '@ac/library-api';
import { AcTile } from '@ac/mobile-components/dist/components/tile';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { IconName } from '@ac/web-components';

export interface MaintenanceAttachmentsTileProp extends Testable {
  attachments: MaintenanceAttachmentData[];
  roomId: string;
  roomVersionId: number;
  maintenanceId: string;
  customerId?: string;
  manageAccessLevel: AttachmentMangeAccessLevel;
  onAdd(data: AddMaintenanceAttachmentData): void;
  onRemove(data: RemoveMaintenanceAttachmentData): void;
  onUpdate(data: UpdateMaintenanceAttachmentData): void;
  onFileRequest(data: RoomMaintenancesAttachmentPathParam): void;
}

const MaintenanceAttachmentsTile: FC<MaintenanceAttachmentsTileProp> = (
  props
) => {
  const { t } = useTranslation();
  const loadedFiles = useSelector(selectMaintenanceAttachmentsFilesMap);

  function onAdd(data: MaintenanceAttachmentData) {
    props.onAdd({
      description: data.description || '',
      name: data.fileName,
      file: data.file,
      maintenanceId: props.maintenanceId,
      roomId: props.roomId,
      roomVersionId: props.roomVersionId,
    });
  }

  function onRemove(data: MaintenanceAttachmentData) {
    props.onRemove({
      maintenanceId: props.maintenanceId,
      roomId: props.roomId,
      roomVersionId: props.roomVersionId,
      fileId: data.id,
    });
  }

  function onUpdate(data: MaintenanceAttachmentData) {
    props.onUpdate({
      maintenanceId: props.maintenanceId,
      roomId: props.roomId,
      roomVersionId: props.roomVersionId,
      fileId: data.id,
      name: data.fileName,
      description: data.description ?? '',
    });
  }

  function onFileRequest(data: MaintenanceAttachmentData) {
    props.onFileRequest({
      maintenanceId: props.maintenanceId,
      roomId: props.roomId,
      fileId: data.id,
    });
  }

  const fileInjectedAttachments: MaintenanceAttachmentData[] =
    props.attachments.map((attachment) =>
      loadedFiles?.has(attachment.id)
        ? { ...attachment, file: loadedFiles.get(attachment.id) }
        : attachment
    );

  // If we can't edit attachments then we display the tile only if there are already are any attachments
  if (
    props.manageAccessLevel === AttachmentMangeAccessLevel.None &&
    fileInjectedAttachments?.length === 0
  ) {
    return null;
  }

  return (
    <AcTile
      title={t('ATTACHMENTS.TITLE')}
      icon={IconName.eod}
      className="ac-spacing-top-sm"
      testSelector={props.testSelector}
    >
      <MaintenanceAttachments
        customerId={props.customerId}
        manageAccessLevel={props.manageAccessLevel}
        onAttachmentFileRequest={onFileRequest}
        onAttachmentAdd={onAdd}
        onAttachmentRemove={onRemove}
        onAttachmentUpdate={onUpdate}
        attachments={fileInjectedAttachments}
        testSelector={formatTestSelector(props.testSelector, 'attachments')}
      />
    </AcTile>
  );
};

export default memo(MaintenanceAttachmentsTile);
