import { Ref } from 'react';
import * as actions from '@hkm/components/App/domain/actions';
import { AppStore } from '@hkm/components/App/domain/interfaces';
import { ReservationNotes } from '@hkm/components/App/domain/interfaces/BookingNotesStore';
import { DictionariesStore } from '@hkm/components/App/domain/interfaces/DictionariesStore';
import {
  DictionariesConfig,
  DictionaryConfig,
} from '@hkm/shared/dictionaries/dictionariesConfig';
import { Dictionary } from '@hkm/shared/dictionaries/dictionary';
import { ForceRequest } from '@hkm/shared/interfaces/forceRequest';
import { ReducerMap } from 'redux-actions';

import {
  CustomerDetails,
  FeatureToggleItem,
  RoomQueueDetailsViewsDto,
} from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';
import {
  addNewOverlayMessageToArray,
  OverlayMessageState,
} from '@ac/mobile-components/dist/components/overlay-message';

const initialState: AppStore = {
  dictionaries: {},
  overlayMessages: {
    messages: [],
    bottomOffsetRefs: [],
  },
  notes: {
    reservationNotes: [],
  },
  roomQueue: {
    active: {
      date: '',
      version: 0,
      current: [],
      completed: [],
    },
  },
  featureToggles: [],
};

const reducerMap: ReducerMap<AppStore, unknown> = {
  [actions.clearDictionaries]: (state, action: Action<ForceRequest>) => ({
    ...state,
    dictionaries: action.payload.force
      ? {}
      : Object.entries(state.dictionaries)
          .filter(
            ([name]) =>
              (DictionariesConfig.get(name as Dictionary) as DictionaryConfig)
                .neverChanges
          )
          .reduce((prev, [name, dict]) => ({ ...prev, [name]: dict }), {}),
  }),
  [actions.fetchDictionaries.success]: (
    state,
    action: Action<DictionariesStore>
  ) => ({
    ...state,
    dictionaries: {
      ...state.dictionaries,
      ...action.payload,
    },
  }),
  [actions.fetchFeatureToggles.success]: (
    state,
    action: Action<FeatureToggleItem[]>
  ) => ({
    ...state,
    featureToggles: action.payload.filter(
      (featureToggle) => featureToggle.enabled
    ),
  }),

  [actions.setMessages]: (state, action: Action<OverlayMessageState[]>) => ({
    ...state,
    overlayMessages: {
      ...state.overlayMessages,
      messages: action.payload,
    },
  }),
  [actions.addMessage]: (state, action: Action<OverlayMessageState>) => ({
    ...state,
    overlayMessages: {
      ...state.overlayMessages,
      messages: addNewOverlayMessageToArray(
        action.payload,
        state.overlayMessages.messages
      ),
    },
  }),
  [actions.registerBottomMessageOffsetRef]: (
    state,
    action: Action<Ref<HTMLElement>>
  ) => ({
    ...state,
    overlayMessages: {
      ...state.overlayMessages,
      bottomOffsetRefs: [
        ...state.overlayMessages.bottomOffsetRefs,
        action.payload,
      ],
    },
  }),
  [actions.unregisterBottomMessageOffsetRef]: (
    state,
    action: Action<Ref<HTMLElement>>
  ) => ({
    ...state,
    overlayMessages: {
      ...state.overlayMessages,
      bottomOffsetRefs: state.overlayMessages.bottomOffsetRefs.filter(
        (ref: Ref<HTMLElement>) => ref !== action.payload
      ),
    },
  }),
  [actions.fetchCurrentCustomer.success]: (
    state,
    action: Action<CustomerDetails>
  ) => {
    return {
      ...state,
      customer: action.payload,
    };
  },
  [actions.fetchReservationNotes.trigger]: (state) => ({
    ...state,
    notes: {
      ...state.notes,
      isFetching: true,
    },
  }),
  [actions.fetchReservationNotes.success]: (
    state,
    action: Action<ReservationNotes[]>
  ) => ({
    ...state,
    notes: {
      reservationNotes: [...state.notes.reservationNotes, ...action.payload],
      isFetching: false,
    },
  }),
  [actions.fetchReservationNotes.failure]: (state) => ({
    ...state,
    notes: {
      ...state.notes,
      isFetching: false,
    },
  }),
  [actions.clearReservationNotes]: (state) => ({
    ...state,
    notes: {
      ...initialState.notes,
    },
  }),
  [actions.fetchRoomQueue.trigger]: (state) => ({
    ...state,
    roomQueue: {
      ...state.roomQueue,
      archived: { ...state.roomQueue.active },
      isFetching: true,
    },
  }),
  [actions.fetchRoomQueue.success]: (
    state,
    action: Action<RoomQueueDetailsViewsDto>
  ) => ({
    ...state,
    roomQueue: {
      ...state.roomQueue,
      active: action.payload,
      isFetching: false,
    },
  }),
  [actions.fetchRoomQueue.failure]: (state) => ({
    ...state,
    roomQueue: {
      ...state.roomQueue,
      isFetching: false,
    },
  }),
};

export default handleActions(reducerMap, initialState);
