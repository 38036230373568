import i18n from 'i18next';

import { doesContainCreditCardPattern } from '@ac/library-utils/dist/utils';

export const getLuhnAlghorithmValidationObject = (
  value?: string
): string | undefined => {
  if (value) {
    return doesContainCreditCardPattern(value)
      ? i18n.t('WARNINGS.LUHN_ALGORITHM_MATCHED')
      : undefined;
  }

  return undefined;
};
