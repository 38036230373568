import {
  FeatureRequirements,
  FeatureToggleRequirement,
  PermissionRequirement,
  PropertyContext,
  SettingRequirement,
} from '@ac/library-api';

export interface TasksFeatureActions {
  view: FeatureRequirements<void>;
}

export const getTasksFeature = (
  businessContext: PropertyContext | undefined
): TasksFeatureActions => {
  const permissions = businessContext?.permissions.processed;
  const settings = businessContext?.settings.processed;
  const featureToggles = businessContext?.featureToggles;

  return {
    view: new FeatureRequirements<void>([
      new PermissionRequirement({
        condition: permissions?.tasks.mobileTaskManagementApplication ?? false,
      }),
      new SettingRequirement({
        condition: settings?.tasks.useTaskManagementMobileApplication ?? false,
      }),
      new FeatureToggleRequirement({
        condition: featureToggles?.tasks.isMobileTaskManagementEnabled ?? false,
      }),
    ]),
  };
};
