import { useCallback } from 'react';
import { useNotificationsContext } from '@hkm/features/notifications/store/context';

import {
  ToastPresenterInContainer,
  WithoutSeverityToast,
} from '@ac/react-infrastructure/dist/features/notifications/toast';
import { ToastInterface, ToastSeverity, Translation } from '@ac/web-components';

interface Output {
  createToastObject(): ToastPresenterInContainer;
}

export function useContainerToast(): Output {
  const { addToast } = useNotificationsContext();

  const resolveToast = useCallback(
    (toast: Translation | WithoutSeverityToast): WithoutSeverityToast => {
      return typeof toast === 'object'
        ? (toast as WithoutSeverityToast)
        : { message: toast.toString() };
    },
    []
  );

  const createToastObject = useCallback((): ToastPresenterInContainer => {
    return {
      show: (toast: ToastInterface) => {
        addToast(toast);
      },
      showSuccess: (toast: Translation | WithoutSeverityToast) => {
        addToast({
          ...resolveToast(toast),
          severity: ToastSeverity.success,
        });
      },
      showError: (toast: Translation | WithoutSeverityToast) => {
        addToast({ ...resolveToast(toast), severity: ToastSeverity.error });
      },
      showWarning: (toast: Translation | WithoutSeverityToast) => {
        addToast({
          ...resolveToast(toast),
          severity: ToastSeverity.warning,
        });
      },
    };
  }, [addToast, resolveToast]);

  return {
    createToastObject,
  };
}
