import React, { ComponentType, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { selectPermissionsSet } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { PermissionConjunction } from '@hkm/shared/permissions/enum/PermissionConjunction';
import { hasPermission } from '@hkm/shared/permissions/helper/permissionHelper';
import { PermissionData } from '@hkm/shared/permissions/types/permissionData';

export const requirePermission =
  (
    requiredPermission: PermissionData,
    permissionConjunction: PermissionConjunction = PermissionConjunction.All
  ): (<P extends Record<string, unknown>>(
    WrappedComponent: ComponentType<P>
  ) => ComponentType<P>) =>
  // eslint-disable-next-line react/display-name
  <P extends Record<string, unknown>>(
    WrappedComponent: ComponentType<P>
  ): ComponentType<P> =>
  // eslint-disable-next-line react/display-name
  (props: P) => {
    const permissions = useSelector(selectPermissionsSet);

    const hasAccess = useMemo(
      () =>
        hasPermission(permissions, requiredPermission, permissionConjunction),
      [permissions]
    );

    return hasAccess ? (
      <WrappedComponent {...props} />
    ) : (
      <Navigate to={APP_ROUTES.ACCESS_DENIED} />
    );
  };
