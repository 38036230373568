import * as appActions from '@hkm/components/App/domain/actions';
import * as actions from '@hkm/components/Posting/Dashboard/domain/actions';
import { PostingDashboardState } from '@hkm/components/Posting/Dashboard/domain/interfaces';
import { PostingSort } from '@hkm/components/Posting/Dashboard/enum/postingSort';
import { PostingFiltersData } from '@hkm/components/Posting/Dashboard/filters/postingFiltersData';
import { createPaginationReducers } from '@hkm/store/pagination/paginationReducersFactory';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';
import { ReducerMap } from 'redux-actions';

import { HousekeepingRoomDetailsDto } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: PostingDashboardState = {
  paginationState: {
    sort: PostingSort.RoomNumber,
    filters: {},
  },
};

const reducerMap: ReducerMap<PostingDashboardState, unknown> = {
  ...createPaginationReducers<
    PostingDashboardState,
    HousekeepingRoomDetailsDto,
    HousekeepingRoom,
    PostingSort,
    PostingFiltersData
  >('paginationState', actions.pagination),
  [actions.replaceChangedRoom]: (state, action: Action<HousekeepingRoom>) => ({
    ...state,
    paginationState: {
      ...state.paginationState,
      page: !state.paginationState.page
        ? undefined
        : {
            ...state.paginationState.page,
            results: state.paginationState.page?.results.map((room) =>
              room.id === action.payload.id ? action.payload : room
            ),
          },
    },
  }),
  [appActions.clearAllViews]: () => ({ ...initialState }),
};

export default handleActions(reducerMap, initialState);
