import { getTransactionCodeByIdMap } from '@hkm/components/App/domain/selectors';
import { getStore } from '@hkm/store/configureStore';

import {
  buildFIQLFilter,
  FIQLOperators,
  PageResponseDto,
} from '@ac/library-api';
import { TransactionCodeViewsApi } from '@ac/library-api/dist/api/v0/configuration/handcraftedEntities';
import { TransactionCodeReadModel } from '@ac/library-api/dist/types';

interface GetTransactionCodeById {
  transactionCodeId: string;
}

export async function getTransactionCodeById(
  parameters: GetTransactionCodeById
) {
  const { transactionCodeId: id } = parameters;
  try {
    const transactionCodeMap = getTransactionCodeByIdMap(getStore());
    if (transactionCodeMap.has(id)) {
      return transactionCodeMap.get(id)?.code;
    }

    const getByIdFilter = buildFIQLFilter('id', FIQLOperators.equal, id);
    const { results } = (await TransactionCodeViewsApi.getList({
      queryParams: {
        filter: getByIdFilter,
      },
    })) as PageResponseDto<TransactionCodeReadModel>;

    return results[0].code ?? id;
  } catch (e) {
    return id;
  }
}
