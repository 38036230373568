import { Permission } from '@hkm/shared/permissions/enum/Permission';
import { PermissionConjunction } from '@hkm/shared/permissions/enum/PermissionConjunction';
import {
  PermissionArray,
  PermissionData,
} from '@hkm/shared/permissions/types/permissionData';

export function hasPermission(
  permissions: Set<string>,
  required: Permission
): boolean;
export function hasPermission(
  permissions: Set<string>,
  required: PermissionArray
): boolean[];
export function hasPermission(
  permissions: Set<string>,
  required: PermissionData,
  conjecture: PermissionConjunction
): boolean;
export function hasPermission(
  permissions: Set<string>,
  required: PermissionData,
  conjecture?: PermissionConjunction
): boolean | boolean[] {
  const hasConjecture = conjecture !== undefined;
  const hasArray = Array.isArray(required);

  if (hasConjecture) {
    if (conjecture === PermissionConjunction.NoCheck) {
      return true;
    }

    const requiredArray = (hasArray ? required : [required]) as Permission[];
    const method = (req: Permission) => permissions.has(req);

    return conjecture === PermissionConjunction.All
      ? requiredArray.every(method)
      : requiredArray.some(method);
  }

  return Array.isArray(required)
    ? required.map((req) => permissions.has(req))
    : permissions.has(required as Permission);
}

export function hasPermissionObject<T extends string>(
  permissions: Set<string>,
  required: Readonly<T[]>
): Record<(typeof required)[number], boolean> {
  const results = hasPermission(
    permissions,
    required as unknown as Permission[]
  );

  return required.reduce(
    (sum, currentValue, index) => ({ ...sum, [currentValue]: results[index] }),
    {}
  ) as Record<T, boolean>;
}
