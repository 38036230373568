/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TasksReservation } from '@hkm/components/TaskManagement/models/reservations';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import Accordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Accordion/Accordion';
import AreaGrid from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/AreaGrid/AreaGrid';
import { Preferences } from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Preferences/Preferences';
import ReservationAccompanyingGuest from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/ReservationAccompanyingGuest/ReservationAccompanyingGuest';
import ReservationSharedReservationGuest from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/ReservationSharedReservationGuest/ReservationSharedReservationGuest';
import SpecialNeeds from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/SpecialNeeds/SpecialNeeds';
import VipAndMember from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/VipAndMember/VipAndMember';
import BadgeHelpers from '@hkm/components/TaskManagement/utils/badgeHelpers';

import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import {
  FlexDirection,
  JustifyContent,
  MobileColor,
} from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

const Reservation = () => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);
  const { attributes } = useSelector(getRelationsState);
  const reservation = linkedObject?.object as TasksReservation;
  const hasVipOrMembership =
    reservation.guestDetails?.vipStatusCode || !!reservation.memberships.length;

  const preferences = useMemo(() => {
    const attributesCodes = (reservation.preferences || []).map(
      ({ code }) => code ?? ''
    );

    return (
      <Preferences attributesCodes={attributesCodes} attributes={attributes} />
    );
  }, [reservation.preferences, attributes]);

  const sharedReservations = useMemo(
    () =>
      reservation.sharedReservations.length
        ? reservation.sharedReservations
            .filter(({ id }) => reservation.id !== id)
            .splice(0, 5)
        : [],
    [reservation.id, reservation.sharedReservations]
  );

  return (
    <ac-flex direction={FlexDirection.column}>
      {reservation.reservationGuestName && (
        <AcFormElement
          className="ac-spacing-top-sm ac-spacing-bottom-sm"
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.GUEST')}
          testSelector="task-reservation-guest-name"
        >
          {reservation.reservationGuestName}
          {hasVipOrMembership && (
            <AcIndicator
              className="ac-spacing-left-sm"
              icon={IconName.vip}
              backgroundColor={MobileColor.Warning}
              testSelector="task-reservation-vip-indicator"
            />
          )}
          {reservation.hasDisability && (
            <AcIndicator
              className="ac-spacing-left-sm"
              icon={IconName.disability}
              backgroundColor={MobileColor.Alert}
              testSelector="task-reservation-disability-indicator"
            />
          )}
        </AcFormElement>
      )}
      {hasVipOrMembership && (
        <AcFormElement
          className="ac-spacing-bottom-sm"
          label={t(
            'TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.VIP_AND_MEMBER'
          )}
        >
          <VipAndMember
            vipStatusCode={reservation.guestDetails?.vipStatusCode}
            memberships={reservation.memberships}
          />
        </AcFormElement>
      )}
      {!!reservation.guestDetails?.disabilityCodes.length && (
        <AcFormElement
          className="ac-spacing-bottom-sm"
          label={t(
            'TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.SPECIAL_NEEDS'
          )}
        >
          <SpecialNeeds
            disabilityCodes={reservation.guestDetails?.disabilityCodes}
          />
        </AcFormElement>
      )}
      {!!sharedReservations.length && (
        <AcFormElement
          className="ac-spacing-bottom-sm"
          label={t(
            'TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.SHARES_RESERVATION'
          )}
        >
          {sharedReservations.map((sharedReservation, index) => (
            <ReservationSharedReservationGuest
              key={reservation.id}
              reservation={sharedReservation}
              index={index}
            />
          ))}
        </AcFormElement>
      )}
      {!!reservation.accompanyingGuests.length && (
        <AcFormElement
          className="ac-spacing-bottom-sm"
          label={t(
            'TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.ACCOMPANYING_GUEST'
          )}
        >
          {reservation.accompanyingGuests.splice(0, 5).map((guest, index) => (
            <ReservationAccompanyingGuest
              key={guest.id}
              guest={guest}
              index={index}
            />
          ))}
        </AcFormElement>
      )}
      <Accordion
        header={
          reservation.statusCode && (
            <AcFormElement
              className="ac-spacing-bottom-sm"
              label={t(
                'TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.RESERVATION_STATUS'
              )}
            >
              <AcBadge
                badgeText={reservation.statusCode.code}
                backgroundColor={BadgeHelpers.getReservationStatusColor(
                  reservation.statusCode?.code ?? MobileColor.Black
                )}
                testSelector="task-reservation-status"
              />
            </AcFormElement>
          )
        }
        id={reservation.id}
      >
        <AcFormElement
          className="ac-spacing-bottom-sm"
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.PREFERENCES')}
        >
          {reservation.preferences.length ? preferences : '-'}
        </AcFormElement>
        <AreaGrid>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.ROOM_NUMBER'
            )}
            testSelector="task-reservation-room-number"
          >
            {reservation.roomNumber?.code ?? '-'}
          </AcFormElement>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.ROOM_TYPE')}
            testSelector="task-reservation-room-type"
          >
            {reservation.roomTypeCode?.code ?? '-'}
          </AcFormElement>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.ARRIVAL')}
            testSelector="task-reservation-arrival-date"
          >
            {reservation.arrivalDate ?? '-'}
          </AcFormElement>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.NIGHTS')}
            testSelector="task-reservation-nights"
          >
            {reservation.nights ?? '-'}
          </AcFormElement>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.CONFIRMATION_NUMBER'
            )}
            testSelector="task-reservation-confirmation-number"
          >
            {reservation.confirmationNumber ?? '-'}
          </AcFormElement>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.RATE_PLAN')}
            testSelector="task-reservation-rate-plan"
          >
            {reservation.ratePlan?.code ?? '-'}
          </AcFormElement>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.GTD_TYPE')}
            testSelector="task-reservation-gtd"
          >
            {reservation.guaranteeTypeCode?.code ?? '-'}
          </AcFormElement>
        </AreaGrid>
      </Accordion>
      <AcFormElement
        label={t(
          'TASK_MANAGEMENT.TASK_PREVIEW.AREA.RESERVATION.FOLLOW_CHANGES'
        )}
        flatAndJustify={JustifyContent.spaceBetween}
      >
        <ac-switch
          disabled={true}
          checked={linkedObject?.followObjectChanges}
          data-test-selector="task-reservation-follow-changes"
        />
      </AcFormElement>
    </ac-flex>
  );
};

export default Reservation;
