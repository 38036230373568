import { getCurrentRegionCode } from '@hkm/features/region/getCurrentRegionCode';

export const isPropertyLocatedInAnotherRegion = (property?: {
  regionCode?: string;
}): boolean => {
  if (!property?.regionCode) {
    return false;
  }

  const propertyRegionCode = property.regionCode?.toLowerCase();
  const currentRegionCode = getCurrentRegionCode();

  return currentRegionCode !== propertyRegionCode;
};
