import * as appActions from '@hkm/components/App/domain/actions';
import * as notificationActions from '@hkm/components/App/notificationConsumer/domain/actions';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { RoomConditionsChangeActions } from '@hkm/shared/domain/roomConditionsChange/roomConditionsChangeActions';
import { RoomConditionsChangeRequest } from '@hkm/shared/domain/roomConditionsChange/roomConditionsChangeRequest';
import { SourceApiType } from '@hkm/shared/domain/sourceApiType';
import { getCustomConfig } from '@hkm/utils/getCustomConfig';
import { put, takeLatest } from '@redux-saga/core/effects';
import i18n from 'i18next';

import {
  HousekeepingRoomsApi,
  MobileAttendantApi,
} from '@ac/library-api/dist/api/v0/housekeeping';
import { Action } from '@ac/library-utils/dist/declarations';

export function createAttendantRoomConditionsChangeSaga(
  actions: RoomConditionsChangeActions
) {
  return createRoomConditionsChangeSaga(
    actions,
    SourceApiType.MobileAttendantApi
  );
}

export function createHousekeepingRoomConditionsChangeSaga(
  actions: RoomConditionsChangeActions
) {
  return createRoomConditionsChangeSaga(
    actions,
    SourceApiType.HousekeepingRoomsApi
  );
}

function createRoomConditionsChangeSaga(
  actions: RoomConditionsChangeActions,
  sourceApiType: SourceApiType
) {
  function housekeepingChangeConditions(
    action: Action<RoomConditionsChangeRequest>
  ) {
    return HousekeepingRoomsApi.postChangeRoomConditions({
      pathParams: { roomId: action.payload.roomId },
      data: action.payload.roomConditions,
      customConfig: getCustomConfig({
        version: action.payload.housekeepingRoomVersion,
      }),
    });
  }

  function attendantChangeConditions(
    action: Action<RoomConditionsChangeRequest>
  ) {
    return MobileAttendantApi.postChangeRoomConditions({
      pathParams: { roomId: action.payload.roomId },
      data: action.payload.roomConditions,
      customConfig: getCustomConfig({
        version: action.payload.housekeepingRoomVersion,
      }),
    });
  }

  function* changeConditions(action: Action<RoomConditionsChangeRequest>) {
    try {
      yield sourceApiType === SourceApiType.MobileAttendantApi
        ? attendantChangeConditions(action)
        : housekeepingChangeConditions(action);

      yield put(actions.changeRoomConditions.success(action.payload.roomId));
      yield put(
        notificationActions.expectNotification(
          SignalREventType.HousekeepingRoomChanged
        )
      );
      yield put(
        appActions.displaySuccess(i18n.t('ROOM_CONDITIONS_CHANGE.SUCCESS'))
      );
    } catch (e) {
      yield put(appActions.displayExtractedError(e));
      yield put(actions.changeRoomConditions.failure(e));
    }
  }

  return function* () {
    yield takeLatest(actions.changeRoomConditions.trigger, changeConditions);
  };
}
