import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUsersSelectorContext } from '@hkm/components/shared/features/UsersSelector/store/context';
import { useUsersSelectorManager } from '@hkm/components/shared/features/UsersSelector/useUsersSelectorManager';
import SearchField from '@hkm/components/shared/Fields/SearchField/SearchField';
import { MappedConfigurationUser } from '@hkm/components/TaskManagement/Dashboard/mappers';

interface ComponentProps {
  label?: string;
  selectedUserIds?: string[];
  disabled?: boolean;
  onSelectUsers: (selectedUsers?: MappedConfigurationUser[]) => void;
}

// NOTE: Selector is mostly ready to support MultiSearchField when MultiSearchField will be implemented
export const UsersSelector = (props: ComponentProps) => {
  const [selectedUser, setSelectedUser] = useState<
    MappedConfigurationUser | undefined
  >();
  const { label, selectedUserIds, disabled, onSelectUsers } = props;
  const { state } = useUsersSelectorContext();
  const { findUsers, loadAndGetInitialUsers } = useUsersSelectorManager();
  const { usersList } = state;

  const usersListToDisplay = useMemo(() => {
    return usersList.map(({ id, userFullName }) => {
      return {
        id,
        text: userFullName,
      };
    });
  }, [usersList]);

  const selectedUserToDisplay = useMemo(() => {
    if (!selectedUser) {
      return;
    }

    return {
      id: selectedUser.id,
      text: selectedUser.userFullName,
    };
  }, [selectedUser]);

  useEffect(() => {
    if (!selectedUserIds?.length) {
      setSelectedUser(undefined);

      return;
    }
    const setInitialUsers = async () => {
      const users = await loadAndGetInitialUsers(selectedUserIds);
      setSelectedUser(users[0]);
    };
    setInitialUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserIds]);

  const userChange = useCallback(
    (userId?: string) => {
      if (!userId) {
        onSelectUsers(undefined);
        setSelectedUser(undefined);

        return;
      }
      const selected = usersList.find(({ id }) => userId === id);
      setSelectedUser(selected);
      onSelectUsers(selected ? [selected] : undefined);
    },
    [onSelectUsers, usersList]
  );

  const clearSelectedUsers = useCallback(() => {
    setSelectedUser(undefined);
    onSelectUsers(undefined);
  }, [onSelectUsers]);

  return (
    <SearchField
      optionsArray={usersListToDisplay}
      label={label || ''}
      disabled={disabled}
      testSelector="users-selector"
      value={selectedUserToDisplay}
      onSearch={findUsers}
      onOptionSelect={userChange}
      onClearSelection={clearSelectedUsers}
    />
  );
};
