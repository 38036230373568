import dayjs from 'dayjs';

export const isValidDate = (date: string) => {
  return dayjs(date).isValid();
};

export const isISODate = (date: string) => {
  const regexp =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/i;

  return regexp.test(date);
};
