import { BaseApi } from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';

export function initUnauthorizationMiddleware() {
  BaseApi.addRequestMiddlewares([
    (params) => {
      const isUnauthorized = params.response.status === 401;
      if (isUnauthorized) {
        void LoginService.logout();
      }
    },
  ]);
}
