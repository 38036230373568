export enum Dictionary {
  Room = 'room',
  RoomType = 'roomType',
  RoomStatus = 'roomStatus',
  RoomCategory = 'roomCategory',
  RoomCondition = 'roomCondition',
  RoomClass = 'roomClass',
  Attribute = 'attribute',
  Floor = 'floor',
  HousekeepingSection = 'housekeepingSection',
  State = 'state',
  RoomLocation = 'roomLocation',
  HousekeepingStatus = 'housekeepingStatus',
  FrontDeskStatus = 'frontDeskStatus',
  OutOfOrderReason = 'outOfOrderReason',
  HousekeepingAttendant = 'housekeepingAttendant',
  MaintenanceStatus = 'maintenanceStatus',
  Discrepancy = 'discrepancy',
  CleanType = 'cleanType',
  NoteTypes = 'noteTypes',
  SupportedUiLanguages = 'supportedUiLanguages',
}
