import { createMaintenanceUpdateActions } from '@hkm/shared/domain/maintenanceUpdate/maintenanceUpdateActionsFactory';

import { createAction } from '@ac/library-utils/dist/redux-utils';

export const notifyMaintenanceUpdate = createAction<undefined>(
  'maintenance/updateMaintenance/notifyMaintenanceUpdate'
);

export const updateMaintenanceActionsSet = createMaintenanceUpdateActions(
  'maintenance/updateMaintenance',
  {
    notifyMaintenanceUpdate,
  }
);
export const updateAttendantMaintenanceActionsSet =
  createMaintenanceUpdateActions('attendant/maintenance/updateMaintenance', {
    notifyMaintenanceUpdate,
  });
