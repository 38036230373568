import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import PostingReservationDetails from '@hkm/components/Posting/shared/components/PostingReservationDetails';
import DoNotMoveInfo from '@hkm/components/shared/DoNotMove/DoNotMoveInfo';
import IncognitoBadge from '@hkm/components/shared/IncognitoBadge/IncognitoBadge';
import ConnectGuestAvatarTemplate from '@hkm/components/shared/Templates/Guest/GuestAvatar/ConnectGuestAvatarTemplate';
import GuestNameTemplate from '@hkm/components/shared/Templates/Guest/GuestNameTemplate';
import {
  getVipBadge,
  hasReservationDisabilities,
} from '@hkm/components/shared/Templates/Reservation/utils/reservationUtils';
import { extractName } from '@hkm/shared/helpers/extractName';
import { doesReservationHaveDoNotMoveFlag } from '@hkm/shared/reservations/hasDoNotMoveFlag';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { UnifiedRoomDetails } from '@hkm/types/room/model/UnifiedRoomDetails';
import classNames from 'classnames';

import { TitleOrders } from '@ac/library-api';
import {
  AcAccordion,
  AcAccordionContent,
  AcAccordionHeader,
  AcAccordionItem,
  AcAccordionsElementProps,
} from '@ac/mobile-components/dist/components/accordion';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcTile } from '@ac/mobile-components/dist/components/tile';
import { AlignItems } from '@ac/mobile-components/dist/enums';
import {
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { IconName } from '@ac/web-components';

import './PostingViewReservationTile.css';

export interface PostingReservationTileProps extends Styleable, Testable {
  showGuestNames?: boolean;
  reservation: UnifiedReservationDetails;
  unifiedRoomDetails: UnifiedRoomDetails;
}

const PostingViewReservationTile: FC<PostingReservationTileProps> = ({
  showGuestNames = true,
  ...props
}: PostingReservationTileProps) => {
  const { t } = useTranslation();
  const guest = props.reservation.guest;
  const effectiveValues = useSelector(selectEffectiveValues);
  const titleOrder = effectiveValues?.titleOrder
    ? effectiveValues.titleOrder
    : TitleOrders.LastNameFirstNameTitle;

  const classes = classNames('posting-view-guest', props.className);

  const vipBadge = getVipBadge(guest?.vipCode);
  const hasDisabilities: boolean = hasReservationDisabilities(guest);
  const hasDoNotMoveFlag = doesReservationHaveDoNotMoveFlag(props.reservation);
  const guestPersonalData = showGuestNames ? guest?.personalData : undefined;

  const sharedGuestName =
    guest?.personalData && showGuestNames
      ? extractName(guest.personalData.name, titleOrder)
      : t('ROOM_DETAILS.SHARED_GUEST_CUSTOM_NAME');

  return (
    <AcTile
      className={classes}
      style={props.style}
      icon={IconName.postingCharge}
      title={t('POSTING.POSTING_DETAILS.POST_TO', {
        roomNumber: props.unifiedRoomDetails.roomNumber,
      })}
    >
      <AcAccordion className="accordion-posting-view-guest">
        <AcAccordionItem
          id={props.reservation.id}
          render={(accordionProps: AcAccordionsElementProps) => {
            return (
              <>
                <ac-flex class="ac-gap-sm" alignItems={AlignItems.center}>
                  {showGuestNames &&
                    effectiveValues?.displayGuestAvatar &&
                    guest && <ConnectGuestAvatarTemplate guest={guest} />}
                  <ac-box grow={true}>
                    {hasDoNotMoveFlag && (
                      <DoNotMoveInfo
                        small={true}
                        className="ac-spacing-bottom-md"
                      />
                    )}
                    <IncognitoBadge
                      className="ac-spacing-bottom-md"
                      details={guest?.personalData?.incognitoDetails}
                    />
                    <AcAccordionHeader {...accordionProps}>
                      <AcFormElement label={t('ROOM_DETAILS.GUEST')}>
                        <GuestNameTemplate
                          vipBadgeColor={vipBadge?.color}
                          guestPersonalData={guestPersonalData}
                          hasDisabilities={hasDisabilities}
                          customGuestName={sharedGuestName}
                        />
                      </AcFormElement>
                    </AcAccordionHeader>
                  </ac-box>
                </ac-flex>

                <AcAccordionContent {...accordionProps}>
                  <PostingReservationDetails reservation={props.reservation} />
                </AcAccordionContent>
              </>
            );
          }}
        />
      </AcAccordion>
    </AcTile>
  );
};

export default memo(PostingViewReservationTile);
