import { HousekeepingSort } from '@hkm/components/Housekeeping/shared/enum/housekeepingSort';
import { HousekeepingFiltersData } from '@hkm/components/Housekeeping/shared/models/housekeepingFiltersData';
import { SelectedRoom } from '@hkm/components/Housekeeping/shared/models/selectedRoom';
import { DashboardMode } from '@hkm/shared/enum/dashboardMode';
import { createPaginationActions } from '@hkm/store/pagination/paginationActionsFactory';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';

import { HousekeepingRoomDetailsDto } from '@ac/library-api';
import { createAction } from '@ac/library-utils/dist/redux-utils';

export const pagination = createPaginationActions<
  HousekeepingRoomDetailsDto,
  HousekeepingRoom,
  HousekeepingSort,
  HousekeepingFiltersData
>('housekeeping/dashboard');
export const patchChangedRoom = createAction<string>(
  'housekeeping/dashboard/patchChangedRoom'
);
export const replaceChangedRoom = createAction<HousekeepingRoom>(
  'housekeeping/dashboard/replaceChangedRoom'
);
export const setHousekeepingMode = createAction<DashboardMode>(
  'housekeeping/dashboard/setHousekeepingSelectedMode'
);
export const toggleRoomsSelection = createAction<SelectedRoom>(
  'housekeeping/dashboard/toggleRoomsSelection'
);
export const setRoomsSelections = createAction<SelectedRoom[]>(
  'housekeeping/dashboard/setRoomsSelections'
);
export const clearHousekeepingSelection = createAction<undefined>(
  'housekeeping/dashboard/clearHousekeepingSelection'
);
