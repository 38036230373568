import { HousekeepingStatusChangeActions } from '@hkm/shared/domain/housekeepingStatusChange/housekeepingStatusChangeActions';
import { HousekeepingStatusChangeRequest } from '@hkm/shared/domain/housekeepingStatusChange/housekeepingStatusChangeRequest';

import { updateAction } from '@ac/library-utils/dist/redux-utils';

export function createHousekeepingStatusChangeActions(
  prefix: string
): HousekeepingStatusChangeActions {
  return {
    changeHousekeepingStatus: updateAction<
      HousekeepingStatusChangeRequest,
      string
    >(`${prefix}/housekeepingStatus/change`),
  };
}
