import React, { FC, memo, useCallback, useMemo } from 'react';
import { TaskQuickFilter } from '@hkm/components/TaskManagement/domain/enums/TaskQuickFilter';
import { TaskQuickFilterItem } from '@hkm/components/TaskManagement/domain/interfaces/taskQuickFilterItem';

import {
  AcButton,
  AcButtonPattern,
  AcButtonShape,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { IconName } from '@ac/web-components';

import '@hkm/components/shared//QuickFilter/QuickFilter.css';

interface DashboardQuickFilterItemProps {
  quickFilter: TaskQuickFilterItem;
  applyQuickFilter: (quickFilter: TaskQuickFilter) => void;
  appliedQuickFilter?: TaskQuickFilter;
}

const DashboardQuickFilterItem: FC<DashboardQuickFilterItemProps> = ({
  quickFilter,
  applyQuickFilter,
  appliedQuickFilter,
}: DashboardQuickFilterItemProps) => {
  const isActive: boolean = useMemo(
    () => quickFilter.value === appliedQuickFilter,
    [appliedQuickFilter, quickFilter.value]
  );

  const toggleQuickFilter = useCallback(
    () => applyQuickFilter(quickFilter.value),
    [applyQuickFilter, quickFilter.value]
  );

  return (
    <AcButton
      className="quick-filter-button ac-spacing-left-sm"
      onClick={toggleQuickFilter}
      pattern={isActive ? AcButtonPattern.LightSelected : AcButtonPattern.Light}
      testSelector={`task-quick-filter-${quickFilter.value}`}
      shape={AcButtonShape.Rectangle}
    >
      <AcButtonContent
        icon={isActive ? IconName.check : undefined}
        text={quickFilter.label}
      />
    </AcButton>
  );
};

export default memo(DashboardQuickFilterItem);
