export const pickAllBySelector = <T, U>(
  items: T[],
  selector: (item: T) => U
): U[] => {
  return items.reduce<U[]>((acc, item) => {
    return acc
      .concat(selector(item))
      .filter((value) => value !== null && value !== undefined);
  }, []);
};
