import { UnitAssignmentListItem } from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import { ShijiEnterprisePlatformFeatureConfig } from '@ac/react-infrastructure/dist/features/featureSchema/containers/configurable/config';

export interface AuthenticationState {
  token: string | undefined;
  tenantId: string | undefined;
  userId: string | undefined;
  clientId: string | undefined;
  userType: string | undefined;
  expiresAt: number | undefined;
  sessionId: string | undefined;
}

export interface AppState {
  initialized: boolean;
  properties: UnitAssignmentListItem[];
  authentication: AuthenticationState | undefined;
  platformConfiguration: ShijiEnterprisePlatformFeatureConfig | undefined;
}

export function getDefaultAppState(): AppState {
  return {
    initialized: false,
    properties: [],
    authentication: LoginService.authData(),
    platformConfiguration: undefined,
  };
}
