import { RoomStatusChangeActions } from '@hkm/shared/domain/roomStatusChange/roomStatusChangeActions';
import { RoomStatusChangeRequest } from '@hkm/shared/domain/roomStatusChange/roomStatusChangeRequest';

import { updateAction } from '@ac/library-utils/dist/redux-utils';

export function createRoomStatusChangeActions(
  prefix: string
): RoomStatusChangeActions {
  return {
    changeRoomStatus: updateAction<RoomStatusChangeRequest, string>(
      `${prefix}/roomStatus/change`
    ),
  };
}
