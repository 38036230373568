import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TasksArAccount } from '@hkm/components/TaskManagement/models/cashiering';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import Accordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Accordion/Accordion';
import AreaGrid from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/AreaGrid/AreaGrid';

import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { FlexDirection } from '@ac/mobile-components/dist/enums';

const ArAccount = () => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);
  const account = linkedObject?.object as TasksArAccount;

  return (
    <ac-flex direction={FlexDirection.column}>
      <AcFormElement
        className="ac-spacing-top-sm ac-spacing-bottom-sm"
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.AR_ACCOUNT.NAME')}
        testSelector="task-ar-account-name"
      >
        {account.accountName ? account.accountName : -''}
      </AcFormElement>
      <AcFormElement
        className="ac-spacing-bottom-sm"
        label={t(
          'TASK_MANAGEMENT.TASK_PREVIEW.AREA.AR_ACCOUNT.AR_ACCOUNT_TYPE'
        )}
        testSelector="task-ar-account-type"
      >
        {account.arAccountType?.description ?? '-'}
      </AcFormElement>
      <Accordion
        header={
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.AR_ACCOUNT.ACCOUNT_NUMBER'
            )}
            testSelector="task-ar-account-number"
          >
            {account.accountNumber ?? '-'}
          </AcFormElement>
        }
        id={account.id}
      >
        <AreaGrid>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.AR_ACCOUNT.OPEN_BALANCE',
              { currency: account.balance?.currencyCode }
            )}
            testSelector="task-ar-account-balance"
          >
            {account.balance?.amount ?? '-'}
          </AcFormElement>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.AR_ACCOUNT.CREDIT_LIMIT',
              { currency: account.creditLimit?.currencyCode }
            )}
            testSelector="task-ar-account-credit-limit"
          >
            {account.creditLimit?.amount ?? '-'}
          </AcFormElement>
          <AcFormElement
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.AR_ACCOUNT.INVOICES')}
            testSelector="task-ar-account-unsettled-invoices"
          >
            {account.unsettledInvoices > 0 ? account.unsettledInvoices : '-'}
          </AcFormElement>
          <AcFormElement
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.AR_ACCOUNT.CONTACT_PERSON'
            )}
            testSelector="task-ar-account-contact"
          >
            {account.contact?.fullName ?? '-'}
          </AcFormElement>
        </AreaGrid>
      </Accordion>
    </ac-flex>
  );
};

export default ArAccount;
