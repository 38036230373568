export enum FilterObjects {
  taskStatus = 'taskStatus',
  taskPriority = 'taskPriority',
  department = 'assignedDepartment',
  date = 'dueDateTime',
  area = 'objectInfo.type',
  assignees = 'assignedUser',
}

export enum BorderTimeValues {
  startTime = '00:00',
  endTime = '23:59',
}

export enum DashboardSort {
  DUE_DATE_ASC = 'dueDateTime',
  PRIORITY_DESC = '-taskPriority.displaySequence,-taskPriority.code',
  STATUS_ASC = 'taskStatus.displaySequence,taskStatus.code',
}

export enum DateTimeFilters {
  DateFrom = 'dateFrom',
  TimeFrom = 'timeFrom',
  DateTo = 'dateTo',
  TimeTo = 'timeTo',
}

export enum SearchValues {
  Search = 'searchValue',
}

export enum MultiselectFilters {
  Status = 'taskStatuses',
  Priority = 'taskPriorities',
  Department = 'departments',
  Area = 'areas',
  Assignee = 'assignees',
}

export enum MultiselectValueFields {
  ObjectType = 'objectType',
  Id = 'id',
}

export enum MultiselectNameFields {
  FullName = 'userFullName',
  DisplayName = 'displayName',
}
