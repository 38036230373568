import {
  MappedConfigurationUser,
  mapUsersToFullName,
} from '@hkm/components/TaskManagement/Dashboard/mappers';

import {
  ConfigurationUser,
  FilterQueryParams,
  getDataForAllPages,
  PageQueryParams,
  PageResponse,
  PageResponseDto,
  RawConfigurationUser,
  RawTaskStatus,
  TaskStatus,
} from '@ac/library-api';
import { TaskStatusApi } from '@ac/library-api/dist/api/v0/configuration/genericEntities';
import { ConfigurationUsersApi } from '@ac/library-api/dist/api/v0/configuration/users';

export const getAllTaskStatuses = async (
  filter?: FilterQueryParams
): Promise<TaskStatus[]> => {
  return (
    await getDataForAllPages(
      ({ pageNumber, pageSize }: PageQueryParams) =>
        TaskStatusApi.getList({
          queryParams: { pageNumber, pageSize, ...filter },
        }) as Promise<PageResponse<RawTaskStatus, TaskStatus>>
    )
  ).results;
};

export const getConfigurationUsers = async (
  filter?: FilterQueryParams
): Promise<MappedConfigurationUser[]> => {
  const users = (await ConfigurationUsersApi.getUsers({
    pathParams: undefined,
    queryParams: filter,
  })) as PageResponseDto<RawConfigurationUser>;

  return mapUsersToFullName(users.results);
};

export const getConfigurationUserById = async (
  id: string
): Promise<MappedConfigurationUser> => {
  const user = (await ConfigurationUsersApi.getById({
    pathParams: { id },
  })) as ConfigurationUser;

  return {
    ...user,
    userFullName: `${user.personalData?.firstName} ${user.personalData?.lastName}`,
  };
};
