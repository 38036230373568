import i18n from 'i18next';

import { ObjectInfo, ProfileRole, TaskObjectType } from '@ac/library-api';

export enum AdditionalInfoOptions {
  accountNumber = 'accountNumber',
  arAccountNumber = 'arAccountNumber',
  companyName = 'companyName',
  confirmationNumber = 'confirmationNumber',
  groupCode = 'groupCode',
  groupName = 'groupName',
  profileName = 'profileName',
  roomNumber = 'roomNumber',
  travelAgentName = 'travelAgentName',
}

interface SummariesOptions {
  id: string;
  prefix: string;
}

function getSummariesOptions(objectInfo?: ObjectInfo): SummariesOptions[] {
  const linkedProfile = [
    {
      id: AdditionalInfoOptions.profileName,
      prefix: i18n.t('TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.PROFILE'),
    },
    {
      id: AdditionalInfoOptions.companyName,
      prefix: i18n.t('TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.COMPANY'),
    },
    {
      id: AdditionalInfoOptions.travelAgentName,
      prefix: i18n.t('TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.TRAVEL_AGENT'),
    },
  ];

  switch (objectInfo?.type) {
    case TaskObjectType.ARAccount:
      return [
        {
          id: AdditionalInfoOptions.arAccountNumber,
          prefix: i18n.t(
            'TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.AR_ACCOUNT_NUMBER'
          ),
        },
        ...linkedProfile,
      ];
    case TaskObjectType.Profile: {
      if (objectInfo.profile?.role === ProfileRole.Individual) {
        return [
          {
            id: AdditionalInfoOptions.profileName,
            prefix: i18n.t('TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.PROFILE'),
          },
        ];
      }

      if (objectInfo.profile?.role === ProfileRole.Company) {
        return [
          {
            id: AdditionalInfoOptions.companyName,
            prefix: i18n.t('TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.COMPANY'),
          },
        ];
      }

      return [
        {
          id: AdditionalInfoOptions.travelAgentName,
          prefix: i18n.t(
            'TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.TRAVEL_AGENT'
          ),
        },
      ];
    }
    case TaskObjectType.Reservation:
      return [
        {
          id: AdditionalInfoOptions.roomNumber,
          prefix: i18n.t(
            'TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.ROOM_NUMBER'
          ),
        },
        {
          id: AdditionalInfoOptions.confirmationNumber,
          prefix: i18n.t(
            'TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.CONFIRMATION_NUMBER'
          ),
        },
        ...linkedProfile,
      ];
    case TaskObjectType.Group:
      return [
        {
          id: AdditionalInfoOptions.groupCode,
          prefix: i18n.t(
            'TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.GROUP_CODE'
          ),
        },
        {
          id: AdditionalInfoOptions.groupName,
          prefix: i18n.t(
            'TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.GROUP_NAME'
          ),
        },
        ...linkedProfile,
      ];
    case TaskObjectType.Account:
      return [
        {
          id: AdditionalInfoOptions.accountNumber,
          prefix: i18n.t(
            'TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.ACCOUNT_NUMBER'
          ),
        },
        ...linkedProfile,
      ];
    case TaskObjectType.Room:
      return [
        {
          id: AdditionalInfoOptions.roomNumber,
          prefix: i18n.t(
            'TASK_MANAGEMENT.DASHBOARD.ADDITIONAL_INFO.ROOM_NUMBER'
          ),
        },
        ...linkedProfile,
      ];

    default:
      return [];
  }
}

export function getDetails(
  query: string = '',
  objectInfo?: ObjectInfo
): string | undefined {
  const { additionalInfo } = objectInfo || { additionalInfo: {} };
  const summariesOptions = getSummariesOptions(objectInfo);
  const summary = summariesOptions.find(({ id }) => additionalInfo?.[id]);
  const details = summariesOptions.find(
    ({ id }) =>
      additionalInfo?.[id] &&
      additionalInfo?.[id].toLowerCase().includes(query.toLowerCase())
  );

  const summaryPrefix = summary?.prefix || '';
  const detailsPrefix = details?.prefix || '';
  const summaryValue = summary?.id ? additionalInfo?.[summary.id] : undefined;
  const additionalInfoValue = details?.id
    ? additionalInfo?.[details.id]
    : undefined;

  if (!additionalInfo || !additionalInfoValue || !query) {
    const dataToReturn = summaryValue
      ? `${summaryPrefix}${summaryValue}`
      : undefined;

    return dataToReturn;
  }

  return `${detailsPrefix}${additionalInfoValue}`;
}
