import { PropertyDetails } from '@ac/library-api/dist/types/entities/configuration/properties';
import { usePropertyBusinessContext } from '@ac/react-infrastructure';

export interface ActiveProperty {
  property: PropertyDetails | undefined;
  propertyId: string | undefined;
  businessDate: string | undefined;
}

export function useActiveProperty(): ActiveProperty {
  const { state } = usePropertyBusinessContext();

  return {
    property: state.businessContext?.details,
    propertyId: state.businessContext?.details?.id,
    businessDate: state.businessContext?.businessDate,
  };
}
