import * as actions from '@hkm/components/Posting/PostingView/domain/actions';
import { createTransactionCodesApiFilter } from '@hkm/components/Posting/PostingView/filters/TransactionCodesApiFilterFactory';
import { transactionCodesDefaultFilterValues } from '@hkm/components/Posting/PostingView/filters/TransactionCodesDefaultFilterValues';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import { createReservationChargesSaga } from '@hkm/shared/domain/reservationCharges/reservationChargesSagaFactory';
import { fork, put, takeLatest } from 'redux-saga/effects';

import { PageResponse } from '@ac/library-api';
import { TransactionCodeViewsApi } from '@ac/library-api/dist/api/v0/configuration/handcraftedEntities';
import {
  HousekeepingReservationApi,
  MobileAttendantApi,
} from '@ac/library-api/dist/api/v0/housekeeping';
import {
  RawTransactionCodeReadModel,
  TransactionCodeReadModel,
} from '@ac/library-api/dist/types';

function* fetchTransactionCodes() {
  try {
    const transactionCodesPage: PageResponse<
      RawTransactionCodeReadModel,
      TransactionCodeReadModel
    > = yield TransactionCodeViewsApi.getList({
      queryParams: {
        pageNumber: 1,
        pageSize: APP_CONSTANTS.MAX_FETCH_SIZE,
        filter: createTransactionCodesApiFilter(
          transactionCodesDefaultFilterValues
        ),
      },
    }) as Promise<
      PageResponse<RawTransactionCodeReadModel, TransactionCodeReadModel>
    >;

    yield put(
      actions.fetchTransactionCodes.success(transactionCodesPage.results)
    );
  } catch (e) {
    yield put(actions.fetchTransactionCodes.failure(e));
  }
}

export default function* postingViewSagas() {
  yield fork(
    createReservationChargesSaga(
      actions.attendantReservationChargesActionsSet,
      MobileAttendantApi as never
    )
  );
  yield fork(
    createReservationChargesSaga(
      actions.housekeepingReservationChargesActionsSet,
      HousekeepingReservationApi as never
    )
  );
  yield takeLatest(
    actions.fetchTransactionCodes.trigger,
    fetchTransactionCodes
  );
}
