import { DateTimeFilters } from '@hkm/components/TaskManagement/Dashboard/domain/enums';
import { Filters } from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import i18n from 'i18next';

export interface DateTimeFiltersConfigItem {
  name: DateTimeFilters;
  updateValue: (value?: string) => void;
  label: string;
  value?: string;
  relatedField?: DateTimeFilters;
}

export type DateTimeFiltersConfig = DateTimeFiltersConfigItem[];

export interface DateTimeFiltersOptions {
  [key: string]: {
    name: DateTimeFilters;
    label: string;
    relatedField?: DateTimeFilters;
  };
}

export const getDateTimeFiltersOptions = (): DateTimeFiltersOptions => {
  return {
    [DateTimeFilters.DateFrom]: {
      name: DateTimeFilters.DateFrom,
      label: i18n.t('TASK_MANAGEMENT.DASHBOARD.FILTERS.DATE_FROM'),
      relatedField: DateTimeFilters.TimeFrom,
    },
    [DateTimeFilters.TimeFrom]: {
      name: DateTimeFilters.TimeFrom,
      label: i18n.t('TASK_MANAGEMENT.DASHBOARD.FILTERS.TIME_FROM'),
    },
    [DateTimeFilters.DateTo]: {
      name: DateTimeFilters.DateTo,
      label: i18n.t('TASK_MANAGEMENT.DASHBOARD.FILTERS.DATE_TO'),
      relatedField: DateTimeFilters.TimeTo,
    },
    [DateTimeFilters.TimeTo]: {
      name: DateTimeFilters.TimeTo,
      label: i18n.t('TASK_MANAGEMENT.DASHBOARD.FILTERS.TIME_TO'),
    },
  };
};

export const getDateTimeFiltersConfig = (
  updateFiltersState: (valueToSet: Partial<Filters>) => void,
  selectedFilters: Filters
): DateTimeFiltersConfig => {
  const dateTimeFilters = getDateTimeFiltersOptions();

  return Object.keys(dateTimeFilters).map((fieldKey) => {
    const dateTimeFilterField = dateTimeFilters[fieldKey];

    return {
      name: dateTimeFilterField.name,
      value: selectedFilters ? selectedFilters[fieldKey] : undefined,
      label: dateTimeFilterField.label,
      relatedField: dateTimeFilterField.relatedField,
      updateValue: (value?: string) => {
        if (!value) {
          return dateTimeFilterField.relatedField
            ? updateFiltersState({
                [fieldKey]: undefined,
                [dateTimeFilterField.relatedField]: undefined,
              })
            : updateFiltersState({ [fieldKey]: undefined });
        }

        updateFiltersState({ [fieldKey]: value });
      },
    };
  });
};
