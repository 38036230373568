import { PropertyStore } from '@hkm/components/Menu/PropertySelector/domain/interfaces';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import { apiToDateFormat } from '@hkm/shared/helpers/apiToDateFormat';
import { PropertyDateFormats } from '@hkm/store/interfaces/propertyDateFormats';
import { Store } from '@hkm/store/interfaces/store';
import i18n from 'i18next';
import { createSelector } from 'reselect';

import { TimeFormat } from '@ac/library-api';

export const selectPropertyStore = (state: Store): PropertyStore =>
  state.property;
export const selectBusinessDate = createSelector(
  selectPropertyStore,
  (property) => property.businessDate
);
export const selectPermissions = createSelector(
  selectPropertyStore,
  (property) => property.permissions || []
);
export const selectEffectiveValues = createSelector(
  selectPropertyStore,
  (property) => property.effectiveValues
);
export const selectPermissionsSet = createSelector(
  selectPermissions,
  (permissions) => new Set(permissions)
);
export const selectCheckOutTime = createSelector(
  selectPropertyStore,
  (property) =>
    property && property.activeProperty && property.activeProperty.checkOutTime
);
export const selectActiveProperty = createSelector(
  selectPropertyStore,
  (property) => property.activeProperty
);

export const selectPropertyDateFormats = createSelector(
  selectActiveProperty,
  (property) => {
    const time =
      property?.timeFormatCode === TimeFormat.H12
        ? APP_CONSTANTS.DEFAULT_12H_DISPLAY_TIME
        : APP_CONSTANTS.DEFAULT_24H_DISPLAY_TIME;
    const longDate =
      property?.longDateFormat && apiToDateFormat(property.longDateFormat);
    const shortDate =
      property?.shortDateFormat && apiToDateFormat(property.shortDateFormat);
    const longDateTime = i18n.t('GLOBAL.DATE_TIME_FORMAT', {
      date: longDate,
      time,
      interpolation: { escapeValue: false },
    });
    const shortDateTime = i18n.t('GLOBAL.DATE_TIME_FORMAT', {
      date: shortDate,
      time,
      interpolation: { escapeValue: false },
    });

    return {
      time,
      longDate,
      longDateTime,
      shortDate,
      shortDateTime,
    } as PropertyDateFormats;
  }
);
