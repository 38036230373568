/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import NotificationConsumerManager from '@hkm/components/App/notificationConsumer/NotificationConsumerManager';
import ConnectedOverlayMessageContainer from '@hkm/components/shared/OverlayMessage/ConnectedOverlayMessageContainer';
import { useInitAuthorization } from '@hkm/features/app/features/container/behaviours/useInitAuthorization';
import { AppContainerError } from '@hkm/features/app/features/container/features/Error';
import { AppLayout } from '@hkm/features/app/features/container/Layout';
import { AppPanels } from '@hkm/features/app/panels/Panels';
import { AppPanelsPresenterProviders } from '@hkm/features/app/panels/Providers';
import { useAppContext } from '@hkm/features/app/store/app/context';
import { MobileFeaturesContextProvider } from '@hkm/features/app/store/features/Provider';

import { AcSpinnerCover } from '@ac/mobile-components/dist/components/spinner';
import {
  FrontendCommunicationContextProvider,
  ShijiEnterprisePlatformConfigurableFeature,
} from '@ac/react-infrastructure';

export const AppContainer = (): JSX.Element => {
  const { state } = useAppContext();

  useInitAuthorization();

  if (!state.platformConfiguration) {
    return <AcSpinnerCover coverScreen={true} />;
  }

  if (state.properties.length <= 0) {
    return <AppContainerError />;
  }

  return (
    <ShijiEnterprisePlatformConfigurableFeature
      config={state.platformConfiguration}
    >
      <MobileFeaturesContextProvider>
        <FrontendCommunicationContextProvider>
          <NotificationConsumerManager>
            <AppPanelsPresenterProviders>
              <AppLayout />
              <AppPanels />

              <ConnectedOverlayMessageContainer />
            </AppPanelsPresenterProviders>
          </NotificationConsumerManager>
        </FrontendCommunicationContextProvider>
      </MobileFeaturesContextProvider>
    </ShijiEnterprisePlatformConfigurableFeature>
  );
};
