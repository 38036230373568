export function apiToDateFormat(api: string): string {
  api = api.replace(/y/g, 'Y');
  let current = api;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const execution = /d+/g.exec(current);
    if (!execution) {
      break;
    }
    const match = execution[0];
    const index = execution.index;
    const removed = api.length - current.length;
    const currentIndex = removed + index;
    const nextIndex = match.length + index;
    if (match.length <= 2) {
      api = `${api.substring(
        0,
        currentIndex
      )}${match.toUpperCase()}${api.substring(match.length + currentIndex)}`;
    }
    current = current.substr(nextIndex);
  }

  return api;
}
