/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */

import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppContainer } from '@hkm/features/app/features/container/Container';
import { AppContextProvider } from '@hkm/features/app/store/app/Provider';
import { MobileProvider } from '@hkm/features/app/store/mobile/Provider';
import { ReduxProvider } from '@hkm/features/app/store/redux/Provider';
import { MicroFrontendsContextProvider } from '@hkm/features/microFrontends/store/Provider';
import { Notifications } from '@hkm/features/notifications/Notifications';
import { NotificationsContextProvider } from '@hkm/features/notifications/store/Provider';

import 'pure-swipe';
import './App.scss';

interface Props {
  root: HTMLElement;
}

export const App = ({ root }: Props): JSX.Element => {
  return (
    <BrowserRouter>
      <MicroFrontendsContextProvider>
        <NotificationsContextProvider>
          <MobileProvider root={root}>
            <AppContextProvider>
              <ReduxProvider>
                <AppContainer />
                <Notifications />
              </ReduxProvider>
            </AppContextProvider>
          </MobileProvider>
        </NotificationsContextProvider>
      </MicroFrontendsContextProvider>
    </BrowserRouter>
  );
};
