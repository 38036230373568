export default class BadgeHelpers {
  // Maybe should be removed and use RESERVATION_STATUS_COLOR_MAP
  public static getReservationStatusColor = (
    code: string,
    withHashMark: boolean = false
  ): string => {
    code = (code || '').toLowerCase();
    let color: string;
    switch (code) {
      case 'di':
      case 'duein':
        color = '5bc0de';
        break;

      case 'rs':
      case 'reserved':
        color = 'f0ad4e';
        break;

      case 'ih':
      case 'inhouse':
        color = '5cb85c';
        break;

      case 'do':
      case 'dueout':
        color = 'd9534f';
        break;

      case 'co':
      case 'checkedout':
        color = 'a18cd0';
        break;

      case 'coo':
      case 'checkedoutopen':
        color = '1fc0de';
        break;

      case 'cx':
      case 'cancelled':
        color = 'afafaf';
        break;

      case 'ns':
      case 'noshow':
        color = 'f57c00';
        break;

      case 'wl':
      case 'waitlist':
        color = '80E8FF';
        break;

      default:
        color = '000';
        break;
    }

    return withHashMark ? `#${color}` : color;
  };

  public static getRoomStatusColor = (
    code: string,
    withHashMark: boolean = false
  ): string => {
    code = (code || '').toLowerCase();
    let color: string;
    switch (code) {
      case 'cl':
        color = '5BC0DE';
        break;
      case 'di':
        color = 'd9534f';
        break;
      case 'in':
        color = '5CB85C';
        break;
      case 'pu':
        color = 'f0ad4e';
        break;

      default:
        color = '000';
        break;
    }

    return withHashMark ? `#${color}` : color;
  };

  public static getAccountStatusColor = (
    code: string,
    withHashMark: boolean = false
  ): string => {
    code = (code || '').toLowerCase();
    let color: string;
    switch (code) {
      case 'di':
      case 'duein':
        color = '5bc0de';
        break;

      case 'rs':
      case 'reserved':
        color = 'f0ad4e';
        break;

      case 'ih':
      case 'inhouse':
        color = '5cb85c';
        break;

      case 'do':
      case 'dueout':
        color = 'd9534f';
        break;

      case 'co':
      case 'checkedout':
        color = 'a18cd0';
        break;

      case 'op':
      case 'open':
        color = 'a6ff00';
        break;

      case 'in':
      case 'inactive':
        color = 'afafaf';
        break;

      default:
        color = '000';
        break;
    }

    return withHashMark ? `#${color}` : color;
  };
}
