import React, { ComponentType } from 'react';
import { Navigate } from 'react-router';
import AppSpinner from '@hkm/components/shared/AppSpinner/AppSpinner';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { ExpectedEffectiveValues } from '@hkm/shared/effectiveValues/expectedEffectiveValues';
import {
  ExpectedEffectiveValuesResult,
  useHasExpectedEffectiveValues,
} from '@hkm/shared/hooks/useHasRequiredEffectiveValues';

export const requireEffectiveValues =
  (
    expected: ExpectedEffectiveValues
  ): (<P extends Record<string, unknown>>(
    WrappedComponent: ComponentType<P>
  ) => ComponentType<P>) =>
  // eslint-disable-next-line react/display-name
  <P extends Record<string, unknown>>(
    WrappedComponent: ComponentType<P>
    // eslint-disable-next-line react/display-name
  ): ComponentType<P> =>
  // eslint-disable-next-line react/display-name
  (props: P) => {
    const result = useHasExpectedEffectiveValues(expected);

    return result === ExpectedEffectiveValuesResult.POSITIVE ? (
      <WrappedComponent {...props} />
    ) : result === ExpectedEffectiveValuesResult.NEGATIVE ? (
      <Navigate to={APP_ROUTES.HOME} />
    ) : (
      <AppSpinner />
    );
  };
