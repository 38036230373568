import { createContext } from 'react';
import { NotificationsStore } from '@hkm/features/notifications/store/store';

import { useDefinedContext } from '@ac/react-infrastructure';

export const NotificationsContext = createContext<
  NotificationsStore | undefined
>(undefined);

export const useNotificationsContext = (): NotificationsStore =>
  useDefinedContext(NotificationsContext);
