import { SheetSummaryValue } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryValue';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { pickAllBySelector } from '@hkm/utils/pickAllBySelector';
import { reduceToUnique } from '@hkm/utils/reduceToUnique';
import flatten from 'lodash/flatten';

import { IdCodeDescriptionColor } from '@ac/library-api';

export const createSpecialNeedsFactory = (
  roomsAssignments: AttendantRoom[]
): SheetSummaryValue[] => {
  const getReservationDisabilityStatuses = (
    reservation: UnifiedReservationDetails
  ): IdCodeDescriptionColor[] => {
    return flatten([
      ...(reservation.guest?.disabilityStatusCodes || []),
      ...(reservation.accompanyingGuests || []).map(
        (guest) => guest.disabilityStatusCodes || []
      ),
    ]);
  };

  const allSpecialNeeds: IdCodeDescriptionColor[] = flatten(
    pickAllBySelector(roomsAssignments, (roomAssignment) =>
      flatten(
        (roomAssignment.currentReservations || []).map(
          getReservationDisabilityStatuses
        )
      )
    )
  );

  return reduceToUnique(
    allSpecialNeeds,
    (original, result) => original.code === result.code,
    (specialNeed) => ({
      code: specialNeed.code || '',
      description: specialNeed.code || '',
      color: specialNeed.color || '',
      count: 1,
    }),
    (item) => ({ ...item, count: item.count + 1 })
  );
};
