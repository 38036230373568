import React, { FC, memo, ReactNode } from 'react';
import EtaEtdGuaranteedBadge from '@hkm/components/shared/EtaEtdGuaranteedBadge/EtaEtdGuaranteedBadge';

import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  FlexDirection,
  JustifyContent,
} from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';

interface Props extends Testable {
  label: string | ReactNode;
  isEtaEtdGuaranteed: boolean;
}

const ReservationsMovementsLabel: FC<Props> = ({
  label,
  isEtaEtdGuaranteed,
  testSelector,
}) => {
  if (isEtaEtdGuaranteed) {
    return (
      <ac-flex
        justifyContent={JustifyContent.spaceBetween}
        direction={FlexDirection.column}
        data-test-selector={testSelector}
      >
        <AcText>{label}</AcText>
        <EtaEtdGuaranteedBadge />
      </ac-flex>
    );
  }

  return <>{label}</>;
};

export default memo(ReservationsMovementsLabel);
