import React from 'react';
import { useTranslation } from 'react-i18next';
import BookingNotesAccordion from '@hkm/components/shared/Templates/Reservation/BookingNotesAccordion/BookingNotesAccordion';
import { NotesReservationMap } from '@hkm/shared/hooks/useGroupedReservationNotes';

import {
  AcTile,
  AcTileVariant,
} from '@ac/mobile-components/dist/components/tile';
import { IconName } from '@ac/web-components';

interface Props {
  notesReservationMap: NotesReservationMap;
  reservationId: string;
}

export const ReservationNotesTile = ({
  notesReservationMap,
  reservationId,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const notesMap = notesReservationMap.get(reservationId);

  if (!notesMap || notesMap.size === 0) {
    return null;
  }

  const mapEntriesArray = Object.entries(Object.fromEntries(notesMap));

  return (
    <AcTile
      variant={AcTileVariant.Borderless}
      title={t('NEXT_USE_MODAL.NOTES')}
      icon={IconName.notes}
    >
      {mapEntriesArray.map(([key, value]) => (
        <BookingNotesAccordion
          key={key}
          bookingNotes={value.bookingNotes}
          title={value.description}
        />
      ))}
    </AcTile>
  );
};
