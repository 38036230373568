import { getCurrentRegionCode } from '@hkm/features/region/getCurrentRegionCode';

import { UnitAssignmentListItem } from '@ac/library-api';
import { SessionService } from '@ac/library-utils/dist/services';

interface Output {
  sessionProperty: UnitAssignmentListItem | undefined;
  regionProperty: UnitAssignmentListItem | undefined;
  firstAvailableProperty: UnitAssignmentListItem | undefined;
  effectiveProperty: UnitAssignmentListItem | undefined;
}

export function getEffectiveProperties(
  properties: UnitAssignmentListItem[]
): Output {
  const firstAvailableProperty = properties[0];
  const regionProperty = properties.find(
    ({ regionCode }) => regionCode?.toLowerCase() === getCurrentRegionCode()
  );
  const sessionProperty = properties.find(
    ({ unitId }) => unitId === SessionService.getPropertyId()
  );

  return {
    sessionProperty,
    regionProperty,
    firstAvailableProperty,
    effectiveProperty:
      sessionProperty ?? regionProperty ?? firstAvailableProperty,
  };
}
