import React from 'react';
import { NotificationConsumer } from '@hkm/components/App/notificationConsumer/NotificationConsumer';
import { ConsumerMap } from '@hkm/components/App/notificationConsumer/NotificationConsumerManager';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';

export interface NotificationConsumerContext {
  notificationConsumerMap: ConsumerMap;
  subscribe(
    signalREventType: SignalREventType,
    notificationConsumer: NotificationConsumer
  ): void;
  unSubscribe(signalREventType: SignalREventType, consumerName: string): void;
}

export default React.createContext<NotificationConsumerContext | null>(null);
