import {
  CommunicationMode,
  formatAddress,
  Nullable,
  ProfilesDashboardAddressDto,
  ProfilesDashboardCommunicationChannelDto,
} from '@ac/library-api';

export const getProfileAddress = (
  addresses?: Nullable<ProfilesDashboardAddressDto[]>
): string | undefined => {
  const primaryAddress = addresses?.find(({ isPrimary }) => isPrimary);

  if (primaryAddress) {
    const countryName = primaryAddress.countryCode?.description;
    const { addressLine1, addressLine2, city, postalCode } = primaryAddress;
    const stateName = primaryAddress.stateCode?.description;

    return formatAddress({
      addressLine1: addressLine1 as string,
      addressLine2: addressLine2 as string,
      postalCode: postalCode as string,
      city: city as string,
      stateName: stateName as string,
      countryName: countryName as string,
    });
  }

  return undefined;
};

export const getProfileCommunicationChannel = (
  mode: CommunicationMode,
  communicationChannels?: Nullable<ProfilesDashboardCommunicationChannelDto[]>,
  findPrimary = true
): string | undefined => {
  const communicationChannel = communicationChannels?.find(
    (channel) =>
      channel?.modeCode?.code === mode &&
      (findPrimary ? channel?.isPrimary : true)
  )?.details;

  return communicationChannel ? communicationChannel : undefined;
};
