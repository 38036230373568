import React, { FC, HTMLAttributes, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IncognitoModal from '@hkm/components/shared/IncognitoBadge/IncognitoModal';
import classNames from 'classnames';

import { IncognitoDetails } from '@ac/library-api';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  FlexDisplay,
  JustifyContent,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorProp } from '@ac/mobile-components/dist/utils';

import './IncognitoBadge.css';

interface Props extends HTMLAttributes<HTMLDivElement>, Testable {
  disableModal?: boolean;
  details?: IncognitoDetails;
}

const IncognitoBadge: FC<Props> = ({
  testSelector,
  className: classNameProp,
  details,
  disableModal,
}) => {
  const { t } = useTranslation();
  const className = classNames(
    'incognito-badge',
    'ac-background-color-incognito',
    classNameProp
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const hasIncognitoFlag = !!details;

  const onClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  if (!hasIncognitoFlag) {
    return null;
  }

  return (
    <>
      <ac-flex
        onClick={disableModal ? undefined : onClick}
        display={FlexDisplay.inlineFlex}
        class={className}
        alignItems={AlignItems.center}
        justifyContent={JustifyContent.center}
        {...getTestSelectorProp(testSelector, 'incognito')}
      >
        <AcText size={TextSize.Main3} equalLineHeight={true}>
          {t('GLOBAL.INCOGNITO.BADGE')}
        </AcText>
      </ac-flex>
      <IncognitoModal
        details={details}
        onClose={onClose}
        isOpen={isModalOpen}
      />
    </>
  );
};

export default memo(IncognitoBadge);
