// https://stackoverflow.com/questions/18913207/detect-double-byte-characters
// tslint:disable: no-bitwise
export const getStringByteLength = (value: string = '') => {
  const stringValue =
    typeof value === 'string' || (value as unknown) instanceof String
      ? value
      : String(value);

  let byteLength: number = 0;
  for (let i = 0; i < stringValue.length; i++) {
    const code = stringValue.charCodeAt(i);
    byteLength +=
      code < 1 << 7
        ? 1
        : code < 1 << 11
        ? 2
        : code < 1 << 16
        ? 3
        : code < 1 << 21
        ? 4
        : code < 1 << 26
        ? 5
        : code < 1 << 31
        ? 6
        : Number.NaN;
  }

  return byteLength;
};
