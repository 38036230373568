import { PostingRoomState } from '@hkm/components/Posting/shared/domain/interfaces';
import { Store } from '@hkm/store/interfaces/store';
import { createSelector } from 'reselect';

export const selectPostingRoomState = (state: Store): PostingRoomState =>
  state.postingRoom;

export const selectHousekeepingPostingRoomState = createSelector(
  selectPostingRoomState,
  (state) => {
    return state.housekeepingPostingRoomState;
  }
);

export const selectHousekeepingIsLoadingRoom = createSelector(
  selectHousekeepingPostingRoomState,
  (state) => {
    return !state.didInitialize || state.isChanging || state.isFetching;
  }
);

export const selectAttendantPostingRoomState = createSelector(
  selectPostingRoomState,
  (state) => {
    return state.attendantPostingRoomState;
  }
);

export const selectAttendantIsLoadingRoom = createSelector(
  selectAttendantPostingRoomState,
  (state) => {
    return !state.didInitialize || state.isChanging || state.isFetching;
  }
);
