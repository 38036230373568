import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceFormValidationConflictData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/Validation/maintenanceFormValidationConflictData';
import { createMaintenanceFormValidationConflictsDataFromConflictDetails } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/Validation/maintenanceFormValidationConflictDataFactory';
import MaintenanceFormValidationModal from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/Validation/MaintenanceFormValidationModal';
import classNames from 'classnames';

import { ConflictDetails } from '@ac/library-api';
import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import {
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { Color, IconName } from '@ac/web-components';

import './MaintenanceFormValidationButton.css';

interface Props extends Styleable, Testable {
  conflicts?: ConflictDetails[];
  isLoading?: boolean;
  onValidateRequest?: () => void;
  canValidate?: boolean;
}

const MaintenanceFormValidationButton: FC<Props> = (props) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const conflictData = useMemo<MaintenanceFormValidationConflictData[]>(
    () =>
      createMaintenanceFormValidationConflictsDataFromConflictDetails(
        props.conflicts
      ),
    [props.conflicts]
  );

  const hasConflictData: boolean = !!props.conflicts;
  const hasNoConflicts: boolean = hasConflictData && conflictData.length === 0;
  const onValidateRequest = props.onValidateRequest;

  const onModalClose = useCallback(() => setModalOpen(false), []);

  const onButtonClick = useCallback(() => {
    if (!hasNoConflicts && onValidateRequest) {
      onValidateRequest();
    }
  }, [hasNoConflicts, onValidateRequest]);

  useEffect(() => {
    if (!isModalOpen && hasConflictData && conflictData.length > 0) {
      setModalOpen(true);
    }

    // eslint-disable-next-line
  }, [conflictData]);

  const className = classNames(
    'maintenance-form-validation-button',
    { 'maintenance-form-validation-button-unclickable': hasNoConflicts },
    props.className
  );

  return (
    <>
      <AcButton
        loading={props.isLoading}
        pattern={AcButtonPattern.Secondary}
        testSelector={props.testSelector}
        disabled={!props.canValidate}
        onClick={onButtonClick}
        style={props.style}
        className={className}
      >
        {!hasNoConflicts && (
          <AcButtonContent
            text={t('MAINTENANCE_CREATE.VALIDATION.BUTTON.VALIDATE')}
          />
        )}
        {hasNoConflicts && (
          <AcButtonContent
            text={t('MAINTENANCE_CREATE.VALIDATION.BUTTON.NO_CONFLICTS')}
            icon={IconName.done}
            iconColor={Color.success}
          />
        )}
      </AcButton>

      <MaintenanceFormValidationModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        conflicts={conflictData}
        testSelector={formatTestSelector(props.testSelector, 'modal')}
      />
    </>
  );
};

export default memo(MaintenanceFormValidationButton);
