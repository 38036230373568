import React, { FC, memo, Ref, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import * as actions from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import { selectHousekeepingMode } from '@hkm/components/Housekeeping/Dashboard/domain/selectors';
import { findAccompanying } from '@hkm/components/Housekeeping/Dashboard/helper/findAccompanying';
import HousekeepingDashboardRoomDropdownItems from '@hkm/components/Housekeeping/Dashboard/RoomItem/DropdownItems/HousekeepingDashboardRoomDropdownItems';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import DoNotMoveInfo from '@hkm/components/shared/DoNotMove/DoNotMoveInfo';
import EtaEtdGuaranteedBadge from '@hkm/components/shared/EtaEtdGuaranteedBadge/EtaEtdGuaranteedBadge';
import { GuestServiceBadge } from '@hkm/components/shared/GuestService/GuestServiceBadge';
import IncognitoBadge from '@hkm/components/shared/IncognitoBadge/IncognitoBadge';
import MaintenanceStatusBadge from '@hkm/components/shared/MaintenanceStatusBadge/MaintenanceStatusBadge';
import { ReservationMovementDashboardItem } from '@hkm/components/shared/ReservationMovements/ReservationMovementDashboardItem';
import { RoomConditionsBadge } from '@hkm/components/shared/RoomConditions/RoomConditionsBadge';
import RoomStatusBadge from '@hkm/components/shared/RoomStatusBadge/RoomStatusBadge';
import GuestAvatarTemplate from '@hkm/components/shared/Templates/Guest/GuestAvatar/GuestAvatarTemplate';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { DashboardMode } from '@hkm/shared/enum/dashboardMode';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';
import { getDiscrepancyColor } from '@hkm/shared/helpers/getDiscrapencyColor';
import { useGuestServiceMap } from '@hkm/shared/hooks/useGuestServiceMap';
import { doesRoomHaveDoNotMoveFlag } from '@hkm/shared/reservations/hasDoNotMoveFlag';
import { useReservationMovement } from '@hkm/shared/reservations/reservationMovementHooks';
import { ReservedKind } from '@hkm/shared/reservedKind/reservedKind';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { UnifiedReservationGuestDetails } from '@hkm/types/reservation/models/UnifiedReservationGuestDetails';
import classNames from 'classnames';

import {
  DiscrepancyType,
  ReservationStatus,
  RoomMaintenanceStatus,
} from '@ac/library-api';
import {
  AcBadge,
  BadgeSize,
} from '@ac/mobile-components/dist/components/badge';
import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcButtonDropdown } from '@ac/mobile-components/dist/components/button-dropdown';
import { AcCheckbox } from '@ac/mobile-components/dist/components/checkbox';
import { AcDropdownList } from '@ac/mobile-components/dist/components/dropdown-list';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTileShell } from '@ac/mobile-components/dist/components/tile';
import {
  AlignItems,
  JustifyContent,
  MobileColor,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import {
  Selectable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import {
  formatTestSelector,
  getBackgroundColorClass,
  getTestSelectorAttribute,
  getTestSelectorProp,
} from '@ac/mobile-components/dist/utils';
import { IconName, Size } from '@ac/web-components';

import './HousekeepingDashboardRoom.css';

interface Props extends Testable, Selectable {
  room: HousekeepingRoom;
}

const HousekeepingDashboardRoom: FC<Props> = ({
  room,
  testSelector,
  selected,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const { moveIn, moveOut } = useReservationMovement(
    room,
    (reservation) => reservation.status?.code as ReservationStatus,
    { noTimeLabel: true }
  );
  const guestServiceMap = useGuestServiceMap();
  const inSelectionMode =
    useSelector(selectHousekeepingMode) === DashboardMode.Selection;
  const guest = room.currentMainReservation?.guest;
  const hasDisabilities = !!guest && !!guest.disabilityStatusCodes?.length;
  const hasReservation = room.currentReservedKind === ReservedKind.Reservation;

  // check if day use is set up for today and if it is the only one reservation
  const hasSingleDayUseReservationMovement =
    !!(moveIn ? !moveOut : moveOut) && (moveIn || moveOut)?.isDayUse;

  // in day use case reservation can be in due in status (next reservation) or due out status (current reservation)
  const accompanyingGuests: UnifiedReservationGuestDetails[] | undefined =
    hasSingleDayUseReservationMovement &&
    (room.currentMainReservation || room.nextMainReservation)
      ? findAccompanying(
          room.currentMainReservation ||
            (room.nextMainReservation as UnifiedReservationDetails)
        )
      : hasReservation && room.currentMainReservation
      ? findAccompanying(
          room.currentMainReservation,
          room.currentSharedReservations
        )
      : undefined;

  const hasSharedReservations = !!room.currentSharedReservations?.length;
  const hasAccompanyingGuests = !!accompanyingGuests?.length;
  const isGreenServiceEnabled = useSelector(
    selectEffectiveValues
  )?.greenService;
  const hasGreenService = room.greenService && isGreenServiceEnabled;
  const hasDoNotMoveFlag = doesRoomHaveDoNotMoveFlag(room);
  const hasIncognitoFlag = !!guest?.personalData?.incognitoDetails;
  const showEtaEtdGuaranteed = !!(
    room.currentMainReservation?.status?.code === ReservationStatus.IH &&
    room.currentMainReservation.isTimeGuaranteed
  );
  const displayGuestService =
    (guestServiceMap.get(GuestServiceStatusType.ServiceDeferred) &&
      room.serviceDeferred) ||
    (guestServiceMap.get(GuestServiceStatusType.ServicePreferred) &&
      room.servicePreferredAfterTime) ||
    (guestServiceMap.get(GuestServiceStatusType.ServiceRequested) &&
      room.serviceRequestedInstruction);
  const hasRoomConditions = !!room.roomConditions?.length;
  const hasAnyExtraInfo =
    hasSharedReservations ||
    hasAccompanyingGuests ||
    showEtaEtdGuaranteed ||
    hasGreenService ||
    hasDoNotMoveFlag ||
    hasIncognitoFlag ||
    displayGuestService ||
    hasRoomConditions;

  const onCellSelectHandler = useCallback(() => {
    dispatch(
      actions.toggleRoomsSelection({
        id: room.id,
        numericRoomNumber: room.roomNumber?.numberNumeric,
        roomNumber: room.roomNumber?.code ?? '',
      })
    );
  }, [dispatch, room]);

  const onTileLinkClick = useCallback(
    (event: React.MouseEvent) => {
      const isOptionsClick = optionsContainerRef.current?.contains(
        event.target as Node
      );

      if (isOptionsClick || inSelectionMode) {
        event.preventDefault();
      }

      if (inSelectionMode) {
        onCellSelectHandler();
      }
    },
    [inSelectionMode, onCellSelectHandler]
  );

  const shellClassNames = classNames(
    'housekeeping-dashboard-room',
    selected && 'housekeeping-dashboard-room-selected'
  );

  const indicatorClassName = classNames(
    'housekeeping-dashboard-room-indicator',
    !!room.discrepancy &&
      getBackgroundColorClass(
        getDiscrepancyColor(room.discrepancy.code as DiscrepancyType)
      )
  );

  return (
    <AcTileShell className={shellClassNames} testSelector={testSelector}>
      <Link
        to={generatePath(APP_ROUTES.HOUSEKEEPING.DETAILS, { id: room.id })}
        className="unstyled-link housekeeping-dashboard-room-link"
        onClick={onTileLinkClick}
      >
        <div
          {...getTestSelectorAttribute(testSelector, 'indicator')}
          className={indicatorClassName}
        />
        <div className="housekeeping-dashboard-room-content">
          <ac-flex alignItems={AlignItems.center}>
            {inSelectionMode && (
              <ac-flex
                justifyContent={JustifyContent.flexStart}
                class="housekeeping-dashboard-room-checkbox-container"
              >
                <AcCheckbox
                  contractedTouchArea={true}
                  checked={selected}
                  testSelector={formatTestSelector(
                    testSelector,
                    'selectionCheckbox'
                  )}
                />
              </ac-flex>
            )}

            <ac-flex
              justifyContent={JustifyContent.flexStart}
              class="housekeeping-dashboard-room-number"
            >
              <AcFormElement
                smallLabel={true}
                label={t('GLOBAL.ROOM_NUMBER.SHORT')}
                testSelector={formatTestSelector(testSelector, 'roomNumber')}
                ellipsis={true}
              >
                <ac-flex alignItems={AlignItems.center} wrapped={true}>
                  <div className="ac-spacing-right-sm">
                    {room.roomNumber?.code}
                  </div>
                  {room.currentMaintenance && (
                    <MaintenanceStatusBadge
                      status={
                        room.currentMaintenance.status
                          ?.code as RoomMaintenanceStatus
                      }
                      testSelector={testSelector}
                      badgeSize={BadgeSize.sm}
                      badgeTextSize={TextSize.Main3}
                    />
                  )}
                </ac-flex>
              </AcFormElement>
            </ac-flex>

            <ac-flex
              justifyContent={JustifyContent.center}
              class="housekeeping-dashboard-room-avatar"
            >
              {guest && (
                <GuestAvatarTemplate
                  guestPersonalData={guest.personalData}
                  hasDisabilities={hasDisabilities}
                  guestVipCode={guest.vipCode}
                  testSelector={testSelector}
                />
              )}
            </ac-flex>

            <ac-flex
              justifyContent={JustifyContent.center}
              class="housekeeping-dashboard-room-reservation"
            >
              {room.currentReservedKind === ReservedKind.Reservation && (
                <AcFormElement
                  smallLabel={true}
                  label={t('GLOBAL.RESERVATION_STATUS.TITLE.SHORT')}
                  testSelector={formatTestSelector(
                    testSelector,
                    'reservationStatus'
                  )}
                >
                  {room.currentMainReservation?.status?.code}
                </AcFormElement>
              )}
            </ac-flex>

            <ac-flex
              justifyContent={JustifyContent.center}
              class="housekeeping-dashboard-room-housekeeping"
            >
              <AcFormElement
                smallLabel={true}
                label={t('GLOBAL.HOUSEKEEPING_STATUS.SHORT')}
                testSelector={formatTestSelector(
                  testSelector,
                  'housekeepingStatus'
                )}
              >
                {room.housekeepingStatus?.code}
              </AcFormElement>
            </ac-flex>

            {room.roomStatus?.code && (
              <ac-flex
                justifyContent={JustifyContent.center}
                class="housekeeping-dashboard-room-badge"
              >
                <RoomStatusBadge
                  status={room.roomStatus?.code}
                  testSelector={testSelector}
                />
              </ac-flex>
            )}

            {!inSelectionMode && (
              <div ref={optionsContainerRef}>
                <AcButtonDropdown
                  /* tslint:disable-next-line:jsx-no-lambda */
                  button={(ref) => (
                    <AcButton
                      pattern={AcButtonPattern.Tertiary}
                      ref={ref as Ref<HTMLButtonElement>}
                      testSelector={formatTestSelector(
                        testSelector,
                        'optionsButton'
                      )}
                    >
                      <AcButtonContent icon={IconName.actionMenu} />
                    </AcButton>
                  )}
                  /* tslint:disable-next-line:jsx-no-lambda */
                  popover={(ref) => (
                    <AcDropdownList ref={ref as Ref<HTMLDivElement>}>
                      <HousekeepingDashboardRoomDropdownItems
                        room={room}
                        testSelector={formatTestSelector(
                          testSelector,
                          'option'
                        )}
                      />
                    </AcDropdownList>
                  )}
                />
              </div>
            )}
          </ac-flex>

          <ReservationMovementDashboardItem
            room={room}
            testSelector={testSelector}
          />

          {(room.isInPendingRoomQueue || room.isInReadyRoomQueue) && (
            <div className="ac-spacing-top-md">
              <AcBadge
                badgeText={
                  room.isInPendingRoomQueue
                    ? t('GLOBAL.QUEUE_ROOM_PENDING')
                    : t('GLOBAL.QUEUE_ROOM_READY')
                }
                backgroundColor={
                  room.isInPendingRoomQueue
                    ? MobileColor.Purple
                    : MobileColor.Good
                }
                badgeTextSize={TextSize.Main3}
                fit={true}
              />
            </div>
          )}

          {hasAnyExtraInfo && (
            <ac-flex
              {...getTestSelectorProp(testSelector, 'extraInfo')}
              wrapped={true}
              class={classNames(
                'ac-spacing-top-sm',
                'housekeeping-dashboard-room-extras',
                inSelectionMode &&
                  'housekeeping-dashboard-room-extras-with-checkbox'
              )}
            >
              {hasSharedReservations && (
                <ac-flex alignItems={AlignItems.center}>
                  <ac-icon icon={IconName.sharers} size={Size.sm} />
                  <AcText
                    size={TextSize.Main2}
                    className="ac-spacing-left-sm"
                    {...getTestSelectorProp(testSelector, 'shared')}
                  >
                    {t('ROOM_LIST.SHARED_GUEST', {
                      sharedAmount: room.currentSharedReservations?.length,
                    })}
                  </AcText>
                </ac-flex>
              )}

              {hasAccompanyingGuests && (
                <ac-flex alignItems={AlignItems.center}>
                  <ac-icon icon={IconName.accompanying} size={Size.sm} />
                  <AcText
                    size={TextSize.Main2}
                    className="ac-spacing-left-sm"
                    {...getTestSelectorProp(testSelector, 'accompanying')}
                  >
                    {t('ROOM_LIST.ACCOMPANYING_GUEST', {
                      accompanyAmount: accompanyingGuests?.length,
                    })}
                  </AcText>
                </ac-flex>
              )}

              {hasGreenService && (
                <ac-flex alignItems={AlignItems.center}>
                  <ac-icon icon={IconName.greenService} size={Size.sm} />
                  <AcText
                    size={TextSize.Main2}
                    className="ac-spacing-left-sm"
                    {...getTestSelectorProp(testSelector, 'greenService')}
                  >
                    {t('GLOBAL.GREEN_SERVICE.TITLE')}
                  </AcText>
                </ac-flex>
              )}

              {hasDoNotMoveFlag && <DoNotMoveInfo />}

              <GuestServiceBadge room={room} />

              {hasRoomConditions && (
                <RoomConditionsBadge roomConditions={room.roomConditions} />
              )}

              {showEtaEtdGuaranteed && (
                <ac-flex>
                  <EtaEtdGuaranteedBadge />
                </ac-flex>
              )}

              <IncognitoBadge
                testSelector={testSelector}
                details={guest?.personalData?.incognitoDetails}
                disableModal={true}
              />
            </ac-flex>
          )}
        </div>
      </Link>
    </AcTileShell>
  );
};

export default memo(HousekeepingDashboardRoom);
