import React, { FC, memo } from 'react';
import { Chips } from '@hkm/components/TaskManagement/Dashboard/config/chipsConfig';

import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { MobileColor } from '@ac/mobile-components/dist/enums';

interface ComponentProps {
  chips: Chips;
}

const DashboardFiltersChipsItem: FC<ComponentProps> = (props) => {
  return (
    <AcBadge
      backgroundColor={MobileColor.Gray4}
      badgeText={props.chips.fieldDisplayValue}
      onRemove={props.chips.clearFieldCallback}
      testSelector={`chips-${props.chips.fieldDisplayValue}`}
    />
  );
};

export default memo(DashboardFiltersChipsItem);
