import { RoomConditionsChangeActions } from '@hkm/shared/domain/roomConditionsChange/roomConditionsChangeActions';
import { RoomConditionsChangeRequest } from '@hkm/shared/domain/roomConditionsChange/roomConditionsChangeRequest';

import { updateAction } from '@ac/library-utils/dist/redux-utils';

export function createRoomConditionsChangeActions(
  prefix: string
): RoomConditionsChangeActions {
  return {
    changeRoomConditions: updateAction<RoomConditionsChangeRequest, string>(
      `${prefix}/roomConditions/change`
    ),
  };
}
