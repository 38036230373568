import {
  AttendantAssignmentTaskDetails,
  AttendantTaskProgressType,
} from '@ac/library-api';

export function isTaskProgress(
  action: AttendantTaskProgressType[],
  activeTask?: AttendantAssignmentTaskDetails
): boolean {
  return activeTask
    ? action.includes(activeTask.progress?.code as AttendantTaskProgressType)
    : false;
}
