import { useCallback, useMemo, useState } from 'react';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';

export interface GuestServiceModalFormState {
  instruction?: string;
  serviceDeferredUntil?: string;
  servicePreferredAfterTime?: string;
  serviceType?: GuestServiceStatusType;
  initialServiceType?: GuestServiceStatusType;
}

export const useFormState = () => {
  const [state, setState] =
    useState<GuestServiceModalFormState>(defaultFormState);

  const initialize = useCallback((data: GuestServiceModalFormState) => {
    setState(data);
  }, []);

  return useMemo(
    () => ({
      state,
      initialize,
    }),
    [state, initialize]
  );
};

const defaultFormState = {
  instruction: undefined,
  cleanAfter: undefined,
  serviceDeferredUntil: undefined,
  servicePreferredAfterTime: undefined,
  serviceType: undefined,
  initialServiceType: undefined,
};
