import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import PostingReservationTile from '@hkm/components/Posting/PostingReservation/Body/ReservationTile/PostingReservationTile';
import { activeReservationStates } from '@hkm/shared/reservations/activeReservationStatuses';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

import { ReservationStatus } from '@ac/library-api';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTileGroup } from '@ac/mobile-components/dist/components/tile';
import { TextSize } from '@ac/mobile-components/dist/enums';

export interface PostingReservationListBodyProps {
  roomId: string;
  showGuestNames?: boolean;
  reservations: UnifiedReservationDetails[];
}

const PostingReservationListBody: FC<PostingReservationListBodyProps> = (
  props: PostingReservationListBodyProps
) => {
  const { t } = useTranslation();

  return (
    <>
      <AcText size={TextSize.Main2}>
        {t('POSTING.RESERVATION_LIST.DESCRIPTION')}
      </AcText>
      <AcTileGroup className="posting-reservation-list-body ac-spacing-top-s">
        {props.reservations.map((reservation: UnifiedReservationDetails) => {
          return activeReservationStates.has(
            reservation.status?.code as ReservationStatus
          ) ? (
            <PostingReservationTile
              key={reservation.id}
              roomId={props.roomId}
              reservation={reservation}
              showGuestNames={props.showGuestNames}
            />
          ) : undefined;
        })}
      </AcTileGroup>
    </>
  );
};

export default memo(PostingReservationListBody);
