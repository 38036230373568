import { UnifiedMaintenanceDetails } from '@hkm/types/maintenance/models/UnifiedMaintenanceDetails';

import {
  AttendantAssignmentRoomMaintenanceDetails,
  RoomMaintenanceDetails,
} from '@ac/library-api';

export function mapHousekeepingMaintenanceDetails(
  maintenance: RoomMaintenanceDetails
): UnifiedMaintenanceDetails {
  return {
    id: maintenance.id,
    reason: maintenance.reasonCode,
    returnRoomStatus: maintenance.returnRoomStatusCode,
    state: maintenance.stateCode,
    comment: maintenance.comment,
    fromTime: maintenance.fromTime,
    status: maintenance.statusCode,
    toTime: maintenance.toTime,
  };
}

export function mapAttendantMaintenanceDetails(
  maintenance: AttendantAssignmentRoomMaintenanceDetails
): UnifiedMaintenanceDetails {
  return maintenance;
}
