import {
  UsersSelectorAction,
  UsersSelectorActionType,
} from '@hkm/components/shared/features/UsersSelector/reducer/actions';
import { UsersSelectorState } from '@hkm/components/shared/features/UsersSelector/store/state';

export const createUsersSelectorReducer = (
  state: UsersSelectorState,
  action: UsersSelectorAction
) => {
  const { type, payload } = action;
  switch (type) {
    case UsersSelectorActionType.LoadUsers: {
      return {
        ...state,
        usersList: payload.usersList,
      };
    }
    default:
      return state;
  }
};
