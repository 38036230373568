import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CountWithDescription from '@hkm/components/Attendant/SheetSummary/body/CountWithDesciption/CountWithDescription';
import SheetsSummaryAccordion from '@hkm/components/Attendant/SheetSummary/body/SheetSummaryAccordion/SheetsSummaryAccordion';
import { SheetSummaryLinenChange } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryLinenChange';

import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  FlexDirection,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

export interface LinenChangeProps {
  linenChange: SheetSummaryLinenChange;
}

export const LinenChange: React.FC<LinenChangeProps> = (
  props: LinenChangeProps
) => {
  const { t } = useTranslation();
  const { total, roomTypesLinenChange } = props.linenChange;
  const renderSubtitle = useMemo(
    () => (
      <AcText
        size={TextSize.H1}
        weight={TextWeight.Semibold}
        testSelector="linen-change-total"
      >
        {total}
      </AcText>
    ),
    [total]
  );

  const renderContent = useMemo(
    () => (
      <ac-flex direction={FlexDirection.column}>
        {roomTypesLinenChange.map((roomTypeInfo, index) => (
          <CountWithDescription
            key={index}
            count={roomTypeInfo.count}
            description={roomTypeInfo.codeName}
          />
        ))}
      </ac-flex>
    ),
    [roomTypesLinenChange]
  );

  return (
    <SheetsSummaryAccordion
      icon={IconName.linenChange}
      title={t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.LINEN_CHANGE.TITLE')}
      renderSubtitle={renderSubtitle}
      renderContent={renderContent}
      expandDisabled={total === 0}
    />
  );
};

export default memo(LinenChange);
