import { DashboardSort as DashboardSortOption } from '@hkm/components/TaskManagement/Dashboard/domain/enums';
import { TaskQuickFilter } from '@hkm/components/TaskManagement/domain/enums/TaskQuickFilter';
import { i18n } from 'i18next';

export const sortOptions = (i18: i18n) => {
  return Object.keys(DashboardSortOption).map((sortOption) => ({
    value: DashboardSortOption[sortOption],
    itemLabel: i18.t(`TASK_MANAGEMENT.DASHBOARD.SORT.${sortOption}`),
  }));
};

export const quickFilters = (i18: i18n) => {
  return Object.keys(TaskQuickFilter).map((quickFilterItem) => ({
    label: i18.t(`TASK_MANAGEMENT.DASHBOARD.QUICK_FILTERS.${quickFilterItem}`),
    value: TaskQuickFilter[quickFilterItem],
  }));
};
