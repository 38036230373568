import {
  FeatureRequirements,
  PermissionRequirement,
  PropertyContext,
  SettingRequirement,
} from '@ac/library-api';

export interface MaintenanceFeatureActions {
  view: FeatureRequirements<void>;
}

export const getMaintenanceFeature = (
  businessContext: PropertyContext | undefined
): MaintenanceFeatureActions => {
  const permissions = businessContext?.permissions.processed;
  const settings = businessContext?.settings.processed;

  return {
    view: new FeatureRequirements<void>([
      new PermissionRequirement({
        condition: permissions?.housekeeping.rooms.hasPermissionToView ?? false,
      }),
      new PermissionRequirement({
        condition:
          permissions?.housekeeping.maintenance.hasPermissionToView ?? false,
      }),
      new SettingRequirement({
        condition:
          settings?.housekeeping.isMaintenanceMobileApplicationEnabled ?? false,
      }),
    ]),
  };
};
