import { createGreenServiceToggleActions } from '@hkm/shared/domain/greenServiceToggle/greenServiceToggleActionsFactory';
import { createGuestServiceChangeActions } from '@hkm/shared/domain/guestServiceChange/guestServiceChangeActionsFactory';
import { createHousekeepingStatusChangeActions } from '@hkm/shared/domain/housekeepingStatusChange/housekeepingStatusChangeActionsFactory';
import { createRoomOccupancyActions } from '@hkm/shared/domain/occupancyChange/occupancyChangeActionsFactory';
import { createRoomConditionsChangeActions } from '@hkm/shared/domain/roomConditionsChange/roomConditionsChangeActionsFactory';
import { createRoomStatusChangeActions } from '@hkm/shared/domain/roomStatusChange/roomStatusChangeActionsFactory';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';

import { MaintenanceFile } from '@ac/library-api';
import {
  createAction,
  getListAction,
  getOneAction,
} from '@ac/library-utils/dist/redux-utils';

export const initialize = createAction<string>(
  'housekeeping/details/initialize'
);
export const setRoomFromDashboard = createAction<HousekeepingRoom>(
  'housekeeping/details/setRoomFromDashboard'
);
export const fetchRoom = getOneAction<undefined, HousekeepingRoom>(
  'housekeeping/details/fetchRoom'
);
export const reset = createAction('housekeeping/details/reset');
export const fetchAttachments = getListAction<undefined, MaintenanceFile[]>(
  'housekeeping/details/fetchAttachments'
);

export const roomStatus = createRoomStatusChangeActions('housekeeping/details');
export const roomConditions = createRoomConditionsChangeActions(
  'housekeeping/details'
);
export const housekeepingStatus = createHousekeepingStatusChangeActions(
  'housekeeping/details'
);
export const roomOccupancy = createRoomOccupancyActions('housekeeping/details');
export const guestService = createGuestServiceChangeActions(
  'housekeeping/details'
);
export const greenService = createGreenServiceToggleActions(
  'housekeeping/details'
);
