import {
  MaintenanceUpdateActions,
  MaintenanceUpdateCustomActions,
} from '@hkm/shared/domain/maintenanceUpdate/maintenanceUpdateActions';
import { MaintenanceUpdateData } from '@hkm/shared/domain/maintenanceUpdate/models/maintenanceUpdateData';

import { createInApiAction } from '@ac/library-utils/dist/redux-utils';

export function createMaintenanceUpdateActions(
  prefixResourceName: string,
  customActions: MaintenanceUpdateCustomActions
): MaintenanceUpdateActions {
  return {
    notifyMaintenanceUpdate: customActions.notifyMaintenanceUpdate,
    updateMaintenance: createInApiAction<MaintenanceUpdateData, string>(
      `${prefixResourceName}/update/updateMaintenance`
    ),
  };
}
