import { setDefaultApiConfiguration } from '@hkm/features/api/setDefaultApiConfiguration';
import { isPropertyLocatedInAnotherRegion } from '@hkm/features/property/isPropertyLocatedInAnotherRegion';

import { SessionService } from '@ac/library-utils/dist/services';

export const setPropertyInThirdPartLibraries = (property?: {
  unitId?: string;
  regionCode?: string;
}): void => {
  if (!property?.unitId || isPropertyLocatedInAnotherRegion(property)) {
    return;
  }

  SessionService.setPropertyId(property.unitId);
  setDefaultApiConfiguration({
    propertyId: property.unitId,
  });
};
