import { GreenServiceToggleActions } from '@hkm/shared/domain/greenServiceToggle/greenServiceToggleActions';
import { GreenServiceToggleRequest } from '@hkm/shared/domain/greenServiceToggle/greenServiceToggleRequest';

import { updateAction } from '@ac/library-utils/dist/redux-utils';

export function createGreenServiceToggleActions(
  prefixResourceName: string
): GreenServiceToggleActions {
  return {
    toggleGreenService: updateAction<GreenServiceToggleRequest, string>(
      `${prefixResourceName}/setGreenService`
    ),
  };
}
