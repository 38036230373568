import { useCallback, useState } from 'react';
import {
  getDefaultNotificationsState,
  NotificationsState,
  Toast,
} from '@hkm/features/notifications/store/state';

import { guid } from '@ac/library-utils/dist/utils';
import { ToastInterface } from '@ac/web-components';

export interface NotificationsStore {
  state: NotificationsState;
  addToast(toast: ToastInterface): void;
  removeToast(id: string): void;
  setToasts(toasts: Toast[]): void;
}

export function useNotificationsStore(): NotificationsStore {
  const [state, setState] = useState(getDefaultNotificationsState);

  const addToast = useCallback((toast: ToastInterface): void => {
    setState((prevState) => ({
      ...prevState,
      toasts: [
        ...prevState.toasts,
        {
          ...toast,
          id: guid('hkm-toast'),
        },
      ],
    }));
  }, []);

  const removeToast = useCallback((id: string): void => {
    setState((prevState) => ({
      ...prevState,
      toasts: prevState.toasts.filter((toast) => toast.id !== id),
    }));
  }, []);

  const setToasts = useCallback((toasts: Toast[]): void => {
    setState((prevState) => ({
      ...prevState,
      toasts,
    }));
  }, []);

  return {
    state,
    addToast,
    removeToast,
    setToasts,
  };
}
