import { useEffect } from 'react';
import { setDefaultApiConfiguration } from '@hkm/features/api/setDefaultApiConfiguration';
import { useActiveProperty } from '@hkm/features/property/useActiveProperty';

export const useOverrideBaseApiFromBusinessContext = () => {
  const { propertyId } = useActiveProperty();

  useEffect(() => {
    setDefaultApiConfiguration({
      propertyId,
    });
  }, [propertyId]);
};
