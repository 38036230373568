import React, { FC, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAttributes } from '@hkm/components/App/domain/selectors';

import { IdCodeDescription } from '@ac/library-api';
import {
  AcBadge,
  AcBadgeList,
  AcInfoBadge,
} from '@ac/mobile-components/dist/components/badge';
import { AcButton } from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader,
} from '@ac/mobile-components/dist/components/modal';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';

import './GustPreferencesTemplate.css';

interface GuestPreferencesTemplateProps extends Testable {
  disableModal?: boolean;
  preferences: IdCodeDescription[];
}

const GuestPreferencesTemplate: FC<GuestPreferencesTemplateProps> = (
  props: GuestPreferencesTemplateProps
) => {
  const { t } = useTranslation();
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const attributes = useSelector(getAttributes);

  function openModal(): void {
    if (!props.disableModal) {
      setModalVisibility(true);
    }
  }

  function closeModal(): void {
    setModalVisibility(false);
  }

  const preferences = useMemo(
    () =>
      props.preferences.map((preference) => {
        const preferenceAttribute = (attributes || []).find(
          (attribute) => attribute.code === preference.code
        );
        const attributeColor = preferenceAttribute?.ref_attributeGroupId?.color;

        return {
          ...preference,
          attributeColor,
        };
      }),
    [props.preferences, attributes]
  );

  return (
    <>
      <AcBadgeList testSelector={props.testSelector}>
        {preferences.map((preference) =>
          preference.attributeColor ? (
            <AcBadge
              key={preference.id}
              badgeText={preference.code}
              onClick={openModal}
              backgroundColor={preference.attributeColor}
            />
          ) : (
            <AcInfoBadge
              key={preference.id}
              badgeText={preference.code}
              onClick={openModal}
            />
          )
        )}
      </AcBadgeList>

      <AcModal
        className="preferences-modal"
        isOpen={isModalVisible}
        onClose={closeModal}
      >
        <AcModalHeader
          className="preferences-modal-header"
          showCloseButton={true}
        >
          {t('ROOM_DETAILS.PREFERENCES')}
        </AcModalHeader>

        <AcModalBody className="preferences-modal-body">
          {preferences.map((preference) => (
            <div key={preference.id} className="ac-spacing-top-sm">
              {preference.attributeColor ? (
                <AcBadge
                  badgeText={preference.code}
                  label={preference.description}
                  testSelector={`${props.testSelector}-${preference.code}`}
                  backgroundColor={preference.attributeColor}
                />
              ) : (
                <AcInfoBadge
                  badgeText={preference.code}
                  label={preference.description}
                  testSelector={`${props.testSelector}-${preference.code}`}
                />
              )}
            </div>
          ))}
        </AcModalBody>

        <AcModalFooter>
          <AcButton onClick={closeModal} style={{ width: '100%' }}>
            <AcButtonContent text={t('GLOBAL.CLOSE')} />
          </AcButton>
        </AcModalFooter>
      </AcModal>
    </>
  );
};

export default memo(GuestPreferencesTemplate);
