import { Logger } from '@hkm/utils/logger';

import {
  EmptyMetrics,
  initLoggerWithDefaultMiddlewares,
  MetricsFactory,
} from '@ac/library-api';
import { Metrics } from '@ac/library-api/dist/services/metrics/metrics';
import { IEventBus } from '@ac/library-utils/dist/communication/event-bus';
import { DeveloperToolsRequiredByReactInfrastructure } from '@ac/react-infrastructure/dist/features/developerTools';

async function getMetrics(eventBus: IEventBus): Promise<Metrics> {
  const logger = await initLoggerWithDefaultMiddlewares('housekeepingMobile');

  if (!logger) {
    await Logger.error(
      'DEVELOPER TOOLS | Metrics are disabled in case of missing logger'
    );

    return new EmptyMetrics();
  }

  return await new MetricsFactory().createMetrics({
    logger,
    eventBus,
  });
}

export function getDeveloperTools(
  eventBus: IEventBus
): DeveloperToolsRequiredByReactInfrastructure {
  return {
    metrics: getMetrics(eventBus),
  };
}
