import {
  DateTimeFiltersConfigItem,
  DateTimeFiltersOptions,
} from '@hkm/components/TaskManagement/Dashboard/config/dateFiltersConfig';
import {
  DashboardRelationField,
  MultiselectFiltersConfigItem,
  MultiselectFiltersOptions,
} from '@hkm/components/TaskManagement/Dashboard/config/multiselectFiltersConfig';
import {
  DateTimeFilters,
  SearchValues,
} from '@hkm/components/TaskManagement/Dashboard/domain/enums';
import {
  Filters,
  UpdateFiltersAndSearchPayload,
} from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import { getFieldsExcludedByQuickFilter } from '@hkm/components/TaskManagement/Dashboard/helpers';
import { TaskQuickFilter } from '@hkm/components/TaskManagement/domain/enums/TaskQuickFilter';
import { TasksRelationsState } from '@hkm/components/TaskManagement/relations/domain/interfaces';
import DateTimeHelpers from '@hkm/components/TaskManagement/utils/dateTime';
import i18n from 'i18next';

export interface Chips {
  clearFieldCallback: () => void;
  fieldDisplayValue: string;
  fieldName: string;
}

export type ChipsConfig = Chips[];

export const getChipsConfig = (
  dateTimeFiltersOptions: DateTimeFiltersOptions,
  multiselectFiltersOptions: MultiselectFiltersOptions,
  filtersState: Filters,
  shortDateFormat: string,
  timeFormat: string,
  updateFiltersAndSearch?: (value: UpdateFiltersAndSearchPayload) => void,
  quickFilter?: TaskQuickFilter,
  searchValue?: string,
  relations?: TasksRelationsState
): ChipsConfig => {
  const filtersApplied = Object.keys(filtersState)?.filter(
    (filterField) => filtersState[filterField]
  );
  const dateFields = Object.values(DateTimeFilters);
  const fieldsExcludedByQuickFilter = quickFilter
    ? getFieldsExcludedByQuickFilter(quickFilter)
    : [];

  const chips = [];

  if (searchValue) {
    chips.push({
      clearFieldCallback: () =>
        updateFiltersAndSearch &&
        updateFiltersAndSearch({ search: { searchValue: undefined } }),
      fieldDisplayValue: `${i18n.t('GLOBAL.SEARCH')}: ${searchValue}`,
      fieldName: SearchValues.Search,
    });
  }

  filtersApplied?.map((fieldKey) => {
    const isExcludedByQuickFilter = fieldsExcludedByQuickFilter.some(
      (field) => field === fieldKey
    );

    if (!isExcludedByQuickFilter) {
      const isDateField = dateFields.some(
        (dateField) => dateField === fieldKey
      );
      const isTimeField =
        DateTimeFilters.TimeTo === fieldKey ||
        DateTimeFilters.TimeFrom === fieldKey;

      const valueForDateTimeField = isTimeField
        ? DateTimeHelpers.formatTimeForField(
            filtersState[fieldKey] ?? '',
            timeFormat
          )
        : DateTimeHelpers.formatDateForField(
            filtersState[fieldKey],
            shortDateFormat
          );

      const currentFieldConfig = isDateField
        ? dateTimeFiltersOptions[fieldKey]
        : multiselectFiltersOptions[fieldKey];

      const relatedField = isDateField
        ? (currentFieldConfig as DateTimeFiltersConfigItem).relatedField
        : undefined;

      const clearFieldCallback = () =>
        updateFiltersAndSearch &&
        updateFiltersAndSearch({
          filters: {
            [fieldKey]: undefined,
            ...(relatedField && { [relatedField]: undefined }),
          },
        });

      const fieldValue = isDateField
        ? filtersState[fieldKey]
        : getValueForMultiselectField(
            currentFieldConfig as MultiselectFiltersConfigItem,
            filtersState,
            relations
          );

      const fieldDisplayValue = isDateField
        ? `${currentFieldConfig?.label}: ${valueForDateTimeField}`
        : `${currentFieldConfig?.label}: ${fieldValue?.join(', ')}`;

      chips.push({
        clearFieldCallback: () => clearFieldCallback(),
        fieldDisplayValue,
        fieldName: fieldKey,
      });
    }
  });

  return chips;
};

const getValueForMultiselectField = (
  fieldInConfig: MultiselectFiltersConfigItem,
  filtersState?: Filters,
  relations?: TasksRelationsState
) => {
  const fieldSelectedValues = filtersState && filtersState[fieldInConfig.name];
  const fieldInRelations: DashboardRelationField[] | undefined =
    relations?.[fieldInConfig.name];

  const fieldValues = fieldSelectedValues?.reduce(
    (acc: string[], item: string) => {
      const valueInRelation: DashboardRelationField | undefined =
        fieldInRelations?.find(
          (relationItem: DashboardRelationField) =>
            relationItem[fieldInConfig.optionValueField] === item
        );

      return valueInRelation
        ? [...acc, valueInRelation[fieldInConfig.optionNameField]]
        : acc;
    },
    []
  );

  return fieldValues?.length ? fieldValues : undefined;
};
