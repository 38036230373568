import APP_CONSTANTS from '@hkm/constants/app.constants';
import { setDefaultApiConfiguration } from '@hkm/features/api/setDefaultApiConfiguration';
import getLoginUrl, { getRootUrl } from '@hkm/utils/moduleNavigator';
import { Deferred } from '@hkm/utils/promise/Deferred';

import { LoginService } from '@ac/library-utils/dist/services';
import { deserializeUrlSearchString } from '@ac/library-utils/dist/utils';

export const authenticate = async (): Promise<void> => {
  const deferred = new Deferred();
  const { returnUrl } = deserializeUrlSearchString(location.search);
  const loginUrl = getLoginUrl();

  if (window.location.hash) {
    if (window.frameElement) {
      window.parent.postMessage(
        `${LoginService.ACIFRAME_MESSAGE}&${window.location.hash}`,
        loginUrl
      );

      return;
    }
  }

  LoginService.overrideIdentityClientId(APP_CONSTANTS.IDENTITY_CLIENT_ID);
  LoginService.setRedirectUri(loginUrl);
  LoginService.setOnSuccessHandler((url?: string): void => {
    if (window.ACP?.businessContext?.context.property?.details.id) {
      setDefaultApiConfiguration({
        propertyId: window.ACP.businessContext.context.property.details.id,
      });
    }
    if (url) {
      if (url === getLoginUrl()) {
        history.replaceState({}, '', getRootUrl());
      } else {
        history.replaceState({}, '', url);
      }
    }
    deferred.resolve();
  });

  LoginService.handleModuleCallBack({
    moduleLoginUrl: loginUrl,
    returnUrl:
      !!returnUrl && returnUrl.trim !== '' && returnUrl.trim !== '/'
        ? returnUrl
        : window.location.href,
  });

  await deferred.promise;
};
