import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CountWithDescription from '@hkm/components/Attendant/SheetSummary/body/CountWithDesciption/CountWithDescription';
import SheetsSummaryAccordion from '@hkm/components/Attendant/SheetSummary/body/SheetSummaryAccordion/SheetsSummaryAccordion';
import { SheetSummaryGuestServiceStatus } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryGuestServiceStatus';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';

import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  FlexDirection,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

export interface GuestServiceStatusProps {
  guestServiceStatus: SheetSummaryGuestServiceStatus;
}

export const GuestServiceStatus: React.FC<GuestServiceStatusProps> = (
  props: GuestServiceStatusProps
) => {
  const { t } = useTranslation();
  const effectiveValues = useSelector(selectEffectiveValues);
  const isHousekeepingCleaningScheduleEnabled =
    effectiveValues?.enabledHousekeepingSchedule;

  const {
    doNotDisturb,
    serviceDeferred,
    serviceRefused,
    serviceRequested,
    servicePreferred,
    total,
    plannedCount,
  } = props.guestServiceStatus;

  const servicesTotalCount = useMemo(() => {
    return isHousekeepingCleaningScheduleEnabled ? plannedCount : total;
  }, [plannedCount, total, isHousekeepingCleaningScheduleEnabled]);

  const renderSubtitle = useMemo(
    () => (
      <AcText
        size={TextSize.H1}
        weight={TextWeight.Semibold}
        testSelector="guest-service-status-total"
      >
        {servicesTotalCount}
      </AcText>
    ),
    [servicesTotalCount]
  );

  const renderContent = useMemo(
    () => (
      <ac-flex direction={FlexDirection.column}>
        {effectiveValues?.guestServiceStatusDoNotDisturbed && (
          <CountWithDescription
            count={doNotDisturb}
            description={t(
              `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.DoNotDisturb}`
            )}
          />
        )}

        {effectiveValues?.guestServiceStatusServiceRefused && (
          <CountWithDescription
            count={serviceRefused}
            description={t(
              `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.ServiceRefused}`
            )}
          />
        )}

        {isHousekeepingCleaningScheduleEnabled &&
          effectiveValues?.guestServiceStatusServicePreferred && (
            <CountWithDescription
              count={servicePreferred}
              description={t(
                `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.ServicePreferred}`
              )}
            />
          )}

        {!isHousekeepingCleaningScheduleEnabled && (
          <>
            {effectiveValues?.guestServiceStatusServiceDeferred && (
              <CountWithDescription
                count={serviceDeferred}
                description={t(
                  `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.ServiceDeferred}`
                )}
              />
            )}

            {effectiveValues?.guestServiceStatusServiceRequested && (
              <CountWithDescription
                count={serviceRequested}
                description={t(
                  `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.ServiceRequested}`
                )}
              />
            )}
          </>
        )}
      </ac-flex>
    ),
    [
      effectiveValues?.guestServiceStatusDoNotDisturbed,
      effectiveValues?.guestServiceStatusServiceRefused,
      effectiveValues?.guestServiceStatusServicePreferred,
      effectiveValues?.guestServiceStatusServiceDeferred,
      effectiveValues?.guestServiceStatusServiceRequested,
      doNotDisturb,
      t,
      serviceRefused,
      isHousekeepingCleaningScheduleEnabled,
      servicePreferred,
      serviceDeferred,
      serviceRequested,
    ]
  );

  return (
    <SheetsSummaryAccordion
      icon={IconName.guestServiceStatus}
      title={t('GLOBAL.GUEST_SERVICE_STATUS.TITLE')}
      renderSubtitle={renderSubtitle}
      renderContent={renderContent}
      expandDisabled={servicesTotalCount === 0}
    />
  );
};

export default memo(GuestServiceStatus);
