export class Deferred<TResolve = unknown, TReject = void> {
  private fulfilled: boolean = false;
  private rejected: boolean = false;
  private resolved: boolean = false;

  public readonly promise: Promise<TResolve>;
  public reject: (_result?: TReject) => unknown = () => {
    /* empty */
  };
  public resolve: (_result?: TResolve) => unknown = () => {
    /* empty */
  };

  constructor(public readonly input?: TResolve) {
    this.promise = new Promise((resolve, reject) => {
      this.reject = (result: TReject) => {
        this.fulfilled = true;
        this.rejected = true;
        this.resolved = false;

        reject(result);
      };
      this.resolve = (result: TResolve) => {
        this.fulfilled = true;
        this.rejected = false;
        this.resolved = true;

        resolve(result);
      };
    });
  }

  get isFulfilled(): boolean {
    return this.fulfilled;
  }

  get isRejected(): boolean {
    return this.rejected;
  }

  get isResolved(): boolean {
    return this.resolved;
  }
}
