import {
  ImageBlob,
  ImageQueryParams,
  ImageResolutionType,
  ImagesResolutionTypeQueue,
} from '@hkm/components/App/imageQueue/domain/interfaces';
import { selectIndividualImages } from '@hkm/components/App/imageQueue/domain/selectors';
import { createImagesQueueFromBlobs } from '@hkm/components/App/imageQueue/utils/createImagesQueueFromBlobs';
import { filterCachedImagesIds } from '@hkm/components/App/imageQueue/utils/filterCachedImagesIds';
import { refreshImagesQueue } from '@hkm/components/App/imageQueue/utils/refreshImagesQueue';
import { refreshImageUrlMap } from '@hkm/components/App/imageQueue/utils/refreshImageUrlMap';
import { TakeableChannel } from '@redux-saga/core';
import {
  actionChannel,
  all,
  call,
  put,
  select,
  take,
} from '@redux-saga/core/effects';

import { LibraryApiResponse } from '@ac/library-api';
import { ImageApi } from '@ac/library-api/dist/api/v0/profiles/individual/index';

import * as actions from './actions';

function* fetchIndividualImages(imageQueryParams: ImageQueryParams) {
  try {
    const { imageIds, resolutionConfig } = imageQueryParams;
    const individualImages: ImageResolutionType = yield select(
      selectIndividualImages
    );
    const cachedImagesQueue: ImagesResolutionTypeQueue =
      individualImages[resolutionConfig.resolutionName];

    const uniqueImageIds = filterCachedImagesIds(imageIds, cachedImagesQueue);

    const getProfileImage = (
      id: string
    ): Promise<Blob | LibraryApiResponse<Blob>> =>
      ImageApi.getImage({
        pathParams: { profileId: id },
        queryParams: {
          height: resolutionConfig.height,
          width: resolutionConfig.width,
        },
      });

    const responses: ImageBlob[] = yield all(
      uniqueImageIds.map((imageId: string) =>
        getProfileImage(imageId)
          .then((data) => ({ data, imageId } as ImageBlob))
          .catch(() => ({ data: null, imageId } as ImageBlob))
      )
    );

    const newImageQueue: ImagesResolutionTypeQueue =
      createImagesQueueFromBlobs(responses);
    const freshImageQueue = cachedImagesQueue
      ? refreshImagesQueue(
          cachedImagesQueue.imageQueue,
          newImageQueue.imageQueue,
          resolutionConfig.limit
        )
      : newImageQueue.imageQueue;

    const freshImageUrlMap = cachedImagesQueue
      ? refreshImageUrlMap(
          freshImageQueue,
          cachedImagesQueue.imageUrlMap,
          newImageQueue.imageUrlMap
        )
      : newImageQueue.imageUrlMap;

    const imagesResolutionsTypeStore: ImageResolutionType = {
      [resolutionConfig.resolutionName]: {
        imageQueue: freshImageQueue,
        imageUrlMap: freshImageUrlMap,
      },
    };

    yield put(
      actions.fetchIndividualImages.success(imagesResolutionsTypeStore)
    );
  } catch (e) {
    yield put(actions.fetchIndividualImages.failure(e));
  }
}

export default function* () {
  const channel: TakeableChannel<unknown> = yield actionChannel(
    actions.fetchIndividualImages.trigger
  );
  while (true) {
    const { payload } = yield take(channel);
    yield call(fetchIndividualImages, payload);
  }
}
