import { ValidationStatuses } from '@hkm/shared/interfaces/validationStatuses';
import { UnknownObject } from '@hkm/types/shared/unknownObject';
import get_ from 'lodash/get';
import isFunction from 'lodash/isFunction';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import toPairs from 'lodash/toPairs';

import {
  ValidationExpression,
  ValidationResult,
  ValidationSchema,
} from '@ac/react-infrastructure';

export class Validator<P extends UnknownObject, T extends ValidationStatuses> {
  public schema: ValidationSchema<T> | ValidationExpression<P>;
  public isValid = true;
  public statusesTree: T = {} as T;
  public data: P;
  public ignoreFields: string[];

  constructor(schema: ValidationSchema<T>, ignoreFields: string[] = []) {
    this.schema = schema;
    this.ignoreFields = ignoreFields;
  }

  public validateField(data: P, path: string): ValidationResult[] {
    this.data = data;
    let innerData = data;
    let innerSchema = this.schema;

    for (const pathPart of path.split('.')) {
      innerSchema = innerSchema[pathPart];
      if (!innerSchema) {
        return [];
      }

      innerData = get_(innerData, pathPart);

      if (isFunction(innerSchema)) {
        return innerSchema(innerData, data) as unknown as ValidationResult[];
      }
    }

    return [];
  }

  public validate(data: P, path?: string | string[]): T {
    this.data = data;
    const schema = path ? pick(this.schema, path) : this.schema;

    this.isValid = !this._validate(data, schema, this.statusesTree);

    this.statusesTree = merge({}, this.statusesTree);

    return this.statusesTree;
  }

  private _validate(
    data: UnknownObject,
    schema: ValidationSchema<T> | ValidationExpression<P>,
    statusesTree: ValidationResult[] | ValidationStatuses
  ): boolean {
    let invalid = false;

    for (const [key, value] of toPairs(schema)) {
      if (this.ignoreFields.includes(key)) {
        continue;
      } else if (isFunction(value)) {
        const dataValue = data[key];
        const status = value(dataValue, this.data);
        invalid = !!(status && status.length) || invalid;
        statusesTree[key] = status;
      } else if (Array.isArray(value)) {
        const dataValue = data[key];
        statusesTree[key] = [];
        value.forEach((validator) => {
          const status = validator(dataValue, this.data);
          invalid = !!(status && status.length) || invalid;
          statusesTree[key].push(...status);
        });
      } else if (value instanceof Object) {
        statusesTree[key] = {};
        const statusesSlice = statusesTree[key];
        const dataSlice = get_(data, key);
        invalid = this._validate(dataSlice, value, statusesSlice) || invalid;
      }
    }

    return invalid;
  }
}
