import { Dictionary } from '@hkm/shared/dictionaries/dictionary';

export const MaintenanceAdvancedFiltersDictionaries: Set<Dictionary> =
  new Set<Dictionary>()
    .add(Dictionary.RoomStatus)
    .add(Dictionary.RoomLocation)
    .add(Dictionary.Floor)
    .add(Dictionary.RoomCategory)
    .add(Dictionary.RoomType)
    .add(Dictionary.OutOfOrderReason);
