import {
  FeatureRequirements,
  PermissionRequirement,
  PropertyContext,
  SettingRequirement,
} from '@ac/library-api';

export interface PostingFeatureActions {
  view: FeatureRequirements<void>;
}

export const getPostingFeature = (
  businessContext: PropertyContext | undefined
): PostingFeatureActions => {
  const permissions = businessContext?.permissions.processed;
  const settings = businessContext?.settings.processed;

  return {
    view: new FeatureRequirements<void>([
      new PermissionRequirement({
        condition:
          permissions?.housekeeping.reservationCharges
            .hasPermissionToPostViaMobileApp ?? false,
      }),
      new PermissionRequirement({
        condition: permissions?.housekeeping.rooms.hasPermissionToView ?? false,
      }),
      new SettingRequirement({
        condition: settings?.housekeeping.isMobilePostingEnabled ?? false,
      }),
    ]),
  };
};
