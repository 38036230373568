import { AttendantViewPermissions } from '@hkm/components/Attendant/shared/config/attendantViewPermissionsConfig';
import { HousekeepingViewPermissions } from '@hkm/components/Housekeeping/shared/config/housekeepingViewPermissionsConfig';
import { Permission } from '@hkm/shared/permissions/enum/Permission';
import { PermissionConjunction } from '@hkm/shared/permissions/enum/PermissionConjunction';
import { PermissionsConfig } from '@hkm/shared/permissions/types/permissionsConfig';

export const MobilePostingPermissions = [
  Permission.HousekeepingPostChargesUsingMobilePosting,
] as const;

export const HousekeepingPostingPermissions = [
  ...MobilePostingPermissions,
  ...HousekeepingViewPermissions,
] as const;

export const AttendantPostingPermissions = [
  ...MobilePostingPermissions,
  ...AttendantViewPermissions,
] as const;

export const HousekeepingPostingPermissionsConfig: PermissionsConfig = [
  HousekeepingPostingPermissions,
  PermissionConjunction.All,
];

export const AttendantPostingPermissionsConfig: PermissionsConfig = [
  AttendantPostingPermissions,
  PermissionConjunction.All,
];
