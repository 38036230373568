interface Input {
  regionProperty: { regionCode?: string } | undefined;
  effectiveProperty: { regionCode?: string; unitId?: string } | undefined;
}

export function shouldUserOperateInAnotherRegion({
  effectiveProperty,
  regionProperty,
}: Input): boolean {
  const effectiveRegion = effectiveProperty?.regionCode?.toLowerCase();
  const propertyRegion = regionProperty?.regionCode?.toLowerCase();

  return effectiveRegion !== propertyRegion && !!effectiveProperty?.unitId;
}
