/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { useTranslation } from 'react-i18next';
import { logout } from '@hkm/features/app/commands/logout/logout';
import { Module } from '@hkm/features/app/panels/menu/features/content/features/module/Module';

import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import {
  AcBody,
  AcHeader,
  AcLayout,
} from '@ac/mobile-components/dist/components/layout';
import { Color, IconName, Size } from '@ac/web-components';

export const AppContainerError = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <AcLayout className="off-canvas-menu">
      <AcHeader>
        <ac-icon icon={IconName.acProject} size={Size.xlg} />
        {t('MENU.WELCOME')}
      </AcHeader>

      <AcBody contentClassName="app-menu" noPadding={true}>
        <div className="top-wrapper">
          <div className="active-module-container">
            <div className="module-icon-wrapper">
              <Module icon={IconName.alert} isActive={true} />
            </div>
            <div className="active-module-content-wrapper">
              <div className="active-module-title">
                {t(
                  'INITIALIZATION_PROCESS.ERRORS.USER_IS_NOT_ASSIGNED_TO_ANY_PROPERTY'
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-wrapper">
          <div className="item-user-data">
            <div className="item-icon" />

            <div className="item-content">
              <div className="user-data" />

              <div className="item-logout">
                <AcButton
                  pattern={AcButtonPattern.Tertiary}
                  onClick={logout}
                  testSelector="navigation-button-logout"
                >
                  <ac-icon icon={IconName.power} color={Color.primaryDark} />
                </AcButton>
              </div>
            </div>
          </div>
        </div>
      </AcBody>
    </AcLayout>
  );
};
