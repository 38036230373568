/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import {
  MenuPanelInput,
  MenuPanelPresenterContext,
} from '@hkm/features/app/panels/menu/presenter/context';

import {
  PropsWithChildren,
  usePanelPresenterStore,
} from '@ac/react-infrastructure';

export const MenuPanelPresenterContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = usePanelPresenterStore<MenuPanelInput, void>({
    configuration: {
      closeAnimationTimeInMs: 300,
    },
  });

  return (
    <MenuPanelPresenterContext.Provider value={store}>
      {props.children}
    </MenuPanelPresenterContext.Provider>
  );
};
