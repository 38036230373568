import { BusinessContextUnitType, WebSocketFactory } from '@ac/library-api';
import { IPropertyWebSocketClients } from '@ac/library-api/dist/services/webSockets/types';

export function getPropertyWebsocket(
  propertyId: string | undefined
): IPropertyWebSocketClients | undefined {
  if (!propertyId) {
    return undefined;
  }

  return new WebSocketFactory().create({
    unitType: BusinessContextUnitType.Property,
    unitId: propertyId,
  });
}
