import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import { getDateTimeFiltersConfig } from '@hkm/components/TaskManagement/Dashboard/config/dateFiltersConfig';
import { getMultiselectFiltersConfig } from '@hkm/components/TaskManagement/Dashboard/config/multiselectFiltersConfig';
import DashboardFiltersBody from '@hkm/components/TaskManagement/Dashboard/DashboardFilters/DashboardFiltersBody/DashboardFiltersBody';
import DashboardFiltersFooter from '@hkm/components/TaskManagement/Dashboard/DashboardFilters/DashboardFiltersFooter/DashboardFiltersFooter';
import { Filters } from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import {
  getDashboardFilters,
  getDashboardSearchString,
} from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import { useTaskDashboardManager } from '@hkm/components/TaskManagement/Dashboard/useTaskDashboardManager';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import APP_ROUTES from '@hkm/constants/routing.constants';

const DashboardFilters: FC = () => {
  const { initialize, updateFiltersAndSearch } = useTaskDashboardManager();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const appliedSearchValue = useSelector(getDashboardSearchString);
  const appliedFilters = useSelector(getDashboardFilters);
  const relations = useSelector(getRelationsState);

  const [searchValue, setSearchValue] = useState<string | undefined>(
    appliedSearchValue
  );
  const [filtersState, setFiltersState] = useState<Filters>(appliedFilters);

  const onFiltersApply = useCallback(() => {
    updateFiltersAndSearch({ search: { searchValue }, filters: filtersState });
    navigate(APP_ROUTES.TASK_MANAGEMENT.DASHBOARD);
  }, [searchValue, filtersState, navigate, updateFiltersAndSearch]);

  const updateFiltersState = useCallback((valueToSet: Partial<Filters>) => {
    setFiltersState((prevState) => ({ ...prevState, ...valueToSet }));
  }, []);

  const dateTimeFiltersConfig = useMemo(
    () => getDateTimeFiltersConfig(updateFiltersState, filtersState),
    [filtersState, updateFiltersState]
  );
  const multiselectFiltersConfig = useMemo(
    () =>
      getMultiselectFiltersConfig(updateFiltersState, filtersState, relations),
    [filtersState, relations, updateFiltersState]
  );

  useEffect(() => {
    setFiltersState(appliedFilters);
  }, [appliedFilters]);

  useEffect(() => {
    if (!relations.areRelationsLoaded) {
      initialize();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ConnectedHeader close={APP_ROUTES.TASK_MANAGEMENT.DASHBOARD}>
        {t('ADVANCED_FILTERS.TITLE')}
      </ConnectedHeader>
      <DashboardFiltersBody
        filtersState={filtersState}
        dateTimeFiltersConfig={dateTimeFiltersConfig}
        multiselectFiltersConfig={multiselectFiltersConfig}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
      />
      <DashboardFiltersFooter onFiltersApply={onFiltersApply} />
    </>
  );
};

export default DashboardFilters;
