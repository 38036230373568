import React, { FC, memo, useCallback, useState } from 'react';
import BookingNotesAccordionItem from '@hkm/components/shared/Templates/Reservation/BookingNotesAccordion/BookingNotesAccordionItem';

import { BookingNote } from '@ac/library-api';
import { AcAccordion } from '@ac/mobile-components/dist/components/accordion';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { TextSize, TextWeight } from '@ac/mobile-components/dist/enums';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';

import './BookingNotesAccordion.css';

interface NotesAccordionProps extends Childless {
  title: string;
  bookingNotes: BookingNote[];
}

const BookingNotesAccordion: FC<NotesAccordionProps> = (
  props: NotesAccordionProps
) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const onExpandedChange = useCallback((expandedList: string[]) => {
    setExpandedItems(expandedList);
  }, []);

  return (
    <div className="notes-accordion">
      <AcText size={TextSize.Main2} weight={TextWeight.Semibold}>
        {props.title}
      </AcText>
      <AcAccordion className="ac-spacing-top-sm" onChange={onExpandedChange}>
        {props.bookingNotes.map((bookingNote: BookingNote) => (
          <BookingNotesAccordionItem
            id={bookingNote.noteId}
            key={bookingNote.noteId}
            bookingNote={bookingNote}
            isExpanded={expandedItems.includes(bookingNote.noteId)}
          />
        ))}
      </AcAccordion>
    </div>
  );
};

export default memo(BookingNotesAccordion);
