import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as appActions from '@hkm/components/App/domain/actions';
import { getAllTaskStatuses } from '@hkm/components/TaskManagement/apis/configuration';
import {
  changeTaskAssignee,
  changeTaskStatus,
  getTaskById,
} from '@hkm/components/TaskManagement/apis/tasks';
import {
  fetchLinkObjectDependentSettings,
  updateFiltersAndSearch as updateFiltersAndSearchAction,
  updatePaginationPage as updatePaginationPageAction,
  updatePagingAndFilters as updatePagingAndFiltersAction,
  updateQuickFilter as updateQuickFilterAction,
  updateSort,
} from '@hkm/components/TaskManagement/Dashboard/domain/actions';
import * as dashboardActions from '@hkm/components/TaskManagement/Dashboard/domain/actions';
import { DashboardSort } from '@hkm/components/TaskManagement/Dashboard/domain/enums';
import {
  Filters,
  UpdateFiltersAndSearchPayload,
} from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import {
  getDashboardList,
  getPropertyTimezone,
} from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import { MappedConfigurationUser } from '@hkm/components/TaskManagement/Dashboard/mappers';
import { TaskQuickFilter } from '@hkm/components/TaskManagement/domain/enums/TaskQuickFilter';
import { TaskDashboardItemWithFlag } from '@hkm/components/TaskManagement/domain/interfaces/taskDashboardItemWithFlag';
import { getQuickFilter } from '@hkm/components/TaskManagement/utils/quickFilter';
import { getStore } from '@hkm/store/configureStore';
import { getLocalizedString } from '@hkm/utils/getLocalizedString';

import {
  buildFIQLFilter,
  DashboardItemTaskStatus,
  FIQLOperators,
  RawTask,
  TaskStatusRole,
  TaskUser,
} from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';

export const useTaskDashboardManager = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const propertyTimeZone = useSelector(getPropertyTimezone);
  const dashboardList = useSelector(getDashboardList);

  const initialize = () => {
    dispatch(fetchLinkObjectDependentSettings.trigger());
  };

  const updatePaginationPage = (pageNumber: number): void => {
    dispatch(updatePaginationPageAction(pageNumber));
  };

  const updateFiltersAndSearch = (
    filtersAndSearch: UpdateFiltersAndSearchPayload,
    previousFiltersIncluded = true
  ): void => {
    const currentFiltersApplied = getStore().tasks.dashboard.selectedFilters;
    const currentSearchStringAppiled = getStore().tasks.dashboard.searchValue;
    const { search, filters } = filtersAndSearch;

    dispatch(
      updateFiltersAndSearchAction({
        search: {
          ...(previousFiltersIncluded && {
            searchValue: currentSearchStringAppiled,
          }),
          ...search,
        },
        filters: {
          ...(previousFiltersIncluded && { ...currentFiltersApplied }),
          ...filters,
        },
      })
    );
  };

  const updatePagingAndFilters = (
    filters: Partial<Filters>,
    pageNumber: number,
    pageSize?: number
  ): void => {
    dispatch(updatePagingAndFiltersAction({ filters, pageNumber, pageSize }));
  };

  const updateDashboardSort = (sortValue: DashboardSort): void => {
    dispatch(updateSort(sortValue));
  };

  const updateQuickFilter = (
    previousQuickFilter?: TaskQuickFilter,
    quickFilter?: TaskQuickFilter,
    currentUser?: MappedConfigurationUser
  ): void => {
    if (!currentUser) {
      return;
    }
    const currentQuickFilter = getQuickFilter(
      currentUser,
      previousQuickFilter,
      quickFilter
    );

    updatePagingAndFilters(currentQuickFilter, 1);
    dispatch(updateQuickFilterAction(quickFilter ?? null));
  };

  const saveTaskStatus = async (
    taskId: string,
    statusId: string,
    comment?: string
  ) => {
    const taskToChange = (await getTaskById(taskId)) as RawTask;
    try {
      await changeTaskStatus(taskId, statusId, taskToChange.version, comment);
      // NOTE: Fetch of TaskStatus is required to update dashboard without waiting for backend data propagation
      const taskStatuses = await getAllTaskStatuses({
        filter: buildFIQLFilter('id', FIQLOperators.equal, statusId),
      });
      const changedTaskStatus = taskStatuses[0];
      updateDashboard(taskId, 'taskStatus', {
        code: changedTaskStatus.code,
        description: getLocalizedString(changedTaskStatus.description),
        id: changedTaskStatus.id,
        role: changedTaskStatus.role as TaskStatusRole,
        color: changedTaskStatus.color,
      });

      dispatch(
        appActions.displaySuccess(
          t('TASK_MANAGEMENT.DASHBOARD.MESSAGES.STATUS_CHANGED')
        )
      );
    } catch (e) {
      dispatch(appActions.displayExtractedError(e));
    }
  };

  const assignTaskToUser = async (taskId: string, user?: TaskUser) => {
    try {
      const loggedInUser = LoginService.authData();
      const selectedUser = user || {
        id: loggedInUser?.userId,
        firstName: '',
        lastName: '',
        userName: '',
      };
      const taskToChange = (await getTaskById(taskId)) as RawTask;
      await changeTaskAssignee(taskId, taskToChange.version, selectedUser.id);
      updateDashboard(taskId, 'assignedUser', selectedUser as TaskUser);
      dispatch(
        appActions.displaySuccess(
          t('TASK_MANAGEMENT.DASHBOARD.MESSAGES.TASK_ASSIGNED')
        )
      );
    } catch (e) {
      dispatch(appActions.displayExtractedError(e));
    }
  };

  const unassignTask = async (taskId: string) => {
    try {
      const taskToChange = (await getTaskById(taskId)) as RawTask;
      await changeTaskAssignee(taskId, taskToChange.version, undefined);
      setTimeout(
        () => dispatch(dashboardActions.fetchDashboard.trigger()),
        1000
      );
      dispatch(
        appActions.displaySuccess(
          t('TASK_MANAGEMENT.DASHBOARD.MESSAGES.TASK_UNASSIGNED')
        )
      );
    } catch (e) {
      dispatch(appActions.displayExtractedError(e));
    }
  };

  const updateDashboard = (
    taskId: string,
    key: keyof TaskDashboardItemWithFlag,
    value?: string | DashboardItemTaskStatus | TaskUser
  ) => {
    const updatedTasks = dashboardList.results.map((task) => {
      if (task.id === taskId) {
        return {
          ...task,
          [key]: value,
        };
      }

      return task;
    });
    dispatch(
      dashboardActions.fetchDashboard.success({
        list: {
          results: updatedTasks,
          paging: dashboardList.paging,
        },
        propertyTimeZone: propertyTimeZone as string,
      })
    );
  };

  return {
    initialize,
    updatePaginationPage,
    updateFiltersAndSearch,
    updateDashboardSort,
    updateQuickFilter,
    saveTaskStatus,
    assignTaskToUser,
    unassignTask,
  };
};
