import { getLibraryApiHeaders } from '@hkm/features/api/getLibraryApiHeaders';

import { Config, ConfigBuilder } from '@ac/library-api';

interface Props {
  skipCache?: boolean;
  headers?: {
    version?: string | number;
    correlationId?: string;
  };
}

type Output = Config & {
  skipCache?: boolean;
};

export function getLibraryApiCustomConfig(props?: Props): Output {
  const headers = getLibraryApiHeaders(props?.headers ?? {});

  return {
    ...new ConfigBuilder().setHeaders(headers).getConfig(),
    skipCache: props?.skipCache,
  };
}
