import { AttachmentMangeAccessLevel } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/enum/attachmentMangeAccessLevel';
import { MaintenanceAttachmentData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/maintenanceAttachmentData';

export function getAttachmentManageAccess(
  data: MaintenanceAttachmentData,
  accessLevel: AttachmentMangeAccessLevel,
  customerId?: string
): boolean {
  switch (accessLevel) {
    case AttachmentMangeAccessLevel.All:
      return true;
    case AttachmentMangeAccessLevel.Owner:
      return customerId === data.metadata?.modifierId;
    default:
      return false;
  }
}
