import { GuestCount } from '@hkm/shared/interfaces/guestCount';
import { activeReservationStates } from '@hkm/shared/reservations/activeReservationStatuses';
import {
  RoomOccupancyFactoryData,
  RoomOccupancyFactoryOccupancyDetails,
} from '@hkm/shared/roomOccupancy/factory/roomOccupancyFactoryData';
import { RoomOccupancy } from '@hkm/shared/roomOccupancy/roomOccupancy';
import { dayjs } from '@hkm/utils/dayjs-extended';

import { FrontDeskStatus, HousekeepingStatus } from '@ac/library-api';

export function getRoomOccupancy(
  room: RoomOccupancyFactoryData,
  businessDate: string
): RoomOccupancy {
  const isRoomOccupied =
    room.housekeepingStatus === HousekeepingStatus.Occupied &&
    room.frontdeskStatus === FrontDeskStatus.Occupied;
  const totalGuestCount: GuestCount = room.currentReservations
    .filter((res) => activeReservationStates.has(res.status))
    .map((res) => getNearestGuestCount(res.occupancyDetails, businessDate))
    .reduce(
      (totals, current) => ({
        adultCount: (totals.adultCount ?? 0) + (current.adultCount ?? 0),
        childCount: (totals.childCount ?? 0) + (current.childCount ?? 0),
      }),
      { adultCount: 0, childCount: 0 }
    );

  const canReport = isRoomOccupied;
  const hasReport = canReport && !!room.occupancyDiscrepancy;
  const totalAdults = totalGuestCount.adultCount;
  const totalChildren = totalGuestCount.childCount;

  const reportedAdults = room.occupancyDiscrepancy?.adultCount;
  const reportedChildren = room.occupancyDiscrepancy?.childCount;

  const hasAdultsDiscrepancy = hasReport && totalAdults !== reportedAdults;
  const hasChildrenDiscrepancy =
    hasReport && totalChildren !== reportedChildren;
  const hasDiscrepancy = hasAdultsDiscrepancy || hasChildrenDiscrepancy;

  return {
    totalAdults,
    totalChildren,
    reportedAdults,
    reportedChildren,
    canReport,
    hasReport,
    hasDiscrepancy,
    hasAdultsDiscrepancy,
    hasChildrenDiscrepancy,
  };
}

export function getNearestGuestCount(
  guestCounts: RoomOccupancyFactoryOccupancyDetails[],
  businessDate: string
): GuestCount {
  const businessMoment = dayjs(businessDate);

  return (
    guestCounts.concat().sort((a, b) => {
      const aDiff = businessMoment.diff(a.date, 'day');
      const bDiff = businessMoment.diff(b.date, 'day');

      return aDiff > bDiff ? 1 : aDiff < bDiff ? -1 : 0;
    })[0] || { childCount: 0, adultCount: 0 }
  );
}
