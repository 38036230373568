import * as appActions from '@hkm/components/App/domain/actions';
import * as notificationActions from '@hkm/components/App/notificationConsumer/domain/actions';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { HousekeepingStatusChangeActions } from '@hkm/shared/domain/housekeepingStatusChange/housekeepingStatusChangeActions';
import { HousekeepingStatusChangeRequest } from '@hkm/shared/domain/housekeepingStatusChange/housekeepingStatusChangeRequest';
import { SourceApiType } from '@hkm/shared/domain/sourceApiType';
import { getCustomConfig } from '@hkm/utils/getCustomConfig';
import { put, takeLatest } from '@redux-saga/core/effects';
import i18n from 'i18next';

import {
  HousekeepingRoomsApi,
  MobileAttendantApi,
} from '@ac/library-api/dist/api/v0/housekeeping';
import { Action } from '@ac/library-utils/dist/declarations';

export function createHousekeepingHkStatusChangeSaga(
  actions: HousekeepingStatusChangeActions
) {
  return createHousekeepingStatusChangeSaga(
    actions,
    SourceApiType.HousekeepingRoomsApi
  );
}

export function createAttendantHkStatusChangeSaga(
  actions: HousekeepingStatusChangeActions
) {
  return createHousekeepingStatusChangeSaga(
    actions,
    SourceApiType.MobileAttendantApi
  );
}

function createHousekeepingStatusChangeSaga(
  actions: HousekeepingStatusChangeActions,
  sourceApiType: SourceApiType
) {
  function housekeepingChangeStatus(
    action: Action<HousekeepingStatusChangeRequest>
  ) {
    return HousekeepingRoomsApi.postChangeHousekeepingStatus({
      pathParams: { roomNumber: action.payload.roomNumber },
      data: {
        statusCode: action.payload.housekeepingStatus,
      },
      customConfig: getCustomConfig({
        version: action.payload.housekeepingRoomVersion,
      }),
    });
  }

  function attendantChangeStatus(
    action: Action<HousekeepingStatusChangeRequest>
  ) {
    return MobileAttendantApi.postChangeHousekeepingStatus({
      pathParams: { roomId: action.payload.roomId },
      data: {
        statusCode: action.payload.housekeepingStatus,
      },
      customConfig: getCustomConfig({
        version: action.payload.housekeepingRoomVersion,
      }),
    });
  }

  function* changeStatus(action: Action<HousekeepingStatusChangeRequest>) {
    try {
      yield sourceApiType === SourceApiType.MobileAttendantApi
        ? attendantChangeStatus(action)
        : housekeepingChangeStatus(action);

      yield put(
        actions.changeHousekeepingStatus.success(action.payload.roomId)
      );
      yield put(
        notificationActions.expectNotification(
          SignalREventType.HousekeepingRoomChanged
        )
      );
      yield put(
        appActions.displaySuccess(i18n.t('HOUSEKEEPING_STATUS_CHANGE.SUCCESS'))
      );
    } catch (e) {
      yield put(appActions.displayExtractedError(e));
      yield put(actions.changeHousekeepingStatus.failure(e));
    }
  }

  return function* () {
    yield takeLatest(actions.changeHousekeepingStatus.trigger, changeStatus);
  };
}
