import { TransactionCodesFiltersData } from '@hkm/components/Posting/PostingView/filters/TransactionCodesFiltersData';
import { createCombinedApiFilters } from '@hkm/shared/helpers/apiFilter/apiCombinedFiltersFactory';
import {
  ApiFiltersFactoryConfig,
  createApiFilters,
} from '@hkm/shared/helpers/apiFilter/apiFiltersFactory';

import { FIQLOperators, TransactionCodeReadModel } from '@ac/library-api';

const filterMap = new Map<
  keyof TransactionCodesFiltersData,
  ApiFiltersFactoryConfig<TransactionCodeReadModel>
>()
  .set('allowManualPosting', {
    field: 'allowManualPosting',
  })
  .set('defaultPostingValue', {
    field: 'defaultPostingValue',
    customOperator: FIQLOperators.greaterThenOrEqual,
  })
  .set('isActive', {
    field: 'isActive',
  })
  .set('isNonRevenue', {
    field: 'isNonRevenue',
  })
  .set('isPostingAllowed', {
    field: 'isPostingAllowed',
  });

export function createTransactionCodesApiFilter(
  filters: TransactionCodesFiltersData
): string | undefined {
  return createCombinedApiFilters(
    ...createApiFilters<TransactionCodesFiltersData, TransactionCodeReadModel>(
      filters,
      filterMap
    )
  );
}
