import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router';
import { FinalizeStepProcessType } from '@hkm/components/Attendant/RoomDetails/body/FinalizeTaskProcess/FinalizeStepProcessType';
import { useCreateProcessingSteps } from '@hkm/components/Attendant/RoomDetails/body/FinalizeTaskProcess/hooks/useCreateProcessingSteps';
import ChargePostingConfirmation from '@hkm/components/Attendant/RoomDetails/body/FinalizeTaskProcess/steps/ChargePostingConfirmation';
import OccupancyConfirmation from '@hkm/components/Attendant/RoomDetails/body/FinalizeTaskProcess/steps/OccupancyConfirmation';
import { AttendantRoomUpdateData } from '@hkm/components/Attendant/shared/domain/interfaces/assignmentTask';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { GuestCount } from '@hkm/shared/interfaces/guestCount';
import { PostChargeLocationState } from '@hkm/shared/interfaces/locationStates';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';

import { HousekeepingStatus, RoomStatus } from '@ac/library-api';
import { AcModal } from '@ac/mobile-components/dist/components/modal';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

export interface FinalizeTaskProcessProps extends Childless {
  room: AttendantRoom;
  initializedProcess?: HousekeepingStatus;

  resetProcessHandler(): void;
  finalizeProcessHandler(data: AttendantRoomUpdateData): void;
}

const FinalizeTaskProcess: FC<FinalizeTaskProcessProps> = (
  props: FinalizeTaskProcessProps
) => {
  const prefix = 'finalize-task-process';
  const {
    room,
    initializedProcess,
    finalizeProcessHandler,
    resetProcessHandler,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const processingSteps: FinalizeStepProcessType[] =
    useCreateProcessingSteps(room);
  const [currentStepProcess, setCurrentStepProcess] = useState<number>(0);
  const [occupancyDiscrepancy, setOccupancyDiscrepancy] =
    useState<GuestCount>();

  const getCurrentStep = () => processingSteps[currentStepProcess];
  const nextStepProcess = () => setCurrentStepProcess(currentStepProcess + 1);
  const resetProcess = () => setCurrentStepProcess(0);

  const isOpen = ![
    FinalizeStepProcessType.Finalized,
    FinalizeStepProcessType.NotInitialized,
  ].includes(getCurrentStep());

  const closeModal = useCallback(() => {
    resetProcess();
    resetProcessHandler();
  }, [resetProcessHandler]);

  const roomDataToUpdate = useMemo(
    () => ({
      newRoomStatus: RoomStatus.CL,
      newOccupancy: occupancyDiscrepancy,
      newHousekeepingStatus: initializedProcess,
    }),
    [occupancyDiscrepancy, initializedProcess]
  );

  // init process
  useEffect(() => {
    if (initializedProcess) {
      nextStepProcess();
    }

    // eslint-disable-next-line
  }, [initializedProcess]);

  // finalize task process
  useEffect(() => {
    if (getCurrentStep() === FinalizeStepProcessType.Finalized) {
      finalizeProcessHandler(roomDataToUpdate);
      resetProcess();
    }

    // eslint-disable-next-line
  }, [currentStepProcess]);

  // if state has data it means that we just back from posting page and we need to finalize task
  useEffect(() => {
    const locationState = (location.state || {}) as PostChargeLocationState;
    if (locationState.data) {
      /**
       * WARNING: we don't need to clear location state because we redirect user straight after task is complete
       * so it will be cleared automatically BUT if you want to clear it manually by any reason then remember that
       * there is a problem with refreshing sheet (saga or notification listener issue)
       * after using history.replace in order to clear location state
       */
      finalizeProcessHandler(locationState.data);
      resetProcess();
    }

    // eslint-disable-next-line
  }, []);

  const occupancyDiscrepancyConfirm = (newOccupancy?: GuestCount) => {
    setOccupancyDiscrepancy(newOccupancy);
    nextStepProcess();
  };

  const chargePostingConfirm = () => {
    navigate;
    navigate(
      generatePath(APP_ROUTES.MOBILE_POSTING.ATTENDANT.RESERVATIONS, {
        roomId: props.room.id,
      }),
      {
        state: {
          data: roomDataToUpdate,
        } as PostChargeLocationState,
      }
    );
  };

  return (
    <AcModal isOpen={isOpen} onClose={closeModal}>
      {getCurrentStep() === FinalizeStepProcessType.OccupancyConfirmation && (
        <OccupancyConfirmation
          occupancy={props.room.roomOccupancy}
          onConfirm={occupancyDiscrepancyConfirm}
          testSelector={formatTestSelector(prefix, 'occupancy-confirmation')}
        />
      )}

      {getCurrentStep() === FinalizeStepProcessType.ChargePosting && (
        <ChargePostingConfirmation
          onDecline={nextStepProcess}
          onConfirm={chargePostingConfirm}
          testSelector={formatTestSelector(
            prefix,
            'charge-posting-confirmation'
          )}
        />
      )}
    </AcModal>
  );
};

export default memo(FinalizeTaskProcess);
