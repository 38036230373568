import { useEffect } from 'react';
import { useMobileAppInitialization } from '@hkm/features/app/commands/initialization/useMobileAppInitialization';

export function useInitAuthorization() {
  const { setUp } = useMobileAppInitialization();

  useEffect(() => {
    void setUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
