import * as actions from '@hkm/components/App/imageQueue/domain/actions';
import {
  ImageResolutionType,
  ImagesStore,
} from '@hkm/components/App/imageQueue/domain/interfaces';
import { ImageSource } from '@hkm/components/App/imageQueue/enum/imageSource';
import { ReducerMap } from 'redux-actions';

import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: ImagesStore = {
  [ImageSource.IndividualApi]: {},
};

const reducerMap: ReducerMap<ImagesStore, unknown> = {
  [actions.fetchIndividualImages.success]: (
    state,
    action: Action<ImageResolutionType>
  ) => {
    return {
      ...state,
      [ImageSource.IndividualApi]: {
        ...state[ImageSource.IndividualApi],
        ...action.payload,
      },
    };
  },
};

export default handleActions(reducerMap, initialState);
