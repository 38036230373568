import {
  TasksCompanyProfile,
  TasksIndividualProfile,
  TasksTravelAgentProfile,
} from '@hkm/components/TaskManagement/models/profiles';
import { getProfileName } from '@hkm/components/TaskManagement/utils/getProfileName';

import {
  buildFIQLFilter,
  CodeValue,
  CompanyDashboardDetailsDto,
  FIQLOperators,
  IndividualDashboardDto,
  IndividualPreferenceDto,
  Nullable,
  ProfileRole,
  ProfilesDashboardAddressDto,
  ProfilesDashboardCommunicationChannelDto,
  TitleOrders,
  TravelAgentDashboardDetailsDto,
} from '@ac/library-api';
import {
  CompanyApi,
  IndividualApi,
  TravelAgentsApi,
} from '@ac/library-api/dist/api/v0/profiles';

export const getIndividualProfile = async (
  nameSetting: TitleOrders,
  id: string
): Promise<TasksIndividualProfile[]> => {
  const profile = await IndividualApi.getById({
    pathParams: { profileId: id },
  });
  const profiles = await IndividualApi.getDashboard({
    queryParams: { filter: buildFIQLFilter('id', FIQLOperators.equal, id) },
  });

  return [
    mapIndividualProfile(
      profiles.data.results?.[0],
      nameSetting,
      profile.data.preferences
    ),
  ];
};

export const getCompanyProfile = async (
  nameSetting: TitleOrders,
  id: string
): Promise<TasksCompanyProfile[]> => {
  const profiles = await CompanyApi.getDashboard({
    queryParams: { filter: buildFIQLFilter('id', FIQLOperators.equal, id) },
  });

  return [mapCompanyProfile(profiles.data.results?.[0], nameSetting)];
};

export const getTravelAgentProfile = async (
  nameSetting: TitleOrders,
  id: string
): Promise<TasksTravelAgentProfile[]> => {
  const profiles = await TravelAgentsApi.getDashboard({
    queryParams: { filter: buildFIQLFilter('id', FIQLOperators.equal, id) },
  });

  return [mapTravelAgentProfile(profiles.data.results?.[0], nameSetting)];
};

export const mapIndividualProfile = (
  profile: IndividualDashboardDto,
  nameSetting: TitleOrders,
  preferences?: Nullable<IndividualPreferenceDto[]>
): TasksIndividualProfile => {
  const {
    id,
    details,
    typeCode,
    addresses,
    memberships,
    communicationChannels,
  } = profile;

  const normalizedProfile: TasksIndividualProfile = {
    profileRole: ProfileRole.Individual,
    id,
    details,
    typeCode,
    addresses,
    memberships,
    communicationChannels,
    preferences: preferences?.map((preference) => ({
      code: preference.attributeCode,
      id: preference.id,
    })),
    displayName: '',
  };

  return {
    ...normalizedProfile,
    displayName: getDisplayNameForProfile(normalizedProfile, nameSetting),
  };
};

export const mapCompanyProfile = (
  {
    id,
    details,
    typeCode,
    addresses,
    communicationChannels,
  }: {
    id: string;
    typeCode: CodeValue;
    details: CompanyDashboardDetailsDto;
    addresses?: Nullable<ProfilesDashboardAddressDto[]>;
    communicationChannels?: Nullable<
      ProfilesDashboardCommunicationChannelDto[]
    >;
  },
  nameSetting: TitleOrders
): TasksCompanyProfile => {
  const normalizedProfile: TasksCompanyProfile = {
    profileRole: ProfileRole.Company,
    id,
    details,
    typeCode,
    addresses,
    communicationChannels,
    displayName: '',
  };

  return {
    ...normalizedProfile,
    displayName: getDisplayNameForProfile(normalizedProfile, nameSetting),
  };
};

export const mapTravelAgentProfile = (
  {
    id,
    details,
    typeCode,
    addresses,
    communicationChannels,
  }: {
    id: string;
    typeCode: CodeValue;
    details: TravelAgentDashboardDetailsDto;
    addresses?: Nullable<ProfilesDashboardAddressDto[]>;
    communicationChannels?: Nullable<
      ProfilesDashboardCommunicationChannelDto[]
    >;
  },
  nameSetting: TitleOrders
): TasksTravelAgentProfile => {
  const normalizedProfile: TasksTravelAgentProfile = {
    profileRole: ProfileRole.TravelAgent,
    id,
    details,
    typeCode,
    addresses,
    communicationChannels,
    displayName: '',
  };

  return {
    ...normalizedProfile,
    displayName: getDisplayNameForProfile(normalizedProfile, nameSetting),
  };
};

export function getDisplayNameForProfile(
  profile:
    | TasksIndividualProfile
    | TasksCompanyProfile
    | TasksTravelAgentProfile,
  nameSetting: TitleOrders
): string {
  const isIndividual = profile.profileRole === ProfileRole.Individual;
  const isCompany = profile.profileRole === ProfileRole.Company;

  if (isIndividual) {
    return getProfileName({
      firstName: profile.details.firstName,
      middleName: profile.details.middleName ?? '',
      lastName: profile.details.lastName,
      title: profile.details.titleCode?.description,
      suffix: profile.details.suffix?.description,
      nameSetting,
    });
  } else if (isCompany) {
    return profile.details.fullName;
  }

  return profile.details.fullName;
}
