import { useCallback } from 'react';
import { isMicroFrontend } from '@hkm/features/microFrontends/commands/isMicroFrontend';
import { useMicroFrontendsReactLoader } from '@hkm/features/microFrontends/commands/useMicroFrontendsReactLoader';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

interface Output {
  register(): void;
}

export function useMicroFrontendsRegistration(): Output {
  const { load } = useMicroFrontendsReactLoader();

  const register = useCallback(() => {
    registerApplication(
      'frontend-housekeeping',
      () => load(acConfig.frontendUrls.housekeeping),
      (location: Location) =>
        isMicroFrontend({
          pathname: location.pathname,
          expectedUrl: acConfig.newFrontendUrls.housekeeping,
        })
    );

    registerApplication(
      'frontend-task-management',
      () => load(acConfig.frontendUrls.taskManagement),
      (location: Location) =>
        isMicroFrontend({
          pathname: location.pathname,
          expectedUrl: acConfig.newFrontendUrls.taskManagement,
        })
    );
  }, [load]);

  return {
    register,
  };
}
