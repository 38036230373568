import APP_CONSTANTS from '@hkm/constants/app.constants';

import {
  BaseApi,
  ConfigBuilder,
  LibraryApiRequestHeaders,
} from '@ac/library-api';
import { ModuleNames } from '@ac/library-utils/dist/declarations';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';

interface Options {
  propertyId?: string;
  headers?: LibraryApiRequestHeaders;
  noCacheSkip?: boolean;
}

export function setDefaultApiConfiguration(options?: Options) {
  const { propertyId, headers, noCacheSkip } = options ?? {};
  const combinedHeaders = headers ? { ...headers } : {};

  combinedHeaders['Accept-Language'] =
    SessionService.getLanguage()?.toUpperCase() || APP_CONSTANTS.DATA_LANGUAGE;

  const authData = LoginService.authData();

  if (authData) {
    combinedHeaders.Authorization = `Bearer ${authData.token}`;
  }

  if (propertyId) {
    combinedHeaders['AC-Property-ID'] = propertyId;
  }

  BaseApi.defaultConfig = new ConfigBuilder()
    .setHeaders(combinedHeaders)
    .setSkipCache(!noCacheSkip)
    .getConfig();
  BaseApi.moduleName = ModuleNames.HOUSEKEEPING_MOBILE;
  BaseApi.clearAllCache();
}
