import React, { FC, memo, useCallback } from 'react';
import { useMenuPanelPresenterContext } from '@hkm/features/app/panels/menu/presenter/context';

import { AcIconButton } from '@ac/mobile-components/dist/components/icon-button';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { IconName } from '@ac/web-components';

const HeaderMenuButton: FC<Childless> = () => {
  const presenter = useMenuPanelPresenterContext();

  const showMenu = useCallback(() => {
    presenter.state.isVisible
      ? presenter.hide()
      : presenter.show({
          hideCloseButton: false,
        });
  }, [presenter]);

  return <AcIconButton icon={IconName.menu} onClick={showMenu} />;
};

export default memo(HeaderMenuButton);
