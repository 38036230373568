/* eslint-disable no-console */

type Log =
  | string
  | Record<string, unknown>
  | string[]
  | Array<Record<string, unknown>>
  | undefined
  | null
  | unknown;

export class Logger {
  static async info(log: Log): Promise<void> {
    console.log(log);
  }

  static async warning(log: Log): Promise<void> {
    console.warn(log);
  }

  static async error(log: Log): Promise<void> {
    console.error(log);
  }
}
