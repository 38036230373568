import { Store } from '@hkm/store/interfaces/store';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from './reducers';

const composeEnhancers = composeWithDevTools;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let store: any;

export const getStore = (): Store => store.getState();

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const storeConfiguration = {
    ...createStore(
      createRootReducer(),
      composeEnhancers(applyMiddleware(sagaMiddleware))
    ),
    runSaga: sagaMiddleware.run,
  };

  store = storeConfiguration;

  return store;
}
