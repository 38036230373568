import { FC, memo } from 'react';
import { useAppSpinner } from '@hkm/components/shared/AppSpinner/useAppSpinner';

const AppSpinner: FC = () => {
  useAppSpinner(true);

  return null;
};

export default memo(AppSpinner);
