import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';
import { useGuestServiceMap } from '@hkm/shared/hooks/useGuestServiceMap';

import { DictionaryEntity } from '@ac/library-api';

interface Output {
  activePlannedGuestServices: DictionaryEntity[];
  activeAdHocGuestServices: DictionaryEntity[];
  allPlannedGuestServices: DictionaryEntity[];
  allAdHocGuestServices: DictionaryEntity[];
  activeGuestServices: DictionaryEntity[];
  allGuestServices: DictionaryEntity[];
  filteredGuestServices: (
    excludeStatus?: GuestServiceStatusType[],
    filteredServices?: DictionaryEntity[]
  ) => DictionaryEntity[];
  isServiceActive: (service: GuestServiceStatusType) => boolean;
}

export function useGuestServices(): Output {
  const { t } = useTranslation();
  const enabledStatuses = useGuestServiceMap();
  const effectiveValues = useSelector(selectEffectiveValues);

  const allGuestServices = useMemo((): DictionaryEntity[] => {
    if (!effectiveValues?.guestServiceStatuses) {
      return [];
    }

    return Array.from(enabledStatuses.keys()).map(
      (status: GuestServiceStatusType) =>
        new DictionaryEntity({
          code: status,
          name: t(`GLOBAL.GUEST_SERVICE_STATUS.VALUES.${status}`),
        })
    );
  }, [effectiveValues?.guestServiceStatuses, enabledStatuses, t]);

  const activeGuestServices = useMemo((): DictionaryEntity[] => {
    if (!effectiveValues?.guestServiceStatuses) {
      return [];
    }

    return Array.from(enabledStatuses.keys())
      .map((status: GuestServiceStatusType) => {
        return enabledStatuses.get(status)
          ? new DictionaryEntity({
              code: status,
              name: t(`GLOBAL.GUEST_SERVICE_STATUS.VALUES.${status}`),
            })
          : false;
      })
      .filter(Boolean) as DictionaryEntity[];
  }, [effectiveValues?.guestServiceStatuses, enabledStatuses, t]);

  const filteredGuestServices = useCallback(
    (
      excludeStatus?: GuestServiceStatusType[],
      filteredServices?: DictionaryEntity[]
    ) => {
      return (filteredServices ?? activeGuestServices).filter(
        (status) =>
          !(excludeStatus ?? []).includes(status.code as GuestServiceStatusType)
      );
    },
    [activeGuestServices]
  );

  const allPlannedGuestServices = useMemo(() => {
    return allGuestServices.filter((status) =>
      [
        GuestServiceStatusType.DoNotDisturb,
        GuestServiceStatusType.ServiceRefused,
        GuestServiceStatusType.ServicePreferred,
      ].includes(status.code as GuestServiceStatusType)
    );
  }, [allGuestServices]);

  const allAdHocGuestServices = useMemo(() => {
    return allGuestServices.filter((status) =>
      [
        GuestServiceStatusType.ServiceDeferred,
        GuestServiceStatusType.ServiceRequested,
      ].includes(status.code as GuestServiceStatusType)
    );
  }, [allGuestServices]);

  const activePlannedGuestServices = useMemo(() => {
    return activeGuestServices.filter((status) =>
      [
        GuestServiceStatusType.DoNotDisturb,
        GuestServiceStatusType.ServiceRefused,
        GuestServiceStatusType.ServicePreferred,
      ].includes(status.code as GuestServiceStatusType)
    );
  }, [activeGuestServices]);

  const activeAdHocGuestServices = useMemo(() => {
    return activeGuestServices.filter((status) =>
      [
        GuestServiceStatusType.ServiceDeferred,
        GuestServiceStatusType.ServiceRequested,
      ].includes(status.code as GuestServiceStatusType)
    );
  }, [activeGuestServices]);

  const isServiceActive = useCallback(
    (service: GuestServiceStatusType) => {
      return activeGuestServices
        .map((activeGuestService) => activeGuestService.code)
        .includes(service);
    },
    [activeGuestServices]
  );

  return useMemo(
    () => ({
      allGuestServices,
      activeGuestServices,
      filteredGuestServices,
      activePlannedGuestServices,
      activeAdHocGuestServices,
      allAdHocGuestServices,
      allPlannedGuestServices,
      isServiceActive,
    }),
    [
      allGuestServices,
      activeGuestServices,
      activePlannedGuestServices,
      activeAdHocGuestServices,
      filteredGuestServices,
      allAdHocGuestServices,
      allPlannedGuestServices,
      isServiceActive,
    ]
  );
}
