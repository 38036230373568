/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { PropsWithChildren } from 'react';
import { MenuContext } from '@hkm/features/app/panels/menu/store/context';
import { useMenuStore } from '@hkm/features/app/panels/menu/store/store';

export const MenuContextProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const store = useMenuStore();

  return <MenuContext.Provider value={store}>{children}</MenuContext.Provider>;
};
