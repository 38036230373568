import { createContext } from 'react';
import { MobileFeaturesStore } from '@hkm/features/app/store/features/store';

import { useDefinedContext } from '@ac/react-infrastructure';

export const MobileFeaturesContext = createContext<
  MobileFeaturesStore | undefined
>(undefined);

export const useMobileFeaturesContext = (): MobileFeaturesStore =>
  useDefinedContext(MobileFeaturesContext);
