import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AlertBar } from '@hkm/components/Attendant/shared/components/alertBar/AlertBar';
import {
  selectAssignedSheetState,
  selectIsLoadingOrEditingSheets,
  selectPendingRoomQueue,
  selectServiceRequested,
  selectSheetRooms,
} from '@hkm/components/Attendant/shared/domain/selectors';
import AttendantSheetsSummaryBody from '@hkm/components/Attendant/SheetSummary/body/AttendantSheetsSummaryBody';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import { useFetchAssignedSheetOnNotification } from '@hkm/shared/hooks/useFetchAssignedSheetOnNotification';

import { AttendantTaskProgressType } from '@ac/library-api';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { TextSize, TextWeight } from '@ac/mobile-components/dist/enums';

import './AttendantSheetSummary.css';

const AttendantSheetSummary: React.FC = () => {
  const { t } = useTranslation();

  const sheetRooms = useSelector(selectSheetRooms);
  const showSpinner = useSelector(selectIsLoadingOrEditingSheets);
  const assignedSheetState = useSelector(selectAssignedSheetState);
  const pendingRoomQueue = useSelector(selectPendingRoomQueue);
  const serviceRequestedRooms = useSelector(selectServiceRequested);
  const isSheetInProgress = useMemo(
    () =>
      sheetRooms.some(
        (room) =>
          room.activeTask?.progress?.code !== AttendantTaskProgressType.Pending
      ),
    [sheetRooms]
  );

  useFetchAssignedSheetOnNotification();

  return (
    <>
      <ConnectedHeader showHelpButton={true}>
        <AcText size={TextSize.Main2} weight={TextWeight.Semibold}>
          {t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.TITLE')}
        </AcText>
      </ConnectedHeader>
      <AlertBar
        queueRoomsCount={pendingRoomQueue.length}
        serviceRequestedRoomsCount={serviceRequestedRooms.length}
      />
      <AcBody
        className="attendant-assignments-summary"
        showSpinner={showSpinner}
      >
        {assignedSheetState.data && (
          <AttendantSheetsSummaryBody
            attendantSheet={assignedSheetState.data}
            isSheetInProgress={isSheetInProgress}
          />
        )}
      </AcBody>
    </>
  );
};

export default memo(AttendantSheetSummary);
