import React, { useMemo } from 'react';
import { AttributeDictionary } from '@hkm/components/TaskManagement/relations/domain/interfaces';

import {
  AcBadge,
  AcInfoBadge,
} from '@ac/mobile-components/dist/components/badge';

interface ComponentProps {
  attributesCodes: string[];
  attributes: AttributeDictionary[];
}

export const Preferences = (props: ComponentProps): JSX.Element => {
  const preferences = useMemo(
    () =>
      (props.attributesCodes || []).map((attributeCode) => {
        const preferenceAttribute = (props.attributes || []).find(
          ({ code }) => code === attributeCode
        );

        const attributeColor = preferenceAttribute?.ref_attributeGroupId?.color;

        return {
          id: preferenceAttribute?.id,
          code: preferenceAttribute?.code,
          attributeColor,
        };
      }),
    [props.attributesCodes, props.attributes]
  );

  return (
    <ac-flex wrapped={true}>
      {preferences.map((preference, index) =>
        preference.attributeColor ? (
          <AcBadge
            className="ac-spacing-right-sm ac-spacing-bottom-sm"
            key={preference.id}
            badgeText={preference.code}
            backgroundColor={preference.attributeColor}
            testSelector={`task-preference-${index}`}
          />
        ) : (
          <AcInfoBadge
            className="ac-spacing-right-sm ac-spacing-bottom-sm"
            key={preference.id}
            badgeText={preference.code}
            testSelector={`task-preference-${index}`}
          />
        )
      )}
    </ac-flex>
  );
};
