import React, { FC, memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HousekeepingManagePermissionsConfig } from '@hkm/components/Housekeeping/shared/config/housekeepingManagePermissionsConfig';
import OccupancyDiscrepancyModal from '@hkm/components/shared/OccupancyDiscrepancy/Modal/OccupancyDiscrepancyModal';
import { GuestCount } from '@hkm/shared/interfaces/guestCount';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';
import { RoomOccupancy } from '@hkm/shared/roomOccupancy/roomOccupancy';
import classNames from 'classnames';

import {
  AcButton,
  AcButtonPattern,
  AcButtonShape,
  AcButtonSize,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AlignItems } from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorAttribute } from '@ac/mobile-components/dist/utils';
import { IconName } from '@ac/web-components';

import './OccupancyDiscrepancyButton.css';

export interface HousekeepingOccupancyProps extends Testable {
  occupancy: RoomOccupancy;
  onSave: (count: GuestCount) => void;
  onDelete: () => void;
}
const OccupancyDiscrepancyButton: FC<HousekeepingOccupancyProps> = ({
  testSelector,
  onDelete,
  onSave,
  occupancy,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const removeAreaRef = useRef<HTMLDivElement>(null);
  const hasManageHousekeepingPermission = usePermission(
    ...HousekeepingManagePermissionsConfig
  );

  const onModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const onButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const target = e.target as HTMLElement;
      if (removeAreaRef.current?.contains(target)) {
        onDelete();
      } else {
        setModalOpen(true);
      }
    },
    [onDelete]
  );

  if (!hasManageHousekeepingPermission && !occupancy.hasReport) {
    return null;
  }

  return (
    <>
      {occupancy.canReport && (
        <div className="occupancy-discrepancy">
          <AcFormElement
            label={t('ROOM_DETAILS.HOUSEKEEPING_OCCUPANCY.LABEL')}
            testSelector={testSelector}
          >
            <AcButton
              onClick={onButtonClick}
              testSelector={`${testSelector}-button`}
              pattern={AcButtonPattern.Light}
              size={AcButtonSize.Small}
              shape={AcButtonShape.Rectangle}
              disabled={!hasManageHousekeepingPermission}
            >
              {occupancy.hasReport ? (
                <>
                  <ac-flex
                    alignItems={AlignItems.center}
                    class={classNames({
                      'occupancy-discrepancy-error':
                        occupancy.hasAdultsDiscrepancy,
                    })}
                  >
                    <AcButtonContent
                      icon={
                        occupancy.hasAdultsDiscrepancy
                          ? IconName.todo
                          : IconName.default
                      }
                      text={t('GLOBAL.ADULTS', {
                        count: occupancy.reportedAdults,
                      })}
                    />
                  </ac-flex>
                  <ac-flex
                    alignItems={AlignItems.center}
                    class={classNames('ac-spacing-left-md', {
                      'occupancy-discrepancy-error':
                        occupancy.hasChildrenDiscrepancy,
                    })}
                  >
                    <AcButtonContent
                      icon={
                        occupancy.hasChildrenDiscrepancy
                          ? IconName.todo
                          : IconName.default
                      }
                      text={t('GLOBAL.CHILDREN', {
                        count: occupancy.reportedChildren,
                      })}
                    />
                  </ac-flex>
                  <ac-flex
                    alignItems={AlignItems.center}
                    class="ac-spacing-left-md"
                  >
                    <AcButtonContent icon={IconName.cancel} />
                  </ac-flex>
                  <div
                    ref={removeAreaRef}
                    className="occupancy-discrepancy-remove-area"
                    {...getTestSelectorAttribute(testSelector, 'removeArea')}
                  />
                </>
              ) : (
                <AcButtonContent
                  icon={IconName.plus}
                  text={t('ROOM_DETAILS.HOUSEKEEPING_OCCUPANCY.ADD')}
                />
              )}
            </AcButton>
          </AcFormElement>
          <OccupancyDiscrepancyModal
            open={isModalOpen}
            occupancy={occupancy}
            onClose={onModalClose}
            testSelector={testSelector}
            onSave={onSave}
          />
        </div>
      )}
    </>
  );
};

export default memo(OccupancyDiscrepancyButton);
