import React, { useMemo } from 'react';

import { AcText } from '@ac/mobile-components/dist/components/text';
import { TextWeight } from '@ac/mobile-components/dist/enums';

interface ComponentProps {
  text: string;
  searchValue?: string;
}

const HighlightText = (props: ComponentProps) => {
  const { text, searchValue } = props;

  const textToHighlight = useMemo(() => {
    if (!searchValue) {
      return '';
    }
    const parts = text.split(new RegExp(`(${searchValue})`, 'gi'));

    return (
      <span>
        {' '}
        {parts.map((part, i) => (
          <AcText
            key={i}
            weight={
              part.toLowerCase() === searchValue.toLowerCase()
                ? TextWeight.Semibold
                : TextWeight.Regular
            }
          >
            {part}
          </AcText>
        ))}{' '}
      </span>
    );
  }, [searchValue, text]);

  return <AcText>{textToHighlight}</AcText>;
};

export default HighlightText;
