import * as actions from '@hkm/components/Posting/shared/domain/actions';
import { PostingRoomState } from '@hkm/components/Posting/shared/domain/interfaces';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';
import { ReducerMap } from 'redux-actions';

import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: PostingRoomState = {
  attendantPostingRoomState: {},
  housekeepingPostingRoomState: {},
};

const reducerMap: ReducerMap<PostingRoomState, unknown> = {
  [actions.initializeHousekeepingPostingRoom]: (
    state,
    action: Action<string>
  ) => ({
    ...state,
    housekeepingPostingRoomState: {
      ...state.housekeepingPostingRoomState,
      roomId: action.payload,
    },
  }),
  [actions.setRoomFromDashboard]: (
    state,
    action: Action<HousekeepingRoom>
  ) => ({
    ...state,
    housekeepingPostingRoomState: {
      ...state.housekeepingPostingRoomState,
      room: action.payload,
      didInitialize: true,
    },
  }),
  [actions.fetchHousekeepingPostingRoom.trigger]: (state) => ({
    ...state,
    housekeepingPostingRoomState: {
      ...state.housekeepingPostingRoomState,
      isFetching: true,
    },
  }),
  [actions.fetchHousekeepingPostingRoom.failure]: (state) => ({
    ...state,
    housekeepingPostingRoomState: {
      ...state.housekeepingPostingRoomState,
      isFetching: false,
      didInitialize: true,
    },
  }),
  [actions.fetchHousekeepingPostingRoom.success]: (
    state,
    action: Action<HousekeepingRoom>
  ) => ({
    ...state,
    housekeepingPostingRoomState: {
      ...state.housekeepingPostingRoomState,
      room: {
        ...action.payload,
      },
      isFetching: false,
      didInitialize: true,
    },
  }),
  [actions.initializeAttendantPostingRoom]: (
    state,
    action: Action<string>
  ) => ({
    ...state,
    attendantPostingRoomState: {
      ...state.attendantPostingRoomState,
      roomId: action.payload,
    },
  }),
  [actions.setRoomFromAttendantSheet]: (
    state,
    action: Action<AttendantRoom>
  ) => ({
    ...state,
    attendantPostingRoomState: {
      ...state.attendantPostingRoomState,
      room: action.payload,
      didInitialize: true,
    },
  }),
  [actions.reset]: () => ({
    ...initialState,
  }),
};

export default handleActions(reducerMap, initialState);
