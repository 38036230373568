import i18n from 'i18next';

import { AcNoContentText } from '@ac/mobile-components/dist/components/no-content/acNoContentText';

export function createTranslatedNoContentText(title?: string): AcNoContentText {
  return {
    title: title || i18n.t('GLOBAL.NO_CONTENT.TITLE'),
    description: i18n.t('GLOBAL.NO_CONTENT.DESCRIPTION'),
    filteredDescription: i18n.t('GLOBAL.NO_CONTENT.FILTERED_DESCRIPTION'),
    resetFiltersButton: i18n.t('GLOBAL.NO_CONTENT.RESET_FILTERS_BUTTON'),
  };
}
