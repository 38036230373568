import React, { ElementType, FC, memo } from 'react';
import { RouteProps } from 'react-router';

interface CustomRouteProps {
  component: ElementType;
}

type Props = RouteProps & CustomRouteProps;

const RouteComposeWrapper: FC<Props> = ({ component: Component, ...rest }) => {
  return <Component {...rest} />;
};

export default memo(RouteComposeWrapper);
