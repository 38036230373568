import { SheetSummaryMembership } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryMembership';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { UnifiedReservationGuestMembership } from '@hkm/types/reservation/models/UnifiedReservationGuestMembership';
import { pickAllBySelector } from '@hkm/utils/pickAllBySelector';
import flatten from 'lodash/flatten';

import { IdCodeDescriptionColor } from '@ac/library-api';

const createMembershipLevel = (
  membershipLevel?: IdCodeDescriptionColor
): SheetSummaryMembership['levels'][0] => {
  return {
    id: membershipLevel?.id || '',
    description: membershipLevel?.description || '',
    color: membershipLevel?.color || '',
    count: 1,
  };
};

export const createMembershipsFactory = (
  roomsAssignments: AttendantRoom[]
): SheetSummaryMembership[] => {
  const getAllRoomMemberships = (
    roomAssignment: AttendantRoom
  ): UnifiedReservationGuestMembership[] =>
    flatten(
      pickAllBySelector(
        roomAssignment.currentReservations || [],
        (reservation) => reservation.memberships
      )
    );

  const allMemberships = flatten(
    pickAllBySelector(roomsAssignments, (roomAssignment) =>
      getAllRoomMemberships(roomAssignment)
    )
  );

  return allMemberships.reduce<SheetSummaryMembership[]>((acc, membership) => {
    const existingMembership = acc.find(
      (record) => record.id === membership.membershipType?.id
    );

    if (!existingMembership) {
      return acc.concat({
        id: membership.membershipType?.id || '',
        description: membership.membershipType?.description || '',
        levels: membership.membershipLevel
          ? [createMembershipLevel(membership.membershipLevel)]
          : [],
      });
    }

    if (membership.membershipLevel) {
      const existingMembershipLevel = existingMembership.levels.find(
        (level) => level.id === membership.membershipLevel?.id
      );

      if (!existingMembershipLevel) {
        existingMembership.levels.push(
          createMembershipLevel(membership.membershipLevel)
        );
      } else {
        existingMembershipLevel.count = existingMembershipLevel.count + 1;
      }

      return acc;
    }

    return acc;
  }, []);
};
