import * as actions from '@hkm/components/Menu/PropertySelector/domain/actions';
import {
  ActivePropertyData,
  PropertyStore,
} from '@hkm/components/Menu/PropertySelector/domain/interfaces';
import { ReducerMap } from 'redux-actions';

import { UnitAssignmentListItem } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';
import { LoadingState } from '@ac/mobile-components/dist/enums';

const initialState: PropertyStore = {
  loadingState: LoadingState.Default,
  propertiesList: [],
  activeProperty: undefined,
  businessDate: undefined,
  permissions: [],
  effectiveValues: undefined,
};

const reducerMap: ReducerMap<PropertyStore, unknown> = {
  [actions.properties.success]: (
    state,
    action: Action<UnitAssignmentListItem[]>
  ) => ({
    ...state,
    propertiesList: action.payload,
  }),

  [actions.property.trigger]: (state) => ({
    ...state,
    businessDate: undefined,
    loadingState: LoadingState.Loading,
  }),

  [actions.property.success]: (state, action: Action<ActivePropertyData>) => ({
    ...state,
    activeProperty: action.payload.activeProperty,
    permissions: action.payload.permissions,
    effectiveValues: action.payload.effectiveValues,
    loadingState: LoadingState.Default,
  }),

  [actions.property.failure]: (state) => ({
    ...state,
    activeProperty: undefined,
    permissions: [],
    loadingState: LoadingState.LoadingError,
  }),

  [actions.businessDate.success]: (state, action: Action<string>) => ({
    ...state,
    businessDate: action.payload,
  }),
};

export default handleActions(reducerMap, initialState);
