import * as actions from '@hkm/components/Housekeeping/Batch/domain/actions';
import {
  ChangeBatchRoomsStatusState,
  ChangeRoomStatusResult,
} from '@hkm/components/Housekeeping/Batch/domain/interfaces';
import { combineActions, ReducerMap } from 'redux-actions';

import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: ChangeBatchRoomsStatusState = {
  isPending: false,
  changeRoomsStatusResults: null,
};

const reducerMap: ReducerMap<ChangeBatchRoomsStatusState, unknown> = {
  [actions.changeBatchRoomsStatus.trigger]: (state) => ({
    ...state,
    isPending: true,
  }),
  [actions.changeBatchRoomsStatusResult]: (
    state,
    action: Action<ChangeRoomStatusResult[]>
  ) => ({
    ...state,
    changeRoomsStatusResults: action.payload,
  }),
  [actions.clearBatchRoomsStatusResult]: (state) => ({
    ...state,
    changeRoomsStatusResults: null,
  }),
  [actions.changeSingleRoomStatus.success]: (
    state,
    action: Action<ChangeRoomStatusResult>
  ) => ({
    ...state,
    changeRoomsStatusResults: state.changeRoomsStatusResults?.map((result) => {
      return result.item.id === action.payload.item.id
        ? action.payload
        : result;
    }),
  }),
  [combineActions(
    actions.changeBatchRoomsStatus.failure,
    actions.changeBatchRoomsStatus.success
  ) as never]: (state) => ({
    ...state,
    isPending: false,
  }),
};

export default handleActions(reducerMap, initialState);
