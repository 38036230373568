import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as attendantActions from '@hkm/components/Attendant/shared/domain/actions';
import { selectAssignedSheetState } from '@hkm/components/Attendant/shared/domain/selectors';
import { HousekeepingNotificationEnvelopeContent } from '@hkm/services/signalRClient/envelopeContent/HousekeepingNotificationEnvelopeContent';
import { MaintenanceNotificationEnvelopeContent } from '@hkm/services/signalRClient/envelopeContent/MaintenanceNotificationEnvelopeContent';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { useNotification } from '@hkm/shared/hooks/useNotification';

import { NotificationEnvelope } from '@ac/library-api/dist/types/entities/signalr/notificationEnvelope';

export function useFetchAssignedSheetOnNotification() {
  const dispatch = useDispatch();
  const assignedSheetState = useSelector(selectAssignedSheetState);

  const triggerFetch = useCallback(() => {
    dispatch(attendantActions.fetchAssignedSheet.trigger());
  }, [dispatch]);

  const perRoomChangeCallback = useCallback(
    (
      data?: NotificationEnvelope<
        | MaintenanceNotificationEnvelopeContent
        | HousekeepingNotificationEnvelopeContent
      >
    ) => {
      if (data) {
        const { roomId } = data.notification;
        const hasRoom = assignedSheetState?.data?.roomAssignments.some(
          (room) => room.id === roomId
        );
        if (hasRoom) {
          triggerFetch();
        }
      } else {
        triggerFetch();
      }
    },
    [assignedSheetState, triggerFetch]
  );

  useNotification(SignalREventType.RoomQueueChanged, triggerFetch);
  useNotification(SignalREventType.AssignedTaskChanged, triggerFetch);
  useNotification(SignalREventType.AttendantSheetChanged, triggerFetch);
  useNotification(
    SignalREventType.RoomMaintenanceChanged,
    perRoomChangeCallback
  );
  useNotification(
    SignalREventType.HousekeepingRoomChanged,
    perRoomChangeCallback
  );
}
