import { RoomCategory, RoomType } from '@ac/library-api';
import { AcSelectValue } from '@ac/mobile-components/dist/components/select';

export interface RoomTypesFilterOptions {
  selectedRoomCategoryCodes?: string[];
  list: AcSelectValue[];
  roomCategories: RoomCategory[];
  roomTypes: RoomType[];
}

function roomTypesFilter(options: RoomTypesFilterOptions): AcSelectValue[] {
  const selected = new Set(options.selectedRoomCategoryCodes || []);

  if (selected.size) {
    const categoryIds = new Set(
      options.roomCategories
        .map((category) =>
          selected.has(category.code) ? category.id : undefined
        )
        .filter(Boolean)
    );

    const roomCodes = new Set(
      options.roomTypes
        .map((type) =>
          categoryIds.has(type.roomCategoryId) ? type.code : undefined
        )
        .filter(Boolean)
    );

    return options.list.filter((item) => roomCodes.has(item.value));
  }

  return options.list;
}

export default roomTypesFilter;
