import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SheetsSummaryAccordion from '@hkm/components/Attendant/SheetSummary/body/SheetSummaryAccordion/SheetsSummaryAccordion';

import { AcText } from '@ac/mobile-components/dist/components/text';
import { MobileColor, TextStyle } from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

import './AttendantInstructions.css';

export interface AttendantInstructionsProps {
  instructions?: string;
}

export const AttendantInstructions: React.FC<AttendantInstructionsProps> = (
  props: AttendantInstructionsProps
) => {
  const { t } = useTranslation();

  const renderSubtitle = useMemo(() => {
    const textToRender = props.instructions
      ? props.instructions
      : t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.INSTRUCTIONS.NOT_PROVIDED');

    return (
      <AcText
        fontStyle={props.instructions ? TextStyle.Normal : TextStyle.Italic}
        testSelector="instructions-summary-text"
        color={MobileColor.Gray3}
      >
        {textToRender}
      </AcText>
    );
  }, [t, props.instructions]);

  return (
    <SheetsSummaryAccordion
      icon={IconName.instruction}
      title={t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.INSTRUCTIONS.TITLE')}
      className="instructions-summary"
      renderClampContent={renderSubtitle}
    />
  );
};

export default memo(AttendantInstructions);
