import { MicroFrontendError } from '@hkm/features/microFrontends/errors/MicroFrontendError';
import { PageNavBar } from '@hkm/publicApi/windowObject';

export interface MicroFrontendsState {
  navBar: PageNavBar;
  loading: boolean;
  error: MicroFrontendError | undefined;
}

export function getDefaultMicroFrontendsState(): MicroFrontendsState {
  return {
    navBar: {
      title: undefined,
      showHelpIcon: false,
    },
    loading: true,
    error: undefined,
  };
}
