import { ReservationChargesData } from '@hkm/shared/domain/reservationCharges/models/reservationChargesData';
import { ReservationChargesActions } from '@hkm/shared/domain/reservationCharges/reservationChargesActions';

import { HousekeepingReservationCharges } from '@ac/library-api';
import {
  createInApiAction,
  getOneAction,
} from '@ac/library-utils/dist/redux-utils';

export function createReservationChargesActions(
  prefixResourceName: string
): ReservationChargesActions {
  return {
    postReservationCharges: createInApiAction<
      ReservationChargesData,
      undefined
    >(`${prefixResourceName}/reservationCharges/postCharges`),
    fetchReservationRecentCharges: getOneAction<
      string,
      HousekeepingReservationCharges
    >(`${prefixResourceName}/reservationCharges/fetchResentCharges`),
  };
}
