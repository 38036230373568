import { MappedConfigurationUser } from '@hkm/components/TaskManagement/Dashboard/mappers';
import { SelectedObjectModel } from '@hkm/components/TaskManagement/shared/interfaces/selectedObjectModel';
import { Store } from '@hkm/store/interfaces/store';

import { RawTask } from '@ac/library-api';

export const getIsLoading = (state: Store): boolean =>
  state.tasks.taskDetails.isLoading;
export const getIsLoadingLinkedObject = (state: Store): boolean =>
  state.tasks.taskDetails.isLoadingLinkedObject;
export const getTask = (state: Store): RawTask | undefined =>
  state.tasks.taskDetails.task;
export const getLinkedObject = (
  state: Store
): SelectedObjectModel | undefined => state.tasks.taskDetails.linkedObject;
export const getTaskRelatedUsers = (
  state: Store
): MappedConfigurationUser[] | undefined =>
  state.tasks.taskDetails.relatedUsers;
