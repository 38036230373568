import { useCallback } from 'react';

import {
  BusinessContextUnitIdentifier,
  SepModuleBusinessContextData,
  SepModuleBusinessContextLoader,
} from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import { BusinessContextInContainer as BusinessContextRequiredByReactInfrastructure } from '@ac/react-infrastructure/dist/features/featureSchema/businessContext/base/businessContextInContainer';

interface Output {
  setUp(
    propertyId: string
  ): Promise<BusinessContextRequiredByReactInfrastructure>;
}

export function useBusinessContextInitialization(): Output {
  const load = useCallback(
    async (propertyId?: string): Promise<SepModuleBusinessContextData> => {
      const loader = new SepModuleBusinessContextLoader();

      return await loader.load({
        tenantId: LoginService.authData()?.tenantId,
        propertyId,
      });
    },
    []
  );

  const clearCache = useCallback((): void => {
    // unsupported by mobile app
  }, []);

  const loadBusinessContextData = useCallback(
    async (
      unit: BusinessContextUnitIdentifier
    ): Promise<SepModuleBusinessContextData> => {
      const context = await load(unit.propertyId);

      if (
        !!window.ACP &&
        !!window.ACP.businessContext &&
        !!window.ACP.businessContext.context
      ) {
        window.ACP.businessContext.context = context;
      }

      return context;
    },
    [load]
  );

  const setUp = useCallback(
    async (
      propertyId: string
    ): Promise<BusinessContextRequiredByReactInfrastructure> => {
      const context = await load(propertyId);

      return {
        context,
        loadBusinessContextData,
        clearCache,
      };
    },
    [load, loadBusinessContextData, clearCache]
  );

  return {
    setUp,
  };
}
