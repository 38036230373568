export interface ActionResult<T> {
  error?: unknown;
  item: T;
}

export enum ResultStatus {
  Indefinite = 'Indefinite',
  Success = 'Success',
  Failed = 'Failed',
}

export function getFailedActions<T>(
  items: Array<ActionResult<T>>
): Array<ActionResult<T>> {
  return items.filter((item) => !!item.error);
}

export function getSucceedActions<T>(
  items: Array<ActionResult<T>>
): Array<ActionResult<T>> {
  return items.filter((item) => !item.error);
}

export function hasFailedAction(items: Array<ActionResult<unknown>>): boolean {
  return !!items.find((item) => !!item.error);
}

export function checkActionsStatus(
  items: Array<ActionResult<unknown>> | null | undefined
): ResultStatus {
  if (items) {
    return hasFailedAction(items) ? ResultStatus.Failed : ResultStatus.Success;
  }

  return ResultStatus.Indefinite;
}
