import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { AttendantTaskProgressType } from '@ac/library-api';
import {
  AcDotBadge,
  AcDotBadgeProps,
} from '@ac/mobile-components/dist/components/dot-badge';
import { MobileColor } from '@ac/mobile-components/dist/enums';

interface Props extends AcDotBadgeProps {
  status: AttendantTaskProgressType;
  label?: string;
}

export const taskProgressColorMap = new Map<
  AttendantTaskProgressType,
  MobileColor
>()
  .set(
    AttendantTaskProgressType.InProgress,
    MobileColor.AttendantTaskInProgress
  )
  .set(AttendantTaskProgressType.Skipped, MobileColor.AttendantTaskSkipped)
  .set(AttendantTaskProgressType.Paused, MobileColor.AttendantTaskPaused)
  .set(AttendantTaskProgressType.Completed, MobileColor.AttendantTaskCompleted)
  .set(AttendantTaskProgressType.Pending, MobileColor.AttendantTaskPending);

const AttendantTaskProgressTypeBadge: FC<Props> = ({
  status,
  label,
  className: classNameProp,
  ...rest
}) => {
  const { t } = useTranslation();

  const color: MobileColor =
    taskProgressColorMap.get(status) || MobileColor.Gray4;
  const labelText = label || t(`GLOBAL.TASK_PROGRESS_TYPE.${status}`);

  const className = classNames('attendant-task-type-badge', classNameProp);

  return (
    <AcDotBadge className={className} {...rest} color={color}>
      {labelText}
    </AcDotBadge>
  );
};

export default memo(AttendantTaskProgressTypeBadge);
