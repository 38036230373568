import React, {
  FC,
  memo,
  Ref,
  RefObject,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTaskDashboardManager } from '@hkm/components/TaskManagement/Dashboard/useTaskDashboardManager';
import { TaskDashboardItemWithFlag } from '@hkm/components/TaskManagement/domain/interfaces/taskDashboardItemWithFlag';
import { TaskDetails } from '@hkm/components/TaskManagement/models/taskManagement/index';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import TaskAssignToUserModal from '@hkm/components/TaskManagement/shared/components/Modals/TaskAssignToUserModal/TaskAssignToUserModal';
import TaskChangeStatusModal from '@hkm/components/TaskManagement/shared/components/Modals/TaskChangeStatusModal/TaskChangeStatusModal';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { Permission } from '@hkm/shared/permissions/enum/Permission';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';

import { TaskObjectType, TaskStatusRole, TaskUser } from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import {
  AcButton,
  AcButtonPattern,
  AcButtonSize,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcButtonDropdown } from '@ac/mobile-components/dist/components/button-dropdown';
import {
  AcDropdownItem,
  AcDropdownList,
} from '@ac/mobile-components/dist/components/dropdown-list';
import { IconName } from '@ac/web-components';

interface ComponentProps {
  task: TaskDashboardItemWithFlag | TaskDetails;
  index: number;
  actionsButtonRef?: RefObject<HTMLDivElement>;
}

const DashboardItemActionButton: FC<ComponentProps> = (props) => {
  const { index, actionsButtonRef, task } = props;
  const { saveTaskStatus, assignTaskToUser, unassignTask } =
    useTaskDashboardManager();
  const dataTestSelector = `task-dashboard-item-action-menu-${index}`;
  const { t } = useTranslation();
  const relations = useSelector(getRelationsState);
  const navigate = useNavigate();

  const hasPermissionToAssignUser = usePermission(Permission.TaskAssignUser);
  const hasPermissionToViewAndUpdate = usePermission(
    Permission.TaskViewAndUpdateAll
  );

  const [isChangeStatusModalVisible, setIsChangeStatusModalVisible] =
    useState(false);
  const [isAssignToUserModalVisible, setIsAssignToUserModalVisible] =
    useState(false);

  const isTaskInCancellationStatus = useMemo(() => {
    return task.taskStatus.role === TaskStatusRole.Cancellation;
  }, [task.taskStatus.role]);

  const currentUserId = LoginService.authData()?.userId;

  const isTaskAssignedToCurrentUser = useMemo(() => {
    return task.assignedUser?.id === currentUserId;
  }, [currentUserId, task.assignedUser?.id]);

  const isTaskAssigned = useMemo(() => {
    return !!task.assignedUser?.id;
  }, [task.assignedUser?.id]);

  const isAssignableToOwner = useMemo(() => {
    const taskObjectType = task.objectInfo?.type;
    if (!taskObjectType) {
      return false;
    }
    const isCorrectObjectType = [
      TaskObjectType.Profile,
      TaskObjectType.MeetingsAndEventsBooking,
      TaskObjectType.Profile,
    ].includes(taskObjectType);

    return !!(
      isCorrectObjectType &&
      task.objectInfo?.owner?.userId &&
      task.assignedUser?.id !== task.objectInfo?.owner?.userId
    );
  }, [
    task.assignedUser?.id,
    task.objectInfo?.owner?.userId,
    task.objectInfo?.type,
  ]);

  const closeModals = useCallback(() => {
    setIsChangeStatusModalVisible(false);
    setIsAssignToUserModalVisible(false);
  }, []);

  const displayChangeStatusModal = useCallback(() => {
    setIsChangeStatusModalVisible(true);
  }, []);

  const assignToMe = useCallback(() => {
    assignTaskToUser(task.id);
  }, [assignTaskToUser, task.id]);

  const unassign = useCallback(() => {
    unassignTask(task.id);
  }, [task.id, unassignTask]);

  const assignToOwner = useCallback(() => {
    if (!task.objectInfo?.owner) {
      return;
    }
    assignTaskToUser(task.id, {
      id: task.objectInfo?.owner.userId,
      firstName: '',
      lastName: '',
      userName: '',
    });
  }, [assignTaskToUser, task.id, task.objectInfo?.owner]);

  const displayAssignToUserModal = useCallback(() => {
    setIsAssignToUserModalVisible(true);
  }, []);

  const assignToUser = useCallback(
    (user: TaskUser) => {
      assignTaskToUser(task.id, user);
      closeModals();
    },
    [assignTaskToUser, closeModals, task.id]
  );

  const updateTaskStatus = useCallback(
    async (statusId: string, comment?: string) => {
      saveTaskStatus(task.id, statusId, comment);
      setIsChangeStatusModalVisible(false);
    },
    [saveTaskStatus, task.id]
  );

  const onTaskTap = useCallback(() => {
    navigate(
      generatePath(APP_ROUTES.TASK_MANAGEMENT.TASK_PREVIEW, {
        taskId: task.id,
      })
    );
  }, [navigate, task.id]);

  return (
    <div ref={actionsButtonRef} onClickCapture={(e) => e.preventDefault()}>
      <AcButtonDropdown
        button={(ref) => (
          <AcButton
            className="ac-spacing-left-lg"
            pattern={AcButtonPattern.Tertiary}
            ref={ref as Ref<HTMLButtonElement>}
            contractedTouchArea={true}
            size={AcButtonSize.Small}
            testSelector={dataTestSelector}
          >
            <AcButtonContent icon={IconName.actionMenu} />
          </AcButton>
        )}
        popover={(ref) => (
          <AcDropdownList ref={ref as Ref<HTMLDivElement>}>
            <AcDropdownItem
              onClick={displayChangeStatusModal}
              testSelector="change-status"
            >
              {t('TASK_MANAGEMENT.DASHBOARD.OPTIONS.CHANGE_STATUS')}
            </AcDropdownItem>
            {hasPermissionToViewAndUpdate &&
              !isTaskAssignedToCurrentUser &&
              !isTaskInCancellationStatus && (
                <AcDropdownItem
                  onClick={assignToMe}
                  testSelector="assign-to-me"
                >
                  {t('TASK_MANAGEMENT.DASHBOARD.OPTIONS.ASSIGN_TO_ME')}
                </AcDropdownItem>
              )}
            {hasPermissionToAssignUser &&
              hasPermissionToAssignUser &&
              isTaskAssigned &&
              !isTaskInCancellationStatus && (
                <AcDropdownItem
                  onClick={unassign}
                  testSelector="remove-assignee"
                >
                  {t('TASK_MANAGEMENT.DASHBOARD.OPTIONS.REMOVE_ASSIGNEE')}
                </AcDropdownItem>
              )}
            {hasPermissionToAssignUser &&
              isAssignableToOwner &&
              !isTaskInCancellationStatus && (
                <AcDropdownItem
                  onClick={assignToOwner}
                  testSelector="assign-to-owner"
                >
                  {t('TASK_MANAGEMENT.DASHBOARD.OPTIONS.ASSIGN_TO_OWNER')}
                </AcDropdownItem>
              )}
            {hasPermissionToViewAndUpdate &&
              hasPermissionToAssignUser &&
              !isTaskInCancellationStatus && (
                <AcDropdownItem
                  onClick={displayAssignToUserModal}
                  testSelector="assign-to-user"
                >
                  {t('TASK_MANAGEMENT.DASHBOARD.OPTIONS.ASSIGN_TO_USER')}
                </AcDropdownItem>
              )}
            <AcDropdownItem
              className="menu-border-top"
              testSelector="view-details"
              onClick={onTaskTap}
            >
              {t('TASK_MANAGEMENT.DASHBOARD.OPTIONS.VIEW_DETAILS')}
            </AcDropdownItem>
          </AcDropdownList>
        )}
      />
      <TaskChangeStatusModal
        isVisible={isChangeStatusModalVisible}
        statuses={relations.taskStatuses}
        currentStatusId={task.taskStatus.id}
        onChange={updateTaskStatus}
        onClose={closeModals}
      />
      <TaskAssignToUserModal
        isVisible={isAssignToUserModalVisible}
        onChange={assignToUser}
        onClose={closeModals}
      />
    </div>
  );
};

export default memo(DashboardItemActionButton);
