import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';

import {
  BusinessContextUnitType,
  IPropertyWebSocketClients,
  NotificationChannel,
  NotificationEnvelope,
  WebSocketFactory,
} from '@ac/library-api';

interface NotificationChannels {
  [signalrEventType: string]: NotificationChannel;
}

export let signalrCurrentPropertyId: string;
let signalrClient: IPropertyWebSocketClients;
// NOTE: signalrClient's subscribe now returns communication channel, that is required for 'unsubscribe()'
const notificationChannels: NotificationChannels = {};

export const setSignalRClient = (propertyId: string) => {
  signalrClient = new WebSocketFactory().create({
    unitType: BusinessContextUnitType.Property,
    unitId: propertyId,
  });
  propertyId = signalrCurrentPropertyId;
};

export const signalrSubscribe = async (
  signalrEventType: SignalREventType,
  handler: (envelope: NotificationEnvelope) => void
) => {
  const notificationChannel = await signalrClient.currentProperty.subscribe(
    {
      notificationType: signalrEventType,
    },
    handler
  );
  notificationChannels[signalrEventType] = notificationChannel;
};

export const signalrUnsubscribe = (signalrEventType: SignalREventType) => {
  notificationChannels[signalrEventType]?.unsubscribe();
};
