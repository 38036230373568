import { HousekeepingViewPermissions } from '@hkm/components/Housekeeping/shared/config/housekeepingViewPermissionsConfig';
import { Permission } from '@hkm/shared/permissions/enum/Permission';
import { PermissionConjunction } from '@hkm/shared/permissions/enum/PermissionConjunction';
import { PermissionsConfig } from '@hkm/shared/permissions/types/permissionsConfig';

export const MaintenancePermissions = [
  ...HousekeepingViewPermissions,
  Permission.HousekeepingViewMaintenance,
] as const;

export const MaintenanceViewPermissionsConfig: PermissionsConfig = [
  MaintenancePermissions,
  PermissionConjunction.All,
];
