import { MaintenanceAttachmentsState } from '@hkm/shared/domain/maintenanceAttachment/uploadAttachement/models/maintenanceUploadAttachmentState';
import { Store } from '@hkm/store/interfaces/store';
import { createSelector } from 'reselect';

export const selectMaintenanceAttachmentsState = (
  state: Store
): MaintenanceAttachmentsState => state.maintenanceAttachments;

export const selectMaintenanceAttachmentsFilesMap = createSelector(
  selectMaintenanceAttachmentsState,
  (state) => state.loadedFiles
);
