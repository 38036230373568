import { TasksGroup } from '@hkm/components/TaskManagement/models/groups';
import { trimString } from '@hkm/components/TaskManagement/utils/trimString';
import i18n from 'i18next';

import {
  DashboardGroupLinkedProfiles,
  DashboardGroupRoomNights,
  DashboardGroupStatus,
  QueryQueryParams,
} from '@ac/library-api';
import { GroupApi } from '@ac/library-api/dist/api/v0/booking/groups';

export const findGroups = async (
  params?: QueryQueryParams
): Promise<TasksGroup[]> => {
  let queryParams = {};
  if (params) {
    const { filter, query } = params;
    queryParams = filter ? { filter, ...queryParams } : { ...queryParams };
    queryParams = query ? { query, ...queryParams } : { ...queryParams };
  }

  return (
    await GroupApi.getDashboard({
      queryParams,
    })
  ).data.results.map((group) => mapGroup(normalizeGroup(group)));
};

const normalizeGroup = ({
  id,
  key,
  name,
  status,
  linkedProfiles,
  startDate,
  endDate,
  cutoffDays,
  cutoffDate,
  decisionDate,
  nextStepDueDate,
  roomNights,
}: {
  id: string;
  key?: string;
  name?: string;
  status?: DashboardGroupStatus;
  linkedProfiles?: DashboardGroupLinkedProfiles;
  startDate: string;
  endDate: string;
  cutoffDays?: number;
  cutoffDate?: string;
  decisionDate?: string;
  nextStepDueDate?: string;
  roomNights?: DashboardGroupRoomNights;
}): TasksGroup => {
  return {
    id,
    key,
    name,
    status,
    linkedProfiles,
    startDate,
    endDate,
    cutoffDays,
    cutoffDate,
    decisionDate,
    nextStepDueDate,
    roomNights,
    displayName: '',
  };
};

const mapGroup = (group: TasksGroup): TasksGroup => {
  const groupName = group.name
    ? `${i18n.t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.NAME')} ${
        group.name
      },`
    : '';
  const groupKey = group.key
    ? `${i18n.t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.CODE')} ${group.key},`
    : '';
  const profileName = group.linkedProfiles
    ? `${i18n.t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.PROFILE')} ${
        group.linkedProfiles.guest?.lastName ||
        group.linkedProfiles.travelAgent?.name ||
        group.linkedProfiles.company?.name
      },`
    : '';
  const stayDates = `${i18n.t(
    'TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.START_END_DATE'
  )} ${group.startDate} / ${group.endDate},`;
  const resStatus = group.status
    ? `${i18n.t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.STATUS')} ${
        group.status.code
      }`
    : '';

  return {
    ...group,
    displayName: trimString(
      `${groupName} ${groupKey} ${profileName} ${stayDates} ${resStatus}`
    ),
  };
};
