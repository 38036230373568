import { TaskDashboardItemWithFlag } from '@hkm/components/TaskManagement/domain/interfaces/taskDashboardItemWithFlag';
import { LocalizedDictionary } from '@hkm/components/TaskManagement/relations/domain/interfaces';
import DateTimeHelpers from '@hkm/components/TaskManagement/utils/dateTime';
import { getLocalizedString } from '@hkm/utils/getLocalizedString';

import {
  Attribute,
  ConfigurationUser,
  Department,
  GroupStatus,
  GuestStatus,
  LocationEntity,
  MembershipLevel,
  ProfileType,
  RawConfigurationUser,
  TaskDashboardItem,
  TaskPriority,
  TaskStatus,
} from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';

export const mapDashboardItem = (
  task: TaskDashboardItem,
  propertyTimezone: string
): TaskDashboardItemWithFlag => {
  return {
    ...task,
    isOverdue: DateTimeHelpers.isDateTimeOverdue(
      propertyTimezone,
      task.dueDate,
      task.dueTime,
      task.taskStatus.role
    ),
    hasAssignee: !!task.assignedUser,
    isAssignedToLoggedUser:
      task.assignedUser?.id === LoginService.authData()?.userId,
    taskStatusDescription: task.taskStatus.description,
  };
};

export interface MappedConfigurationUser extends ConfigurationUser {
  userFullName: string;
}

export const mapUsersToFullName = (
  users: RawConfigurationUser[]
): MappedConfigurationUser[] => {
  const mappedUsers = users?.map((user: ConfigurationUser) => {
    return {
      ...user,
      userFullName: `${user.personalData?.firstName} ${user.personalData?.lastName}`,
    };
  });

  return mappedUsers || [];
};

type Dictionaries =
  | TaskStatus
  | Department
  | TaskPriority
  | MembershipLevel
  | GroupStatus
  | LocationEntity
  | ProfileType
  | GuestStatus
  | Attribute;

export const mapToLocalizedDescriptionDisplayName = (
  list: Dictionaries[]
): LocalizedDictionary[] => {
  return list.map((element: Dictionaries) => {
    const description = getLocalizedString(element.description);

    return {
      ...element,
      displayName: description ? description : element.code,
    };
  }) as LocalizedDictionary[];
};
