import appConstants from '@hkm/constants/app.constants';
import i18n from '@hkm/i18n';

import { HousekeepingCustomerSettingsKeys } from '@ac/library-api';
import { SessionService } from '@ac/library-utils/dist/services';

interface SupportedLanguage {
  languageCode: string | undefined;
  supportedLanguages: string[];
}

interface BusinessContext {
  user: {
    preferences?: {
      preferences?: {
        defaultLanguage?: string;
      };
    };
  };
  property?: {
    details?: {
      defaultLanguageCode?: string;
    };
    settings?: {
      effectiveSettingsDetails?: Array<{
        code?: string;
        value?: {
          value?: SupportedLanguage;
        };
      }>;
    };
  };
}

export function setUpLanguage(context: BusinessContext | undefined): void {
  const settings = context?.property?.settings?.effectiveSettingsDetails ?? [];
  const setting = settings.find(
    ({ code }) =>
      code ===
      HousekeepingCustomerSettingsKeys.SupportedHousekeepingMobileLanguages
  );
  const supportedLanguages = (
    setting?.value?.value?.supportedLanguages ?? []
  ).map((lang) => lang.toUpperCase());
  const defaultLanguage = (
    setting?.value?.value?.languageCode ?? appConstants.FALLBACK_LANGUAGE
  ).toUpperCase();
  const selectedLanguage = (
    SessionService.getLanguage() ??
    context?.user.preferences?.preferences?.defaultLanguage ??
    context?.property?.details?.defaultLanguageCode ??
    setting?.value?.value?.languageCode ??
    appConstants.FALLBACK_LANGUAGE
  ).toUpperCase();

  const language = supportedLanguages.includes(selectedLanguage)
    ? selectedLanguage
    : defaultLanguage;

  SessionService.setLanguage(language);
  void i18n.changeLanguage(language);
}
