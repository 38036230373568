import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectedFooter from '@hkm/components/shared/LayoutComponents/ConnectedFooter';
import APP_ROUTES from '@hkm/constants/routing.constants';

import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFooterWideButtons } from '@ac/mobile-components/dist/components/layout';
import { AcWrappedLink } from '@ac/mobile-components/dist/components/wrapped-link';
import { FlexDirection } from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

import './DashboardFiltersFooter.css';

interface ComponentProps {
  onFiltersApply: () => void;
}

const DashboardFiltersFooter: FC<ComponentProps> = (props) => {
  const { t } = useTranslation();

  return (
    <ConnectedFooter className="advanced-filters-footer" noPadding={true}>
      <ac-flex direction={FlexDirection.column}>
        <AcFooterWideButtons>
          <AcButton
            testSelector="task-dashboard-filters-apply"
            onClick={props.onFiltersApply}
          >
            <AcButtonContent text={t('GLOBAL.APPLY_AND_CLOSE')} />
          </AcButton>

          <AcWrappedLink link={APP_ROUTES.TASK_MANAGEMENT.DASHBOARD}>
            <AcButton
              className="advanced-filters-footer-wrapped-button"
              pattern={AcButtonPattern.Tertiary}
              testSelector="task-dashboard-filters-cancel"
            >
              <AcButtonContent
                text={t('GLOBAL.CANCEL')}
                icon={IconName.cancel}
              />
            </AcButton>
          </AcWrappedLink>
        </AcFooterWideButtons>
      </ac-flex>
    </ConnectedFooter>
  );
};

export default memo(DashboardFiltersFooter);
