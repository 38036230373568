import { activeReservationStates } from '@hkm/shared/reservations/activeReservationStatuses';

import { ReservationStatus } from '@ac/library-api';
import { API_TIME_FORMAT, DateUtils } from '@ac/library-utils/dist/utils';

interface Reservation {
  status: ReservationStatus;
  arrivalDate: string;
  eta?: string;
  isDayUse: boolean;
}

// TODO: Check why reservations are mapped to "ReservedKindReservation"???
export function getCurrentMainReservation<T extends Reservation>(
  sortedReservations: T[],
  propertyCheckInTime?: string,
  propertyCheckOutTime?: string
): T | undefined {
  const getReservationArrivalDateTime = (reservation: T): string => {
    if (reservation.eta) {
      return DateUtils.serialization.formatDateTimeForApi(reservation.eta);
    }

    const checkOutTime = DateUtils.time.setTime(
      reservation.arrivalDate ?? '',
      propertyCheckOutTime ?? '00:00:00',
      API_TIME_FORMAT
    );

    const checkInTime = DateUtils.time.setTime(
      reservation.arrivalDate ?? '',
      propertyCheckInTime ?? '00:00:00',
      API_TIME_FORMAT
    );

    return reservation.isDayUse
      ? DateUtils.serialization.formatDateTimeForApi(checkOutTime)
      : DateUtils.serialization.formatDateTimeForApi(checkInTime);
  };

  const getTime = (date: string): number => {
    return new Date(date).getTime();
  };

  const inHouseReservation = sortedReservations.find(
    (reservation) => reservation.status === ReservationStatus.IH
  );

  return (
    inHouseReservation ??
    sortedReservations
      .filter((reservation) => activeReservationStates.has(reservation.status))
      .sort((reservation1, reservation2) => {
        return (
          getTime(getReservationArrivalDateTime(reservation1)) -
          getTime(getReservationArrivalDateTime(reservation2))
        );
      })[0]
  );
}
